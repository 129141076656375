import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, PageBlockFragment } from '@hooks/api/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  width: 100%;
  
    height: initial;

    @media (min-width: 30em) {
      height: 78.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 17.500vw;
    }

    @media (min-width: 90em) {
      height: 252px;
    }
  
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    gap: initial;

    @media (min-width: 30em) {
      gap: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.667vw;
    }

    @media (min-width: 90em) {
      gap: 24px;
    }
  
  
    align-items: center;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: center;
    }

    @media (min-width: 90em) {
      align-items: center;
    }
  ;
  
    padding: 5.000vw 0.000vw 0.000vw 0.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 16px;
    }
  
  background-color: ${({ backgroundColor }): string => backgroundColor};

`

const IconContainer = styled.div`
  
    width: 46.875vw;

    @media (min-width: 30em) {
      width: 87.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 19.444vw;
    }

    @media (min-width: 90em) {
      width: 280px;
    }
  
  
    height: 46.875vw;

    @media (min-width: 30em) {
      height: 93.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 20.833vw;
    }

    @media (min-width: 90em) {
      height: 300px;
    }
  
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  

`

export interface PageAwardBlockProps {
  block: PageBlockFragment
}

export function PageAwardBlock({ block }: PageAwardBlockProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum
  const { image, backgroundColor, description, foregroundColor, title } = block

  return (
    <Container backgroundColor={backgroundColor}>
      <IconContainer>
        <ResponsiveImage image={image} objectFit='fill' />
      </IconContainer>
      <ContentContainer>
        <Heading
          variant={appData.app.deviceType === MOBILE ? 'h3' : 'h1'}
          color={foregroundColor}
          align={appData.app.deviceType === MOBILE ? 'center' : 'left'}>
          {title}
        </Heading>
        <HTML align={appData.app.deviceType === MOBILE ? 'center' : 'left'}>
          {description}
        </HTML>
      </ContentContainer>
    </Container>
  )
}
