import React, { useEffect } from 'react'

import Cookies from 'js-cookie'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/index'
import { Button, Heading, LocalIconEnums, Message, Paragraph, Seperator } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SuccessResponse as ReactFacebookLoginInfo, FailResponse as ReactFacebookLoginFail } from '@greatsumini/react-facebook-login'
import { useGetAppQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { useForm, LogInModalDisplayType, LoginModalDisplayStatus, SocialLoginData, SocialLoginType, Form, TextInput } from '@molecules/index'
import { FacebookLoginButton, GoogleLoginButton, NativeFacebookLoginButton, NativeGoogleLoginButton } from '@organisms/index'
import { CredentialResponse, TokenResponse, useGoogleOneTapLogin } from '@react-oauth/google'
import { MobileOSTypeEnum, SsoProviderEnum } from '@uctypes/api/globalTypes'

export interface Credentials {
  email: string
  password: string
}

const Container = styled.div`
  width: 100%;

  .form-button {
    width: 100%;
    
    margin: 3.750vw 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 3.750vw 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.833vw 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 12px 0 12px 0;
    }
  
  }

  .title {
    
    margin: 0 0 4.375vw 0;

    @media (min-width: 30em) {
      margin: 0 0 4.375vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.972vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 14px 0;
    }
  
    font-weight: 700;
  }

  .input {
    
    margin-bottom: 2.813vw;

    @media (min-width: 30em) {
      margin-bottom: 2.813vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.625vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 9px;
    }
  
  }

  .messageClass{
    
    margin-bottom: 6.250vw;

    @media (min-width: 30em) {
      margin-bottom: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.389vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 20px;
    }
  
  }
`

const SocialButtons = styled.div`
  display: flex;
  position: relative;
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
  

  .platform-button {
    flex-grow: 1;
    width: 50%;
  }

  .loader {
    margin: auto;
    
    height: 10.000vw;

    @media (min-width: 30em) {
      height: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.222vw;
    }

    @media (min-width: 90em) {
      height: 32px;
    }
  
    
    width: 10.000vw;

    @media (min-width: 30em) {
      width: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.222vw;
    }

    @media (min-width: 90em) {
      width: 32px;
    }
  
  }
`

const SocialButtonsOverlay = styled.div`
   position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 1.875vw;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
  
    height: 21.250vw;

    @media (min-width: 30em) {
      height: 21.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.722vw;
    }

    @media (min-width: 90em) {
      height: 68px;
    }
  
  
    margin-bottom: -7.500vw;

    @media (min-width: 30em) {
      margin-bottom: -7.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: -1.667vw;
    }

    @media (min-width: 90em) {
      margin-bottom: -24px;
    }
  

`
export interface LogInData {
  email: string
  password: string
}

export interface SocialLogInData {
  provider: string
  accessToken: string
  accessType: string
}

export interface LogInFormProps {
  onLogInWithCredentials: (credentials: Credentials) => void
  onSocialLogin: (data: SocialLoginData) => void
  onSetDisplayType: (displayType: LogInModalDisplayType) => void
  onSocialLoginError: (error: string) => void
  displayStatus: LoginModalDisplayStatus
  errorMessage?: string
}

export function LogInForm({ onLogInWithCredentials, onSocialLogin, onSocialLoginError, onSetDisplayType, displayStatus, errorMessage }: LogInFormProps): JSX.Element {

  const form = useForm()
  const theme = useTheme()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApp = appData.app.isNativeApp
  const isApple = appData.app.mobileOSType === MobileOSTypeEnum.APPLE

  const _handleLogin = async (credentials: Credentials): Promise<void> => {
    onLogInWithCredentials(credentials)
  }

  const _handleGoogleOneTapLogIn = async (user: CredentialResponse): Promise<void> => {
    const token = user?.credential
    _handleSocialLogin(token, SsoProviderEnum.GOOGLE_ONE_TAP, 'id_token')
  }

  const _handleNativeGoogleLogin = async (token: string): Promise<void> => {
    const provider = isApple ? SsoProviderEnum.GOOGLE_IOS : SsoProviderEnum.GOOGLE_ANDROID
    onSocialLogin({ token, provider, accessType: 'id_token', loginType: SocialLoginType.LOG_IN })
  }

  const _handleNativeFacebookLogin = async (token: string): Promise<void> => {
    onSocialLogin({ token, provider: SsoProviderEnum.FACEBOOK, accessType: '', loginType: SocialLoginType.LOG_IN })
  }

  const _handleGoogleLogIn = async (user: TokenResponse): Promise<void> => {
    const token = user?.access_token
    _handleSocialLogin(token, SsoProviderEnum.GOOGLE)
  }

  const _handleFacebookLogIn = async (res: ReactFacebookLoginInfo): Promise<void> => {
    const token = res?.accessToken
    _handleSocialLogin(token, SsoProviderEnum.FACEBOOK)
  }

  const _handleSocialLogin = async (token: string, provider: SsoProviderEnum, accessType?: string): Promise<void> => {
    onSocialLogin({ token, provider, accessType, loginType: SocialLoginType.LOG_IN })
  }

  const _handleKeyDown = (e: KeyboardEvent): void => {
    const charCode = e.key || e.code
    if (charCode === 'Enter') {
      form.submit()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', _handleKeyDown)
    return () => document.removeEventListener('keydown', _handleKeyDown)
  }, [])

  useEffect(() => {
    if (Cookies.get('email')) {
      form.setFieldsValue({ email: Cookies.get('email') })
      form?.focus?.('password')
    } else {
      form?.focus?.('email')
    }
  }, [])

  useGoogleOneTapLogin({
    onSuccess: user => _handleGoogleOneTapLogIn(user),
    onError: () => onSocialLoginError('Google One Tap Login Failed'),
  })

  const buttonTitle = displayStatus === LoginModalDisplayStatus.INPUT || displayStatus === LoginModalDisplayStatus.LOADING || displayStatus === LoginModalDisplayStatus.ERROR
    ? 'LOGIN'
    : ''
  const buttonIcon = displayStatus === LoginModalDisplayStatus.SUCCESS
    ? LocalIconEnums.CHECK
    : false

  const loading = displayStatus === LoginModalDisplayStatus.LOADING
  const disabled = displayStatus === LoginModalDisplayStatus.SUCCESS

  return (
    <Container>
      <Heading className='title' variant='h3' >Login</Heading>
      <Form form={form} onFinish={_handleLogin} loading={loading} disabled={disabled}>
        <TextInput
          variant='email'
          showLabel
          label='Email address'
          name='email'
          placeholder='Email address'
          rules={[{ required: true, message: 'Please enter an email address' }]} className='input' />
        <TextInput
          variant='password'
          showLabel
          label='Password'
          name='password'
          placeholder='Password'
          rules={[{ required: true, message: 'Please enter a password' }]} className='input' />
        <If condition={displayStatus === LoginModalDisplayStatus.ERROR}>
          <Message
            wrapperClassName='messageClass'
            backgroundColor={theme.colors.pink.bridesmaid}
            color={theme.colors.red.cinnabar}
            message={errorMessage} />
        </If>
        <Button
          variant='text'
          size='medium'
          title='Forgot password?'
          disabled={loading || disabled}
          onClick={() => onSetDisplayType(LogInModalDisplayType.FORGOT_PASSWORD)} />
        <Button
          loading={loading}
          variant='primary'
          fullWidth
          className='form-button'
          title={buttonTitle}
          onClick={() => disabled ? null : form.submit()}
          icon={buttonIcon} />
        <Seperator align='horizontal' color={theme.colors.white.pampas} />
        <SocialButtons>
          <Choose>
            <When condition={isNativeApp}>
              <NativeGoogleLoginButton
                onSuccess={_handleNativeGoogleLogin}
                onError={(error: TokenResponse) => onSocialLoginError(error.error_description)} />
            </When>
            <Otherwise>
              <GoogleLoginButton
                onSuccess={_handleGoogleLogIn}
                onError={(error: TokenResponse) => onSocialLoginError(error.error_description)} />
            </Otherwise>
          </Choose>
          <Choose>
            <When condition={isNativeApp}>
              <NativeFacebookLoginButton
                onSuccess={_handleNativeFacebookLogin}
                onError={(error: TokenResponse) => onSocialLoginError(error.error_description)} />
            </When>
            <Otherwise>
              <FacebookLoginButton
                onSuccess={_handleFacebookLogIn}
                onFail={(error: ReactFacebookLoginFail) => {
                  const errorMessage = error.status === 'loginCancelled'
                    ? 'User cancelled the request'
                    : 'Facebook internal error, please try again later'
                  onSocialLoginError(errorMessage)
                }} />
            </Otherwise>
          </Choose>
          <If condition={loading || disabled}>
            <SocialButtonsOverlay />
          </If>
        </SocialButtons>
      </Form>
      <Footer>
        <Paragraph
          display='inline'
          align='center'
          variant='p2'>
            Not part of the Faithful community?
        </Paragraph>
        <Button
          loading={loading || disabled}
          variant='text'
          size='medium'
          title='Sign Up'
          onClick={() => disabled ? null : onSetDisplayType(LogInModalDisplayType.SIGN_UP)} />
      </Footer>
    </Container>
  )
}
