import React, { useEffect, useRef, useState } from 'react'

import { decode } from 'html-entities'
import update from 'react-addons-update'
import styled, { LayoutSizeClass, useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Pagination } from '@atoms/controls/Pagination'
import { Button, PercentageBar, Rating, Heading, Paragraph, Tag, Card, LayoutCol, LayoutGrid, LayoutRow, Skeleton, SkeletonNode, Loader } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, ProductReviewFragment, useGetProductReviewsQuery, ProductDetailsFragment, useGetAppQuery } from '@hooks/api/index'
import { useLoadingData } from '@hooks/UseLoadingData'
import { SiteHelper } from '@lib/SiteHelper'
import { Form, RadioInput, SelectInput, useForm } from '@molecules/inputs'
import { RatingModal } from '@molecules/modals'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, DeviceTypeEnum, ReviewSortInput, ReviewSortOrderEnum, SortEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductReview } from './ProductReview'

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
  
  .review-filter-radio {
    
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
  }
`

const OuterContainer = styled.div`
  
    margin-bottom: 3.750vw;

    @media (min-width: 30em) {
      margin-bottom: initial;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: initial;
    }

    @media (min-width: 90em) {
      margin-bottom: initial;
    }
  

  .review-title {
    
    margin-left: 4.688vw;

    @media (min-width: 30em) {
      margin-left: initial;
    }

    @media (min-width: 30.0625em) {
      margin-left: initial;
    }

    @media (min-width: 90em) {
      margin-left: initial;
    }
  
    
    margin: 0 0 3.125vw 0.000vw;

    @media (min-width: 30em) {
      margin: 0 0 6.250vw 0.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 1.389vw 0.000vw;
    }

    @media (min-width: 90em) {
      margin: 0 0 20px 0px;
    }
  
  }
  .no-review{
    
    margin: 5.000vw 0 3.125vw 0;

    @media (min-width: 30em) {
      margin: 5.000vw 0 3.125vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0 1.111vw 0;
    }

    @media (min-width: 90em) {
      margin: 16px 0 16px 0;
    }
  
  }
  .title {
    
    margin: 0 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 0 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 12px 0;
    }
  
  }
  .copy {
    
    margin: 0 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 0 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 12px 0;
    }
  
  }
  .button {
    
    margin: 0 auto;

    @media (min-width: 30em) {
      margin: 0 auto;
    }

    @media (min-width: 30.0625em) {
      margin: 0 auto;
    }

    @media (min-width: 90em) {
      margin: 0 auto;
    }
  
  }
`

const Summary = styled.div`
  position: relative;
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  

  .rate-button {
    flex-shrink: 0;
    
    margin-top: 3.750vw;

    @media (min-width: 30em) {
      margin-top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-top: 12px;
    }
  
    
    margin-top: 0;

    @media (min-width: 30em) {
      margin-top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-top: 12px;
    }
  
    
    width: initial;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 100%;
    }

    @media (min-width: 90em) {
      width: 100%;
    }
  
  }
`

const RatingWrapper = styled.div`
  display: flex;
  align-items:flex-end;
  justify-content: flex-start;
  
    margin-bottom: 3.750vw;

    @media (min-width: 30em) {
      margin-bottom: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 12px;
    }
  
`

const SummaryHeader = styled.div`
  display: flex;
  justify-content:  space-between;
  
    margin-bottom: 1.563vw;

    @media (min-width: 30em) {
      margin-bottom: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 5px;
    }
  

  
    flex-grow: 1;

    @media (min-width: 30em) {
      flex-grow: initial;
    }

    @media (min-width: 30.0625em) {
      flex-grow: initial;
    }

    @media (min-width: 90em) {
      flex-grow: initial;
    }
  
  
    gap: 1.563vw;

    @media (min-width: 30em) {
      gap: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.347vw;
    }

    @media (min-width: 90em) {
      gap: 5px;
    }
  

  .rating-summary {
    margin: 0;
  }
`

const Reviews = styled.div`
  position: relative;
  
    padding: 0;

    @media (min-width: 30em) {
      padding: 6.875vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 1.528vw 0;
    }

    @media (min-width: 90em) {
      padding: 22px 0;
    }
  
  
    margin: -5.000vw 5.000vw;

    @media (min-width: 30em) {
      margin: 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  

  .more-button {
    font-weight: 700;
    width: 100%;
    cursor: pointer;
  }
`

const Strong = styled.span`
  font-weight: 600;
`

const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 0;

    @media (min-width: 30em) {
      padding: 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0;
    }

    @media (min-width: 90em) {
      padding: 0;
    }
  ;
  
    margin-bottom: 3.750vw;

    @media (min-width: 30em) {
      margin-bottom: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 12px;
    }
  ;

  .result-size {
    margin: 0;
  }
  .result-select {
    margin: 0;
  }
  .showAll {
    font-weight: 700;
    cursor: pointer;
    
    margin: 0;

    @media (min-width: 30em) {
      margin: 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }
`

const List = styled.div`

`

const ReviewsContainer = styled.div`
    
    position: initial;

    @media (min-width: 30em) {
      position: relative;
    }

    @media (min-width: 30.0625em) {
      position: relative;
    }

    @media (min-width: 90em) {
      position: relative;
    }
  
    
    z-index: initial;

    @media (min-width: 30em) {
      z-index: 2;
    }

    @media (min-width: 30.0625em) {
      z-index: 2;
    }

    @media (min-width: 90em) {
      z-index: 2;
    }
  
`

const RatingItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 1.875vw;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
  
    margin-left: 1.875vw;

    @media (min-width: 30em) {
      margin-left: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.417vw;
    }

    @media (min-width: 90em) {
      margin-left: 6px;
    }
  
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
    min-height: 50vh;

    @media (min-width: 30em) {
      min-height: 100%;
    }

    @media (min-width: 30.0625em) {
      min-height: 100%;
    }

    @media (min-width: 90em) {
      min-height: 100%;
    }
  
  background-color: ${(props) => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5)};
`

export interface ProductReviewsProps {
  product: ProductDetailsFragment
}

export interface FormValues {
  stars: number
}

enum ViewEnum {
  Skeleton = 'SKELETON',
  NoReviews = 'NO_REVIEWS',
  Reviews = 'REVIEWS',
}

const REVIEWS_PER_PAGE = 5

interface ProductReviewsState {
  tempPage: number
  currentPage: number
  limit: number
  skip: number
  sort: ReviewSortInput
  showModal: boolean
  starFilter: { [k: string]: string | string[] }
  canShowMore: boolean
  shownReviewCount?: number
  view: ViewEnum
  isPageLoading: boolean
}

const DEFAULT_STATE: ProductReviewsState = {
  tempPage: 1,
  currentPage: 1,
  limit: 5,
  skip: 0,
  sort: {
    order: ReviewSortOrderEnum.RECENT,
    direction: SortEnum.DESC,
  },
  showModal: false,
  starFilter: null,
  canShowMore: true,
  shownReviewCount: 0,
  view: ViewEnum.Skeleton,
  isPageLoading: false,
}

const timeout: any = null

export function ProductReviews({ product }: ProductReviewsProps): JSX.Element {

  const ratingForm = useForm()
  const orderForm = useForm()
  const [state, setState] = useState<ProductReviewsState>({ ...DEFAULT_STATE })
  const { data: customerData } = useCustomerQuery()
  const { data: reviewData, loading: reviewsLoading, refetch, fetchMore } = useGetProductReviewsQuery({
    skip: !product,
    variables: {
      sku: product?.sku,
      skip: (state.currentPage - 1) * state.limit,
      limit: state.limit,
      order: state.sort,
      filters: state.starFilter
        ? {
          rating: state.starFilter,
        }
        : null,
    },
  })
  const timerRef = useRef<any>(null)
  const reviewsSectionRef = useRef<HTMLDivElement>(null)

  const { addToast } = useSimpleToasts()
  const theme = useTheme()

  const reviews = useLoadingData<ProductReviewFragment[]>({
    data: reviewData?.productReviews?.items,
    loading: reviewsLoading || state.isPageLoading,
    defaultData: [],
  })

  const performFetcMore = async (): Promise<void> => {
    fetchMore({
      variables: {
        skip: state.skip,
      },
    })
  }

  const _handleRefetch = (): void => {
    refetch({
      skip: 0,
      limit: state.skip + state.limit,
    })
  }

  const _handleShowAddReview = (): void => {
    if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED) {
      setState((prevState) => update(prevState, {
        showModal: { $set: true },
      }))
    } else {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }
  }

  const _handleHideAddReview = (): void => {
    setState((prevState) => update(prevState, {
      showModal: { $set: false },
    }))
  }

  const _handleReviewError = (e: Error): void => {
    addToast({
      message: e.message,
      appearance: 'error',
    })
  }

  const _handleReviewSuccess = (): void => {
    addToast({
      message: 'Successfully created review',
      appearance: 'success',
    })
    setState((prevState) => update(prevState, {
      showModal: { $set: false },
    }))
    refetch({
      skip: 0,
      limit: state.skip + state.limit,
    })
  }

  const _handleResetFilters = (): void => {
    ratingForm.setFieldsValue({ stars: null })
    setState((prevState) => update(prevState, {
      filter: { $set: null },
    }))
  }

  const _handleChange = (_: unknown, { stars }: FormValues): void => {
    setState((prevState) => update(prevState, {
      starFilter: { $set: { eq: stars } },
      tempPage: { $set: 1 },
      currentPage: { $set: 1 },
    }))
  }

  const _handlePageChange = (newPage: number) => {
    setState((prevState) => update(prevState, {
      tempPage: { $set: newPage },
    }))
  }

  useEffect(() => {
    if (state.tempPage === state.currentPage) {
      return
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(async () => {
      setState((prevState) => update(prevState, {
        isPageLoading: {
          $set: true,
        },
      }))

      await refetch({
        skip: (state.tempPage - 1) * state.limit,
        limit: state.limit,
        order: state.sort,
        filters: state.starFilter ? { rating: state.starFilter } : null,
      })

      setState((prevState) => update(prevState, {
        currentPage: {
          $set: state.tempPage,
        },
        shouldScrollToTop: {
          $set: true,
        },
        isPageLoading: {
          $set: false,
        },
      }))

      if (reviewsSectionRef.current) {
        reviewsSectionRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 300)
  }, [state.tempPage])

  useEffect(() => {
    if (product?.sku) {
      refetch({
        skip: (state.currentPage - 1) * state.limit,
        limit: state.limit,
        order: state.sort,
        filters: state.starFilter
          ? {
            rating: state.starFilter,
          }
          : null,
      })
    }
  }, [state.currentPage, state.sort.order, state.starFilter])

  const _hndleSortChange = (values: { order: ReviewSortOrderEnum }): void => {

    setState((prevState) => update(prevState, {
      skip: {
        $set: 0,
      },
      sort: {
        order: {
          $set: values.order,
        },
      },
    }))
  }

  const size: LayoutSizeClass = {
    mobile: 0,
    tablet: 0,
    desktop: 0,
  }

  const totalReviews =
  state.starFilter
    ? product?.ratingSummary?.ratingBreakdown.find((item) => item.rating === state.starFilter.eq)?.total || 0
    : product?.ratingSummary?.ratingBreakdown.reduce((total, ra) => total + ra.total, 0)

  const totalAllReviews = product?.ratingSummary?.ratingBreakdown.reduce((total, ra) => total + ra.total, 0) || 0

  useEffect(() => {
    if (product?.sku) {
      performFetcMore()
    }
  }, [state.skip])

  useEffect(() => {
    if (product && product.reviewCount === 0) {
      setState((prevState) => update(prevState, {
        view: {
          $set: ViewEnum.NoReviews,
        },
      }))
    } else if (product && !reviewsLoading) {
      if (state.starFilter?.eq && !product.ratingSummary.ratingBreakdown.find((bre) => bre.rating === state.starFilter?.eq)?.total) {
        const nextStar = product.ratingSummary.ratingBreakdown.find((bre) => bre.total !== 0)
        if (nextStar) {
          setState((prevState) => update(prevState, {
            starFilter: {
              $set: {
                eq: nextStar.rating,
              },
            },
          }))
        }
      }
      setState((prevState) => update(prevState, {
        view: {
          $set: ViewEnum.Reviews,
        },
      }))
    }
  }, [reviewsLoading, reviews.length, product])

  useEffect(() => {
    if (state.starFilter && state.starFilter.eq !== ratingForm.getFieldValue('starts')) {
      ratingForm.setFieldValue('stars', state.starFilter.eq)
    }
  }, [state.starFilter])

  const ratingStars = ['5', '4', '3', '2', '1']
  let review: ProductReviewFragment

  return (
    <OuterContainer>
      <Choose>
        <When condition={state.view === ViewEnum.Skeleton}>
          <Card backgroundColor={theme.colors.white.pureWhite} className='no-review' >
            <Skeleton direction='column' justify='center' align='center' gap='18px' height='auto'>
              <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
                height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
                width={{ mobile: '90%', tablet: '70%', desktop: '65%' }}
              />
              <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
                height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
                width={{ mobile: '30%', tablet: '25%', desktop: '70%' }}
              />
              <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
                height={{ mobile: '12px', tablet: '24px', desktop: '52px' }}
                width={{ mobile: '30%', tablet: '25%', desktop: '20%' }}
              />
            </Skeleton>
          </Card>
        </When>
        <Otherwise>
          <RatingModal
            open={state.showModal}
            sku={product?.sku}
            onError={_handleReviewError}
            onSuccess={_handleReviewSuccess}
            onClose={_handleHideAddReview} />
          <Choose>
            <When condition={state.view === ViewEnum.NoReviews}>
              <Card backgroundColor={theme.colors.white.pureWhite} className='no-review' >
                <Heading variant='h4' className='title' align='center'>Be the first to leave a review. </Heading>
                <Paragraph
                  variant='p2' align='center' className='copy'>
                  Have you purchased <Strong>{decode(product?.name) ?? 'Product'}</Strong> before?
                </Paragraph>
                <Button
                  variant='ghost'
                  onClick={_handleShowAddReview}
                  className='button'
                  title='SHARE YOUR THOUGHTS' />
              </Card>
            </When>
            <Otherwise>
              <LayoutGrid margin={size}>
                <LayoutRow>
                  <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 3.5 }}>
                    <Summary>
                      <Heading variant='h8' className='review-title'>Customer Reviews</Heading>
                      <SummaryHeader>
                        <RatingWrapper>
                          <Rating rating={product?.ratingSummary.averageRating} size='large' />
                          <Tag variant='t1' color={theme.colors.grey.silver}>{product?.ratingSummary.averageRating} ({product?.reviewCount ?? 0})</Tag>
                        </RatingWrapper>
                        <DeviceContainer mobile>
                          <Button variant='ghost' size='medium' onClick={_handleShowAddReview} className='rate-button' title='Write A Review' />
                        </DeviceContainer>
                      </SummaryHeader>
                      <DeviceContainer desktop tablet>
                        <FilterContainer>
                          <Form form={ratingForm} className='registerForm' onValuesChange={_handleChange}>
                            <RadioInput
                              name='stars'
                              showLabel={false}
                              className='review-filter-radio'
                              options={ratingStars.map((rating) => {
                                const ratingCount = product?.ratingSummary?.ratingBreakdown.find((item) => item.rating === rating)?.total || 0

                                return {

                                  title: (
                                    <RatingItem>
                                      <Paragraph>{rating} stars</Paragraph>
                                      <PercentageBar
                                        className="review-bar"
                                        percentage={product?.ratingSummary?.ratingBreakdown.find((item) => { return item.rating === rating })?.total || 0}
                                        count={ratingCount}
                                        totalReviews={totalAllReviews}/>
                                    </RatingItem>
                                  ),
                                  value: rating,
                                  disabled: !product?.ratingSummary?.ratingBreakdown.find((item) => item.rating === rating)?.total,
                                }

                              })}
                              variant="vertical"
                            />
                          </Form>
                        </FilterContainer>
                        <Paragraph variant='p2' >
                          Have you purchased <Strong>{decode(product?.name) ?? 'Product'}</Strong> before?
                        </Paragraph>
                        <Button
                          variant='ghost'
                          onClick={_handleShowAddReview}
                          className='rate-button'
                          fullWidth
                          title='SHARE YOUR THOUGHTS' />
                      </DeviceContainer>
                    </Summary>
                  </LayoutCol>
                  <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 4.2 }}>
                    <Reviews ref={reviewsSectionRef}>
                      <If condition={reviewsLoading || state.isPageLoading}>
                        <LoadingContainer>
                          <Loader />
                        </LoadingContainer>
                      </If>
                      <FilterBar>
                        <Tag variant='t1' align='left' className='result-size'>{totalReviews} Results</Tag>
                        <Form
                          form={orderForm}
                          onValuesChange={_hndleSortChange}
                          initialValues={{ order: ReviewSortOrderEnum.TOP }}>
                          <SelectInput
                            name='order'
                            wrapperClassName='result-select'
                            options={[{ title: 'Helpful', value: ReviewSortOrderEnum.TOP }, { title: 'Recent', value: ReviewSortOrderEnum.RECENT }]} />
                        </Form>
                      </FilterBar>
                      <List>
                        <For each='review' index='index' of={reviews ?? []}>
                          <ProductReview
                            key={review.reviewId}
                            productId={product.id}
                            review={review}
                            didUpdate={_handleRefetch} />
                        </For>
                      </List>
                      <If condition={totalReviews > REVIEWS_PER_PAGE}>
                        <ReviewsContainer>
                          <Pagination
                            currentPage={state.tempPage}
                            itemsPerPage={REVIEWS_PER_PAGE}
                            totalItems={totalReviews}
                            loading={reviewsLoading}
                            onPageChange={_handlePageChange}
                            className='pagination' />
                        </ReviewsContainer>
                      </If>
                    </Reviews>
                  </LayoutCol>
                </LayoutRow>
              </LayoutGrid>
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </OuterContainer>
  )

}
