import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'

import { HTML } from './HTML'

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const ContentContainer = styled.div <{ backgroundColor: string, foregroundColor: string }>`
  
    height: 31.250vw;

    @media (min-width: 30em) {
      height: 34.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 7.639vw;
    }

    @media (min-width: 90em) {
      height: 110px;
    }
  
  
    padding: 4.688vw;

    @media (min-width: 30em) {
      padding: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.042vw;
    }

    @media (min-width: 90em) {
      padding: 15px;
    }
  

  display: flex;
  flex-shrink: 0;
  flex-direction: row;

  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
`

const ImageContainer = styled.div`
  
    width: 28.125vw;

    @media (min-width: 30em) {
      width: 32.813vw;
    }

    @media (min-width: 30.0625em) {
      width: 7.292vw;
    }

    @media (min-width: 90em) {
      width: 105px;
    }
  
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.167vw;
    }

    @media (min-width: 90em) {
      height: 60px;
    }
  
  
    padding-right: 1.563vw;

    @media (min-width: 30em) {
      padding-right: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      padding-right: 0.694vw ;
    }

    @media (min-width: 90em) {
      padding-right: 10px ;
    }
  

`
const DescriptionContainer = styled.div`  
  
    padding: 1.563vw 0 0 3.125vw;

    @media (min-width: 30em) {
      padding: 1.563vw 0 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw 0 0 0.694vw;
    }

    @media (min-width: 90em) {
      padding: 5px 0 0 10px;
    }
  
`
export interface PageIconTextBlockProps {
  block: PageBlockFragment
}

export function PageIconTextBlock({ block }: PageIconTextBlockProps): JSX.Element {

  const { image, description, backgroundColor, foregroundColor } = block

  return (
    <Container >
      <ContentContainer backgroundColor={backgroundColor} foregroundColor={foregroundColor}>
        <If condition={!!image}>
          <ImageContainer>
            <ResponsiveImage image={image} objectFit='fill' />
          </ImageContainer>
        </If>
        <If condition={!!description}>
          <DescriptionContainer>
            <HTML color={foregroundColor} align='left'>
              {description}
            </HTML>
          </DescriptionContainer>
        </If>
      </ContentContainer>
    </Container>
  )
}
