import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Title } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { OrderItemFragment } from '@hooks/api/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  
    height: 56.250vw;

    @media (min-width: 30em) {
      height: 68.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 15.278vw;
    }

    @media (min-width: 90em) {
      height: 220px;
    }
  
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
`

const CardLink = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
`
const ImageContainer = styled.div`
  flex-basis: 100%;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  
    height: 39.063vw;

    @media (min-width: 30em) {
      height: 46.875vw;
    }

    @media (min-width: 30.0625em) {
      height: 10.417vw;
    }

    @media (min-width: 90em) {
      height: 150px;
    }
  
  
    padding: 2.813vw 0 0.938vw 0;

    @media (min-width: 30em) {
      padding: 2.813vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 1.250vw 0;
    }

    @media (min-width: 90em) {
      padding: 18px 0;
    }
  

`

const TitleContainer = styled.div`
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 12px;
    }
  
  font-family: open-sans;
  font-weight: 600;
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
  
    line-height: 3.750vw;

    @media (min-width: 30em) {
      line-height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.111vw;
    }

    @media (min-width: 90em) {
      line-height: 16px;
    }
  
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  
    -webkit-line-clamp: 2;

    @media (min-width: 30em) {
      -webkit-line-clamp: 3;
    }

    @media (min-width: 30.0625em) {
      -webkit-line-clamp: 3;
    }

    @media (min-width: 90em) {
      -webkit-line-clamp: 3;
    }
  
  
    padding-bottom: 0.625vw;

    @media (min-width: 30em) {
      padding-bottom: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      padding-bottom: 0.139vw;
    }

    @media (min-width: 90em) {
      padding-bottom: 2px;
    }
  
  margin: 0;
`

export interface OrderProductCardProps {
  product: OrderItemFragment
  disabled?: boolean
}

export function OrderProductCard({ product }: OrderProductCardProps): JSX.Element {

  const navigate = useNavigate()

  const openDetails = (): void => {
    navigate(`/${product.productUrlKey}`)
  }

  const _handleLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.preventDefault()
    openDetails()
  }

  return (
    <Container>
      <CardLink onClick={_handleLink}>
        <ImageContainer>
          <ResponsiveImage image={product?.coverImage} objectFit='contain' />
        </ImageContainer>
        <TitleContainer>
          {product.productName}
        </TitleContainer>
      </CardLink>
    </Container>
  )

}
