import React, { useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Button } from '@atoms/buttons'
import { Pagination } from '@atoms/controls/Pagination'
import { Loader } from '@atoms/notifications'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CustomerOrderFragment, useCustomerOrdersQuery } from '@hooks/api/index'
import { useLoadingData } from '@hooks/UseLoadingData'
import { SiteHelper } from '@lib/SiteHelper'
import { CustomerOrderSortableField, SortEnum } from '@uctypes/api/globalTypes'

import { Order } from './Order'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 5.938vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.319vw;
    }

    @media (min-width: 90em) {
      gap: 19px;
    }
  
`

const LoadingContainer = styled.div<{ hasOrders: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
    min-height: 50vh;

    @media (min-width: 30em) {
      min-height: 100%;
    }

    @media (min-width: 30.0625em) {
      min-height: 100%;
    }

    @media (min-width: 90em) {
      min-height: 100%;
    }
  
  background-color: ${(props): string => props.hasOrders ? SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5) : ''};
  /* 
    height: 100vh;

    @media (min-width: 30em) {
      height: 100vh;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
   */
`

const NoOrders = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  
    padding: 5.000vw 0.000vw 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
`

const OrderContainer = styled.div`
  position: relative;
`

const OrdersSection = styled.div`
  display: flex;
  flex-direction: column;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const ORDER_PER_PAGE = 4

interface UserOrdersState {
  tempPage: number
  currentPage: number
  shouldScrollToTop: boolean
}

const DEFAULT_STATE: UserOrdersState = {
  tempPage: 1,
  currentPage: 1,
  shouldScrollToTop: false,
}

export function UserOrders(): JSX.Element {

  const [state, setState] = useState<UserOrdersState>({ ...DEFAULT_STATE })
  const navigate = useNavigate()
  const timerRef = useRef<any>(null)

  const { data, loading } = useCustomerOrdersQuery({
    // TODO: !!!!!!!!!!!!!!!!!!! this must be removed when the issue is sorted on Magento !!!!!!!!!!!!!!!!!!!
    errorPolicy: 'ignore',
    variables: {
      pageSize: ORDER_PER_PAGE,
      currentPage: state.currentPage,
      sort: {
        sortDirection: SortEnum.DESC,
        sortField: CustomerOrderSortableField.CREATED_AT,
      },
    },
  })

  const allOrders = useLoadingData<CustomerOrderFragment[]>({
    loading,
    defaultData: [],
    data: data?.allOrders?.orders?.items,
  })

  const orderCount = useLoadingData<number>({
    loading,
    defaultData: 0,
    data: data?.allOrders?.orders?.totalCount,
  })

  const completeStages = ['Collected in store', 'Collected', 'Delivered', 'Cancelled', 'Complete', 'Closed']

  const _handleContinueShopping = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/')
  }

  const _handlePageChange = (currentPage: number): void => {
    setState((prevState) => update(prevState, {
      tempPage: {
        $set: currentPage,
      },
    }))
  }

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      setState((prevState) => update(prevState, {
        currentPage: {
          $set: state.tempPage,
        },
        shouldScrollToTop: {
          $set: true,
        },
      }))
    }, 300)
  }, [state.tempPage])

  useEffect(() => {
    if (!loading && state.shouldScrollToTop) {
      setState((prevState) => update(prevState, {
        shouldScrollToTop: {
          $set: true,
        },
      }))
      SiteHelper.scrollTo(0, 300)
    }
  }, [loading])

  let order: CustomerOrderFragment

  return (

    <Container>
      <Choose>
        <When condition={!!allOrders.length || loading}>
          <OrderContainer>
            <OrdersSection>
              <For each='order' of={allOrders}>
                <Order key={order.id} order={order} isActive={!completeStages.includes(order.status)} />
              </For>
            </OrdersSection>
            <If condition={loading}>
              <LoadingContainer hasOrders={!!allOrders.length}>
                <Loader />
              </LoadingContainer>
            </If>
          </OrderContainer>
          <If condition={orderCount > ORDER_PER_PAGE}>
            <Pagination
              totalItems={orderCount}
              currentPage={state.tempPage}
              itemsPerPage={ORDER_PER_PAGE}
              loading={loading}
              onPageChange={_handlePageChange} />
          </If>
        </When>
        <Otherwise>
          <NoOrders>
            <Paragraph variant='p1' align='center'>No active orders, let&apos;s get shopping!</Paragraph>
            <Button variant='primary' title='Continue Shopping' href='/' onClick={_handleContinueShopping} />
          </NoOrders>
        </Otherwise>
      </Choose>
    </Container>
  )
}
