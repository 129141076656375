import { Heading, Paragraph } from "@atoms/typography"
import { ResponsivePXValue } from "@components/Theme"
import { ContentChangeDocument, PageTimetableFragment } from "@hooks/api"
import { HTML } from "@molecules/content"
import React from "react"
import styled, { useTheme } from "styled-components"

const Container = styled.div`
    width: 100%;
    
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 20px;
    }
  
    background-color: ${({ theme }) => theme.colors.white.pureWhite};
`

const TitleContainer = styled.div`
    width: 100%;
    
    margin-bottom: 1.250vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
    display: flex;

    
    .scheduledToClose {
        
    margin-left: 3.750vw;

    @media (min-width: 30em) {
      margin-left: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-left: 12px;
    }
      
    }
`

const ContentContainer = styled.div`

`

export interface PageTimetableProps {
    timetable: PageTimetableFragment
}
export function PageTimetable({ timetable }: PageTimetableProps): JSX.Element {

    const { colors } = useTheme()

    return (
        <Container>
            <If condition={!!timetable.title}>
                <TitleContainer>
                    <Heading variant='h5' color={colors.green.greenVogue} >{timetable.title}</Heading>
                    <If condition={!!timetable.isClosing}>
                        <Paragraph color={colors.red.cinnabar} variant='p2' className='scheduledToClose'>*Scheduled to close</Paragraph>
                    </If>
                </TitleContainer>
            </If>
            <ContentContainer>
                <HTML children={timetable.content} align='left'  />
            </ContentContainer>
        </Container>
    )
}
    