import React, { ReactNode } from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'h8' | 'h9'
export type HeadingAlignment = 'left' | 'center' | 'right' | 'justify'
export type HeadingTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type HeadingDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const H1 = styled.h1<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: proxima-nova;
  font-weight: 600;
  
    font-size: 5.625vw;

    @media (min-width: 30em) {
      font-size: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 2.639vw;
    }

    @media (min-width: 90em) {
      font-size: 38px;
    }
  
  
    line-height: 7.500vw;

    @media (min-width: 30em) {
      line-height: 11.875vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.639vw;
    }

    @media (min-width: 90em) {
      line-height: 38px;
    }
  

`

const H2 = styled.h2<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  text-transform: ${(props): string => props.transform};
  font-family: open-sans;
  font-weight: 700;
  
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.944vw;
    }

    @media (min-width: 90em) {
      font-size: 28px;
    }
  
  
    line-height: 6.875vw;

    @media (min-width: 30em) {
      line-height: 11.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.500vw;
    }

    @media (min-width: 90em) {
      line-height: 36px;
    }
  
`

const H3 = styled.h3<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: proxima-nova;
  font-weight: 600;
  
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.667vw;
    }

    @media (min-width: 90em) {
      font-size: 24px;
    }
  
  
    line-height: 6.875vw;

    @media (min-width: 30em) {
      line-height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.083vw;
    }

    @media (min-width: 90em) {
      line-height: 30px;
    }
  
`

const H4 = styled.h4<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: proxima-nova;
  font-weight: 600;
  
    font-size: 4.375vw;

    @media (min-width: 30em) {
      font-size: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.389vw;
    }

    @media (min-width: 90em) {
      font-size: 20px;
    }
  
  
    line-height: 6.250vw;

    @media (min-width: 30em) {
      line-height: 8.125vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.806vw;
    }

    @media (min-width: 90em) {
      line-height: 26px;
    }
  
`

const H5 = styled.h5<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: open-sans;
  font-weight: 700;
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.250vw;
    }

    @media (min-width: 90em) {
      font-size: 18px;
    }
  
  
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.667vw;
    }

    @media (min-width: 90em) {
      line-height: 24px;
    }
  
`

const H6 = styled.h6<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: proxima-nova;
  font-weight: 600;
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.250vw;
    }

    @media (min-width: 90em) {
      font-size: 18px;
    }
  
  
    line-height: 6.250vw;

    @media (min-width: 30em) {
      line-height: 6.875vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
`

const H7 = styled.h6<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: proxima-nova;
  font-weight: 600;
  
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.111vw;
    }

    @media (min-width: 90em) {
      font-size: 16px;
    }
  
  
    line-height: 6.875vw;

    @media (min-width: 30em) {
      line-height: 6.875vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
  
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.111vw;
    }

    @media (min-width: 90em) {
      font-size: 16px;
    }
  
  
    line-height: 5.000vw;

    @media (min-width: 30em) {
      line-height: 6.875vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
`

const H8 = styled.h6<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: open-sans;
  font-weight: 700;
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
  
    line-height: 4.375vw;

    @media (min-width: 30em) {
      line-height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.389vw;
    }

    @media (min-width: 90em) {
      line-height: 20px;
    }
  
`

const H9 = styled.h1<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: open-sans;
  font-weight: 700;
  
    font-size: 5.625vw;

    @media (min-width: 30em) {
      font-size: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.250vw;
    }

    @media (min-width: 90em) {
      font-size: 18px;
    }
  
  
    line-height: 8.125vw;

    @media (min-width: 30em) {
      line-height: 8.125vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.806vw;
    }

    @media (min-width: 90em) {
      line-height: 26px;
    }
  

`

export interface HeadingProps {
  children: ReactNode
  variant?: HeadingVariant
  align?: HeadingAlignment
  transform?: HeadingTransforms
  decoration?: HeadingDecorations
  color?: string
  skeleton?: boolean
  className?: string
  onClick?: () => void
}

export function Heading({
  children,
  variant = 'h1',
  align = 'left',
  transform = 'none',
  decoration = 'none',
  color,
  className,
  onClick,
}: HeadingProps): JSX.Element {

  switch (variant) {

    case 'h1':
      return (
        <H1
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H1>
      )
    case 'h2':
      return (
        <H2
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H2>
      )
    case 'h3':
      return (
        <H3
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H3>
      )
    case 'h4':
      return (
        <H4
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H4>
      )
    case 'h5':
      return (
        <H5
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H5>
      )
    case 'h6':
      return (
        <H6
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H6>
      )
    case 'h7':
      return (
        <H7
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H7>
      )
    case 'h8':
      return (
        <H8
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H8>
      )
    case 'h9':
      return (
        <H9
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H9>
      )

  }

}
