import React, { ReactNode } from 'react'

import styled, { useTheme } from 'styled-components'

import { Divider, Heading, Paragraph } from '@atoms/index'
import { LiteBoxShadow, ResponsivePXValue, theme } from '@components/Theme'

const Container = styled.div<{backgroundColor: string}>`
  background-color: ${(props): string => props.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

    ${LiteBoxShadow}
    ${(props): string => {
    return ResponsivePXValue('border-radius', `3px solid ${props}`)
  }}

  .header {
    width: 100%;
    
    padding: 5.313vw 5.000vw;

    @media (min-width: 30em) {
      padding: 5.313vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.181vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 17px 16px;
    }
  
  }
  
  
  .content {
    width: 100%;
    
    padding: 6.250vw;

    @media (min-width: 30em) {
      padding: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 20px;
    }
  
  }
`
export interface MessageErrorProps {
  heading?: string
  message?: string
  backgroundColor?: string
  color?: string
  wrapperClassName?: string
  children?: ReactNode
}

export function Message({ heading, message, children, backgroundColor, color, wrapperClassName }: MessageErrorProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container className={wrapperClassName} backgroundColor={backgroundColor || theme.colors.white.pureWhite}>
      <If condition={!!heading}>
        <Heading variant='h3' align='center' className='header'>{heading}</Heading>
        <Divider />
      </If>
      <If condition={!!message}>
        <Paragraph color={color} className='content' align='center'>{message}</Paragraph>
      </If>
      <If condition={!!children}>
        {children}
      </If>
    </Container>
  )

}
