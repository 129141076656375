import React from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery } from '@hooks/api'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.a`
  position: relative;
  display: flex;
  cursor: pointer;
`

const NavigationFavouriteButtonLoader = styled.div`
  
    width: 7.813vw;

    @media (min-width: 30em) {
      width: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.736vw;
    }

    @media (min-width: 90em) {
      width: 25px;
    }
  
  
    height: 7.813vw;

    @media (min-width: 30em) {
      height: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.736vw;
    }

    @media (min-width: 90em) {
      height: 25px;
    }
  
`
export interface NavigationFavouriteButtonProps {
  className?: string
  mobile?: boolean
}

export function NavigationFavouriteButton({ className }: NavigationFavouriteButtonProps): JSX.Element {

  const navigate = useNavigate()
  const { data: customerData } = useCustomerQuery()

  const _handleCartClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST) {
      return ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }
    navigate('/my-ftn-shop/my-discounts')
  }

  const theme = useTheme()
  return (
    <Container href='/my-ftn-shop/my-discounts' onClick={_handleCartClick} className={className} >
      <NavigationFavouriteButtonLoader data-tip='Cart'>
        <Icon icon={LocalIconEnums.HEART_OUTLINE} color={theme.colors.green.bottleGreen} />
      </NavigationFavouriteButtonLoader>
    </Container>
  )
}
