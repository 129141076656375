import React, { useState, useEffect, ReactNode } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'

const ToggleWrapper = styled.div`
  position: relative;
`

const ToggleInputWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const Check = styled.div`
  border-radius: 50%;
  background: ${(props) => props.theme.colors.white.pureWhite};
  transition: 0.4s ease;

  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.083vw;
    }

    @media (min-width: 90em) {
      width: 30px;
    }
  
  
    height: 9.375vw;

    @media (min-width: 30em) {
      height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.083vw;
    }

    @media (min-width: 90em) {
      height: 30px;
    }
  

  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    margin-left: 2.500vw;

    @media (min-width: 30em) {
      margin-left: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-left: 8px;
    }
  

  :before, :after {
    transition: 0.4s ease;
  }
`

const CheckBoxInput = styled.input`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;

  
    min-width: 31.250vw;

    @media (min-width: 30em) {
      min-width: 31.250vw;
    }

    @media (min-width: 30.0625em) {
      min-width: 6.944vw;
    }

    @media (min-width: 90em) {
      min-width: 100px;
    }
  
  
    height: 14.063vw;

    @media (min-width: 30em) {
      height: 14.063vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.125vw;
    }

    @media (min-width: 90em) {
      height: 45px;
    }
  

  :checked + label {
    background: ${(props) => props.theme.colors.green.watercourse};
  }
`

const CheckboxLabel = styled.label`
  background: ${(props) => props.theme.colors.grey.silver};
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  transform-origin: 20% center;
  cursor: pointer;


  
    border-radius: 15.625vw;

    @media (min-width: 30em) {
      border-radius: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 3.472vw;
    }

    @media (min-width: 90em) {
      border-radius: 50px;
    }
  
  
    min-width: 31.250vw;

    @media (min-width: 30em) {
      min-width: 31.250vw;
    }

    @media (min-width: 30.0625em) {
      min-width: 6.944vw;
    }

    @media (min-width: 90em) {
      min-width: 100px;
    }
  
  
    height: 14.063vw;

    @media (min-width: 30em) {
      height: 14.063vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.125vw;
    }

    @media (min-width: 90em) {
      height: 45px;
    }
  
`

const ChildrenContainer = styled.div`
  color: ${(props) => props.theme.colors.white.pureWhite};
  font-family: 'Open-sans';
  font-weight: 600;
  display: flex;
  justify-content: center;
  
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
  
    margin-left: 2.500vw;

    @media (min-width: 30em) {
      margin-left: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-left: 8px;
    }
  
  
    margin-right: 4.375vw;

    @media (min-width: 30em) {
      margin-right: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 0.972vw;
    }

    @media (min-width: 90em) {
      margin-right: 14px;
    }
  
  
    min-width: 9.375vw;

    @media (min-width: 30em) {
      min-width: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      min-width: 2.083vw;
    }

    @media (min-width: 90em) {
      min-width: 30px;
    }
  
`

export interface ToggleProps {
  onClick: (toggleVale: boolean) => void
  children?: ReactNode
  toggled?: boolean
}

interface ToggleStateInterface {
  toggled: boolean
}

const DEFAULT_STATE: ToggleStateInterface = {
  toggled: true,
}

export function Toggle({ onClick, children, toggled = true }: ToggleProps): JSX.Element {

  const [state, setState] = useState(DEFAULT_STATE)

  useEffect(() => {
    setState((prevState) => update(prevState, {
      toggled: { $set: toggled },
    }))
  }, [])

  const _handleClick = () => {
    setState((prevState) => update(prevState, {
      toggled: { $set: !prevState.toggled },
    }))
    if (onClick) {
      onClick(state.toggled)
    }
  }

  return (
    <>
      <ToggleWrapper>
        <ToggleInputWrapper>
          <CheckBoxInput type='checkbox' checked={state.toggled} readOnly id='toggle' onClick={() => _handleClick()} />
          <CheckboxLabel htmlFor='toggle'>
            <Check>
              <Choose>
                <When condition={state.toggled}>
                  <Icon color={theme.colors.black.bottleGreen} icon={LocalIconEnums.CHECK}/>
                </When>
                <Otherwise>
                  <Icon color={theme.colors.grey.silver} icon={LocalIconEnums.SMALL_CROSS}/>
                </Otherwise>
              </Choose>
            </Check>
            <ChildrenContainer>{children}</ChildrenContainer>
          </CheckboxLabel>
        </ToggleInputWrapper>
      </ToggleWrapper>
    </>
  )
}
