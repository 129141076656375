import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Button, Message, Paragraph, ResponsiveImage, Title } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useRemoveItemFromCartMutation, CartItemFragment } from '@hooks/api/index'
import { Modal } from '@molecules/index'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  
    width: 100%;

    @media (min-width: 30em) {
      width: 156.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 34.722vw;
    }

    @media (min-width: 90em) {
      width: 500px;
    }
  
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

const Products = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  
    margin: 5.000vw 0;

    @media (min-width: 30em) {
      margin: 7.500vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.667vw 0;
    }

    @media (min-width: 90em) {
      margin: 24px 0;
    }
  
`

const ProductImage = styled.div<{ placeHolder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'open-sans';
  font-weight: 700;
  
  ${(props) => ResponsivePXValue('border', `1px solid ${props.theme.colors.grey.pampas}`)}
  background-color: ${(props): string => props.placeHolder ? props.theme.colors.white.fantasy : props.theme.colors.white.pureWhite};
  color: ${(props): string => props.theme.colors.green.bottleGreen};

  
    font-size: 5.625vw;

    @media (min-width: 30em) {
      font-size: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.250vw;
    }

    @media (min-width: 90em) {
      font-size: 18px;
    }
  
  
    padding: 3.125vw;

    @media (min-width: 30em) {
      padding: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.694vw;
    }

    @media (min-width: 90em) {
      padding: 10px;
    }
  
  
    width: 27.500vw;

    @media (min-width: 30em) {
      width: 32.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 7.222vw;
    }

    @media (min-width: 90em) {
      width: 104px;
    }
  
  
    height: 27.500vw;

    @media (min-width: 30em) {
      height: 32.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 7.222vw;
    }

    @media (min-width: 90em) {
      height: 104px;
    }
  
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    
    margin-top: 5.000vw;

    @media (min-width: 30em) {
      margin-top: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.667vw;
    }

    @media (min-width: 90em) {
      margin-top: 24px;
    }
  
    
    width: 100%;

    @media (min-width: 30em) {
      width: 68.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 15.278vw;
    }

    @media (min-width: 90em) {
      width: 220px;
    }
  
  }

`

export interface OutOfStockModalProps {
  open: boolean
  cartId: string
  onClose?: () => void
  cartItems: Readonly<CartItemFragment[]>
}

interface OutOfStockModalState {
  loading: boolean
  error: string
}

const DEFAULT_STATE: OutOfStockModalState = {
  loading: false,
  error: '',
}

export function OutOfStockModal({ open, cartId, cartItems, onClose }: OutOfStockModalProps): JSX.Element {

  const [state, setState] = useState<OutOfStockModalState>({ ...DEFAULT_STATE })
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()

  const _handleClose = (): void => {
    if (!state.loading) {
      onClose?.()
    }
  }

  const removeProduct = async (cartItem: CartItemFragment): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          cartId,
          cartItemUid: cartItem.uid,
        },
      })
      setState((prevState) => update(prevState, {
        newAmount: { $set: null },
      }))
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: {
          $set: e.message,
        },
      }))
    }
  }

  const _removeProducts = async (): Promise<void> => {
    setState((prevState) => update(prevState, {
      loading: {
        $set: true,
      },
    }))
  }

  useEffect(() => {
    if (state.loading && cartItems.length) {
      removeProduct(cartItems[cartItems.length - 1])
    } else if (state.loading && !cartItems.length) {
      onClose?.()
    }
  }, [state.loading, cartItems.length])

  const $title = (
    <Title variant='t3'>
      Products out of stock
    </Title>
  )

  const mobileExtra = cartItems.length > 5 ? cartItems.length - 5 : 0
  const desktopExtra = cartItems.length > 3 ? cartItems.length - 3 : 0

  let cartItem!: CartItemFragment

  return (
    <Modal title={$title} open={open} onClose={_handleClose}>
      <Choose>
        <When condition={state.error !== ''}>
          <Message wrapperClassName='messageClass' backgroundColor={theme.colors.pink.bridesmaid} color={theme.colors.red.cinnabar} message={state.error}></Message>
        </When>
        <Otherwise>
          <Container>
            <Paragraph>
              The following products are out of stock and will be removed from your cart:
            </Paragraph>
            <DeviceContainer desktop tablet>
              <Products>
                <For each='cartItem' of={desktopExtra ? [...cartItems].splice(0, 3) : cartItems}>
                  <ProductImage key={cartItem.id} placeHolder={false}>
                    <ResponsiveImage image={cartItem.product.coverImage} />
                  </ProductImage>
                </For>
                <If condition={!!desktopExtra}>
                  <ProductImage placeHolder={true}>
                    +{desktopExtra}
                  </ProductImage>
                </If>
              </Products>
            </DeviceContainer>
            <DeviceContainer mobile>
              <Products>
                <For each='cartItem' of={mobileExtra ? [...cartItems].splice(0, 5) : cartItems}>
                  <ProductImage key={cartItem.id} placeHolder={false}>
                    <ResponsiveImage image={cartItem.product.coverImage} />
                  </ProductImage>
                </For>
                <If condition={!!mobileExtra}>
                  <ProductImage placeHolder={true}>
                    +{mobileExtra}
                  </ProductImage>
                </If>
              </Products>
            </DeviceContainer>
            <Controls>
              <Button
                className='button'
                variant='primary'
                title='OK'
                loading={state.loading}
                onClick={_removeProducts} />
            </Controls>
          </Container>
        </Otherwise>
      </Choose>
    </Modal>
  )

}
