import React from 'react'

import styled from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { CategorySuggestionFragment } from '@hooks/api/index'

const Container = styled.a`
  text-decoration: none;
`

const HeightComponent = styled.div`
  cursor: pointer;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
  
    margin: 1.563vw 0 0 0;

    @media (min-width: 30em) {
      margin: 1.563vw 0 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.347vw 0 0 0;
    }

    @media (min-width: 90em) {
      margin: 5px 0 0 0;
    }
  
  .text-element {
    padding: 0;
    margin: 0;
  }
`
export interface CategorySearchItemProps {
  item: CategorySuggestionFragment
  onSelect?: (path: string) => void
}

export function CategorySearchItem({ item, onSelect }: CategorySearchItemProps): JSX.Element {

  const _handlClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    onSelect?.(`/${item.urlKey}`)
  }

  return (
    <Container href={item.urlKey} onClick={_handlClick}>
      <HeightComponent>
        <Paragraph variant='p2' className='text-element' bold>
          {item.title}
        </Paragraph>
      </HeightComponent>
    </Container>
  )

}
