import React, { useEffect } from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE, AppPlugin } from '@api/local/AppPlugin'
import { Button, ResponsiveImage, UltraContainer } from '@atoms/index'
import { Heading, Link } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, useStaticImageQuery } from '@hooks/api'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 15.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 3.333vw;
    }

    @media (min-width: 90em) {
      padding: 48px;
    }
  ;
  
    margin: 0.000vw;

    @media (min-width: 30em) {
      margin: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 2.222vw;
    }

    @media (min-width: 90em) {
      margin: 32px;
    }
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  
    flex-direction: column-reverse;

    @media (min-width: 30em) {
      flex-direction: column-reverse;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
`

const ContentContainer = styled.div`

  display: flex;
  flex-direction: column;
  
    gap: 4.375vw;

    @media (min-width: 30em) {
      gap: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.944vw;
    }

    @media (min-width: 90em) {
      gap: 28px;
    }
  

  .copy {
    max-width: 
    max-width: 209.375vw;

    @media (min-width: 30em) {
      max-width: 209.375vw;
    }

    @media (min-width: 30.0625em) {
      max-width: 46.528vw;
    }

    @media (min-width: 90em) {
      max-width: 670px;
    }
  ;
    margin: 0;
  }

  .oops {
    margin: 0;
  }

  .errorCode {
    margin: 0;
  }

  .redirectLink {
    color: ${({ theme }): string => theme.colors.green.silverTree};
    &:hover {
      color: ${({ theme }): string => theme.colors.orange.casablanca};
    }
  }

  .inlineLink {
    text-decoration: underline;
  }
`

const ImageContainer = styled.div`
  
    width: 78.125vw;

    @media (min-width: 30em) {
      width: 143.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 31.944vw;
    }

    @media (min-width: 90em) {
      width: 460px;
    }
  
  
    margin-bottom: 6.250vw;

    @media (min-width: 30em) {
      margin-bottom: initial;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: initial;
    }

    @media (min-width: 90em) {
      margin-bottom: initial;
    }
  
  flex-grow: 0;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const array404 = [
  {
    image: '404-plasticbottles',
    copy: <Heading variant='h3' className='copy'>
      Our site is currently down for maintenance, our developers are hard at work and we will be back up shortly. You can get to work too by swapping to <Link href='/home-and-lifestyle/waste-free-living/water-bottles-flasks' className='redirectLink inlineLink'>reusable bottles</Link>.
    </Heading>,

  },
  {
    image: '404-coral',
    copy: <Heading variant='h3' className='copy'>
      Our site is currently down for maintenance, our developers are fixing the broblem. Our coral reefs also have a problem due to chemicals commonly found in sunscreens.
      You can help fix that by using a <Link href='/body-beauty/bath-and-body/sun-protection' className='redirectLink inlineLink'>natural sunscreen</Link>.
    </Heading>,

  },
  {
    image: '404-plasticbags',
    copy: <Heading variant='h3' className='copy'>
     Our site is currently down for maintenance, our developers are fixing the problem. Single use shopping bags are also a problem. Be a part of the change and swap them for <Link href='/home-and-lifestyle/waste-free-living/waste-free-shopping' className='redirectLink inlineLink'>reusable bags</Link>!
    </Heading>,

  },
  {
    image: '404-animaltesting',
    copy: <Heading variant='h3' className='copy'>
      Our site is currently down for maintenance, our developers are fixing the issue. Testing on animals is also an issue in our world. Not one of our <Link href='/body-beauty/makeup' className='redirectLink inlineLink'>makeup</Link> and <Link href='/body-beauty' className='redirectLink inlineLink'>beauty</Link> products is tested on animals.
    </Heading>,

  },
]

const random404 = array404[Math.floor(Math.random() * array404.length)]

export function Maintenance(): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const { data, loading } = useStaticImageQuery({
    variables: {
      staticId: random404.image,
      mobileWidth: 700,
      mobileHeight: 729,
      tabletWidth: 700,
      desktopWidth: 700,
      desktopHeight: 729,
    },
  })

  const mobileProps = {
    align: 'center',
  }

  return (
    <UltraContainer>
      <Container>
        <Wrapper>
          <ContentContainer>
            <DeviceContainer mobile>
              <ContentContainer>
                <Heading
                  variant='h1'
                  align='center'
                  className='oops'>
                  Oops!
                </Heading>
                { React.cloneElement(random404.copy, {
                  ...random404.copy.props,
                  ...mobileProps,
                }) }
                <Heading variant='h3' className='errorCode' align='center'>Error Code: 504</Heading>
              </ContentContainer>

            </DeviceContainer>
            <DeviceContainer tablet desktop>
              <ContentContainer>
                <Heading
                  variant='h1'
                  align='left'
                  className='oops'>
                    Oops!
                </Heading>
                { random404.copy }
                <Heading variant='h3' className='errorCode' align='left'>Error Code: 504</Heading>
              </ContentContainer>

            </DeviceContainer>
          </ContentContainer>
          <ImageContainer>
            <ResponsiveImage image={data?.oneImage } lazy={true} objectFit='contain'/>
          </ImageContainer>

        </Wrapper>
      </Container>
    </UltraContainer>
  )

}
