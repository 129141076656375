
import React from 'react'

import loadable from '@loadable/component'

import { Loader, PageLoader } from '@atoms/notifications'
import { LoadingContainer } from '@atoms/misc'

import(/* webpackPrefetch: true */ '@pages/IngredientPolicy')

const IngredientPolicy = loadable(() => import(/* webpackChunkName: "IngredientPolicy" */'@pages/IngredientPolicy'), {
  resolveComponent: (components) => components.IngredientPolicy,
})

export function IngredientPolicyAsync(): JSX.Element {

  return <IngredientPolicy fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
