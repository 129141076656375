
import React from 'react'

import loadable from '@loadable/component'

import { Loader } from '@atoms/notifications'
import { LoadingContainer } from '@atoms/misc'

import(/* webpackPrefetch: true */ '@pages/AboutUs')

const AboutUs = loadable(() => import(/* webpackChunkName: "AboutUs" */'@pages/AboutUs'), {
  resolveComponent: (components) => components.AboutUs,
})

export function AboutUsAsync(): JSX.Element {
  return <AboutUs fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
