import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ $size: 'small' | 'large' }>`
  display: flex;
  
    padding: 0;

    @media (min-width: 30em) {
      padding: 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0;
    }

    @media (min-width: 90em) {
      padding: 0;
    }
  
  ${(props): string => {
    if (props.$size === 'small') {
      return ResponsivePXValue('gap', '8px')
    }
    return ResponsivePXValue('gap', '6px')
  }}

`

const StarWrapper = styled.div<{ $size: 'small' | 'large' }>`
  display: flex;
  ${(props): string => {
    if (props.$size === 'small') {
      return `
        
    width: 4.063vw;

    @media (min-width: 30em) {
      width: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      width: 0.903vw;
    }

    @media (min-width: 90em) {
      width: 13px;
    }
  
        
    height: 4.063vw;

    @media (min-width: 30em) {
      height: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.903vw;
    }

    @media (min-width: 90em) {
      height: 13px;
    }
  
      `
    }
    return `
      
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.111vw;
    }

    @media (min-width: 90em) {
      width: 16px;
    }
  
      
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.111vw;
    }

    @media (min-width: 90em) {
      height: 16px;
    }
  
    `
  }}
`

export interface RatingProps {
  rating: number
  className?: string
  size?: 'small' | 'large'
  onRatingChange?: (rating: number) => void
  starCount?: number
  color?: string
  isInput?: boolean
}

interface RatingStateInterface {
  currentRating: number
}

const DEFAULT_STATE: RatingStateInterface = {
  currentRating: 0,
}

export function Rating({ rating, className, color, size = 'small', onRatingChange, starCount = 5, isInput = false }: RatingProps): JSX.Element {

  const theme = useTheme()
  const actualRating = rating || 0
  const [state, setState] = useState<RatingStateInterface>({ ...DEFAULT_STATE })

  const $widgets = Array.from(Array(starCount)).map((_, index) => index)
  let index!: number

  const _fillStars = (index: number) => {
    if (isInput) {
      setState((prevState) => update(prevState, {
        currentRating: { $set: index },
      }))
    }
  }

  const _setRating = (index: number) => {
    setState((prevState) => update(prevState, {
      currentRating: { $set: index },
    }))
    onRatingChange(index)
  }

  return (
    <Container className={className} $size={size}>
      <For each='star' index='index' of={$widgets}>
        <StarWrapper
          onMouseOver={() => (isInput) ? _fillStars(index+1) : undefined}
          onMouseOut={() => (isInput) ? _fillStars(0): undefined}
          onClick={() => (isInput) ? _setRating(index+1) : false}
          key={index}
          $size={size}>
          <Choose>
            <When condition={(isInput) && state.currentRating !== 0 && (state.currentRating > index)}>
              <Icon color={color || theme.colors.orange.casablanca} icon={LocalIconEnums.STAR_FILLED} />
            </When>
            <When condition={actualRating >= (index + 1) && state.currentRating === 0}>
              <Icon color={color || theme.colors.orange.casablanca} icon={LocalIconEnums.STAR_FILLED} />
            </When>
            <Otherwise>
              <Icon color={color || theme.colors.orange.casablanca} icon={LocalIconEnums.STAR_OUTLINE} />
            </Otherwise>
          </Choose>
        </StarWrapper>
      </For>
    </Container>
  )

}
