
import React from 'react'

import loadable from '@loadable/component'
import { LoadingContainer } from '@atoms/misc'
import { Loader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/Ingredients')

const Ingredients = loadable(() => import(/* webpackChunkName: "ingredients" */'@pages/Ingredients'), {
  resolveComponent: (components) => components.Ingredients,
})

export function IngredientsAsync(): JSX.Element {

  return <Ingredients fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
