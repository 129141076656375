import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

import { Loader } from './Loader'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
    height: CALC(100vh - 43.750vw);

    @media (min-width: 30em) {
      height: CALC(100vh - 43.750vw);
    }

    @media (min-width: 30.0625em) {
      height: CALC(100vh - 9.722vw);
    }

    @media (min-width: 90em) {
      height: CALC(100vh - 140px);
    }
  
`

export function PageLoader(): JSX.Element {

  return (
    <Container>
      <Loader />
    </Container>
  )

}
