import { camelCase, snakeCase } from 'change-case'
import TagManager from 'react-gtm-module'

export interface GAEvent {
  event: string
  ecommerce?: GAEcommerce
  page?: GAPageView
  params?: GAParams
}

export interface GAClearEvent {
  ecommerce: null
}

export interface GAEcommerce {
  items?: GAEcommerceItem[]
}

export interface GAParams {

}

export interface GAPageView {
  url: string
  title: string
}

export interface GAEcommercePaymentInfo extends GAEcommerce {
  currency?: string
  value: number
  coupon?: string
  paymentType?: string
  items: GAEcommerceItem[]
}

export interface GAEcommerceShippingInfo extends GAEcommerce {
  currency?: string
  value: number
  coupon?: string
  shippingType?: string
  items: GAEcommerceItem[]
}

export interface GAEcommerceAddToCart extends GAEcommerce {
  currency?: string
  value: number
  items: GAEcommerceItem[]
}

export interface GAEcommerceAddToWishlist extends GAEcommerce {
  currency?: string
  value: number
  items: GAEcommerceItem[]
}

export interface GAEcommerceBeginCheckout extends GAEcommerce {
  currency?: string
  value: number
  coupon?: string
  items: GAEcommerceItem[]
}

export interface GAEarnVirtualCurency extends GAParams {
  virtualCurrencyName?: string
  value?: number
}

export interface GAGenerateLead extends GAParams {
  currency?: string
  value: number
}

export interface GALogin extends GAParams {
  method?: string
}

export interface GAEcommercePurchase extends GAEcommerce {
  transactionId: string
  value: number
  tax?: number
  shipping?: number
  currency?: string
  coupon?: string
  items: GAEcommerceItem[]
}

export interface GAEcommerceRefund extends GAEcommerce {
  transactionIdd: string
  value: number
  tax?: number
  shipping?: number
  currency?: string
  coupon?: string
  items: GAEcommerceItem[]
}

export interface GAEcommerceRemoveFromCart extends GAEcommerce {
  currency?: string
  value: number
  items: GAEcommerceItem[]
}

export interface GASearch extends GAParams {
  searchTerm: string
}

export interface GASelectContent extends GAParams {
  contentType?: string
  contentId?: string
}

export interface GAEcommerceSelectItem extends GAEcommerce {
  itemListId?: string
  itemListName?: string
  items: GAEcommerceItem[]
}

export interface GAEcommerceSelectPromotion extends GAEcommerce {
  creativeName?: string
  creativeSlot?: string
  promotionId?: string
  promotionName?: string
  items?: GAEcommerceItem[]
}

export interface GAShare extends GAParams {
  method?: string
  contentType?: string
  itemId?: string
}

export interface GASignUp extends GAParams {
  method?: string
}

export interface GASpendVirualCurrentcy extends GAParams {
  value: number
  virtualCurrencyName: string
  itemName?: string
}

export interface GAEcommerceViewCart extends GAEcommerce {
  currency?: string
  value: number
  items: GAEcommerceItem[]
}

export interface GAEcommerceViewItem extends GAEcommerce {
  currency?: string
  value: number
  items: GAEcommerceItem[]
}

export interface GAEcommerceViewItemList extends GAEcommerce {
  itemListId?: string
  itemListName?: string
  items: GAEcommerceItem[]
}

export interface GAEcommerceViewPromotion extends GAEcommerce {
  creativeName?: string
  creativeSlot?: string
  promotionId?: string
  promotionName?: string
  items: GAEcommerceItem[]
}

export interface GAEcommerceItem {
  itemId: string
  itemName: string
  affiliation?: string
  coupon?: string
  discount?: number
  index?: number
  itemBrand?: string
  categories?: string[]
  itemListId?: string
  itemListName?: string
  itemVariant?: string
  locationId?: string
  price?: number
  quantity?: number
  [k: string]: any
}

const currencyEvents = ['add_payment_info', 'add_shipping_info', 'add_to_cart', 'add_to_wishlist',
  'begin_checkout', 'generate_lead', 'purchase', 'refund', 'remove_from_cart', 'view_cart', 'view_item']

export class GTMBase {

  eventPrefix: string
  currency: string

  constructor(key: string, eventPrefix = '', currency = 'ZAR') {
    this.eventPrefix = eventPrefix
    this.currency = currency
    const tagManagerArgs = {
      gtmId: key,
    }
    TagManager.initialize(tagManagerArgs)
  }

  private pushEvent(event: GAEvent): void {
    if (event?.ecommerce) {
      this.clearEcommerceData()
    }
    if (event?.ecommerce?.items) {
      event.ecommerce.items = event.ecommerce.items.map((item) => this.convertCategoriesToParameters(item))
    }
    if (this.eventPrefix) {
      event.event = `${this.eventPrefix}${event.event}`
    }
    // ADD in correct currency
    if (currencyEvents.includes(event.event)) {
      if (event.ecommerce && !(event.ecommerce as { currency?: string }).currency) {
        (event.ecommerce as { currency?: string }).currency = this.currency
      } else if (event.params && !(event.params as { currency?: string }).currency) {
        (event.params as { currency?: string }).currency = this.currency
      }
    }
    window.dataLayer.push(this.camelToSnake(event) as GAEvent)
  }

  private clearEcommerceData() {
    window.dataLayer.push({ ecommerce: null })
  }

  private snakeToCamel(obj: { [k: string]: any }): { [k: string]: any } {
    if (Array.isArray(obj)) {
      return obj.map(item => this.snakeToCamel(item))
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj: { [k: string]: any } = {}
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const newKey = camelCase(key)
          newObj[newKey] = this.snakeToCamel(obj[key])
        }
      }
      return newObj
    } else {
      return obj
    }
  }

  private camelToSnake(obj: { [k: string]: any }): { [k: string]: any } {
    if (Array.isArray(obj)) {
      return obj.map(item => this.camelToSnake(item))
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj: { [k: string]: any } = {}
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const newKey = snakeCase(key)
          newObj[newKey] = this.camelToSnake(obj[key])
        }
      }
      return newObj
    } else {
      return obj
    }
  }

  protected checkCurrency(ecommerce: { currency?: string, [k: string]: any }): { currency?: string, [k: string]: any } {
    if (!ecommerce.currency) {
      ecommerce.currency = this.currency
    }
    return ecommerce
  }

  protected convertCategoriesToParameters(item: GAEcommerceItem): GAEcommerceItem {
    const responseItem = { ...item }
    item.categories.forEach((cat, index) => {
      responseItem[`itemCategory${index ? (index + 1) : ''}`] = cat
    })
    delete responseItem.categories
    return responseItem
  }

  protected pageView(page: GAPageView): void {
    this.pushEvent({
      event: 'pageview',
      page,
    })
  }

  protected addPaymentInfo(ecommerce: GAEcommercePaymentInfo): void {
    this.pushEvent({
      event: 'add_payment_info',
      ecommerce,
    })
  }

  protected addShippingInfo(ecommerce: GAEcommerceShippingInfo): void {
    this.pushEvent({
      event: 'add_shipping_info',
      ecommerce,
    })
  }

  protected addToCart(ecommerce: GAEcommerceAddToCart): void {
    this.pushEvent({
      event: 'add_to_cart',
      ecommerce,
    })
  }

  protected beginCheckout(ecommerce: GAEcommerceBeginCheckout): void {
    this.pushEvent({
      event: 'begin_checkout',
      ecommerce,
    })
  }

  protected earnVirtualCurrency(params: GAEarnVirtualCurency): void {
    this.pushEvent({
      event: 'earn_virtual_currency',
      params,
    })
  }

  protected generateLead(params: GAGenerateLead): void {
    this.pushEvent({
      event: 'generate_lead',
      params,
    })
  }

  protected login(params: GALogin): void {
    this.pushEvent({
      event: 'login',
      params,
    })
  }

  protected purchase(ecommerce: GAEcommercePurchase): void {
    this.pushEvent({
      event: 'purchase',
      ecommerce,
    })
  }

  protected refund(ecommerce: GAEcommerceRefund): void {
    this.pushEvent({
      event: 'refund',
      ecommerce,
    })
  }

  protected removeFromCart(ecommerce: GAEcommerceRemoveFromCart): void {
    this.pushEvent({
      event: 'remove_from_cart',
      ecommerce,
    })
  }

  protected search(params: GASearch): void {
    this.pushEvent({
      event: 'search',
      params,
    })
  }

  protected selectContent(params: GASelectContent): void {
    this.pushEvent({
      event: 'select_content',
      params,
    })
  }

  protected selectItem(ecommerce: GAEcommerceSelectItem): void {
    this.pushEvent({
      event: 'select_item',
      ecommerce,
    })
  }

  protected selectPromotion(ecommerce: GAEcommerceSelectPromotion): void {
    this.pushEvent({
      event: 'select_promotion',
      ecommerce,
    })
  }

  protected share(params: GAShare): void {
    this.pushEvent({
      event: 'share',
      params,
    })
  }

  protected signUp(params: GASignUp): void {
    this.pushEvent({
      event: 'sign_up',
      params,
    })
  }

  protected spendVirtualCurrency(params: GASpendVirualCurrentcy): void {
    this.pushEvent({
      event: 'spend_virtual_currency',
      params,
    })
  }

  protected viewCart(ecommerce: GAEcommerceViewCart): void {
    this.pushEvent({
      event: 'view_cart',
      ecommerce,
    })
  }

  protected viewItem(ecommerce: GAEcommerceViewItem): void {
    this.pushEvent({
      event: 'view_item',
      ecommerce,
    })
  }

  protected viewItemList(ecommerce: GAEcommerceViewItemList): void {
    this.pushEvent({
      event: 'view_item_list',
      ecommerce,
    })
  }

  protected viewPromotion(ecommerce: GAEcommerceViewPromotion): void {
    this.pushEvent({
      event: 'view_promotion',
      ecommerce,
    })
  }

}
