import React, { useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { LayoutGrid, LayoutRow, LayoutCol } from '@atoms/layout'
import { UltraContainer } from '@atoms/misc'
import { ResponsivePXValue } from '@components/Theme'
import { BreadcrumbFragment, ConfigurableProductDetailsFragment, ConfigurableProductVariantDetailsFragment, ProductDetailsFragment, useGetAppQuery } from '@hooks/api'
import { UpSellWidget } from '@molecules/store'
import { AddConfigurableProductToCart } from '@molecules/store/AddConfigurableProductToCart'
import { ProductBreadCrumbs, ProductShare } from '@organisms/product'
import { MobileOSTypeEnum } from '@uctypes/api/globalTypes'
import { Mutable } from '@uctypes/global'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductInformation } from './ProductInformation'

const Sticky = styled.div`
  position: sticky;
  
    top: -15.625vw;

    @media (min-width: 30em) {
      top: -15.625vw;
    }

    @media (min-width: 30.0625em) {
      top: -3.472vw;
    }

    @media (min-width: 90em) {
      top: -50px;
    }
  
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
`

const Fixed = styled(animated.div)<{ bottom?: string }>`
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 29;
  ${props => (props.bottom) ? ResponsivePXValue('bottom', props.bottom) : 0};
  transition: transform 1s ease, opacity 0.3s ease;
`

const StickyContainer = styled.div`
  position: relative;
  
  
    height: 100%;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
`

const ProductInfoWrapper = styled.div``

export interface ConfigurableProductDetailsProps {
  product: ConfigurableProductDetailsFragment & ProductDetailsFragment
  loading: boolean
  onBack: () => void
  overwriteBreadCrumbs?: BreadcrumbFragment[]
}

interface ConfigurableProductDetailsState {
  selectedVariant: string | null
  isInfoVisible: boolean
}

const DEFAULT_STATE: ConfigurableProductDetailsState = {
  selectedVariant: null,
  isInfoVisible: null,
}

const getActualProduct = (product: ConfigurableProductDetailsFragment & ProductDetailsFragment, selectedVariant: string | null): ProductDetailsFragment => {
  const subProduct: ConfigurableProductVariantDetailsFragment = product?.__typename === 'ConfigurableProduct' && selectedVariant
    ? product.variants?.find((vari) => vari.product.sku === selectedVariant)?.product
    : null
  if (!subProduct) {
    return product
  }
  const overrideProperties: (keyof ProductDetailsFragment)[] = ['id', 'sku', 'name', 'brand', 'tags', 'shortDescription', 'description', 'coverImage', 'imageGallery', 'amastyRewardsGuestHighlights', 'amastyRewardsHighlights']
  let combineProduct = { ...subProduct } as Mutable<ProductDetailsFragment>
  overrideProperties.forEach((property) => {
    if (product[property] && !combineProduct[property]) {
      combineProduct = {
        ...combineProduct,
        [property]: product[property],
      }
    }
  })

  const attributes = [...product.attributes]
  subProduct.attributes.forEach((attribute) => {
    const index = attributes.findIndex((attrib) => attrib.code === attribute.code)
    if (index !== -1) {
      if (attribute.value) {
        attributes[index] = { ...attribute }
      }
    } else {
      attributes.push({ ...attribute })
    }
  })

  const valueAttributes = [...product.valueAttributes]
  subProduct.valueAttributes.forEach((valueAttribute) => {
    const index = valueAttributes.findIndex((valueAttrib) => valueAttrib.code === valueAttribute.code)
    if (index !== -1) {
      valueAttributes[index] = { ...valueAttribute }
    } else {
      valueAttributes.push({ ...valueAttribute })
    }
  })

  return {
    ...combineProduct,
    attributes,
    valueAttributes,
  }
}

export function ConfigurableProductDetails({ product, loading, onBack, overwriteBreadCrumbs }: ConfigurableProductDetailsProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [state, setState] = useState<ConfigurableProductDetailsState>({ ...DEFAULT_STATE })
  const productInfoRef: React.RefObject<HTMLDivElement> = useRef(null)

  const actualProduct = getActualProduct(product, state.selectedVariant)

  const _handleSelectedVariantChange = (selectedVariant: string): void => {
    setState((prevState) => update(prevState, {
      selectedVariant: {
        $set: selectedVariant,
      },
    }))
  }

  const springProps = useSpring({
    transform: state.isInfoVisible ? 'translateY(0%)' : 'translateY(100%)',
    opacity: state.isInfoVisible ? 1 : 0,
    config: { tension: 200, friction: 20 },
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setState((prevState) => update(prevState, {
            isInfoVisible: {
              $set: entry.isIntersecting,
            },
          }))
        },
        { threshold: 0.1 },
      )

      if (productInfoRef.current) {
        observer.observe(productInfoRef.current)
      }

      return () => {
        if (productInfoRef.current) {
          observer.unobserve(productInfoRef.current)
        }
      }
    }
  }, [])

  useEffect(() => {
    setState((prevState) => update(prevState, {
      selectedVariant: {
        $set: null,
      },
    }))
  }, [product?.id])

  return (
    <UltraContainer>
      <LayoutGrid>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 9 }}>
            <ProductBreadCrumbs
              categories={product?.categories}
              breadcrumbs={overwriteBreadCrumbs}
              productName={product?.name}
              goBack={onBack}
            />
            <ProductInfoWrapper ref={productInfoRef}>
              <ProductInformation product={actualProduct} />
            </ProductInfoWrapper>
          </LayoutCol>
          <LayoutCol span={{ mobile: 0, tablet: 0, desktop: 3 }}>
            <StickyContainer>
              <Sticky>
                <ProductShare sku={actualProduct.sku} />
                <Choose>
                  <When condition={!!product}>
                    <AddConfigurableProductToCart
                      selectedVariant={state.selectedVariant}
                      onSelectedVariantChange={_handleSelectedVariantChange}
                      product={product}
                      loading={loading} />
                    <UpSellWidget />
                  </When>
                </Choose>
              </Sticky>
            </StickyContainer>
          </LayoutCol>
          <DeviceContainer mobile>
            <Fixed
              bottom={(appData.app.mobileOSType === MobileOSTypeEnum.APPLE) ? '72px' : '56px'}
              className='gonative-ios-push'
              style={springProps}>
              <Choose>
                <When condition={!!product}>
                  <AddConfigurableProductToCart
                    selectedVariant={state.selectedVariant}
                    onSelectedVariantChange={_handleSelectedVariantChange}
                    product={product}
                    loading={loading} />
                </When>
              </Choose>
            </Fixed>
          </DeviceContainer>
        </LayoutRow>
      </LayoutGrid>
    </UltraContainer>
  )

}
