import React from 'react'

import styled, { CSS, css, useTheme } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  position: relative; 
`

const BadgeContainer = styled.div<{ offsetY: number, offsetX: number, color: string, backgroundColor: string, size: 'small' | 'large' }>`

  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.color};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: open-sans;
  font-weight: 700;
  pointer-events: none;
  transform: translateY(${(props): number => props.offsetY}%) translateX(${(props): number => props.offsetX}%);

  ${(props): CSS => {
    if (props.size === 'large') {
      return css`
        
    border-radius: 3.750vw;

    @media (min-width: 30em) {
      border-radius: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.833vw;
    }

    @media (min-width: 90em) {
      border-radius: 12px;
    }
  
        
    padding: 1.875vw;

    @media (min-width: 30em) {
      padding: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.417vw;
    }

    @media (min-width: 90em) {
      padding: 6px;
    }
  
        
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
        
    min-width: 7.500vw;

    @media (min-width: 30em) {
      min-width: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      min-width: 1.667vw;
    }

    @media (min-width: 90em) {
      min-width: 24px;
    }
  
        
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.667vw;
    }

    @media (min-width: 90em) {
      height: 24px;
    }
  
        
    top: -1.875vw;

    @media (min-width: 30em) {
      top: -1.875vw;
    }

    @media (min-width: 30.0625em) {
      top: -0.417vw;
    }

    @media (min-width: 90em) {
      top: -6px;
    }
  
        
    right: -1.875vw;

    @media (min-width: 30em) {
      right: -1.875vw;
    }

    @media (min-width: 30.0625em) {
      right: -0.417vw;
    }

    @media (min-width: 90em) {
      right: -6px;
    }
  
      `
    } else if (props.size === 'small') {
      return css`
        
    border-radius: 2.813vw;

    @media (min-width: 30em) {
      border-radius: 2.813vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.625vw;
    }

    @media (min-width: 90em) {
      border-radius: 9px;
    }
  
        
    padding: 1.250vw;

    @media (min-width: 30em) {
      padding: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw;
    }

    @media (min-width: 90em) {
      padding: 4px;
    }
  
        
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
        
    min-width: 5.625vw;

    @media (min-width: 30em) {
      min-width: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      min-width: 1.250vw;
    }

    @media (min-width: 90em) {
      min-width: 18px;
    }
  
        
    height: 5.625vw;

    @media (min-width: 30em) {
      height: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.250vw;
    }

    @media (min-width: 90em) {
      height: 18px;
    }
  
        
    top: -1.563vw;

    @media (min-width: 30em) {
      top: -1.563vw;
    }

    @media (min-width: 30.0625em) {
      top: -0.347vw;
    }

    @media (min-width: 90em) {
      top: -5px;
    }
  
        
    right: -1.563vw;

    @media (min-width: 30em) {
      right: -1.563vw;
    }

    @media (min-width: 30.0625em) {
      right: -0.347vw;
    }

    @media (min-width: 90em) {
      right: -5px;
    }
  
      `
    } else {
      return css`
        
    border-radius: 2.813vw;

    @media (min-width: 30em) {
      border-radius: 2.813vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.625vw;
    }

    @media (min-width: 90em) {
      border-radius: 9px;
    }
  
        
    padding: 1.250vw;

    @media (min-width: 30em) {
      padding: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw;
    }

    @media (min-width: 90em) {
      padding: 4px;
    }
  
        
    font-size: 1.875vw;

    @media (min-width: 30em) {
      font-size: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.417vw;
    }

    @media (min-width: 90em) {
      font-size: 6px;
    }
  
        
    min-width: 3.750vw;

    @media (min-width: 30em) {
      min-width: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      min-width: 0.833vw;
    }

    @media (min-width: 90em) {
      min-width: 12px;
    }
  
        
    height: 3.750vw;

    @media (min-width: 30em) {
      height: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.833vw;
    }

    @media (min-width: 90em) {
      height: 12px;
    }
  
        
    top: -1.563vw;

    @media (min-width: 30em) {
      top: -1.563vw;
    }

    @media (min-width: 30.0625em) {
      top: -0.347vw;
    }

    @media (min-width: 90em) {
      top: -5px;
    }
  
        
    right: -1.563vw;

    @media (min-width: 30em) {
      right: -1.563vw;
    }

    @media (min-width: 30.0625em) {
      right: -0.347vw;
    }

    @media (min-width: 90em) {
      right: -5px;
    }
  
      `
    }
  }}

`

const TextContainer = styled.span`

  position: relative;

`

export interface BadgeProps {
  children: React.ReactNode
  count: number
  color?: string
  backgroundColor?: string
  offsetY?: number
  offsetX?: number
  size?: 'small' | 'large'
}

export function Badge({ children, count, offsetX = 40, offsetY = -40, color, backgroundColor, size = 'large' }: BadgeProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      {children}
      <BadgeContainer
        size={size}
        color={color || theme.colors.white.floralWhite}
        backgroundColor={backgroundColor || theme.colors.orange.casablanca}
        offsetX={offsetX}
        offsetY={offsetY}>
        <TextContainer>
          {count ?? 0}
        </TextContainer>
      </BadgeContainer>
    </Container>
  )

}
