import { sentenceCase } from 'change-case'
import memoize from 'fast-memoize'
import { ColorGroups, css, DefaultTheme, FlattenInterpolation, LayoutSizeClass, ThemeProps } from 'styled-components'

import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { colors } from './colors'

interface DeviceSizeClass extends LayoutSizeClass {
  ultra: number
}

const cahcedStyles: { [k: string]: string } = {}
const VW_PRECISION = 3
const MAX_WIDTH = 1440
const breakpointSize: DeviceSizeClass = {
  ultra: MAX_WIDTH,
  desktop: 481,
  tablet: 480,
  mobile: 480,
}

const calculationSize: LayoutSizeClass = {
  desktop: 1440,
  tablet: 320,
  mobile: 320,
}

const pagePadding: LayoutSizeClass = {
  desktop: 80,
  tablet: 30,
  mobile: 15,
}

interface SizeClasses {
  ultra: string
  desktop: string
  tablet: string
  mobile: string
  ultraImage: number
  desktopImage: number
  tabletImage: number
  mobileImage: number
  [k: string]: any
}

export interface FormFactor {
  mobile?: string
  tablet?: string
  desktop?: string
  ultra?: string | number
}

const sizeClasses: SizeClasses = {
  ultra: '',
  desktop: '',
  tablet: '',
  mobile: '',
  ultraImage: 2000,
  desktopImage: 1600,
  tabletImage: 1024,
  mobileImage: 768,
}

Object.keys(breakpointSize).forEach((key: keyof DeviceSizeClass) => {
  const val: number = breakpointSize[key] as number
  sizeClasses[key] = `@media (min-width: ${val / 16}em)`
})

/**
 * @namespace
 * @property {object}  colors                 - The default values for parties.
 * @property {object}  colors.green          - The default number of players.
 * @property {string}  colors.green.holly    - The default level for the party.
 * @property {object}  defaults.treasure      - The default treasure.
 * @property {number}  defaults.treasure.gold - How much gold the party starts with.
 */

const isMobile = (): boolean => {
  if (typeof window === 'undefined') {
    return true
  } else {
    if (document.documentElement.clientWidth < breakpointSize.tablet) {
      return true
    }
    return false
  }
}
const isTablet = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  } else {
    if (
      document.documentElement.clientWidth >= breakpointSize.tablet &&
      document.documentElement.clientWidth < breakpointSize.desktop
    ) {
      return true
    }
    return false
  }
}
const isDesktop = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  } else {
    if (document.documentElement.clientWidth >= breakpointSize.desktop) {
      return true
    }
    return false
  }
}
const isUltra = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  } else {
    if (document.documentElement.clientWidth > breakpointSize.ultra) {
      return true
    }
    return false
  }
}

export const theme = {
  colors,
  MAX_WIDTH,
  uw: (size: number): string => {
    return Math.trunc((MAX_WIDTH / 100) * size) + 'px'
  },
  screenWidth: (): number => {
    if (typeof window === 'undefined') {
      return MAX_WIDTH
    }
    return document.documentElement.clientWidth
  },
  isMobile,
  isTablet,
  isDesktop,
  isUltra,
  getPxWidth: (px: number): number => {
    if (typeof window !== 'undefined' && document.documentElement.clientWidth >= MAX_WIDTH) {
      return px
    }
    if (isMobile()) {
      return Math.round(document.documentElement.clientWidth / calculationSize.mobile * px)
    } else if (isTablet()) {
      return Math.round(document.documentElement.clientWidth / calculationSize.tablet * px)
    } else if (isDesktop()) {
      return Math.round(document.documentElement.clientWidth / calculationSize.desktop * px)
    }
    return px
  },
  getVwWidth: (px: number): string => {
    if (typeof window !== 'undefined') {
      return px + 'px'
    }
    if (isMobile()) {
      return (px / calculationSize.mobile * 100).toFixed(VW_PRECISION) + 'vw'
    } else if (isTablet()) {
      return (px / calculationSize.tablet * 100).toFixed(VW_PRECISION) + 'vw'
    } else if (isDesktop()) {
      return (px / calculationSize.desktop * 100).toFixed(VW_PRECISION) + 'vw'
    }
    return px + 'px'
  },
  ...sizeClasses,
  breakpointSize,
  calculationSize,
  pagePadding,
}

export const getAllColors = (): { [k: string]: string } => {
  const allColors: { [k: string]: string } = {}
  for (let g = 0; g < Object.keys(theme.colors).length; g++) {
    const group = Object.keys(theme.colors)[g] as keyof ColorGroups
    for (let c = 0; c < Object.keys(theme.colors[group]).length; c++) {
      const color = Object.keys(theme.colors[group])[c] as string
      // @ts-ignore
      allColors[sentenceCase(color)] = theme.colors[group][color]
    }
  }
  return allColors
}

const getUltraValue = (value: FormFactor): string | number => {
  const isUltraNumber = typeof value?.ultra === 'number'
  const isDesktopNumber = typeof value?.desktop === 'number'
  if (value?.ultra) {
    return isUltraNumber ? theme.uw(Number(value?.ultra)) : value?.ultra
  }
  if (!value?.ultra && value?.desktop) {
    if (isDesktopNumber) {
      return theme.uw(Number(value?.desktop))
    }
    let safety = 0
    let match = value?.desktop.match(/(-?\d+\.?(?:\d+)?vw)/)
    let newValue = value?.desktop
    while (match && safety < 10) {
      const value = Number(match[1].replace('vw', ''))
      newValue = newValue.replace(match[1].trim(), theme.uw(value))
      match = newValue.match(/(-?\d+\.?(?:\d+)?vw)/)
      safety++
    }
    return newValue
  }
  return 'initial'
}

const _ResponsiveProperty = (prop: string, value: FormFactor): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
  ${`${prop}: ${value?.mobile ? value.mobile : 'initial'};`}
      
  ${(props): string => props.theme.tablet} {
    ${`${prop}: ${value?.tablet ? value.tablet : 'initial'};`}
  }

  ${(props): string => props.theme.desktop} {
    ${`${prop}: ${value?.desktop ? value.desktop : 'initial'};`}
  }

  ${(props): string => props.theme.ultra} {
    ${`${prop}: ${getUltraValue(value)};`}
  }
`

export const ResponsiveProperty = memoize(_ResponsiveProperty)

function getDeviceSize(deviceType: DeviceTypeEnum, forceDevice?: DeviceTypeEnum): number {
  if (forceDevice) {
    switch (forceDevice) {
      case DeviceTypeEnum.MOBILE:
        return calculationSize.mobile
      case DeviceTypeEnum.TABLET:
        return calculationSize.tablet
      case DeviceTypeEnum.DESKTOP:
      case DeviceTypeEnum.ULTRA:
        return calculationSize.desktop
    }
  }
  switch (deviceType) {
    case DeviceTypeEnum.MOBILE:
      return calculationSize.mobile
    case DeviceTypeEnum.TABLET:
      return calculationSize.tablet
    case DeviceTypeEnum.DESKTOP:
    case DeviceTypeEnum.ULTRA:
      return calculationSize.desktop
  }
}

function getDeviceValue(deviceType: DeviceTypeEnum, value: { mobile?: string, tablet?: string, desktop?: string }, forceDevice?: DeviceTypeEnum): string {
  if (forceDevice) {
    switch (forceDevice) {
      case DeviceTypeEnum.MOBILE:
        return value.mobile || 'initial'
      case DeviceTypeEnum.TABLET:
        return value.tablet || 'initial'
      case DeviceTypeEnum.DESKTOP:
      case DeviceTypeEnum.ULTRA:
        return value.desktop || 'initial'
    }
  }
  switch (deviceType) {
    case DeviceTypeEnum.MOBILE:
      return value.mobile || 'initial'
    case DeviceTypeEnum.TABLET:
      return value.tablet || 'initial'
    case DeviceTypeEnum.DESKTOP:
    case DeviceTypeEnum.ULTRA:
      return value.desktop || 'initial'
  }
}

function getForcedDevice(): DeviceTypeEnum | undefined {
  if (typeof window !== 'undefined' && window.forcedRenderingDevice) {
    return window.forcedRenderingDevice
  }
}

function getResponsiveValue(cssValue: string, deviceType: DeviceTypeEnum, forceDevice: DeviceTypeEnum): string {
  let mobileMatch = cssValue.match(/(-?\d+\.?(?:\d+)?px)/)
  let safety = 0
  let newCssValue = cssValue
  const screenTargetSize = getDeviceSize(deviceType, forceDevice)
  while (mobileMatch && safety < 10) {
    const value = Number(mobileMatch[1].replace('px', ''))
    newCssValue = newCssValue.replace(mobileMatch[1].trim(), (value / screenTargetSize * 100).toFixed(VW_PRECISION) + 'vw')
    mobileMatch = newCssValue.match(/(-?\d+\.?(?:\d+)?px)/)
    safety++
  }
  return newCssValue
}

function ResponsivePXObjectValue(prop: string, value: { mobile?: string, tablet?: string, desktop?: string }): string {
  const forceDevice = getForcedDevice()
  const mobileValue = getDeviceValue(DeviceTypeEnum.MOBILE, value, forceDevice)
  const tabletValue = getDeviceValue(DeviceTypeEnum.TABLET, value, forceDevice)
  const desktopValue = getDeviceValue(DeviceTypeEnum.DESKTOP, value, forceDevice)
  const ultraValue = getDeviceValue(DeviceTypeEnum.ULTRA, value, forceDevice)
  return `
    ${`${prop}: ${getResponsiveValue(mobileValue, DeviceTypeEnum.MOBILE, forceDevice)};`}

    ${theme.tablet} {
      ${`${prop}: ${getResponsiveValue(tabletValue, DeviceTypeEnum.TABLET, forceDevice)};`}
    }

    ${theme.desktop} {
      ${`${prop}: ${getResponsiveValue(desktopValue, DeviceTypeEnum.DESKTOP, forceDevice)};`}
    }

    ${theme.ultra} {
      ${`${prop}: ${ultraValue};`}
    }
  `
}

function ResponsivePXStringValue(prop: string, value: string): string {
  const forceDevice = getForcedDevice()
  return `
    ${`${prop}: ${getResponsiveValue(value, DeviceTypeEnum.MOBILE, forceDevice)};`}

    ${theme.tablet} {
      ${`${prop}: ${getResponsiveValue(value, DeviceTypeEnum.TABLET, forceDevice)};`}
    }

    ${theme.desktop} {
      ${`${prop}: ${getResponsiveValue(value, DeviceTypeEnum.DESKTOP, forceDevice)};`}
    }

    ${theme.ultra} {
      ${`${prop}: ${value};`}
    }
  `
}

function _ResponsivePXValue(prop: string, value: string | { mobile?: string, tablet?: string, desktop?: string }): string {
  if (typeof value === 'object') {
    return ResponsivePXObjectValue(prop, value)
  } else {
    return ResponsivePXStringValue(prop, value)
  }
}

export const ResponsivePXValue = memoize(_ResponsivePXValue, {
  cache: {
    create() {
      return {
        has(key) {
          const forceDevice = getForcedDevice() ?? ''
          const actualKey = forceDevice ? `${forceDevice}-${key}` : key
          return (actualKey in cahcedStyles)
        },
        get(key) {
          const forceDevice = getForcedDevice() ?? ''
          const actualKey = forceDevice ? `${forceDevice}-${key}` : key
          return cahcedStyles[actualKey]
        },
        set(key, value) {
          const forceDevice = getForcedDevice() ?? ''
          const actualKey = forceDevice ? `${forceDevice}-${key}` : key
          cahcedStyles[actualKey] = value
        },
      }
    },
  },
})

export const IconBorderRadius = css`
   ${ResponsiveProperty('border-radius', { mobile: '1.1vw', tablet: '0.55vw', desktop: '0.27vw' })}
`

export const ContainerBorderRadius = css`
  ${ResponsiveProperty('border-radius', { mobile: '1.66vw', tablet: '1.56vw', desktop: '1.11vw' })}
`

export const ZeroSpace = css`
  margin: 0;
  padding: 0;
`

export const PageWidth = css`
  ${ResponsivePXValue('width', { mobile: `${calculationSize.mobile - (pagePadding.mobile * 2)}px`, tablet: `${calculationSize.tablet - (pagePadding.tablet * 2)}px`, desktop: `${calculationSize.desktop - (pagePadding.desktop * 2)}px` })}
`

export const SidePagePadding = css`
  ${PageWidth}
  ${ResponsivePXValue('padding', { mobile: `0 ${pagePadding.mobile}px`, tablet: `0 ${pagePadding.tablet}px`, desktop: `0 ${pagePadding.desktop}px` })}
`

export const TopPagePadding = css`
  
    padding-top: 6.250vw;

    @media (min-width: 30em) {
      padding-top: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      padding-top: 1.736vw;
    }

    @media (min-width: 90em) {
      padding-top: 25px;
    }
  
`

export const MobileFullWidth = css`
  ${ResponsiveProperty('position', { mobile: 'absolute', tablet: 'absolute', desktop: 'relative' })}
  ${ResponsiveProperty('width', { mobile: '100vw', tablet: '100vw', desktop: '100%' })}
  ${ResponsiveProperty('margin-left', { mobile: '-4vw', tablet: '-8vw' })}
`

export const LiteBoxShadow = css`
  
    box-shadow: 0.000vw 2.500vw 3.125vw rgba(0, 0, 0, 0.035);

    @media (min-width: 30em) {
      box-shadow: 0.000vw 2.500vw 3.125vw rgba(0, 0, 0, 0.035);
    }

    @media (min-width: 30.0625em) {
      box-shadow: 0.000vw 0.556vw 0.694vw rgba(0, 0, 0, 0.035);
    }

    @media (min-width: 90em) {
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.035);
    }
  
`

export const BoxShadow = css`
  
    box-shadow: 0.000vw 1.875vw 2.500vw rgba(0, 0, 0, 0.07);

    @media (min-width: 30em) {
      box-shadow: 0.000vw 1.875vw 2.500vw rgba(0, 0, 0, 0.07);
    }

    @media (min-width: 30.0625em) {
      box-shadow: 0.000vw 0.417vw 0.556vw rgba(0, 0, 0, 0.07);
    }

    @media (min-width: 90em) {
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.07);
    }
  
`

export const StrongBoxShadow = css`
  
    box-shadow: 0.000vw 2.500vw 3.750vw rgba(0, 0, 0, 0.14);

    @media (min-width: 30em) {
      box-shadow: 0.000vw 2.500vw 3.750vw rgba(0, 0, 0, 0.14);
    }

    @media (min-width: 30.0625em) {
      box-shadow: 0.000vw 0.556vw 0.833vw rgba(0, 0, 0, 0.14);
    }

    @media (min-width: 90em) {
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.14);
    }
  
`

export const SectionMargins = css`
  
    margin-top: 15.000vw;

    @media (min-width: 30em) {
      margin-top: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 4.167vw;
    }

    @media (min-width: 90em) {
      margin-top: 60px;
    }
  
  
    margin-bottom: 15.000vw;

    @media (min-width: 30em) {
      margin-bottom: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 4.167vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 60px;
    }
  
`
