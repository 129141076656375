import React from 'react'

import styled, { css, useTheme } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  display: flex;
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.278vw;
    }

    @media (min-width: 90em) {
      gap: 4px;
    }
  
`

const Common = css`
  
    border-radius: 1.563vw;

    @media (min-width: 30em) {
      border-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-radius: 5px;
    }
  
  
    width: 2.500vw;

    @media (min-width: 30em) {
      width: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 0.556vw;
    }

    @media (min-width: 90em) {
      width: 8px;
    }
  
  
    height: 2.500vw;

    @media (min-width: 30em) {
      height: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.556vw;
    }

    @media (min-width: 90em) {
      height: 8px;
    }
  
`

const Dot = styled.div<{ color: string }>`
  cursor: pointer;
  background-color: ${(props): string => props.color};
  ${Common}
`

export interface DotPaginationProps {
  currentIndex: number
  total: number
  className?: string
  selectedColor?: string
  defaultColor?: string
  onChange?: (index: number) => void
}

export function DotPagination({ currentIndex, total, className, selectedColor, defaultColor, onChange }: DotPaginationProps): JSX.Element {

  const theme = useTheme()

  if (!selectedColor) {
    selectedColor = theme.colors.grey.stormDust
  }

  if (!defaultColor) {
    defaultColor = theme.colors.grey.gallery
  }

  const $dots: JSX.Element[] = []
  for (let d = 0; d < total; d++) {
    if (currentIndex === d) {
      $dots.push(<Dot key={d} color={selectedColor} onClick={() => onChange?.(d)} />)
    } else {
      $dots.push(<Dot key={d} color={defaultColor} onClick={() => onChange?.(d)} />)
    }
  }

  return (
    <Container className={className}>
      {$dots}
    </Container>
  )

}
