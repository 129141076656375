import React, { useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import { AppPlugin, APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Heading, UltraContainer } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { ConfigurableProductDetailsFragment, ProductListFragment, useGetRelatedProductsQuery, ProductDetailsFragment, useGetAppQuery, BundleProductDetailsFragment } from '@hooks/api/index'
import { useLoadingData } from '@hooks/UseLoadingData'
import { ProductSlider } from '@organisms/index'
import { BundleProductDetails } from '@organisms/shop/BundleProductDetails'
import { ConfigurableProductDetails } from '@organisms/shop/ConfigurableProductDetails'
import { SimpleProductDetails } from '@organisms/shop/SimpleProductDetails'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimizationParams, SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const RelatedProductsWrapper = styled.div<{ spaceBelow: boolean }>`
  width: 100%;
  overflow: scroll;
  background-color: ${(): string => theme.colors.white.fantasy};
  .recommended {
    ${(props) => props.spaceBelow ? ResponsivePXValue('padding', { mobile: '0 0 96px', tablet: '32px 0', desktop: '16px 104px' }) : ResponsivePXValue('padding', { mobile: '0', tablet: '32px 0 32px', desktop: '16px 104px 0' })}
  }

  .filter-title {
    
    padding: 0 0 0 4.375vw;

    @media (min-width: 30em) {
      padding:  0 0 0 11.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0 0 12.500vw ;
    }

    @media (min-width: 90em) {
      padding: 0 0 0 180px ;
    }
  
    margin: 0;
  }

  .recommended .filter-title {
    
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.667vw;
    }

    @media (min-width: 90em) {
      font-size: 24px;
    }
  
    
    line-height: 6.875vw;

    @media (min-width: 30em) {
      line-height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.083vw;
    }

    @media (min-width: 90em) {
      line-height: 30px;
    }
  
  }
`

const RelatedProductsHeader = styled.div<{ sliderKey: number }>`
 
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.green.swansDown};
  
    mobile: flex-start;

    @media (min-width: 30em) {
      mobile: space-between;
    }

    @media (min-width: 30.0625em) {
      mobile: space-between;
    }

    @media (min-width: 90em) {
      mobile: space-between;
    }
  
  
    padding: 7.813vw 0 7.813vw;

    @media (min-width: 30em) {
      padding: 7.813vw 0 7.813vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.736vw 0 1.736vw;
    }

    @media (min-width: 90em) {
      padding: 25px 0 25px;
    }
  

`

const MobileNavSpacer = styled.div`
  display: block;
  
    height: 37.500vw;

    @media (min-width: 30em) {
      height: 0;
    }

    @media (min-width: 30.0625em) {
      height: 0;
    }

    @media (min-width: 90em) {
      height: 0;
    }
  ;
  width: 100%;
`

export interface ProductProps {
  product?: ProductDetailsFragment
  loading: boolean
}

export const Product = React.memo(
  function Product({ product: productData, loading: productLoading }: ProductProps): JSX.Element {

    const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

    const { state } = useLocation()
    const [hasViewedProduct, setHasViewedProduct] = useState<boolean>(false)
    const navigate = useNavigate()
    const events = useEvents()

    const { data: relatedProductsData } = useGetRelatedProductsQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        slug: productData?.urlKey,
      },
      skip: !productData,
    })

    const product = useLoadingData<ProductDetailsFragment>({ data: productData, loading: productLoading, defaultData: null })

    const _handleGoBack = (): void => {
      navigate(-1)
    }

    useEffect(() => {
      if (!appData.app.isNativeApp) {
        if (appData.app.deviceType === DeviceTypeEnum.MOBILE) {
          AppPlugin.shared().hideFooter()
        } else if (appData.app.deviceType === DeviceTypeEnum.DESKTOP) {
          AppPlugin.shared().showFooter()
        }
        return () => {
          AppPlugin.shared().showFooter()
        }
      }
    }, [appData.app.deviceType])

    useEffect(() => {
      if (productData && !hasViewedProduct) {
        setHasViewedProduct(true)
        events.hasViewedProduct(productData, state)
      }
    }, [productData])

    const seo: SearchEngineOptimizationParams = {
      name: product?.name,
      title: product?.name,
      meta: [], // <---- TODO
    }

    return (
      <>
        <SearchEngineOptimization seo={seo} />
        <Choose>
          <When condition={product?.__typename === 'ConfigurableProduct'}>
            <ConfigurableProductDetails
              product={product as ProductDetailsFragment & ConfigurableProductDetailsFragment}
              loading={productLoading}
              overwriteBreadCrumbs={state}
              onBack={_handleGoBack} />
          </When>
          <When condition={product?.__typename === 'BundleProduct'}>
            <BundleProductDetails
              product={product as ProductDetailsFragment & BundleProductDetailsFragment}
              loading={productLoading}
              overwriteBreadCrumbs={state}
              onBack={_handleGoBack} />
          </When>
          <Otherwise>
            <SimpleProductDetails
              product={product}
              loading={productLoading}
              overwriteBreadCrumbs={state}
              onBack={_handleGoBack} />
          </Otherwise>
        </Choose>

        <If condition={relatedProductsData?.product?.relatedProducts.length > 0}>
          <RelatedProductsWrapper spaceBelow={(relatedProductsData?.product?.upsellProducts.length === 0 || relatedProductsData?.product?.crosssellProducts.length === 0)}>
            <RelatedProductsHeader sliderKey={0}>
              <Heading variant='h3' className='filter-title' align='left' color={theme.colors.green.greenVogue}>
              Recommended products
              </Heading>
            </RelatedProductsHeader>
            <UltraContainer className='recommended'>
              <ProductSlider
                showCount={false}
                products={relatedProductsData?.product?.relatedProducts as unknown as ProductListFragment[]}
                total={relatedProductsData?.product?.relatedProducts.length}/>
            </UltraContainer>
          </RelatedProductsWrapper>
        </If>

        <If condition={relatedProductsData?.product?.upsellProducts.length > 0}>
          <RelatedProductsWrapper spaceBelow={relatedProductsData?.product?.crosssellProducts.length === 0}>
            <RelatedProductsHeader sliderKey={0}>
              <Heading variant='h3' className='filter-title' align='left' color={theme.colors.green.greenVogue}>
              You might also like
              </Heading>
            </RelatedProductsHeader>
            <UltraContainer className='recommended'>
              <ProductSlider
                showCount={false}
                products={relatedProductsData?.product?.upsellProducts as unknown as ProductListFragment[]}
                total={relatedProductsData?.product?.upsellProducts.length}/>
            </UltraContainer>
          </RelatedProductsWrapper>
        </If>
        <MobileNavSpacer />
      </>
    )

  },
)
