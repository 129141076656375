
import { Paragraph } from "@atoms/typography"
import { ResponsivePXValue } from "@components/Theme"
import React, { RefObject, useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { FormContext, FormContextProperties } from '@molecules/inputs/index'
import { Field } from "rc-field-form"
import update from "react-addons-update"

const Track = styled.div`
    position: absolute;
    
    border-radius: 1.563vw;

    @media (min-width: 30em) {
      border-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-radius: 5px;
    }
  
    
    height: 1.563vw;

    @media (min-width: 30em) {
      height: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.347vw;
    }

    @media (min-width: 90em) {
      height: 5px;
    }
  
    background-color: ${({ theme }) => theme.colors.grey.gallery};
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
`

const ActiveTrack = styled.div`
    position: absolute;
    
    border-radius: 1.563vw;

    @media (min-width: 30em) {
      border-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-radius: 5px;
    }
  
    
    height: 1.563vw;

    @media (min-width: 30em) {
      height: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.347vw;
    }

    @media (min-width: 90em) {
      height: 5px;
    }
  
    background-color: ${({ theme }) => theme.colors.green.greenVogue};
    top: 50%;
    transform: translateY(-50%);
`

const Thumb = styled.div`
    position: absolute;
    
    height: 5.625vw;

    @media (min-width: 30em) {
      height: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.250vw;
    }

    @media (min-width: 90em) {
      height: 18px;
    }
  
    
    width: 5.625vw;

    @media (min-width: 30em) {
      width: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.250vw;
    }

    @media (min-width: 90em) {
      width: 18px;
    }
  
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.green.greenVogue};
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2;
`

interface RangeInputInnerProps {
    value: [number, number]
    onChange: (value: [number, number]) => void
    min: number
    max: number
    step: number
    loading: boolean
    disabled: boolean
}

interface Values {
    value?: { min: number, max: number }
    onChange?: (value: [min: number, max: number]) => void
}
    
interface RangeInputInnerState {
    isDragging: boolean
    isInitialized: boolean
}

const DEFAULT_STATE: RangeInputInnerState = {
    isDragging: false,
    isInitialized: false
}
function RangeInputInner({ value, onChange, min, max, step, loading, disabled }: RangeInputInnerProps) {
    
    const [state, setState] = useState<RangeInputInnerState>({ ...DEFAULT_STATE })
    const trackRef = useRef<HTMLDivElement>(null)
    const minThumbRef = useRef<HTMLDivElement>(null)
    const maxThumbRef = useRef<HTMLDivElement>(null)
    const lastUpdateRef = useRef<number>(Date.now())
    const rafRef = useRef<number>()
    const prevValuesRef = useRef(value)
    

    const [minValue, maxValue] = [
        Math.max(min, Math.min(value[0], value[1])),
        Math.min(max, Math.max(value[1], value[0]))
    ]

    const clamp = useCallback((value: number, lower: number, upper: number) => 
        Math.max(lower, Math.min(value, upper )), [])
  
    const updateThumbPositions = useCallback((thumbRef: RefObject<HTMLDivElement>, value: number) => {
        
        if (!trackRef.current || !minThumbRef.current || !maxThumbRef.current) return
        const trackWidth = trackRef.current.clientWidth
        
        if (trackWidth === 0) return

        const position = (value - min) / (max - min)
        const pixelPosition = position * trackWidth
        thumbRef.current.style.left = `${pixelPosition}px`

    }, [min, max])
  
    useEffect(() => {
        if (state.isInitialized ) {
            updateThumbPositions(maxThumbRef, maxValue)
            updateThumbPositions(minThumbRef, minValue)
        }
        
    }, [updateThumbPositions, minValue, maxValue, state.isInitialized])

    
    const handleMove = useCallback((clientX: number, thumbRef: RefObject<HTMLDivElement>, updateValue: (newValue: number) => void) => {
        
        if (!trackRef.current || !thumbRef.current) return
        const now = Date.now()
        if (now - lastUpdateRef.current < 16) return
        lastUpdateRef.current = now
        
        const trackRect = trackRef.current.getBoundingClientRect()
        const newPosition = clamp(clientX - trackRect.left, 0, trackRect.width)
        const percentage = newPosition / trackRect.width
        const newValue = Math.round(percentage * (max - min) / step) * step + min

        updateValue(clamp(newValue, min, max))

    }, [min, max, step, updateThumbPositions])
    
    const handleStart = useCallback((thumbRef: RefObject<HTMLDivElement>, updateValue: (newValue: number) => void ) => (e: MouseEvent | TouchEvent) => {
        if (loading || disabled) return
        e.preventDefault()

        setState(prevState => update(prevState, { isDragging: { $set: true } }))

        const moveHandler = (e: MouseEvent | TouchEvent) => {
            const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX
            handleMove(clientX, thumbRef, updateValue)
        }

        const handleEnd = () => {
            setState(prevState => update(prevState, { isDragging: { $set: false } }))
            document.removeEventListener('mousemove', moveHandler)
            document.removeEventListener('mouseup', handleEnd)
            document.removeEventListener('touchmove', moveHandler)
            document.removeEventListener('touchend', handleEnd)

        }
    
        document.addEventListener('mousemove', moveHandler)
        document.addEventListener('mouseup', handleEnd)
        document.addEventListener('touchmove', moveHandler)
        document.addEventListener('touchend', handleEnd)
    }, [loading, disabled, handleMove])

    useEffect(() => {
        if (!state.isInitialized && trackRef.current) {
            const initializeComponent = () => {
                if (trackRef.current?.clientWidth > 0) {
                    updateThumbPositions(minThumbRef, minValue)
                    updateThumbPositions(maxThumbRef, maxValue)
                    setState(prevState => update(prevState, { isInitialized: { $set: true } }))
                } else {
                    requestAnimationFrame(initializeComponent)
                }
            }
            initializeComponent()
        }
    }, [updateThumbPositions, state.isInitialized, minValue, maxValue])

    useEffect(() => {
        if (!state.isInitialized) return

        if (minValue !== prevValuesRef.current[0]) {
            rafRef.current = requestAnimationFrame(() => {
                updateThumbPositions(minThumbRef, minValue)
            })
        }

        if (maxValue !== prevValuesRef.current[1]) {
            rafRef.current = requestAnimationFrame(() => {
                updateThumbPositions(maxThumbRef, maxValue)
            })
        }

        prevValuesRef.current = [minValue, maxValue]
    }, [minValue, maxValue, updateThumbPositions, state.isInitialized])


    useEffect(() => {
        return () => {
            if (rafRef.current) cancelAnimationFrame(rafRef.current)
        }
    }, [])

    const updateMinValue = (newMin: number) => {
        const clampedMin = Math.min(newMin, maxValue - step)
        onChange([clampedMin, maxValue])
    }
    
    const updateMaxValue = (newMax: number) => {
        const clampedMin = Math.max(newMax, minValue + step)
        onChange([minValue, clampedMin])
    }
  
    return (
        <>
            <Track ref={trackRef} />
            <ActiveTrack
                style={{
                left: `${((minValue - min) / (max - min)) * 100}%`,
                right: `${100 - ((maxValue - min) / (max - min)) * 100}%`,}}/>
            <Thumb
                ref={minThumbRef}
                onMouseDown={handleStart(minThumbRef, updateMinValue)}
                onTouchStart={handleStart(minThumbRef, updateMinValue)}
                style={{ cursor: loading || disabled ? 'not-allowed' : 'pointer' }}/>
            <Thumb
                ref={maxThumbRef}
                onMouseDown={handleStart(maxThumbRef, updateMaxValue)}
                onTouchStart={handleStart(maxThumbRef, updateMaxValue)}
                style={{ cursor: loading || disabled ? 'not-allowed' : 'pointer' }}/>
        </>
    )
  }

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    
    height: 20.313vw;

    @media (min-width: 30em) {
      height: 20.313vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.514vw;
    }

    @media (min-width: 90em) {
      height: 65px;
    }
  
    
    margin-top: 5.000vw;

    @media (min-width: 30em) {
      margin-top: initial;
    }

    @media (min-width: 30.0625em) {
      margin-top: initial;
    }

    @media (min-width: 90em) {
      margin-top: initial;
    }
  
`

const RangeLabels = styled.div`
    display: flex;
    justify-content: space-between;
    
    margin-bottom: 4.375vw;

    @media (min-width: 30em) {
      margin-bottom: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.972vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 14px;
    }
  
`
  
export interface RangeInputProps {
    name: string
    min: number 
    max: number
    step: number
    loading?: boolean
}

export function RangeInput({ name, min, max, step, loading = false }: RangeInputProps) {

    const initialValuesRef = useRef<[number, number]>([min, max])
    return (
        <Field name={name} initialValue={initialValuesRef.current}>
        {(control: { value?: [number, number]; onChange?: (value: [number, number]) => void }) => {
            const { value = initialValuesRef.current, onChange = () => {} } = control
            return (
                <SliderContainer>
                    <RangeLabels>
                        <Paragraph>R{value[0]}</Paragraph>
                        <Paragraph>R{value[1]}</Paragraph>
                    </RangeLabels>
                    <FormContext.Consumer>
                    {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
                        <RangeInputInner
                            value={value}
                            onChange={onChange}
                            min={min}
                            max={max}
                            step={step}
                            loading={loading || formLoading}
                            disabled={formDisabled}/>    
                    )}
                    </FormContext.Consumer>
                </SliderContainer>
            ) 
        }}
        </Field>
    ) 
}



