import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'
import { Heading } from '@atoms/typography'

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
    height: 22.813vw;

    @media (min-width: 30em) {
      height: 22.813vw;
    }

    @media (min-width: 30.0625em) {
      height: 5.069vw;
    }

    @media (min-width: 90em) {
      height: 73px;
    }
  


  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const NumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1;
  background: ${(props) => props.theme.colors.green.magicMint};
  color: ${(props) => props.theme.colors.green.bottleGreen};
  height: 100%;
   
    width: 28.125vw;

    @media (min-width: 30em) {
      width: 25.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 5.556vw;
    }

    @media (min-width: 90em) {
      width: 80px;
    }
  
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;

  
    gap: 6.250vw;

    @media (min-width: 30em) {
      gap: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.389vw;
    }

    @media (min-width: 90em) {
      gap: 20px;
    }
  
`

const ChildrenContainer = styled.div`
  width: 100%;
  z-index: 0;
  background: ${(props) => props.theme.colors.white.pureWhite};
  display: flex;
  align-items: center;

  height: 100%;
  
    margin-left: -11.875vw;

    @media (min-width: 30em) {
      margin-left: -11.875vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: -2.639vw;
    }

    @media (min-width: 90em) {
      margin-left: -38px;
    }
  
  
    padding-left: 12.500vw;

    @media (min-width: 30em) {
      padding-left: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      padding-left: 2.778vw;
    }

    @media (min-width: 90em) {
      padding-left: 40px;
    }
  
  
    padding-right: 2.500vw;

    @media (min-width: 30em) {
      padding-right: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding-right: 0.556vw;
    }

    @media (min-width: 90em) {
      padding-right: 8px;
    }
  
`

interface NumberedListProps {
  items: JSX.Element[]
}

export function NumberedList({ items }: NumberedListProps): JSX.Element {

  let item
  let index
  return (
    <ListContainer>
      <For index='index' each='item' of={items}>
        <ListItemContainer key={index}>
          <NumberContainer>
            <Heading variant='h1'>
              {parseInt(index)+1}
            </Heading>
            </NumberContainer>
          <ChildrenContainer>{item}</ChildrenContainer>
        </ListItemContainer>
      </For>
    </ListContainer>
  )
}
