import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums, Paragraph, ResponsiveImage, Title } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { PageMenuCardSectionFragment, PageMenuImageCardFragment } from '@hooks/api'

const Container = styled.div`
  cursor: pointer;
  
    padding: 5.000vw 10.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw 10.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 2.222vw;
    }

    @media (min-width: 90em) {
      padding: 16px 32px;
    }
  
`

const TitleContainer = styled.div`
  
    padding: 5.000vw 0;

    @media (min-width: 30em) {
      padding: 5.000vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 0;
    }

    @media (min-width: 90em) {
      padding: 16px 0;
    }
  
`

const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  
    width: 62.500vw;

    @media (min-width: 30em) {
      width: 62.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 13.889vw;
    }

    @media (min-width: 90em) {
      width: 200px;
    }
  
  
    height: 62.500vw;

    @media (min-width: 30em) {
      height: 62.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 13.889vw;
    }

    @media (min-width: 90em) {
      height: 200px;
    }
  
`

const CardImageBlock = styled.div`
  width: 100%;
  
    height: 43.750vw;

    @media (min-width: 30em) {
      height: 43.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 9.722vw;
    }

    @media (min-width: 90em) {
      height: 140px;
    }
  
  flex-shrink: 0;
`

const CardTitleBlock = styled.div<{ backgroundColor: string, textColor: string }>`
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.textColor};
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 8px;
    }
  
`

const ChevronContainer = styled.div`
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
`

const IconBlock = styled.div`
  
    width: 36.563vw;

    @media (min-width: 30em) {
      width: 36.563vw;
    }

    @media (min-width: 30.0625em) {
      width: 8.125vw;
    }

    @media (min-width: 90em) {
      width: 117px;
    }
  
  
    height: 45.938vw;

    @media (min-width: 30em) {
      height: 45.938vw;
    }

    @media (min-width: 30.0625em) {
      height: 10.208vw;
    }

    @media (min-width: 90em) {
      height: 147px;
    }
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const IconImageBlock = styled.div`
  
    width: 27.500vw;

    @media (min-width: 30em) {
      width: 27.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 6.111vw;
    }

    @media (min-width: 90em) {
      width: 88px;
    }
  
  
    height: 27.500vw;

    @media (min-width: 30em) {
      height: 27.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 6.111vw;
    }

    @media (min-width: 90em) {
      height: 88px;
    }
  

`

const IconTitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 2.500vw 2.500vw 2.500vw 6.250vw;

    @media (min-width: 30em) {
      padding: 2.500vw 2.500vw 2.500vw 6.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw 0.556vw 0.556vw 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 8px 8px 8px 20px;
    }
  
`

export interface PageMenuCardSectionProps {
  section: PageMenuCardSectionFragment
}

export function PageMenuCardSection({ section }: PageMenuCardSectionProps): JSX.Element {

  const navigate = useNavigate()
  const theme = useTheme()

  const blocks: PageMenuImageCardFragment[] = [
    section.blockOne,
    section.blockTwo,
    section.blockThree,
    section.blockFour,
    section.blockFive,
  ]

  let block!: PageMenuImageCardFragment

  return (
    <Container>
      <TitleContainer>
        <Title variant='t3' color={section.titleColor}>
          {section.title}
        </Title>
      </TitleContainer>
      <Cards>
        <For each='block' of={blocks}>
          <CardBlock key={block.id} onClick={() => navigate(block.href)}>
            <CardImageBlock>
              <ResponsiveImage image={block.image} />
            </CardImageBlock>
            <CardTitleBlock backgroundColor={block.backgroundColor} textColor={block.textColor}>
              <Paragraph color={block.textColor} bold>
                {block.title}
              </Paragraph>
              <ChevronContainer>
                <Icon icon={LocalIconEnums.CHEVRON_RIGHT} color={block.textColor} />
              </ChevronContainer>
            </CardTitleBlock>
          </CardBlock>
        </For>
        <IconBlock>
          <IconImageBlock>
            <ResponsiveImage image={section.iconBlock.image} />
          </IconImageBlock>
          <IconTitleBlock>
            <Paragraph color={theme.colors.green.watercourse}>
              {section.iconBlock.title}
            </Paragraph>
            <ChevronContainer>
              <Icon icon={LocalIconEnums.CHEVRON_RIGHT} color={theme.colors.green.watercourse} />
            </ChevronContainer>
          </IconTitleBlock>
        </IconBlock>
      </Cards>
    </Container>
  )

}
