import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsImageFragment } from '@hooks/api/index'
import { ProductImageGalleryModal } from '@molecules/modals/ProductImageGalleryModal'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
    width: 117.500vw;

    @media (min-width: 30em) {
      width: 117.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 26.111vw;
    }

    @media (min-width: 90em) {
      width: 376px;
    }
  
`

const ImageContainer = styled.div`
  width: 100%;
  
    height: 117.500vw;

    @media (min-width: 30em) {
      height: 117.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 26.111vw;
    }

    @media (min-width: 90em) {
      height: 376px;
    }
  
`

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
    margin-top: 6.250vw;

    @media (min-width: 30em) {
      margin-top: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.389vw;
    }

    @media (min-width: 90em) {
      margin-top: 20px;
    }
  
  
    height: 18.750vw;

    @media (min-width: 30em) {
      height: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.167vw;
    }

    @media (min-width: 90em) {
      height: 60px;
    }
  
`

const Embla = styled.div`
  position: relative;
  overflow: hidden;
`

const EmblaContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const EmblaSlide = styled.div<{ selected: boolean }>`
  position: relative;
  user-select: none;
  
    flex: 0 0 13.125vw;

    @media (min-width: 30em) {
      flex: 0 0 13.125vw;
    }

    @media (min-width: 30.0625em) {
      flex: 0 0 2.917vw;
    }

    @media (min-width: 90em) {
      flex: 0 0 42px;
    }
  
  
    height: 13.125vw;

    @media (min-width: 30em) {
      height: 13.125vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.917vw;
    }

    @media (min-width: 90em) {
      height: 42px;
    }
  

`

const LeftControl = styled.div<{ visible: boolean }>`
  user-select: none;
  opacity: ${(props): number => props.visible ? 1 : 0};
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.083vw;
    }

    @media (min-width: 90em) {
      width: 30px;
    }
  
  
    height: 13.125vw;

    @media (min-width: 30em) {
      height: 13.125vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.917vw;
    }

    @media (min-width: 90em) {
      height: 42px;
    }
  
  
    top: 10.938vw;

    @media (min-width: 30em) {
      top: 10.938vw;
    }

    @media (min-width: 30.0625em) {
      top: 2.431vw;
    }

    @media (min-width: 90em) {
      top: 35px;
    }
  
  
    left: 0;

    @media (min-width: 30em) {
      left: 0;
    }

    @media (min-width: 30.0625em) {
      left: 0;
    }

    @media (min-width: 90em) {
      left: 0;
    }
  
`

const RightControl = styled.div<{ visible: boolean }>`
  user-select: none;
  opacity: ${(props): number => props.visible ? 1 : 0};
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.083vw;
    }

    @media (min-width: 90em) {
      width: 30px;
    }
  
  
    height: 18.750vw;

    @media (min-width: 30em) {
      height: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.167vw;
    }

    @media (min-width: 90em) {
      height: 60px;
    }
  
  
    top: 10.938vw;

    @media (min-width: 30em) {
      top: 10.938vw;
    }

    @media (min-width: 30.0625em) {
      top: 2.431vw;
    }

    @media (min-width: 90em) {
      top: 35px;
    }
  
  
    right: 0;

    @media (min-width: 30em) {
      right: 0;
    }

    @media (min-width: 30.0625em) {
      right: 0;
    }

    @media (min-width: 90em) {
      right: 0;
    }
  
`

export interface ProductDesktopImagesProps {
  gallery: ProductDetailsImageFragment[]
}

interface ProductDesktopImagesState {
  selectedIndex: number
  scrollOffset: number
  modalOpen: boolean
}

const DEFAULT_STATE: ProductDesktopImagesState = {
  selectedIndex: 0,
  scrollOffset: 0,
  modalOpen: false,
}

export function ProductDesktopImages({ gallery }: ProductDesktopImagesProps): JSX.Element {

  const [state, setState] = useState<ProductDesktopImagesState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: false,
    align: 'start',
  })

  const images: ProductDetailsImageFragment[] = gallery
  const selectedImage = images?.[state.selectedIndex]
  // const showLeftControl = state.scrollOffset > 0
  // const showRightControl = state.scrollOffset + 4 < images.length

  const _handleSelectThumbNail = (index: number): void => {
    setState((prevState) => update(prevState, {
      selectedIndex: { $set: index },
    }))
  }

  const _handleModalClose = (): void => {
    setState((prevState) => update(prevState, {
      modalOpen: { $set: false },
    }))
  }

  const _handleModalOpen = (): void => {
    setState((prevState) => update(prevState, {
      modalOpen: { $set: true },
    }))
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(state.scrollOffset)
    }
  }, [state.scrollOffset])

  let image: ProductDetailsImageFragment
  let imageIndex: number

  return (
    <>
      <ProductImageGalleryModal
        index={state.selectedIndex}
        onIndexChange={_handleSelectThumbNail}
        images={images}
        open={state.modalOpen}
        onClose={_handleModalClose} />
      <Container>
        <ImageContainer onClick={_handleModalOpen}>
          <Choose>
            <When condition={!!selectedImage}>
              <ResponsiveImage image={selectedImage} objectFit='contain' />
            </When>
            <Otherwise>
              No Preview
            </Otherwise>
          </Choose>
        </ImageContainer>
        <SliderContainer>
          <Embla>
            <EmblaContainer ref={emblaRef}>
              <For each='image' index='imageIndex' of={images}>
                <EmblaSlide
                  selected={imageIndex === 0 /* state.selectedIndex */}
                  key={`${image.id}-${imageIndex}`}
                  onClick={() => _handleSelectThumbNail(imageIndex)}>
                  <ResponsiveImage image={image} objectFit='contain' />
                </EmblaSlide>
              </For>
            </EmblaContainer>
          </Embla>
        </SliderContainer>
      </Container>
    </>
  )

}
