import React from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import styled, { useTheme } from 'styled-components'

import { Rating, SmallLoader } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

import { FormContext, FormContextProperties } from './Form'
import { InputWrapper } from './InputWrapper'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
 `

const LoadingContainer = styled.div`
  position: absolute;
  
    right: 3.750vw;

    @media (min-width: 30em) {
      right: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      right: 0.833vw;
    }

    @media (min-width: 90em) {
      right: 12px;
    }
  
  
    top: 3.750vw;

    @media (min-width: 30em) {
      top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      top: 0.833vw;
    }

    @media (min-width: 90em) {
      top: 12px;
    }
  
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
`

interface Values {
  value?: number
  onChange?: (value: number) => void
}

export interface RatingFormInputProps {
  rules?: Rule[]
  label?: string
  showLabel?: boolean
  name: string
  loading?: boolean
  disabled?: boolean
  className?: string
  wrapperClassName?: string
  autoComplete?: boolean
}

export function RatingInput(props: RatingFormInputProps): JSX.Element {

  let { label, rules, showLabel, name, loading, wrapperClassName } = props
  const theme = useTheme()

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => {
        if (!rules) {
          rules = []
        }
        const required = !!(
          rules &&
          rules.some(rule => {
            if (rule && typeof rule === 'object' && rule.required) {
              return true
            }
            if (typeof rule === 'function') {
              const ruleEntity = rule(form)
              return ruleEntity && ruleEntity.required
            }
            return false
          })
        )
        const error = meta.errors?.[0]
        const { value, onChange } = control

        const _handleChange = (value: number) => {
          onChange(value * 20)
        }

        return (
          <InputWrapper
            className={wrapperClassName}
            required={required}
            label={label}
            showLabel={showLabel}
            error={error}>
            <FormContext.Consumer>
              {({ loading: formLoading }: FormContextProperties) => {
                return (
                  <Container>
                    <Rating rating={value / 20 ?? 0} onRatingChange={_handleChange} size='large' isInput={true} />
                    <If condition={loading || formLoading}>
                      <LoadingContainer>
                        <SmallLoader
                          color={theme.colors.green.greenVogue} />
                      </LoadingContainer>
                    </If>
                  </Container>
                )
              }}
            </FormContext.Consumer>
          </InputWrapper>
        )
      }}
    </Field>
  )

}
