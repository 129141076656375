import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageMarkupSectionFragment } from '@hooks/api/index'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  width: 100%;
  .markup-section-title {
    
    margin: 0;

    @media (min-width: 30em) {
      margin: 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }
  .markup-section-text {
    margin: 0;
  }
  .markup-section-text p {
    
    margin: 0 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 0 0 10px;
    }
  
  }
`
const Header = styled.div`

  
    margin-bottom: 0;

    @media (min-width: 30em) {
      margin-bottom: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 8px;
    }
  ;

  display: flex;
  align-items: center;
  justify-content: space-between;
  
    height: 16.563vw;

    @media (min-width: 30em) {
      height: auto;
    }

    @media (min-width: 30.0625em) {
      height: auto;
    }

    @media (min-width: 90em) {
      height: auto;
    }
  


  
`

const MarkupContainer = styled.div``

export interface PageMarkupSectionProps {
  section: PageMarkupSectionFragment
}

export function PageMarkupSection({ section }: PageMarkupSectionProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <If condition={!!section.title}>
        <Header>
          <DeviceContainer mobile>
            <Title variant='t5' className='markup-section-title' color={theme.colors.black.pureBlack}>{section.title}</Title>
          </DeviceContainer>
          <DeviceContainer desktop tablet>
            <Title variant='t8' className='markup-section-title' color={theme.colors.black.pureBlack}>{section.title}</Title>
          </DeviceContainer>
        </Header>
      </If>
      <Choose>
        <When condition={section.markupType === 'HTML'}>
          <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
            <MarkupContainer dangerouslySetInnerHTML={{ __html: section.markup }} />
          </Paragraph>
        </When>
        <Otherwise>
          <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
            <MarkupContainer style={{ whiteSpace: 'pre-line' }}>
              {section.markup}
            </MarkupContainer>
          </Paragraph>
        </Otherwise>
      </Choose>

    </Container>
  )

}
