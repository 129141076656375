import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useActiveWidgetsQuery } from '@hooks/api'
import { HTML } from '@molecules/content'

const Container = styled.div`
  width: 100%;
`

const TitleContainer = styled.div<{ backgroundColor: string, foregroundColor: string }>`
  width: 100%;
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 12px;
    }
  
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  

  .html {
    width: fit-content;
    max-width: 100%;
    p:last-child {
      margin-bottom: 0;
    }
  }
`

const ContentContainer = styled.div<{ backgroundColor: string, foregroundColor: string }>`
  width: 100%;
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 8px;
    }
  
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
`

const IconContainer = styled.div`
  
    width: 10.000vw;

    @media (min-width: 30em) {
      width: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.222vw;
    }

    @media (min-width: 90em) {
      width: 32px;
    }
  
  
    height: 10.000vw;

    @media (min-width: 30em) {
      height: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.222vw;
    }

    @media (min-width: 90em) {
      height: 32px;
    }
  
`

export function WidgetDropDownCart(): JSX.Element {

  const { data } = useActiveWidgetsQuery()

  return (
    <If condition={!!data?.activeWidgets?.dropDownCart}>
      <Container>
        <TitleContainer backgroundColor={data.activeWidgets.dropDownCart.titleBackgroundColor} foregroundColor={data.activeWidgets.dropDownCart.titleForegroundColor}>
          <If condition={!!data.activeWidgets.dropDownCart.icon}>
            <IconContainer>
              <ResponsiveImage image={data.activeWidgets.dropDownCart.icon} />
            </IconContainer>
          </If>
          <Title variant='t10' align='center' color={data.activeWidgets.dropDownCart.titleForegroundColor}>{data.activeWidgets.dropDownCart.title}</Title>
        </TitleContainer>
        <ContentContainer backgroundColor={data.activeWidgets.dropDownCart.contentBackgroundColor} foregroundColor={data.activeWidgets.dropDownCart.contentForegroundColor}>
          <HTML className='html' color={data.activeWidgets.dropDownCart.contentForegroundColor}>{data.activeWidgets.dropDownCart.content}</HTML>
        </ContentContainer>
      </Container>
    </If>
  )

}
