import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type TagVariant = 't1' | 't2' | 't3' | 't4' | 't5'
export type DisplayVariant = 'block' | 'inline-block'
export type TagAlignment = 'left' | 'center' | 'right' | 'justify'
export type TagTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type TagDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const T1 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.greenVogue};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 400;
  cursor: pointer;

  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    margin: 0 0 0 1.563vw;

    @media (min-width: 30em) {
      margin: 0 0 0 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 0 0 0 10px;
    }
  

  p {
    
    margin: 3.750vw 0 3.125vw;

    @media (min-width: 30em) {
      margin: 3.750vw 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.833vw 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 12px 0 10px;
    }
  
  }

`

const T2 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.greenVogue};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 400;
  cursor: pointer;

  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    margin: 3.750vw 1.563vw 3.125vw;

    @media (min-width: 30em) {
      margin: 3.750vw 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.833vw 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 12px 0 10px;
    }
  
  p {
    
    margin: 3.750vw 0 3.125vw;

    @media (min-width: 30em) {
      margin: 3.750vw 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.833vw 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 12px 0 10px;
    }
  
  }

`

const T3 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.greenVogue};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 400;
  cursor: pointer;

  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
  
    margin: 3.125vw 0 2.500vw;

    @media (min-width: 30em) {
      margin: 3.125vw 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.694vw 0 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 10px 0 8px;
    }
  
  p {
    
    margin: 3.125vw 0 2.500vw;

    @media (min-width: 30em) {
      margin: 3.125vw 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.694vw 0 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 10px 0 8px;
    }
  
  }

`

const T4 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 700;
  cursor: pointer;

  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  

  p {
    
    margin: 3.125vw 0 2.500vw;

    @media (min-width: 30em) {
      margin: 3.125vw 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.694vw 0 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 10px 0 8px;
    }
  
  }

`

const T5 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 600;
  cursor: pointer;

  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  

  p {
    
    margin: 3.125vw 0 2.500vw;

    @media (min-width: 30em) {
      margin: 3.125vw 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.694vw 0 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 10px 0 8px;
    }
  
  }

`

export interface TagProps {
  children: React.ReactNode
  align?: TagAlignment
  transform?: TagTransforms
  decoration?: TagDecorations
  color?: string
  display?: DisplayVariant
  skeleton?: boolean
  className?: string
  variant?: TagVariant
  onClick?: () => void
}

export function Tag({ children, onClick, variant = 't1', align = 'left', transform = 'none', decoration = 'none', color, className, display = 'block' }: TagProps): JSX.Element {

  switch (variant) {

    case 't1':
      return <T1
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T1>
    case 't2':
      return <T2
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T2>
    case 't3':
      return <T3
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T3>
    case 't4':
      return <T4
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T4>
    case 't5':
      return <T5
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T5>
  }

}
