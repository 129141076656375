import React, { useState, useEffect } from 'react'

import { useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { AuthPlugin } from '@api/local/AuthPlugin'
import { Loader } from '@atoms/notifications'
import { Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CartQueryDocument, CustomerQueryDocument, useValidateLoginAsCustomerMutation } from '@hooks/api'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
`
export interface LoginAsCustomerProps {

}

interface LoginAsCustomerState {
  message: string
}

const DEFAULT_STATE: LoginAsCustomerState = {
  message: '',
}

export function LoginAsCustomer(): JSX.Element {

  const [state, setState] = useState<LoginAsCustomerState>({ ...DEFAULT_STATE })
  const [searchParams] = useSearchParams()
  const client = useApolloClient()
  const secret = searchParams.get('secret') as string
  const navigate = useNavigate()
  const [validate] = useValidateLoginAsCustomerMutation()

  const validateAndLogin = async (): Promise<void> => {
    setState((prevState) => update(prevState, {
      message: {
        $set: 'Validating Secret',
      },
    }))
    try {
      const response = await validate({
        variables: {
          input: {
            secret,
          },
        },
        awaitRefetchQueries: true,
      })
      setState((prevState) => update(prevState, {
        message: {
          $set: 'Successfully validated secret',
        },
      }))
      setTimeout(async () => {
        setState((prevState) => update(prevState, {
          message: {
            $set: 'Updating Customer Info',
          },
        }))
        AuthPlugin.shared().setImpersonationToken(response.data.validateLoginAsCustomer.customerToken)
        await client.refetchQueries({
          include: [CustomerQueryDocument],
        })
        await AuthPlugin.shared().clearCartId()
        navigate('/')
      }, 2000)

    } catch (e) {
      setState((prevState) => update(prevState, {
        message: {
          $set: 'Failed to validate secret',
        },
      }))
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }

  useEffect(() => {
    if (secret) {
      validateAndLogin()
    } else {
      navigate('/')
    }
  }, [secret])

  return (
    <Container>
      <Loader />
      <Title variant='t3'>{state.message}</Title>
    </Container>
  )

}
