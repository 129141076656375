import React, { useEffect } from 'react'

import styled from 'styled-components'

import { Loader, Paragraph, Title } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useCustomerQuery, useUpdateCustomerMutation, usesubscribeEmailToNewsletterMutation } from '@hooks/api/index'
import { CheckBoxInput, FieldData, Form, useForm } from '@molecules/inputs'
import { useSimpleToasts } from '@simple/toasts'

const Container = styled.div``

const SettingsWrapper = styled.div<{ lastItem?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  ${(props) => {
    return (!props.lastItem) ? ResponsivePXValue('margin-bottom', '16px') : undefined
  }};
`

const SettingsDetails = styled.div`
  .title {
    
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 8px;
    }
  
  }
`

const SettingsActions = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: stretch;

  
    gap: 7.813vw;

    @media (min-width: 30em) {
      gap: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.736vw;
    }

    @media (min-width: 90em) {
      gap: 25px;
    }
  ;
`

const SettingsAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.222vw;
    }

    @media (min-width: 90em) {
      width: 32px;
    }
  ;

  .no-grow-checkbox {
    flex-grow: 0;
  }
`

export function ProfileNotificationsForm(): JSX.Element {
  const { data: customerData, loading: customerLoading, refetch: refetchCustomer } = useCustomerQuery()
  const [updateCustomer, { loading: updateCustomerDataLoading }] = useUpdateCustomerMutation()
  const [subscribeEmailToNewsletter] = usesubscribeEmailToNewsletterMutation()

  const { addToast } = useSimpleToasts()
  const notificationForm = useForm()

  const _handleFieldsChange = async (fields: FieldData[]) => {

    for (const i in fields) {
      const name = fields[i].name as string[]
      const value = fields[i].value as boolean[]

      if (name.shift() === 'newsletter_email') {

        if (value[0]) {
          try {
            await updateCustomer({
              variables: {
                input: {
                  isSubscribed: true,
                },
              },
            })
            addToast({
              message: 'Newsletter subscribed successfully',
              appearance: 'success',
            })
          } catch (e) {
            addToast({
              message: e.message,
              appearance: 'success',
            })
          }
        } else {
          try {
            await updateCustomer({
              variables: {
                input: {
                  isSubscribed: false,
                },
              },
            })
            refetchCustomer()
            addToast({
              message: 'Newsletter unsubscribed successfully',
              appearance: 'success',
            })
          } catch (e) {
            addToast({
              message: e.message,
              appearance: 'success',
            })
          }
        }
      }
    }
  }

  useEffect(() => {
    if (customerData?.currentCustomer?.email) {
      notificationForm.setFieldsValue({
        newsletter_email: (customerData?.currentCustomer?.isSubscribed),
        sms: true,
        orders_rewards_email: true,
        orders_rewards_sms: true,
      })
    }
  }, [customerData?.currentCustomer])

  return (
    <Container>
      <Choose>
        <When condition={customerLoading}>
          <Loader />
        </When>
        <Otherwise>
          <Form form={notificationForm} onFieldsChange={_handleFieldsChange}>
            <SettingsWrapper>
              <SettingsDetails></SettingsDetails>
              <SettingsActions>
                <SettingsAction>
                  <Title variant='t5' color={theme.colors.black.pureBlack}>Email</Title>
                </SettingsAction>
                <SettingsAction>
                  <Title variant='t5' color={theme.colors.black.pureBlack}>SMS</Title>
                </SettingsAction>
              </SettingsActions>
            </SettingsWrapper>

            <SettingsWrapper>
              <SettingsDetails>
                <Title className='title' variant='t8' color={theme.colors.black.bottleGreen}>Newsletter</Title>
                <Paragraph variant='p2' color={theme.colors.grey.stormDust}>Stay informed on the latest conscious shopping products and insights</Paragraph>
              </SettingsDetails>

              <SettingsActions>
                <SettingsAction>
                  <CheckBoxInput
                    wrapperClassName='no-grow-checkbox'
                    name='newsletter_email'
                    variant='horizontal'
                    loading={updateCustomerDataLoading}
                    options={[{ title: '', value: true }]} />
                </SettingsAction>
                <SettingsAction>
                  <CheckBoxInput
                    wrapperClassName='no-grow-checkbox'
                    disabled
                    name='sms'
                    variant='horizontal'
                    options={[{ title: '', value: true }]} />
                </SettingsAction>
              </SettingsActions>
            </SettingsWrapper>

            <SettingsWrapper lastItem={true}>
              <SettingsDetails>
                <Title className='title' variant='t8' color={theme.colors.black.bottleGreen}>Orders & Rewards</Title>
                <Paragraph variant='p2' color={theme.colors.grey.stormDust}>These are transactional notifications to ensure that you stay updated with important info about your order and account</Paragraph>
              </SettingsDetails>

              <SettingsActions>
                <SettingsAction>
                  <CheckBoxInput
                    wrapperClassName='no-grow-checkbox'
                    disabled
                    name='orders_rewards_email'
                    variant='horizontal'
                    options={[{ title: '', value: true }]} />
                </SettingsAction>
                <SettingsAction>
                  <CheckBoxInput
                    wrapperClassName='no-grow-checkbox'
                    disabled
                    name='orders_rewards_sms'
                    options={[{ title: '', value: true }]} />
                </SettingsAction>
              </SettingsActions>
            </SettingsWrapper>
          </Form>
        </Otherwise>
      </Choose>
    </Container>

  )
}
