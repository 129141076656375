import React, { useState, useRef } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'
import { PageAccordionSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'

type PageAccordionSectionFragmentQuestions = PageAccordionSectionFragment['questions'][0]

const SectionContainer = styled.div`
  
    width: 90.000vw;

    @media (min-width: 30em) {
      width: 287.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 63.889vw;
    }

    @media (min-width: 90em) {
      width: 920px;
    }
  
  width: 100%;
  height: fit-content;
`
const SectionTitleContainer = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
  
    padding: 3.750vw 0.000vw;

    @media (min-width: 30em) {
      padding: 2.813vw 0.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.625vw 0.000vw;
    }

    @media (min-width: 90em) {
      padding: 9px 0px;
    }
  
  
    margin: 0.000vw 7.500vw;

    @media (min-width: 30em) {
      margin: 0.000vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.000vw 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 0px 16px;
    }
  
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey.athens};
  
  .title {
    
    max-width: 68.750vw;

    @media (min-width: 30em) {
      max-width: initial;
    }

    @media (min-width: 30.0625em) {
      max-width: initial;
    }

    @media (min-width: 90em) {
      max-width: initial;
    }
  
    font-weight: 600
  }
`
const IconContainer = styled.div`
  
    cursor: default;

    @media (min-width: 30em) {
      cursor: default;
    }

    @media (min-width: 30.0625em) {
      cursor: pointer;
    }

    @media (min-width: 90em) {
      cursor: pointer;
    }
  
  
    max-width: 3.750vw;

    @media (min-width: 30em) {
      max-width: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      max-width: 2.222vw;
    }

    @media (min-width: 90em) {
      max-width: 32px;
    }
  
  
    max-height: 3.750vw;

    @media (min-width: 30em) {
      max-height: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      max-height: 2.222vw;
    }

    @media (min-width: 90em) {
      max-height: 32px;
    }
  
`
const SectionContent = styled.div<{ open: boolean }>`
  
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: 0.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.000vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 0px;
    }
  
  max-height: ${({ open }): string => open ? '4000px' : '0px'};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  div {
    
    padding: 3.750vw 5.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 16px;
    }
  
  }
`
interface AccordionSectionState {
  open: boolean
}
const DEFAULT_STATE: AccordionSectionState = {
  open: false,
}
export interface AccordionSectionProps {
  title: string
  content: string
}
export function AccordionSection({ title, content }: AccordionSectionProps): JSX.Element {

  const [state, setState] = useState<AccordionSectionState>({ ...DEFAULT_STATE })

  const ref: React.MutableRefObject<HTMLDivElement> = useRef(null)

  const _handleToggleOpen = (): void => {
    state.open === false &&
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

    setState((prevState) => update(prevState, { open: { $set: !prevState.open } }))
  }

  return (
    <SectionContainer>
      <SectionTitleContainer onClick={_handleToggleOpen} ref={ref} open={state.open}>
        <Paragraph
          variant='p1'
          color={theme.colors.green.bottleGreen}
          className='title'
        >
          {title}
        </Paragraph>
        <IconContainer>
          <Choose>
            <When condition={!!state.open}>
              <Icon icon={LocalIconEnums.CHEVRON_UP} color={theme.colors.green.greenVogue} />
            </When>
            <Otherwise>
              <Icon icon={LocalIconEnums.CHEVRON_DOWN} color={theme.colors.green.greenVogue} />
            </Otherwise>
          </Choose>
        </IconContainer>
      </SectionTitleContainer>
      <SectionContent open={state.open}>
        <HTML color={theme.colors.green.bottleGreen}>
          {content}
        </HTML>
      </SectionContent>
    </SectionContainer>
  )
}

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  
    margin-bottom: 6.250vw;

    @media (min-width: 30em) {
      margin-bottom: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.389vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 20px;
    }
  
  background-color: ${({ theme }) => theme.colors.white.pureWhite};
  
    margin: 0.000vw 5.000vw;

    @media (min-width: 30em) {
      margin: initial;
    }

    @media (min-width: 30.0625em) {
      margin: initial;
    }

    @media (min-width: 90em) {
      margin: initial;
    }
  
`
const AccordionHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
  
    padding: 7.500vw 5.000vw 3.750vw;

    @media (min-width: 30em) {
      padding: 3.750vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 12px 16px;
    }
  
  
    width: 90.000vw;

    @media (min-width: 30em) {
      width: 287.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 63.889vw;
    }

    @media (min-width: 90em) {
      width: 920px;
    }
  
`
export interface AccordionProps {
  title: string
  content: PageAccordionSectionFragmentQuestions
}

export function Accordion({ title, content }: AccordionProps): JSX.Element {

  let questionIndex!: number

  return (
    <>
      <AccordionContainer>
        <If condition={!!title}>
          <AccordionHeading>
            <Heading
              color={theme.colors.green.bottleGreen}
              variant='h3'
              align='left'
              transform='capitalize'>
              {title}
            </Heading>
          </AccordionHeading>
        </If>
        <For each="question" index='questionIndex' of={content.questions}>
          <AccordionSection
            key={questionIndex}
            content={content.questions[questionIndex].answer}
            title={content.questions[questionIndex].question}
          />
        </For>
      </AccordionContainer>
    </>
  )
}
