import React, { Ref, useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useSearchParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'

const IndexContainer = styled.div`

`

const TitleContainer = styled.div<{ showBorder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  
    padding: 3.750vw 0.000vw;

    @media (min-width: 30em) {
      padding: 2.813vw 0.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.625vw 0.000vw;
    }

    @media (min-width: 90em) {
      padding: 9px 0px;
    }
  
  ${(props): string => props.showBorder && ResponsivePXValue('border-bottom', `1px solid ${props.theme.colors.grey.athens}`)}
`

const TitleTextContainer = styled.div`
  flex-grow: 1;
  cursor: pointer;
`

const IconContainer = styled.div<{ isOpen: boolean }>`
  flex-shrink: 0;
  transition: transform 0.3s ease-in-out;
  ${(props): string => props.isOpen && 'transform: rotateX(180deg);'}
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.222vw;
    }

    @media (min-width: 90em) {
      width: 32px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.222vw;
    }

    @media (min-width: 90em) {
      height: 32px;
    }
  
`

const ContentContainer = styled.div<{ isOpen: boolean }>`
  ${(props): string => {
    if (props.isOpen) {
      return `
        
    padding: 3.750vw 0.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 16px;
    }
  
        max-height: max-content;
      `
    }
    return `
      
    padding: 0 0.000vw;

    @media (min-width: 30em) {
      padding: 0 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 0 16px;
    }
  
      max-height: 0px;
    `
  }}
  margin: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`

interface IndexAccordionSectionState {
  activeSection: boolean
}

const DEFAULT_STATE = {
  activeSection: false,
}
export interface IndexSectionProps {
  title: React.ReactNode
  children: React.ReactNode
  id?: string
  isOpen?: boolean
  isLast?: boolean
  onChange?: () => void
  centerOnLoad?: boolean
}

export const IndexSection = React.forwardRef(({ title, children, isOpen, isLast = false, onChange, id }: IndexSectionProps, ref: Ref<HTMLDivElement>): JSX.Element => {

  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const questionRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState<IndexAccordionSectionState>({ ...DEFAULT_STATE })

  useEffect(() => {
    const section = searchParams.get('section')
    if (questionRef.current?.id === section) {
      setState((prevState) => update(prevState, { activeSection: { $set: true } }))
      questionRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }

    return () => {
      setState((prevState) => update(prevState, { activeSection: { $set: false } }))
    }
  }, [state.activeSection])

  return (
    <div id={id} ref={questionRef}>
      <IndexContainer ref={ref}>
        <TitleContainer showBorder={isOpen || !isLast} onClick={onChange}>
          <TitleTextContainer>
            <Title
              variant='t8'
              color={theme.colors.green.bottleGreen}>
              {title}
            </Title>
          </TitleTextContainer>
          <IconContainer isOpen={isOpen}>
            <Icon icon={LocalIconEnums.CHEVRON_DOWN} color={theme.colors.green.bottleGreen} />
          </IconContainer>
        </TitleContainer>
        <ContentContainer isOpen={isOpen}>
          {children}
        </ContentContainer>

      </IndexContainer>
    </div>
  )
})

IndexSection.displayName = 'IndexSection'

const Container = styled.div`
  width: 100%;
`

export interface IndexAccordionProps {
  children: JSX.Element[] | JSX.Element
  onTabsChange: (active: string[]) => void
  tabs: string[]
}

export function IndexAccordion({ children, onTabsChange, tabs }: IndexAccordionProps): JSX.Element {

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const section = searchParams.get('section')
    const activeTabs = section ? [section] : []
    onTabsChange(activeTabs)
  }, [])

  const _handleTabChanged = (id: string): void => {
    const newTabs = tabs.includes(id) ? tabs.filter((tab) => tab !== id) : [id]
    onTabsChange(newTabs)
  }

  const updatedChildren = React.Children.map(children, (child, index) => {
    if (child && child?.props && child?.props?.id) {
      const isOpen = tabs.includes(child.props.id)
      const isLast = index === React.Children.count(children) - 1

      return React.cloneElement(child, {
        ...child.props,
        isOpen,
        isLast,
        onChange: () => _handleTabChanged(child.props.id),
      })
    }

    return child
  })

  return (
    <Container>
      {updatedChildren}
    </Container>
  )
}
