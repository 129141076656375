import { snakeCase } from 'change-case'
import qs from 'qs'

import { AppPlugin } from '@api/local/AppPlugin'
import { CartFragment, CartItemFragment, ProductListFragment, CategoryFragment, ProductDetailsFragment, BaseCategoryFragment, BaseCategoryChildFragment, BreadcrumbFragment } from '@hooks/api'
import { ProductFilterInputs } from '@organisms/index'
import { EntityTypeEnum, FilterTypeInput, ProductFilterInput } from '@uctypes/api/globalTypes'

import { Config } from './Config'
import { CatalogueVars, EventPlugin, PageView } from './UserEvents'

export class Smile implements EventPlugin {

  init(config: Config) {
    try {
      // https://eco-warriors.innovate.faithful-to-nature.co.za/rest/V1/elasticsuite-tracker/hit
      smileTracker.setConfig({
        beaconUrl: `${config.getMagentoUrl()}/elasticsuite/tracker/hit/image/h.png`,
        endpointUrl: `${config.getMagentoUrl()}/rest/V1/elasticsuite-tracker/hit`,
        domain: config.getApiBaseUrl(),
        telemetryEnabled: false,
        // telemetryUrl: 'https://t.elasticsuite.io/track',
        sessionConfig: {
          visit_cookie_name: 'STUID',
          visit_cookie_lifetime: '3600',
          visitor_cookie_name: 'STVID',
          visitor_cookie_lifetime: '365',
          domain: 'smmarkets.ph',
          path: '/',
        },
      })
      smileTracker.addPageVar('store_id', '1')
    } catch (e) {
      console.log(e)
    }
  }

  addBaseVars(title: string): void {
    const app = AppPlugin.shared()
    smileTracker.addPageVar('store_id', app.getStoreId() + '')
    smileTracker.addPageVar('locale', 'en_GB')
    if (typeof window !== undefined) {
      /*
      "site": "eco-warriors.innovate.faithful-to-nature.co.za", // missing
      "url": "/gifts/gifting-by-type/green-thumb-gift-ideas", // missing
      "title": "Gifts for the Green Thumb - Gifts", // missing
      "referrer": { //missing
        "domain": "eco-warriors.innovate.faithful-to-nature.co.za",
        "page": "/body-beauty"
      },
      "resolution": { // missing
        "x": "2048",
        "y": "1175"
      }
      */
      smileTracker.addPageVar('site', window.location.hostname)
      smileTracker.addPageVar('url', window.location.pathname)
      smileTracker.addPageVar('title', title)
      if (document.referrer) {
        const referrer = (new URL(document.referrer))
        smileTracker.addPageVar('referrer.domain', referrer.hostname)
        smileTracker.addPageVar('referrer.page', referrer.pathname)
      }
      smileTracker.addPageVar('resolution.x', window.innerWidth)
      smileTracker.addPageVar('resolution.y', window.innerHeight)
    }
  }

  addProductVars(vars: CatalogueVars): void {
    smileTracker.addPageVar('product_list.page_count', Math.ceil(vars.totalProducts / vars.productsPerPage))
    smileTracker.addPageVar('product_list.product_count', vars.totalProducts)
    smileTracker.addPageVar('product_list.current_page', vars.currentPage)
    smileTracker.addPageVar('product_list.sort_order', vars.sortOrder)
    smileTracker.addPageVar('product_list.sort_direction', vars.sortDirection)
    smileTracker.addPageVar('product_list.display_mode', vars.displayMode)
  }

  addFilters(filters: ProductFilterInputs): void {
    Object.keys(filters).forEach((key: keyof ProductFilterInput) => {
      if (key !== 'or') {
        const filterItem: FilterTypeInput = filters[key] as unknown as FilterTypeInput
        if (filterItem.eq) {
          smileTracker.addPageVar(`product_list.filters.${snakeCase(key)}`, filterItem.eq + '')
        } else if (filterItem.in) {
          smileTracker.addPageVar(`product_list.filters.${snakeCase(key)}`, filterItem.in.join('|'))
        } else if (filterItem.from || filterItem.to) {
          if (filterItem.from && filterItem.to) {
            smileTracker.addPageVar(`product_list.filters.${snakeCase(key)}`, `${filterItem.from}-${filterItem.to}`)
          } else if (filterItem.from) {
            smileTracker.addPageVar(`product_list.filters.${snakeCase(key)}`, `>${filterItem.from}`)
          } else {
            smileTracker.addPageVar(`product_list.filters.${snakeCase(key)}`, `<${filterItem.to}`)
          }
        } else if (filterItem.gt || filterItem.lt) {
          if (filterItem.from && filterItem.to) {
            smileTracker.addPageVar(`product_list.filters.${snakeCase(key)}`, `${filterItem.gt}-${filterItem.lt}`)
          } else if (filterItem.gt) {
            smileTracker.addPageVar(`product_list.filters.${snakeCase(key)}`, `>${filterItem.gt}`)
          } else {
            smileTracker.addPageVar(`product_list.filters.${snakeCase(key)}`, `<${filterItem.lt}`)
          }
        }
      }
    })
  }

  addCartItem(cartItem: CartItemFragment, index: number): void {
    smileTracker.addPageVar(`order.items.${index}.sku`, cartItem.product.sku)
    smileTracker.addPageVar(`order.items.${index}.product_id`, cartItem.product.id)
    smileTracker.addPageVar(`order.items.${index}.qty`, cartItem.quantity)
    smileTracker.addPageVar(`order.items.${index}.price`, cartItem.prices.priceIncludingTax.value)
    smileTracker.addPageVar(`order.items.${index}.row_total`, cartItem.prices.rowTotal.value)
    smileTracker.addPageVar(`order.items.${index}.label`, cartItem.product.name)
    smileTracker.addPageVar(`order.items.${index}.category_ids`, cartItem.product.categories.map((cat) => cat.id).join('|'))
  }

  hasViewedPage(page: PageView): void {
    if (page.type === EntityTypeEnum.CONTENT) {
      try {
        this.addBaseVars(page.title)
        if (page.url === '/') {
          smileTracker.addPageVar('type.identifier', 'cms_index_index')
          smileTracker.addPageVar('type.label', 'CMS Home Page')
        } else {
          smileTracker.addPageVar('type.identifier', 'cms_page_view')
          smileTracker.addPageVar('type.label', 'CMS Pages (All)')
        }

        smileTracker.addPageVar('cms.identifier', page.url)
        smileTracker.addPageVar('cms.title', page.title)
        smileTracker.sendTag(true)
      } catch (e) {
        console.log(e)
      }
    }
  }

  hasMadePurchase(cart: CartFragment, orderId: string): void {
    try {
      this.addBaseVars('Checkout')
      smileTracker.addPageVar('type.identifier', 'checkout_onepage_success')
      smileTracker.addPageVar('type.label', 'One Page Checkout Success')
      smileTracker.addPageVar('order.subtotal', cart.prices.subTotal.value)
      smileTracker.addPageVar('order.discount_total', cart.prices.discounts.reduce((to, cu) => to + cu.amount.value, 0))
      smileTracker.addPageVar('order.shipping_total', cart?.shippingAddresses[0].selectedShippingMethod?.priceInclTax?.value)
      smileTracker.addPageVar('order.grand_total', cart.prices.grandTotal.value)
      smileTracker.addPageVar('order.shipping_method', `${cart?.shippingAddresses[0].selectedShippingMethod.carrierCode}_${cart?.shippingAddresses[0].selectedShippingMethod.methodCode}`)
      smileTracker.addPageVar('order.payment_method', cart.selectedPaymentMethod.code)
      cart.items.forEach((cartItem, index) => {
        this.addCartItem(cartItem, index)
      })

      smileTracker.sendTag(true)
    } catch (e) {
      console.log(e)
    }
  }

  hasPerformedSearch(term: string, isSpellChecked: boolean, vars: CatalogueVars): void {
    try {
      this.addBaseVars('Search')
      smileTracker.addPageVar('type.identifier', 'catalogsearch_result_index')
      smileTracker.addPageVar('type.label', 'Quick Search Form')

      smileTracker.addPageVar('search.query', term)
      smileTracker.addPageVar('search.is_spellchecked', isSpellChecked ? 1 : 0)

      this.addProductVars(vars)
      smileTracker.sendTag(true)
    } catch (e) {
      console.log(e)
    }
  }

  hasFilteredSearch(term: string, isSpellChecked: boolean, vars: CatalogueVars, filters: ProductFilterInputs): void {
    try {
      this.addBaseVars('Search')
      smileTracker.addPageVar('type.identifier', 'catalogsearch_result_index')
      smileTracker.addPageVar('type.label', 'Quick Search Form')

      smileTracker.addPageVar('search.query', term)
      smileTracker.addPageVar('search.is_spellchecked', isSpellChecked ? 1 : 0)

      this.addProductVars(vars)
      this.addFilters(filters)
      smileTracker.sendTag(true)
    } catch (e) {
      console.log(e)
    }
  }

  hasViewedCatalogue(category: CategoryFragment | BaseCategoryFragment | BaseCategoryChildFragment, products: ProductListFragment[], vars: CatalogueVars): void {
    try {
      this.addBaseVars(category.name)
      smileTracker.addPageVar('type.identifier', 'catalog_category_view')
      smileTracker.addPageVar('type.label', 'Catalog Category')

      smileTracker.addPageVar('category.id', category.id)
      smileTracker.addPageVar('category.path', category.urlPath)
      smileTracker.addPageVar('category.label', category.name)
      const breadcrumbs = category.breadcrumbs.map((bc) => bc.categoryName)
      breadcrumbs.push(category.name)
      smileTracker.addPageVar('category.breadcrumb', breadcrumbs.join('|'))

      this.addProductVars(vars)
      smileTracker.sendTag(true)
    } catch (e) {
      console.log(e)
    }
  }

  hasFilteredCatalogue(category: CategoryFragment | BaseCategoryFragment | BaseCategoryChildFragment, products: ProductListFragment[], vars: CatalogueVars, filters: ProductFilterInputs): void {
    try {
      this.addBaseVars(category.name)
      smileTracker.addPageVar('type.identifier', 'catalog_category_view')
      smileTracker.addPageVar('type.label', 'Catalog Category')

      smileTracker.addPageVar('category.id', category.id)
      smileTracker.addPageVar('category.path', category.urlPath)
      smileTracker.addPageVar('category.label', category.name)
      const breadcrumbs = category.breadcrumbs.map((bc) => bc.categoryName)
      breadcrumbs.push(category.name)
      smileTracker.addPageVar('category.breadcrumb', breadcrumbs.join('|'))

      this.addProductVars(vars)
      this.addFilters(filters)
      smileTracker.sendTag(true)
    } catch (e) {
      console.log(e)
    }
  }

  hasViewedProduct(product: ProductDetailsFragment, breadcrumbs?: BreadcrumbFragment[]): void {
    try {
      this.addBaseVars(product.name)
      smileTracker.addPageVar('type.identifier', 'catalog_product_view')
      smileTracker.addPageVar('type.label', 'Catalog Product View (Any)')

      if (breadcrumbs) {
        smileTracker.addPageVar('category.id', breadcrumbs[breadcrumbs.length - 1].categoryId)
        smileTracker.addPageVar('category.path', breadcrumbs[breadcrumbs.length - 1].categoryUrlPath)
        smileTracker.addPageVar('category.label', breadcrumbs[breadcrumbs.length - 1].categoryName)
        smileTracker.addPageVar('category.breadcrumb', breadcrumbs.map((bc) => bc.categoryName).join('|'))
      } else {
        const category = product.categories?.[product?.categories?.length - 1]
        if (category) {
          smileTracker.addPageVar('category.id', category.id)
          smileTracker.addPageVar('category.path', category.urlPath)
          smileTracker.addPageVar('category.label', category.name)
          if (category.breadcrumbs) {
            const breadcrumbs = category.breadcrumbs.map((bc) => bc.categoryName)
            breadcrumbs.push(category.name)
            smileTracker.addPageVar('category.breadcrumb', breadcrumbs.join('|'))
          } else {
            smileTracker.addPageVar('category.breadcrumb', category.name)
          }
        }
      }

      smileTracker.addPageVar('product.id', product.id)
      smileTracker.addPageVar('product.label', product.name)
      smileTracker.addPageVar('product.sku', product.sku)
      smileTracker.sendTag(true)
    } catch (e) {
      console.log(e)
    }
  }

  hasAddedProductToCart(cartItem: CartItemFragment, previoudCartItem?: CartItemFragment): void {
    try {
      this.addBaseVars(document.title)
      smileTracker.addPageVar('type.identifier', 'checkout_cart_add')
      smileTracker.addPageVar('type.label', 'Add Item to Cart')

      smileTracker.addPageVar('cart.product_id', cartItem.product.id)
      smileTracker.sendTag(true)
    } catch (e) {
      console.log(e)
    }
  }

}
