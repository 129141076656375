
import React from 'react'

import loadable from '@loadable/component'

import { Loader, PageLoader } from '@atoms/notifications'
import { LoadingContainer } from '@atoms/misc'

import(/* webpackPrefetch: true */ '@pages/Product')

const Product = loadable(() => import(/* webpackChunkName: "product" */'@pages/Product'), {
  resolveComponent: (components) => components.Product,
})

export function ProductAsync(): JSX.Element {

  return <Product fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
