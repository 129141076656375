import React from 'react'

import styled, { useTheme, CSS, css } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.a<{ backgroundColor: string, active: boolean }>`

  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  background-color: ${(props): string => props.backgroundColor};
  width: 100%;
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    margin-bottom: 1.250vw;

    @media (min-width: 30em) {
      margin-bottom: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.278vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 4px;
    }
  
  
    padding: 0 2.500vw;

    @media (min-width: 30em) {
      padding: 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 0 8px;
    }
  ;

  ${(props): CSS => {
    if (props.active) {
      return css`font-weight: 700;`
    }

  }}

  .category-item-text {
    margin: 0;
    padding: 0;
  }
`

export interface DesktopCategoryMenuItemProps {
  title: string
  className?: string
  active: boolean
  hovering: boolean
  href: string
  onClick: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export function DesktopCategoryMenuItem({ title, active, hovering, href, onClick, onMouseEnter, onMouseLeave }: DesktopCategoryMenuItemProps): JSX.Element {

  const theme = useTheme()

  const _handleMouseEnter = () => {
    onMouseEnter?.()
  }

  const _handleMouseLeave = () => {
    onMouseLeave?.()
  }

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    onClick?.()
  }

  const backgroundColor = hovering ? theme.colors.green.watercourse : active ? theme.colors.green.swansDown : ''
  const foregroundColor = hovering ? theme.colors.white.pureWhite : active ? theme.colors.black.pureBlack : theme.colors.green.bottleGreen

  return (
    <Container
      href={href}
      active={active}
      backgroundColor={backgroundColor}
      onMouseEnter={_handleMouseEnter}
      onMouseLeave={_handleMouseLeave}
      onClick={_handleClick}>
      <Paragraph className='category-item-text' variant='p3' color={foregroundColor}>{title}</Paragraph>
    </Container>
  )

}
