
import React from 'react'

import loadable from '@loadable/component'
import { LoadingContainer } from '@atoms/misc'
import { Loader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/CarbonNeutral')

const CarbonNeutral = loadable(() => import(/* webpackChunkName: "CarbonNeutral" */'@pages/CarbonNeutral'), {
  resolveComponent: (components) => components.CarbonNeutral,
})

export function CarbonNeutralAsync(): JSX.Element {

  return <CarbonNeutral fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
