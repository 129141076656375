import React, { useState } from 'react'

import { ShareLink } from 'social-media-sharing'
import styled from 'styled-components'

import { Button, ButtonColors } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { LineEllipsis } from '@atoms/typography/LineEllipsis'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { Config } from '@lib/Config'
import { SiteHelper } from '@lib/SiteHelper'
import { Modal } from '@molecules/content'

enum ShareTypesEnum {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  EMAIL = 'email',
  COPY = 'copy',
}

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 7.500vw;

    @media (min-width: 30em) {
      gap: initial;
    }

    @media (min-width: 30.0625em) {
      gap: initial;
    }

    @media (min-width: 90em) {
      gap: initial;
    }
  
  
    margin: 5.000vw 0;

    @media (min-width: 30em) {
      margin: initial;
    }

    @media (min-width: 30.0625em) {
      margin: initial;
    }

    @media (min-width: 90em) {
      margin: initial;
    }
  
`

const SocialIcon = styled.div`
  border-style: solid;
  border-color: ${(props): string => theme.colors.green.bottleGreen};
  
    border-width: 0.469vw;

    @media (min-width: 30em) {
      border-width: 0.469vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.104vw;
    }

    @media (min-width: 90em) {
      border-width: 1.5px;
    }
  
  
    border-radius: 10.000vw;

    @media (min-width: 30em) {
      border-radius: initial;
    }

    @media (min-width: 30.0625em) {
      border-radius: initial;
    }

    @media (min-width: 90em) {
      border-radius: initial;
    }
  
  
    width: 10.000vw;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  
    height: 10.000vw;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: initial;
    }

    @media (min-width: 90em) {
      height: initial;
    }
  
  
    padding: 1.250vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
`

const ShareModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  
    padding: 7.500vw 5.000vw 5.000vw 5.000vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
  
    width: 100%;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  
    height: 100%;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: initial;
    }

    @media (min-width: 90em) {
      height: initial;
    }
  

  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.bottleGreen, 0.77)};
`

const ShareModalInner = styled.div`
  z-index: 51;
  position: absolute;
  bottom: 0;
  left: 0;
  
    width: 100%;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  
    padding: 7.500vw 5.000vw 5.000vw 5.000vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const FakeInput = styled.div`
  font-family: open-sans;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${(props): string => props.theme.colors.grey.stormDust};

  
    width: 71.250vw;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  
    padding: 1.563vw 3.750vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: initial;
    }

    @media (min-width: 90em) {
      height: initial;
    }
  
  
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.667vw;
    }

    @media (min-width: 90em) {
      font-size: 24px;
    }
  

  background-color: ${(props): string => props.theme.colors.grey.gallery};
`

const CopySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: initial;
    }

    @media (min-width: 30.0625em) {
      gap: initial;
    }

    @media (min-width: 90em) {
      gap: initial;
    }
  
  .button {
    
    width: 18.125vw;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  }
`

const Close = styled.div`
  position: absolute;
  
    right: 3.750vw;

    @media (min-width: 30em) {
      right: initial;
    }

    @media (min-width: 30.0625em) {
      right: initial;
    }

    @media (min-width: 90em) {
      right: initial;
    }
  
  
    top: 3.750vw;

    @media (min-width: 30em) {
      top: initial;
    }

    @media (min-width: 30.0625em) {
      top: initial;
    }

    @media (min-width: 90em) {
      top: initial;
    }
  
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: initial;
    }

    @media (min-width: 90em) {
      height: initial;
    }
  

`

export interface ShareModalProps {
  open: boolean
  onClose?: () => void
  url?: string
}

export function ShareModal({ open, onClose, url }: ShareModalProps): JSX.Element {

  const config = useConfig()
  let href = '/'
  if (typeof window !=='undefined') {
    href = window?.location?.href
  }
  const pageUrl = url ? config.getBaseUrl() + url : href
  const [complete, setComplete] = useState(false)

  const _handleShare = (platform: ShareTypesEnum): void => {
    const socialMediaLinks = new ShareLink(platform)
    const pageUrl = url ? config.getBaseUrl() + url : href
    const description = 'I\'m a conscious shopper, see my favourite natural products on Faithful to Nature.'

    switch (platform) {
      case ShareTypesEnum.FACEBOOK:
        socialMediaLinks.get({
          u: pageUrl,
          quote: description,
        })
        socialMediaLinks.open()
        break
      case ShareTypesEnum.TWITTER:
        socialMediaLinks.get({
          url: pageUrl,
          text: description,
        })
        socialMediaLinks.open()
        break
      case ShareTypesEnum.EMAIL:
        window.open(
          'mailto:user@example.com?subject=' + description + '&body=' + pageUrl,
          '_blank',
        )
        break
      default:
        navigator.clipboard.writeText(pageUrl)
        setComplete(true)
    }
  }

  const btnColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: complete ? theme.colors.green.deYork : theme.colors.black.pureBlack,
    borderColor: complete ? theme.colors.green.deYork : theme.colors.black.pureBlack,
    // Hover
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: complete ? theme.colors.green.deYork : theme.colors.black.pureBlack,
    hoverBorderColor: complete ? theme.colors.green.deYork : theme.colors.black.pureBlack,
    // Disable
    disabledColor: theme.colors.white.pureWhite,
    disabledBackgrondColor: theme.colors.grey.athens,
  }

  return (
    <ShareModalWrapper>
      <ShareModalInner>

        <Close onClick={() => onClose()}><Icon color={theme.colors.grey.silver} icon={LocalIconEnums.CLOSE} /></Close>

        <Heading variant='h3' align='center' color={theme.colors.black.pureBlack}>Share this Product</Heading>
        <SocialIcons>
          <SocialIcon onClick={() => _handleShare(ShareTypesEnum.FACEBOOK)}><Icon color={theme.colors.black.pureBlack} icon={LocalIconEnums.FACEBOOK} /></SocialIcon>
          <SocialIcon onClick={() => _handleShare(ShareTypesEnum.TWITTER)} ><Icon color={theme.colors.black.pureBlack} icon={LocalIconEnums.TWITTER} /></SocialIcon>
        </SocialIcons>

        <CopySection>
          <FakeInput><LineEllipsis
            text={pageUrl}
            maxLine={0}
            ellipsis='...'
            trimRight
            basedOn='words' /></FakeInput>
          <Button onClick={() => _handleShare(ShareTypesEnum.COPY)} className='button' variant='primary' title={complete ? 'Copied' : 'Copy'} fill={false} colors={btnColors} />
        </CopySection>
      </ShareModalInner>
    </ShareModalWrapper>
  )

}
