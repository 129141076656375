import React, { useState, useEffect, ReactNode } from 'react'

import update from 'react-addons-update'
import styled, { CSS, css } from 'styled-components'

import { Title } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useLocation, useNavigate } from 'react-router'

export interface TabProps {
  tab?: string
  tabElement?: ReactNode
  id: string
  children: ReactNode
  slug?: string
}

export function Tab({ children }: TabProps): JSX.Element {
  return (
    <>
      {children}
    </>
  )
}

const Container = styled.div`
  width: 100%;
`

const Headings = styled.div<{ $backgroundColor?: string }>`
  width: 100%;
  display: flex;
  
    gap: 0;

    @media (min-width: 30em) {
      gap: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.069vw;
    }

    @media (min-width: 90em) {
      gap: 1px;
    }
  

  ${(props): string => props.$backgroundColor ? `background-color: ${props.$backgroundColor};` : ''}

  .tab-heading {
    background-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.white.pureWhite};
  }

  .tab-heading:last-child {
      border-right: 0;
  }
`

const CompactHeader = styled.div<{ $selected: boolean, $backgroundColor?: string }>`

  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  
    padding: 1.875vw;

    @media (min-width: 30em) {
      padding: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.417vw;
    }

    @media (min-width: 90em) {
      padding: 6px;
    }
  

  ${(props): CSS => {
    if (props.$selected) {
      return css`
        
    border-bottom-width: 0.625vw;

    @media (min-width: 30em) {
      border-bottom-width: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.278vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 4px;
    }
  
        border-color: ${(props): string => props.theme.colors.green.deYork};
        .active-tab-header {
          color: ${(props): string => props.theme.colors.green.bottleGreen};
        }
      `
    } else {
      return css`
        
    border-bottom-width: 0.313vw;

    @media (min-width: 30em) {
      border-bottom-width: 0.938vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.208vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 3px;
    }
  
        border-color: ${(): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.white.pureWhite};
        
    margin-bottom: 0.313vw;

    @media (min-width: 30em) {
      margin-bottom: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.069vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 1px;
    }
  
        .active-tab-header {
          color: ${(props): string => props.theme.colors.grey.silver};
        }
      `
    }
  }}

  .tab-header, .active-tab-header{
    
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  }
`

const Header = styled.div<{ $selected: boolean, $backgroundColor?: string }>`
  flex-grow: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  
    padding: 2.500vw 0 2.500vw 0;

    @media (min-width: 30em) {
      padding: 6.875vw 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 1.528vw 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      padding: 22px 0 12px 0;
    }
  

  ${(props): CSS => {
    if (props.$selected) {
      return css`
        
    border-bottom-width: 0.625vw;

    @media (min-width: 30em) {
      border-bottom-width: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.278vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 4px;
    }
  
        border-color: ${(props): string => props.theme.colors.green.deYork};
        .active-tab-header {
          color: ${(props): string => props.theme.colors.green.bottleGreen};
        }
      `
    } else {
      return css`
        
    border-bottom-width: 0.313vw;

    @media (min-width: 30em) {
      border-bottom-width: 0.938vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.208vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 3px;
    }
  
        border-color: ${(): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.white.pureWhite};
        
    margin-bottom: 0.313vw;

    @media (min-width: 30em) {
      margin-bottom: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.069vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 1px;
    }
  
        .active-tab-header {
          color: ${(props): string => props.theme.colors.grey.silver};
        }
      `
    }
  }}

  .tab-header, .active-tab-header{
    
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.250vw;
    }

    @media (min-width: 90em) {
      font-size: 18px;
    }
  
  }
`

const Content = styled.div<{ paddContent: boolean }>`
  width: 100%;
  height: fit-content;
  ${(props): string => props.paddContent ? ResponsivePXValue('padding', { mobile: '12px', tablet: '24px', desktop: '24px' }): ''}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

export interface TabsProps {
  currentTab?: string
  onChange?: (key: string) => void
  children: JSX.Element | JSX.Element[]
  paddContent?: boolean
  headerBackgroundColor?: string
  compact?: boolean
  useUrlNavigation?: boolean
  baseUrl?: string
}

interface TabsState {
  selectedKey: string | null
}

const DEFAULT_STATE: TabsState = {
  selectedKey: null,
}

export function Tabs({ 
  currentTab, 
  onChange, 
  children, 
  paddContent = true, 
  headerBackgroundColor, 
  compact = false, 
  useUrlNavigation= false, 
  baseUrl = '' 
}: TabsProps): JSX.Element {
  
  const [state, setState] = useState<TabsState>({ ...DEFAULT_STATE })
  const navigate = useNavigate()
  const location = useLocation()

  const _handleTabClicked = (tab: string) => {
    setState((prevState) => update(prevState, {
      selectedKey: { $set: tab },
    }))


    if (useUrlNavigation) {
      const childProps = React.Children.toArray(children).find((child: any) => tab === child.props.id)?.props
      const slug = childProps?.slug || tab
      navigate(`${baseUrl}/${slug}`)
    }

    onChange?.(tab)
  }


  useEffect(() => {
    if (state.selectedKey === null) {
      if (Array.isArray(children) && children?.[0].props?.id) {
        setState((prevState) => update(prevState, {
          selectedKey: { $set: children?.[0].props?.id },
        }))
      } else if (!Array.isArray(children) && children?.props?.id) {
        setState((prevState) => update(prevState, {
          selectedKey: { $set: children?.props?.id },
        }))
      }
    }
  }, [children])

  useEffect(() => {
    if (currentTab) {
      setState((prevState) => update(prevState, {
        selectedKey: { $set: currentTab },
      }))
    }
  }, [currentTab])

  useEffect(() => {
    if (useUrlNavigation) {
      const currentPath = location.pathname.replace(baseUrl, '').split('/')[1]
      const activeChild = React.Children.toArray(children).find((child: any) => 
        child.props.slug === currentPath || child.props.id === currentPath
      )

      if (activeChild) {
        setState((prevState) => update(prevState, {
          selectedKey: { $set: (activeChild as any).props.id },
        }))
      }
    }
  }, [location, children, baseUrl, useUrlNavigation])

  let $content: ReactNode
  let selectedIndex = -1
  const $headers: (ReactNode | string)[] = []
  const tags: string[] = []

  React.Children.forEach(children, (child, index) => {
    if ((child.props.tab || child.props.tabElement) && child.props.id) {
      tags.push(child.props.id)
      if (child.props.id === state.selectedKey) {
        selectedIndex = index
        $content = child.props.children
      }
      if (child.props.tab) {
        $headers.push(
          <Title
            variant='t3'
            className={child.props.id === state.selectedKey ? 'active-tab-header' : 'tab-header'}
            color={child.props.id === state.selectedKey ? theme.colors.green.bottleGreen : theme.colors.grey.silver}>
            {child.props.tab}
          </Title>,
        )
      } else if (child.props.tabElement) {
        $headers.push(React.cloneElement(child.props.tabElement, {
          className: state.selectedKey ? 'active-tab-header' : 'tab-header',
        }))
      }
    }
  })

  let $header: ReactNode
  let headerIndex: number

  return (
    <Container>
      <Headings $backgroundColor={headerBackgroundColor}>
        <For each='$header' index='headerIndex' of={$headers}>
          <Choose>
            <When condition={compact}>
              <CompactHeader
                $backgroundColor={headerBackgroundColor}
                className='tab-heading'
                onClick={() => _handleTabClicked(tags[headerIndex])}
                key={headerIndex}
                $selected={headerIndex === selectedIndex}>
                {$header}
              </CompactHeader>
            </When>
            <Otherwise>
              <Header
                $backgroundColor={headerBackgroundColor}
                className='tab-heading'
                onClick={() => _handleTabClicked(tags[headerIndex])}
                key={headerIndex}
                $selected={headerIndex === selectedIndex}>
                {$header}
              </Header>
            </Otherwise>
          </Choose>
        </For>
      </Headings>
      <Content paddContent={paddContent}>
        {$content}
      </Content>
    </Container>
  )

}
