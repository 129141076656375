import { Heading } from "@atoms/typography"
import { PageTimetableGroupFragment, PageTimetableSectionFragment } from "@hooks/api"
import React from "react"

import styled, { useTheme } from 'styled-components'
import { PageTimetableGroup } from "./PageTimetableGroup"
import { ResponsivePXValue } from "@components/Theme"

const Container = styled.div`
    width: 100%;

`

const TitleContainer = styled.div`
    width: 100%;
    
    margin: 4.063vw 0.000vw;

    @media (min-width: 30em) {
      margin: 4.063vw 0.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 3.333vw 0.000vw;
    }

    @media (min-width: 90em) {
      margin: 48px 0px;
    }
  
    /* display: flex;
    justify-content: center;
    align-items: center; */
`

const TimeTableContainer = styled.div`
    width: 100%;
`
interface PageTimetableSectionProps {
 section: PageTimetableSectionFragment
}
export function PageTimetableSection({ section }: PageTimetableSectionProps): JSX.Element {

    const { colors } = useTheme()

    let timetableGroup!: PageTimetableGroupFragment
    let index!: number
    return (
        <Container>
            <If condition={!!section.title}>
                <TitleContainer>
                    <Heading variant='h1' color={colors.green.bottleGreen}>{section.title}</Heading>
                </TitleContainer>
            </If>
            <TimeTableContainer>
                <For each="timetableGroup" index="index" of={section.timetableGroups}>
                    <PageTimetableGroup key={timetableGroup.id} timetableGroup={timetableGroup} />
                </For>
            </TimeTableContainer>  
        </Container>
    )
}
