import React, { useEffect, useMemo, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { Paragraph, Title, Card, FavouriteButton, Button, LocalIconEnums, Tag, Icon } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { BundleProductDetailsFragment, ProductDetailsFragment, useAddBundleProductToCartMutation, useChangeCartItemQuantityMutation, useCustomerQuery, useProductAlertSubscribeMutation, useRemoveItemFromCartMutation } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { Modal } from '@molecules/content/Modal'
import { useSimpleToasts } from '@simple/toasts'
import { BundleOptionInput, CustomerTypeEnum, ProductStockStatus } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductAlertTypeEnum } from './AddSimpleProductToCart'
import { AddToCartButton, AddToCartDisplayTypeEnum } from './AddToCartButton'
import { BundleProductConfig } from './BundleProductConfig'
import { BundleSummary } from './BundleSummary'

const QUANTITY_CHANGE_TIMEOUT = 2000

const Container = styled.div`
  width: 100%;
  
    box-shadow: 0.000vw -0.625vw 1.875vw rgba(0, 0, 0, 0.1);

    @media (min-width: 30em) {
      box-shadow: 0.000vw -0.625vw 1.875vw rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 30.0625em) {
      box-shadow: 0.000vw -0.139vw 0.417vw rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 90em) {
      box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    }
  
  .card {
    margin: 0;
    
    padding: 3.125vw 3.750vw 3.750vw 3.750vw;

    @media (min-width: 30em) {
      padding: 3.125vw 3.750vw 3.750vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.694vw 0.833vw 0.833vw 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 10px 12px 12px 12px;
    }
  
  }
  .add-to-cart-button {
    width: 100%;
  }
`

const UpperContainer = styled.div`
  .heading {
    
    margin: 0 1.563vw 0 0;

    @media (min-width: 30em) {
      margin: 0 1.563vw 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0.347vw 0 0;
    }

    @media (min-width: 90em) {
      margin: 0 5px 0 0;
    }
  
  }
  .notify {
    
    margin: 0 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 0 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 12px 0;
    }
  
  }
  .mobile-inner {
    margin: 0;
  }
  .bundle-options {
    width: 100%;
    
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 8px;
    }
  
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: space-between;
  
    margin: 0 0 3.125vw 0;

    @media (min-width: 30em) {
      margin: 0 0 3.125vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.694vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 10px 0;
    }
  
  .discount {}
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CartFavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
`

const ConfigureBundleButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
    padding: 2.500vw 0;

    @media (min-width: 30em) {
      padding: 2.500vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw 0;
    }

    @media (min-width: 90em) {
      padding: 8px 0;
    }
  
  .configure-button {
    width: 100%;
  }
`

const ModalContainer = styled.div`
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    width: 100vw;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 55.556vw;
    }

    @media (min-width: 90em) {
      width: 800px;
    }
  

  .messageClass {
    
    margin: 6.250vw 0;

    @media (min-width: 30em) {
      margin: 6.250vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.389vw 0;
    }

    @media (min-width: 90em) {
      margin: 20px 0;
    }
  
  }
`

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 1.875vw;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
  
    padding: 1.250vw 1.250vw 3.750vw;

    @media (min-width: 30em) {
      padding: 1.875vw 2.500vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.417vw 0.556vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 6px 8px 24px;
    }
  

  .selection-warning {
    padding: 0;
    margin: 0;
    flex-grow: 1;
  }
`

const WarningIcon = styled.div`
  flex-shrink: 0;
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
`

export interface AddBundleProductToCartProps {
  product: ProductDetailsFragment & BundleProductDetailsFragment
  loading: boolean
  selectedOptions: { optionId: number, valueId: number, quantity: number }[]
  onSelectedOptionsChange: (selectedOptions: { optionId: number, valueId: number, quantity: number }[]) => void
  isValid: boolean
  onIsValidChange: (isValid: boolean) => void
  hasChanged: boolean
}

interface AddBundleProductToCartState {
  newAmount: number | null
  showConfig: boolean
  showMoreItems: boolean
}

const DEFAULT_STATE: AddBundleProductToCartState = {
  newAmount: null,
  showConfig: false,
  showMoreItems: false,
}

export function AddBundleProductToCart({ product, selectedOptions, onSelectedOptionsChange, isValid, onIsValidChange, loading, hasChanged }: AddBundleProductToCartProps): JSX.Element {

  const theme = useTheme()
  const navigate = useNavigate()
  const { data: customerData } = useCustomerQuery()
  const [state, setState] = useState<AddBundleProductToCartState>({ ...DEFAULT_STATE })
  const { cartId } = useCartId()
  const { addToast } = useSimpleToasts()
  const updateTimerRef = useRef(null)
  const [addProductToCart, { loading: addLoading }] = useAddBundleProductToCartMutation()
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()
  const [changeQuantity, { loading: quantityLoading }] = useChangeCartItemQuantityMutation()
  const [productAlertSubscribeMutation, { loading: ProductAlertSubscriptionLoading }] = useProductAlertSubscribeMutation()

  const pricing: { price: number, regularPrice: number } = useMemo(() => {
    let price = 0
    let regularPrice = 0
    product.items.forEach((item) => {
      const optionGroupItems = selectedOptions.filter((opt) => opt.optionId === item.optionId)
      if (optionGroupItems.length) {
        item.options.forEach((opt) => {
          const selectedOption = optionGroupItems.find((ogi) => ogi.valueId === opt.id)
          if (selectedOption) {
            const itemPrice = ((opt.product.priceRange.minimumPrice.finalPrice.value / 100) * (100 - item.priceRange.minimumPrice.discount.percentOff)) * selectedOption.quantity
            const itemRegularPrice = opt.product.priceRange.minimumPrice.regularPrice.value * selectedOption.quantity
            price += itemPrice
            regularPrice += itemRegularPrice
          }
        })
      }
    })
    return { price, regularPrice }
  }, [product.items, selectedOptions])

  const isConfigurable = useMemo(() => {
    for (let i = 0; i < product.items.length; i++) {
      if (!product.items[i].required) {
        return true
      }
      if (product.items[i].options.length > 1) {
        return true
      }
      for (let o = 0; o < product.items[i].options.length; o++) {
        if (product.items[i].options[o].canChangeQuantity) {
          return true
        }
      }
    }
  }, [product.items])

  const addProduct = async (): Promise<void> => {
    try {
      if (product.cartItemUid) {
        await removeProduct()
      }
      const bundleOptions: BundleOptionInput[] = []
      selectedOptions.forEach((sel) => {
        const existing = bundleOptions.find((opt) => opt.id === sel.optionId && opt.quantity === sel.quantity)
        if (existing) {
          existing.value.push(sel.valueId+'')
        } else {
          bundleOptions.push({
            id: sel.optionId,
            quantity: sel.quantity,
            value: [sel.valueId+''],
          })
        }
      })
      await addProductToCart({
        variables: {
          input: {
            cartId,
            cartItems: [{
              bundleOptions,
              data: {
                quantity: 1,
                sku: product.sku,
              },
            }],
          },
        },
      })
      if (state.showConfig) {
        setState((prevState) => update(prevState, {
          showConfig: {
            $set: false,
          },
        }))
      }
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const removeProduct = async (): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          cartId,
          cartItemUid: product.cartItemUid,
        },
      })
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    } finally {
      setState((prevState) => update(prevState, {
        newAmount: { $set: null },
      }))
    }
  }

  const changeProduct = async (): Promise<void> => {
    updateTimerRef.current = setTimeout(async () => {
      try {
        await changeQuantity({
          variables: {
            cartId,
            cartItemUid: product.cartItemUid,
            quantity: state.newAmount,
          },
        })
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      } finally {
        setState((prevState) => update(prevState, {
          newAmount: { $set: null },
        }))
      }
    }, QUANTITY_CHANGE_TIMEOUT)
  }

  const updateProduct = async (): Promise<void> => {
    if (updateTimerRef.current) {
      clearTimeout(updateTimerRef.current)
    }
    if (!product.quantityInCart) {
      await addProduct()
    } else if (product.quantityInCart && state.newAmount === 0) {
      removeProduct()
    } else {
      changeProduct()
    }
  }

  const _handleAddProduct = async (): Promise<void> => {
    await addProduct()
  }

  const _handleIncreaseProduct = async (): Promise<void> => {
    const newAmount = state.newAmount === null ? product?.quantityInCart + 1 : state.newAmount + 1
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleDecreaseProduct = async (): Promise<void> => {
    let newAmount = state.newAmount === null ? product?.quantityInCart - 1 : state.newAmount - 1
    if (newAmount < 0) {
      newAmount = 0
    }
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleShowConfig = (): void => {
    setState((prevState) => update(prevState, {
      showConfig: {
        $set: true,
      },
    }))
  }

  const _handleHideConfig = (): void => {
    setState((prevState) => update(prevState, {
      showConfig: {
        $set: false,
      },
    }))
  }

  const _handleProductAlertSubscription = async (): Promise<void> => {
    if (customerData.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else {
      try {
        await productAlertSubscribeMutation({
          variables: {
            productId: product.id as unknown as string,
            type: ProductAlertTypeEnum.PRODUCT_ALERT_IN_STOCK as unknown as string,
          },
        })
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      } finally {
        addToast({
          message: 'Alert subscription has been saved',
          appearance: 'success',
        })
      }

    }
  }

  const displayType = product?.stockStatus === ProductStockStatus.OUT_OF_STOCK
    ? AddToCartDisplayTypeEnum.OUT_OF_STOCK
    : product?.quantityInCart
      ? AddToCartDisplayTypeEnum.QUANTITY
      : AddToCartDisplayTypeEnum.ADD

  useEffect(() => {
    if (state.newAmount !== null) {
      updateProduct()
    }
  }, [state.newAmount])

  const _handleGoToCart = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/cart')
  }

  const goToCartBtnColors = {
    color: theme.colors.green.bottleGreen,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.grey.gallery,
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.green.bottleGreen,
    hoverBorderColor: theme.colors.green.bottleGreen,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
  }

  const actualLoading = loading || addLoading || quantityLoading || removeLoading
  const buttonLabel = product.cartItemUid ? 'UPDATE OPTIONS' : 'SELECT OPTIONS'

  return (
    <Container>
      <Modal
        open={state.showConfig}
        title={`Bundle Options (R${pricing.price.toFixed(2)})`}
        allowClose={isValid}
        onClose={_handleHideConfig}>
        <ModalContainer>
          <BundleProductConfig
            product={product}
            selection={selectedOptions}
            onSelectionChange={onSelectedOptionsChange}
            onClose={_handleHideConfig}
            onAllowCloseChange={onIsValidChange}
            hasChanged={hasChanged}
            onCommit={_handleAddProduct}
            loading={actualLoading} />
        </ModalContainer>
      </Modal>
      <Card className='card' >
        <DeviceContainer desktop>
          <UpperContainer>
            <>
              <InnerContainer>
                <PriceContainer>
                  <Title className='heading' variant='t9' >R{pricing.price.toFixed(2)}</Title>
                  <If condition={pricing.regularPrice !== pricing.price}>
                    <Paragraph
                      color={theme.colors.grey.gunSmoke}
                      variant='p2'
                      className='discount'
                      decoration='line-through'>
                      R{pricing.regularPrice.toFixed(2)}
                    </Paragraph>
                  </If>
                </PriceContainer>
                <CartFavContainer>
                  <FavouriteButton className='favourite' product={product} />
                  <If condition={product?.quantityInCart > 0}>
                    <DeviceContainer mobile>
                      <Button
                        title='GO TO'
                        rightIcon={LocalIconEnums.CART_FILLED}
                        shape='round' size='large'
                        colors={goToCartBtnColors}
                        href='/cart'
                        onClick={_handleGoToCart} />
                    </DeviceContainer>
                  </If>
                </CartFavContainer>
              </InnerContainer>
              {/* TODO: Default values */}
              <BundleSummary product={product} selectedOptions={selectedOptions} />
              <If condition={isConfigurable}>
                <ConfigureBundleButton>
                  <Button title={buttonLabel} size='large' variant='secondary' className='configure-button' onClick={_handleShowConfig} loading={actualLoading} />
                </ConfigureBundleButton>
              </If>
              <Choose>
                <When condition={!isValid && isConfigurable}>
                  <WarningContainer>
                    <WarningIcon>
                      <Icon icon={LocalIconEnums.ERROR} color={theme.colors.orange.casablanca} />
                    </WarningIcon>
                    <Tag variant='t2' className='selection-warning' color={theme.colors.orange.casablanca}>The selected options for this product are not valid, please update your selected options in order to add to cart.</Tag>
                  </WarningContainer>
                </When>
                <When condition={hasChanged}>
                  <WarningContainer>
                    <WarningIcon>
                      <Icon icon={LocalIconEnums.ERROR} color={theme.colors.orange.casablanca} />
                    </WarningIcon>
                    <Tag variant='t2' className='selection-warning' color={theme.colors.orange.casablanca}>You have updated your selected options but not saved these changes to the items in your cart.</Tag>
                  </WarningContainer>
                  <Button className='bundle-options' title='UPDATE MY CART' loading={actualLoading} onClick={_handleAddProduct} />
                </When>
              </Choose>
              <If condition={displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK}>
                <Paragraph>
                  <Link className='notify' href='#' display='block' onClick={_handleProductAlertSubscription}>Notify me when back in stock</Link>
                </Paragraph>
              </If>
            </>
          </UpperContainer>
          <AddToCartButton
            disabled={!isValid}
            displayType={displayType}
            currentQuantity={state.newAmount ? state.newAmount : product.quantityInCart}
            loading={actualLoading}
            onAdd={_handleAddProduct}
            onIncrease={_handleIncreaseProduct}
            onDecrease={_handleDecreaseProduct} />
        </DeviceContainer>
        <DeviceContainer mobile tablet>
          <UpperContainer>
            <>
              <If condition={isConfigurable}>
                <Button title={buttonLabel} size='large' variant='secondary' className='bundle-options' onClick={_handleShowConfig} loading={actualLoading} />
              </If>
              <Choose>
                <When condition={!isValid && isConfigurable}>
                  <WarningContainer>
                    <WarningIcon>
                      <Icon icon={LocalIconEnums.ERROR} color={theme.colors.orange.casablanca} />
                    </WarningIcon>
                    <Tag variant='t2' className='selection-warning' color={theme.colors.orange.casablanca}>The selected options for this product are not valid, please update your selected options in order to add to cart.</Tag>
                  </WarningContainer>
                </When>
                <When condition={hasChanged}>
                  <WarningContainer>
                    <WarningIcon>
                      <Icon icon={LocalIconEnums.ERROR} color={theme.colors.orange.casablanca} />
                    </WarningIcon>
                    <Tag variant='t2' className='selection-warning' color={theme.colors.orange.casablanca}>You have updated your selected options but not saved these changes to the items in your cart.</Tag>
                  </WarningContainer>
                  <Button className='bundle-options' title='UPDATE MY CART' loading={actualLoading} onClick={_handleAddProduct} />
                </When>
              </Choose>
              <InnerContainer className='mobile-inner'>
                <PriceContainer>
                  <Title className='heading' variant='t9' >R{pricing.price.toFixed(2)}</Title>
                  <If condition={pricing.price !== pricing.regularPrice}>
                    <Paragraph
                      color={theme.colors.grey.gunSmoke}
                      variant='p2'
                      className='discount'
                      decoration='line-through'>
                      R{pricing.regularPrice.toFixed(2)}
                    </Paragraph>
                  </If>
                </PriceContainer>
                <CartFavContainer>
                  <AddToCartButton
                    disabled={!isValid}
                    displayType={displayType}
                    currentQuantity={state.newAmount ? state.newAmount : product.quantityInCart}
                    loading={actualLoading}
                    onAdd={_handleAddProduct}
                    onIncrease={_handleIncreaseProduct}
                    onDecrease={_handleDecreaseProduct} />
                </CartFavContainer>
              </InnerContainer>
              <If condition={displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK}>
                <Paragraph>
                  <Link className='notify' href='#' display='block' onClick={_handleProductAlertSubscription}>Notify me when back in stock</Link>
                </Paragraph>
              </If>
            </>
          </UpperContainer>
        </DeviceContainer>
      </Card>
    </Container>
  )

}
