import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph, Tag, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { ShippingActionFragment, ShippingMethodFragment, TempShippingActionFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    border-radius: 0;

    @media (min-width: 30em) {
      border-radius: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.694vw;
    }

    @media (min-width: 90em) {
      border-radius: 10px;
    }
  
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 4.167vw;
    }

    @media (min-width: 90em) {
      width: 60px;
    }
  
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
  
    border-radius: 3.125vw;

    @media (min-width: 30em) {
      border-radius: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.903vw;
    }

    @media (min-width: 90em) {
      border-radius: 13px;
    }
  
  ${(props): string => {
    if (props.active) {
      return `
        
    border-width: 1.250vw;

    @media (min-width: 30em) {
      border-width: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.347vw;
    }

    @media (min-width: 90em) {
      border-width: 5px;
    }
  
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    
    border-width: 0.313vw;

    @media (min-width: 30em) {
      border-width: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.139vw;
    }

    @media (min-width: 90em) {
      border-width: 2px;
    }
  
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export interface ShippingActionRowProps {
  action: TempShippingActionFragment
  selected: boolean
  onSelect: () => void
}

export function ShippingActionRow({ selected, action, onSelect }: ShippingActionRowProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container active={selected}>
      <If condition={!!onSelect}>
        <CircleContainer onClick={onSelect} >
          <Circle active={selected} />
        </CircleContainer>
      </If>
      <TextContainer onClick={onSelect}>
        <Title variant='t8'>
          {action.title}
        </Title>
        <Paragraph color={theme.colors.grey.gunSmoke}>
          {action.description}
        </Paragraph>
      </TextContainer>
    </Container>
  )

}
