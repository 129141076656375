import React from 'react'

import styled, { useTheme } from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { BaseCategoryFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  position: relative;

  .category-title-standalone {
    
    margin-left: 5.000vw;

    @media (min-width: 30em) {
      margin-left: initial;
    }

    @media (min-width: 30.0625em) {
      margin-left: initial;
    }

    @media (min-width: 90em) {
      margin-left: initial;
    }
  
  }
  .category-title-mobile {
    
    margin: 5.000vw 5.000vw 0;

    @media (min-width: 30em) {
      margin: initial;
    }

    @media (min-width: 30.0625em) {
      margin: initial;
    }

    @media (min-width: 90em) {
      margin: initial;
    }
  
  }
  /* .html {
    p:not(:first-child) {
      display: none;
    }
    
    max-height: 31.250vw;

    @media (min-width: 30em) {
      max-height: 46.875vw;
    }

    @media (min-width: 30.0625em) {
      max-height: 10.417vw;
    }

    @media (min-width: 90em) {
      max-height: 150px;
    }
  
    overflow: hidden;
    text-align: left !important;

    p {
      text-align: left !important;
    }
    a {
      text-decoration: none;
      
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
      font-weight: 700;
      color: ${(props): string => props.theme.colors.black.pureBlack};
    }
    a:hover {
      text-decoration: underline;
      
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
      font-weight: 700;
    }

  } */
`

const Header = styled.div`
  display: flex;
  position: relatvive;
  
    border-radius: 0;

    @media (min-width: 30em) {
      border-radius: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.556vw;
    }

    @media (min-width: 90em) {
      border-radius: 8px;
    }
  
  
    height: 66.250vw;

    @media (min-width: 30em) {
      height: 125.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 27.778vw;
    }

    @media (min-width: 90em) {
      height: 400px;
    }
  
  
    margin: 0;

    @media (min-width: 30em) {
      margin: 0 0 7.500vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 1.667vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 24px 0;
    }
  

  background-color: ${(props): string => props.theme.colors.grey.swirl};
  .mobile-category-title {
    position: absolute;
    width: 100%;
    
    top: 5.000vw;

    @media (min-width: 30em) {
      top: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      top: 1.111vw;
    }

    @media (min-width: 90em) {
      top: 16px;
    }
  
    
    left: 5.000vw;

    @media (min-width: 30em) {
      left: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      left: 1.111vw;
    }

    @media (min-width: 90em) {
      left: 16px;
    }
  
  }
`

const CategoryDescription = styled.div`
  
    padding: 5.000vw 5.000vw 0.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw 5.000vw 0.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 1.111vw 0.000vw;
    }

    @media (min-width: 90em) {
      padding: 16px 16px 0px;
    }
  
  .html {
    
    overflow: visible;

    @media (min-width: 30em) {
      overflow: initial;
    }

    @media (min-width: 30.0625em) {
      overflow: initial;
    }

    @media (min-width: 90em) {
      overflow: initial;
    }
  
  }

  p {
    
    margin: 0;

    @media (min-width: 30em) {
      margin: initial;
    }

    @media (min-width: 30.0625em) {
      margin: initial;
    }

    @media (min-width: 90em) {
      margin: initial;
    }
  
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
    margin: 0;

    @media (min-width: 30em) {
      margin: 34.375vw 10.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 7.639vw 2.222vw;
    }

    @media (min-width: 90em) {
      margin: 110px 32px;
    }
  
  
    width: auto;

    @media (min-width: 30em) {
      width: 55%;
    }

    @media (min-width: 30.0625em) {
      width: 55%;
    }

    @media (min-width: 90em) {
      width: 55%;
    }
  

  .category-title {
    
    margin: 0 0 5.000vw 0;

    @media (min-width: 30em) {
      margin: 0 0 5.000vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 1.111vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 16px 0;
    }
  
  }

`

const ImageContainer = styled.div`
  
    width: 100%;

    @media (min-width: 30em) {
      width: 212.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 47.222vw;
    }

    @media (min-width: 90em) {
      width: 680px;
    }
  
`

const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
    padding: 20.000vw;

    @media (min-width: 30em) {
      padding: 20.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 4.444vw;
    }

    @media (min-width: 90em) {
      padding: 64px;
    }
  
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`

const NewImageContainer = styled.div`
  width: 100%;
  
    min-height: 50.000vw;

    @media (min-width: 30em) {
      min-height: 125.000vw;
    }

    @media (min-width: 30.0625em) {
      min-height: 27.778vw;
    }

    @media (min-width: 90em) {
      min-height: 400px;
    }
  
`

export interface BaseCategoryHeaderProps {
  category: BaseCategoryFragment
}

export function BaseCategoryHeader({ category }: BaseCategoryHeaderProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <Choose>
        <When condition={!category?.coverImage}>
          <Title variant='t1' className='category-title-standalone'>{category?.name}</Title>
        </When>
        <Otherwise>
          <NewImageContainer>
            <DeviceContainer mobile>
              <ResponsiveImage image={category.coverImage} objectFit='contain' />
              <Title variant='t1' className='category-title-mobile'>{category?.name}</Title>
            </DeviceContainer>
            <DeviceContainer desktop tablet>
              <ResponsiveImage image={category.coverImage} objectFit='contain' />
              <TitleContainer>
                <Title variant='t1' className='category-title' color={theme.colors.white.pureWhite}>{category?.name}</Title>
              </TitleContainer>
            </DeviceContainer>
          </NewImageContainer>
        </Otherwise>
      </Choose>

    </Container>
  )

  // return (
  //   <Container>
  //     <Header>
  //       <DeviceContainer mobile><Title variant='t1' className='mobile-category-title'>{category?.name}</Title></DeviceContainer>
  //       <DeviceContainer desktop tablet>
  //         <InfoContainer>
  //           <Title variant='t1' className='category-title'>{category?.name}</Title>
  //           <If condition={!!category?.description}>
  //             <HTML className='html'>
  //               {category?.description}
  //             </HTML>
  //           </If>
  //         </InfoContainer>
  //       </DeviceContainer>
  //       <ImageContainer>
  //         <ResponsiveImage location={category?.image} objectFit='contain' />
  //       </ImageContainer>
  //     </Header>
  //     <DeviceContainer mobile>
  //       <CategoryDescription>
  //         <HTML className='html'>
  //           {category?.description}
  //         </HTML>
  //       </CategoryDescription>
  //     </DeviceContainer>
  //   </Container>

  // )

}
