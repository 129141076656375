import React from 'react'

import loadable from '@loadable/component'
import { LoadingContainer } from '@atoms/misc'
import { Loader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/IngredientsLibrary')

const IngredientsLibrary = loadable(() => import(/* webpackChunkName: "ingredientsLibrary" */'@pages/IngredientsLibrary'), {
  resolveComponent: (components) => components.IngredientsLibrary,
})

export function IngredientsLibraryAsync(): JSX.Element {

  return <IngredientsLibrary fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
