import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const LabelElement = styled.div<{ color: string, backgroundColor: string, addRadius: boolean}>`

  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: open-sans;
  font-weight: 700;
  width: fit-content;
  
  ${(props): string => ResponsivePXValue('border-radius', props.addRadius ? '8px' : '0')};

  
    padding: 1.250vw 3.750vw;

    @media (min-width: 30em) {
      padding: 1.250vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 4px 12px;
    }
  
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    gap: 1.875vw;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
  
    min-height: 7.500vw;

    @media (min-width: 30em) {
      min-height: 8.125vw;
    }

    @media (min-width: 30.0625em) {
      min-height: 1.806vw;
    }

    @media (min-width: 90em) {
      min-height: 26px;
    }
  
`

const Content = styled.div``

const Close = styled.div`
  
    width: 3.750vw;

    @media (min-width: 30em) {
      width: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 0.833vw;
    }

    @media (min-width: 90em) {
      width: 12px;
    }
  
  
    height: 3.750vw;

    @media (min-width: 30em) {
      height: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.833vw;
    }

    @media (min-width: 90em) {
      height: 12px;
    }
  
  &:hover {
    path {
      stroke: ${(props): string => props.theme.colors.white.pureWhite};
    }
  }
`
export interface LabelProps {
  children?: string
  color?: string
  radius?: boolean
  backgroundColor?: string
  onClose?: () => void
}

export function Label({ children, color, backgroundColor, onClose, radius = false }: LabelProps): JSX.Element {

  const theme = useTheme()

  return (
    <LabelElement
      color={color || theme.colors.white.floralWhite}
      addRadius={radius}
      backgroundColor={backgroundColor || theme.colors.green.watercourse}>
      <Content>
        {children}
      </Content>
      <If condition={!!onClose}>
        <Close onClick={onClose}>
          <Icon color={color || theme.colors.green.greenVogue} icon={LocalIconEnums.LARGE_CROSS} />
        </Close>
      </If>
    </LabelElement>
  )

}
