import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { LayoutGrid, LayoutRow, LayoutCol } from '@atoms/layout'
import { UltraContainer } from '@atoms/misc'
import { ResponsivePXValue } from '@components/Theme'
import { BreadcrumbFragment, BundleCartItemFragment, BundleItemFragment, BundleItemOptionFragment, BundleProductDetailsFragment, ProductDetailsFragment, useCartQuery, useGetAppQuery } from '@hooks/api'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { UpSellWidget } from '@molecules/store'
import { AddBundleProductToCart } from '@molecules/store/AddBundleProductToCart'
import { BundleSummary } from '@molecules/store/BundleSummary'
import { ProductBreadCrumbs, ProductShare } from '@organisms/product'
import { MobileOSTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductInformation } from './ProductInformation'

const Sticky = styled.div`
  position: sticky;
  
    top: -15.625vw;

    @media (min-width: 30em) {
      top: -15.625vw;
    }

    @media (min-width: 30.0625em) {
      top: -3.472vw;
    }

    @media (min-width: 90em) {
      top: -50px;
    }
  
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
`

const Fixed = styled.div<{ bottom?: string }>`
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 29;
  ${props => (props.bottom) ? ResponsivePXValue('bottom', props.bottom) : 0};
`

const StickyContainer = styled.div`
  position: relative;
  
  
    height: 100%;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
`

export interface BundleProductDetailsProps {
  product: ProductDetailsFragment & BundleProductDetailsFragment
  loading: boolean
  onBack: () => void
  overwriteBreadCrumbs?: BreadcrumbFragment[]
}

interface BundleProductDetailsState {
  selectedOptions: { optionId: number, valueId: number, quantity: number }[]
  isValid: boolean
  hasChanged: boolean
}

const DEFAULT_STATE: BundleProductDetailsState = {
  selectedOptions: [],
  isValid: true,
  hasChanged: false,
}

export function BundleProductDetails({ product, loading, onBack, overwriteBreadCrumbs }: BundleProductDetailsProps): JSX.Element {

  const [state, setState] = useState<BundleProductDetailsState>({ ...DEFAULT_STATE })
  const { cartId } = useCartId()
  const { data: cartData } = useCartQuery({ variables: { cartId } })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const _handleSelectedOptionsChange = (selectedOptions: { optionId: number, valueId: number, quantity: number }[]): void => {
    setState((prevState) => update(prevState, {
      selectedOptions: {
        $set: selectedOptions,
      },
    }))
  }

  const _handleIsValidChange = (isValid: boolean): void => {
    setState((prevState) => update(prevState, {
      isValid: {
        $set: isValid,
      },
    }))
  }

  useEffect(() => {
    if (product?.items?.length) {
      if (product.quantityInCart) {
        const cartItem = cartData?.cart?.items?.find((itm) => itm.uid === product.cartItemUid) as BundleCartItemFragment
        if (cartItem) {
          const options: { optionId: number, valueId: number, quantity: number }[] = []
          cartItem.bundleOptions.forEach((item) => {
            item.values.forEach((option) => {
              options.push({
                optionId: item.id,
                valueId: option.id,
                quantity: option.quantity,
              })
            })
          })
          setState((prevState) => update(prevState, {
            selectedOptions: {
              $set: options,
            },
            isValid: {
              $set: true,
            },
          }))
        }
      } else if (!state.selectedOptions.length) {
        const options: { optionId: number, valueId: number, quantity: number }[] = []

        const addAndalidateOption = (item: BundleItemFragment, option: BundleItemOptionFragment, isDefault: boolean): boolean => {
          let optionIsValid = true
          const stockCount = option.product.stockInfo.sources.reduce((stock, src) => stock + src.qty, 0)
          let hasAddedItem = false
          if (isDefault && (option.quantity <= stockCount || (stockCount > 0 && option.canChangeQuantity))) {
            if (option.quantity > stockCount && stockCount > 0 && option.canChangeQuantity) {
              optionIsValid = false
            }
            hasAddedItem = true
            options.push({
              optionId: item.optionId,
              valueId: option.id,
              quantity: option.quantity,
            })
          }
          if (item.required && !hasAddedItem) {
            optionIsValid = false
          }
          return optionIsValid
        }

        let isValid = true
        product.items.forEach((item) => {
          if (item.required && item.options.length === 1) {
            const option = item.options[0]
            const optionIsValid = addAndalidateOption(item, option, true)
            if (!optionIsValid) {
              isValid = false
            }
          } else {
            item.options.forEach((option) => {
              const optionIsValid = addAndalidateOption(item, option, option.isDefault)
              if (!optionIsValid) {
                isValid = false
              }
            })
          }

        })
        setState((prevState) => update(prevState, {
          selectedOptions: {
            $set: options,
          },
          isValid: {
            $set: isValid,
          },
        }))
      }
    }
  }, [product?.items?.length, cartData?.cart?.items?.length])

  useEffect(() => {
    const cartItem = cartData?.cart?.items?.find((itm) => itm.uid === product.cartItemUid) as BundleCartItemFragment
    if (cartItem) {
      const oldItems: string[] = []
      const newItems: string[] = []
      state.selectedOptions.forEach((option) => newItems.push(`${option.optionId}-${option.quantity}-${option.valueId}`))
      cartItem.bundleOptions.forEach((option) => {
        option.values.forEach((val) => oldItems.push(`${option.id}-${val.quantity}-${val.id}`))
      })
      const { add, remove } = SiteHelper.getArrayDiff<string>(oldItems, newItems)
      if (add.length || remove.length) {
        setState((prevState) => update(prevState, {
          hasChanged: {
            $set: true,
          },
        }))
      } else {
        setState((prevState) => update(prevState, {
          hasChanged: {
            $set: false,
          },
        }))
      }
    } else {
      setState((prevState) => update(prevState, {
        hasChanged: {
          $set: false,
        },
      }))
    }
  }, [state.selectedOptions, cartData?.cart?.items?.length])

  return (
    <UltraContainer>
      <LayoutGrid>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 9 }}>
            <ProductBreadCrumbs
              categories={product?.categories}
              breadcrumbs={overwriteBreadCrumbs}
              productName={product?.name}
              goBack={onBack}
            />
            <ProductInformation product={product}>
              <DeviceContainer mobile>
                <BundleSummary product={product} selectedOptions={state.selectedOptions} />
              </DeviceContainer>
            </ProductInformation>
          </LayoutCol>
          <LayoutCol span={{ mobile: 0, tablet: 0, desktop: 3 }}>
            <StickyContainer>
              <Sticky>
                <ProductShare />
                <Choose>
                  <When condition={!!product}>
                    <AddBundleProductToCart
                      selectedOptions={state.selectedOptions}
                      onSelectedOptionsChange={_handleSelectedOptionsChange}
                      isValid={state.isValid}
                      onIsValidChange={_handleIsValidChange}
                      product={product}
                      loading={loading}
                      hasChanged={state.hasChanged} />
                    <UpSellWidget />
                  </When>
                </Choose>
              </Sticky>
            </StickyContainer>
          </LayoutCol>
          <DeviceContainer mobile>
            <Fixed bottom={(appData.app.mobileOSType === MobileOSTypeEnum.APPLE) ? '72px' : '56px'} className='gonative-ios-push'>
              <Choose>
                <When condition={!!product}>
                  <AddBundleProductToCart
                    selectedOptions={state.selectedOptions}
                    onSelectedOptionsChange={_handleSelectedOptionsChange}
                    isValid={state.isValid}
                    onIsValidChange={_handleIsValidChange}
                    product={product}
                    loading={loading}
                    hasChanged={state.hasChanged} />
                </When>
              </Choose>
            </Fixed>
          </DeviceContainer>
        </LayoutRow>
      </LayoutGrid>
    </UltraContainer>
  )

}
