import React, { useEffect } from 'react'

import styled, { useTheme } from 'styled-components'

import { Button, ButtonColors } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsFragment } from '@hooks/api/index'
import { Modal } from '@molecules/content'
import { Form, TextInput, useForm } from '@molecules/inputs'
import { useSimpleToasts } from '@simple/toasts'

const Container = styled.div``

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
    margin: 12.500vw 6.250vw;

    @media (min-width: 30em) {
      margin: 12.500vw 6.250vw;
    }

    @media (min-width: 30.0625em) {
      margin: 2.778vw 1.389vw;
    }

    @media (min-width: 90em) {
      margin: 40px 20px;
    }
  
`

const IconContainer = styled.div`
  
    width: 25.000vw;

    @media (min-width: 30em) {
      width: 25.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 5.556vw;
    }

    @media (min-width: 90em) {
      width: 80px;
    }
  
  
    height: 25.000vw;

    @media (min-width: 30em) {
      height: 25.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 5.556vw;
    }

    @media (min-width: 90em) {
      height: 80px;
    }
  
  
    border-radius: 12.500vw;

    @media (min-width: 30em) {
      border-radius: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 2.778vw;
    }

    @media (min-width: 90em) {
      border-radius: 40px;
    }
  
  
    padding: 3.125vw;

    @media (min-width: 30em) {
      padding: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.694vw;
    }

    @media (min-width: 90em) {
      padding: 10px;
    }
  
  ${(props): string => ResponsivePXValue('border', `2px solid ${props.theme.colors.green.bottleGreen}`)}
`

const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  
    gap: 1.563vw;

    @media (min-width: 30em) {
      gap: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.347vw;
    }

    @media (min-width: 90em) {
      gap: 5px;
    }
  
  
    padding: 0 6.250vw 6.250vw;

    @media (min-width: 30em) {
      padding: 0 6.250vw 6.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.389vw 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 0 20px 20px;
    }
  
  .input {
    flex-grow: 1;
  }
  .button {
    flex-shrink: 0;
  }
`

export interface ProductShareModalProps {
  product: ProductDetailsFragment
  open: boolean
  onClose: () => void
}

export function ProductShareModal({ product, open, onClose }: ProductShareModalProps): JSX.Element {

  const { addToast } = useSimpleToasts()
  const theme = useTheme()
  const form = useForm()

  const _handleCopy = (): void => {
    navigator?.clipboard?.writeText(window.location.href)
    addToast({
      message: 'Link copied to clibpoard',
      appearance: 'info',
    })
  }

  const copyButtonColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.bottleGreen,
    borderColor: theme.colors.green.bottleGreen,
    hoverColor: theme.colors.green.bottleGreen,
    hoverBackgroundColor: theme.colors.yellow.cornSilk,
    hoverBorderColor: theme.colors.green.bottleGreen,
  }

  useEffect(() => {
    if (product && form) {
      form.setFieldsValue({ link: `${window.location.href}` })
    }
  }, [product, form])

  return (
    <Modal open={open} title='Share Product' onClose={onClose}>
      <Container>
        <IconsContainer>
          <IconContainer>
            <Icon icon={LocalIconEnums.FACEBOOK} color={theme.colors.green.bottleGreen} />
          </IconContainer>
          <IconContainer>
            <Icon icon={LocalIconEnums.TWITTER} color={theme.colors.green.bottleGreen} />
          </IconContainer>
        </IconsContainer>
        <Form form={form}>
          <FormContainer>
            <TextInput name='link' showLabel={false} wrapperClassName='input' />
            <Button title='COPY' className='button' colors={copyButtonColors} onClick={_handleCopy} />
          </FormContainer>
        </Form>
      </Container>
    </Modal>
  )

}
