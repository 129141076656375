import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageSplitTextSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div<{ backgroundColor: string}>`
  background-color: ${({ backgroundColor }): string => backgroundColor};
  width: 100%;
  
    height: initial;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: fit-content;
    }

    @media (min-width: 90em) {
      height: fit-content;
    }
  
  
    padding: 7.500vw 5.000vw;

    @media (min-width: 30em) {
      padding: 10.000vw 0.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 2.222vw 0.000vw;
    }

    @media (min-width: 90em) {
      padding: 32px 0px;
    }
  
  display: flex;
  align-items: center;
  gap: 16px;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: column;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  `

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  
    align-items: center;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-start;
    }

    @media (min-width: 90em) {
      align-items: flex-start;
    }
  
  
    justidy-content: initial;

    @media (min-width: 30em) {
      justidy-content: initial;
    }

    @media (min-width: 30.0625em) {
      justidy-content: center;
    }

    @media (min-width: 90em) {
      justidy-content: center;
    }
  
  
    padding: 0.000vw;

    @media (min-width: 30em) {
      padding: 0.000vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.000vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 0px 24px;
    }
  
  gap: 16px;
  `

const ContentContainer = styled.div`
  
    padding: 0.000vw;

    @media (min-width: 30em) {
      padding: 0.000vw 15.000vw 0.000vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.000vw 3.333vw 0.000vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 0px 48px 0px 24px;
    }
  
`

export interface PageSplitTextSectionProps {
  section: PageSplitTextSectionFragment
}

export function PageSplitTextSection({ section }: PageSplitTextSectionProps): JSX.Element {

  const { backgroundColor, description, foregroundColor, subTitle, title } = section

  return (
    <Container backgroundColor={backgroundColor}>
      <DeviceContainer desktop tablet>
        <TitlesContainer>
          <Heading
            variant='h1'
            color={foregroundColor}
            align='center'>
            {title}
          </Heading>
          <Heading
            variant='h5'
            align='center'
            color={foregroundColor}>
            {subTitle}
          </Heading>
        </TitlesContainer>
      </DeviceContainer>
      <DeviceContainer mobile>
        <TitlesContainer>
          <Heading
            variant='h2'
            color={foregroundColor}
            align='center'>
            {title}
          </Heading>
          <Paragraph
            variant='p1'
            align='center'
            bold={true}
            color={foregroundColor}>
            {subTitle}
          </Paragraph>
        </TitlesContainer>
      </DeviceContainer>
      <DeviceContainer mobile>
        <ContentContainer>
          <HTML align='center'>
            {description}
          </HTML>
        </ContentContainer>
      </DeviceContainer>
      <DeviceContainer tablet desktop>
        <ContentContainer>
          <HTML align='left'>
            {description}
          </HTML>
        </ContentContainer>
      </DeviceContainer>
    </Container>
  )

}
