
import React from 'react'

import loadable from '@loadable/component'
import { LoadingContainer } from '@atoms/misc'
import { Loader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/GiftVoucher')

const GiftVoucher = loadable(() => import(/* webpackChunkName: "me" */'@pages/GiftVoucher'), {
  resolveComponent: (components) => components.GiftVoucher,
})

export function GiftVoucherAsync(): JSX.Element {

  return <GiftVoucher fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
