import React from 'react'

import { useNavigate } from 'react-router'
import styled, { LayoutSizeClass } from 'styled-components'

import { Button } from '@atoms/buttons'
import { LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { layoutColWidthForGrid } from '@atoms/layout'
import { ResponsivePXValue } from '@components/Theme'
import { ProductListFragment } from '@hooks/api/index'

const Container = styled.div<{ size: LayoutSizeClass }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 12px;
    }
  
  
    height: 62.500vw;

    @media (min-width: 30em) {
      height: 68.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 15.278vw;
    }

    @media (min-width: 90em) {
      height: 220px;
    }
  
  
    width: 49.063vw;

    @media (min-width: 30em) {
      width: 67.813vw;
    }

    @media (min-width: 30.0625em) {
      width: 13.194vw;
    }

    @media (min-width: 90em) {
      width: 190px;
    }
  

  .button {
    width: 100%;
  }

  .product-card-favourite {
    position: absolute;
    
    top: 3.750vw;

    @media (min-width: 30em) {
      top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      top: 0.833vw;
    }

    @media (min-width: 90em) {
      top: 12px;
    }
  
    
    right: 3.750vw;

    @media (min-width: 30em) {
      right: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      right: 0.833vw;
    }

    @media (min-width: 90em) {
      right: 12px;
    }
  
  }
`

const CardFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 2.813vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 12px;
    }
  

`

const ImageContainer = styled.div<{ size: LayoutSizeClass }>`
  align-self: center;
  cursor: pointer;
  
  ${(props): string => ResponsivePXValue('width', {
    mobile: `${props.size.mobile - 24}px`,
    tablet: `${props.size.tablet - 36}px`,
    desktop: `${props.size.desktop - 48}px`,
  })}

  
    height: 45.625vw;

    @media (min-width: 30em) {
      height: 45.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 10.139vw;
    }

    @media (min-width: 90em) {
      height: 146px;
    }
  
  
    margin-bottom: 0;

    @media (min-width: 30em) {
      margin-bottom: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 8px;
    }
  
  
`

export interface GiftVoucherCardProps {
  product: ProductListFragment
  disabled?: boolean
}

export function GiftVoucherCard({ product }: GiftVoucherCardProps): JSX.Element {

  const navigate = useNavigate()

  const _handleAddToCart = (): void => {
    navigate(`/gift-voucher/${product.urlKey}`)
  }

  const size = layoutColWidthForGrid({ mobile: 5.2, tablet: 3.6, desktop: 2.30 })

  return (
    <Container size={size}>

      <ImageContainer size={size} onClick={_handleAddToCart}>
        <ResponsiveImage image={product.coverImage} objectFit='contain' />
      </ImageContainer>

      <CardFooter>
        <Button title='Select' size='medium' fullWidth={true} icon={LocalIconEnums.CART} onClick={() => _handleAddToCart()} />
      </CardFooter>

    </Container>
  )

}
