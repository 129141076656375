import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Label, Skeleton, SkeletonNode, Tag } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsFragment_SimpleProduct_ as SimpleDetails } from '@hooks/api/index'

type ProductValueAttributes = SimpleDetails['valueAttributes'][0]

const Strong = styled.span`
  font-weight: 700;
  
    position: relative;

    @media (min-width: 30em) {
      position: initial;
    }

    @media (min-width: 30.0625em) {
      position: initial;
    }

    @media (min-width: 90em) {
      position: initial;
    }
  
  
    right: 1.563vw;

    @media (min-width: 30em) {
      right: initial;
    }

    @media (min-width: 30.0625em) {
      right: initial;
    }

    @media (min-width: 90em) {
      right: initial;
    }
  
  
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: initial;
    }

    @media (min-width: 30.0625em) {
      line-height: initial;
    }

    @media (min-width: 90em) {
      line-height: initial;
    }
  
`

const TagsInnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
    margin: 2.500vw 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 6.250vw 0 5.000vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.389vw 0 1.111vw 0;
    }

    @media (min-width: 90em) {
      margin: 20px 0 16px 0;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const TagsContainer = styled.div`
  border-style: solid;
  
    padding: 0;

    @media (min-width: 30em) {
      padding: 3.125vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.694vw 0;
    }

    @media (min-width: 90em) {
      padding: 10px 0;
    }
  
  
    border-bottom-width: 0.313vw;

    @media (min-width: 30em) {
      border-bottom-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 1px;
    }
  
  border-color: ${(props): string => props.theme.colors.grey.athens};

  .values-label {
    width: 100%;
    margin: 0;
    
    margin-bottom: 0;

    @media (min-width: 30em) {
      margin-bottom: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 5px;
    }
  
  }
`

interface ProductValueAttributesProps {
  valueAttributes: ProductValueAttributes[]
}

interface ValueTagColorInterface {
  [key: string]: {
    color: string
    background: string
  }
}

export function ProductValueAttributes({ valueAttributes }: ProductValueAttributesProps): JSX.Element {

  if (!valueAttributes) {
    return (
      <TagsContainer>
        <Skeleton direction='column' justify='flex-start' align='flex-start' gap='28px' height='auto'>
          <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
            height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
            variWidth={{ mobile: '30%-45%', tablet: '25%', desktop: '20%-50%' }}
          />
        </Skeleton>
      </TagsContainer>
    )
  }

  const theme = useTheme()

  let tagIndex: number
  let valueAttribute: ProductValueAttributes
  let productValue: string

  const valueTagColors = {
    SOCIAL_VALUES: {
      background: theme.colors.yellow.lemonChiffon,
      color: theme.colors.orange.piper,
    },
    VALUES_SOCIAL: {
      background: theme.colors.yellow.lemonChiffon,
      color: theme.colors.orange.piper,
    },
    DIET_VALUES: {
      background: theme.colors.blue.tropicalBlue,
      color: theme.colors.blue.curiousBlue,
    },
    VALUES_DIET: {
      background: theme.colors.blue.tropicalBlue,
      color: theme.colors.blue.curiousBlue,
    },
    ENVIRONMENTAL_VALUES: {
      background: theme.colors.green.tusk,
      color: theme.colors.green.viridian,
    },
    VALUES_ENVIRONMENTAL: {
      background: theme.colors.green.tusk,
      color: theme.colors.green.viridian,
    },
  } as ValueTagColorInterface

  return (
    <TagsContainer>
      <Tag variant='t2' className='text-item' color={theme.colors.green.bottleGreen}><Strong>Values:</Strong> </Tag>
      <TagsInnerContainer>
        <For each='valueAttribute' of={valueAttributes || []} index='valueIndex'>
          <For each='productValue' of={valueAttribute.value || []} index='tagIndex'>
            <If condition={!!productValue}>
              <Label
                color={valueTagColors[valueAttribute.code].color}
                backgroundColor={valueTagColors[valueAttribute.code].background}
                key={tagIndex}
                radius={true}>
                {productValue.trim()}
              </Label>
            </If>
          </For>
        </For>
      </TagsInnerContainer>
    </TagsContainer>
  )

}
