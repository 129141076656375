import React, { useState, useEffect, ReactNode } from 'react'

// TODO Modal needs title element and sort out before open and after close functions

import ReactDOM from 'react-dom'
import styled, { useTheme } from 'styled-components'
import ModalComponent from 'styled-react-modal'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { LocalIconEnums, Icon, Heading } from '@atoms/index'
import { usePortal } from '@client/hooks/UsePortal'
import { StrongBoxShadow, ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const TRANSITION_DURATION = 500

const StyledModal = ModalComponent.styled`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div<{ opacity: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  opacity: ${(props): number => props.opacity};
  transform: translateY(${(props): string => props.opacity ? '0' : '100%'});
  transition: all ${TRANSITION_DURATION}ms ease;

  ${StrongBoxShadow}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: ${(props): string => props.theme.colors.grey.athens};

  
    border-bottom-width: 0.313vw;

    @media (min-width: 30em) {
      border-bottom-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 1px;
    }
  
  
    width: 100.000vw;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 100%;
    }

    @media (min-width: 90em) {
      width: 100%;
    }
  
  
    padding: 5.000vw 6.250vw;

    @media (min-width: 30em) {
      padding: 5.000vw 6.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 16px 20px;
    }
  

  .modal-heading {
    margin: 0;
  }
`

const IconContainer = styled.div`
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.222vw;
    }

    @media (min-width: 90em) {
      height: 32px;
    }
  
`

const OuterContainer = styled.div`
  position: relative;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};

  
    max-width: initial;

    @media (min-width: 30em) {
      max-width: initial;
    }

    @media (min-width: 30.0625em) {
      max-width: 80.000vw;
    }

    @media (min-width: 90em) {
      max-width: 1152px;
    }
  
  
    height: CALC(100vh - 17.500vw);

    @media (min-width: 30em) {
      height: CALC(100vh - 20.000vw);
    }

    @media (min-width: 30.0625em) {
      height: initial;
    }

    @media (min-width: 90em) {
      height: initial;
    }
  
  
    max-height: initial;

    @media (min-width: 30em) {
      max-height: initial;
    }

    @media (min-width: 30.0625em) {
      max-height: CALC(90vh - 5.000vw);
    }

    @media (min-width: 90em) {
      max-height: CALC(90vh - 72px);
    }
  
`

const ContentContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  
    max-height: CALC(100vh - 17.500vw);

    @media (min-width: 30em) {
      max-height: CALC(100vh - 20.000vw);
    }

    @media (min-width: 30.0625em) {
      max-height: CALC(90vh - 5.000vw);
    }

    @media (min-width: 90em) {
      max-height: CALC(90vh - 72px);
    }
  
`

const ScrollContainer = styled.div``

const SidePlaceholder = styled.div`
  flex-shrink: 0;
  
    width: 7.500vw;

    @media (min-width: 30em) {
      width: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.667vw;
    }

    @media (min-width: 90em) {
      width: 24px;
    }
  
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.667vw;
    }

    @media (min-width: 90em) {
      height: 24px;
    }
  
`

const CloseContainer = styled.div`
  flex-shrink: 0;
`

const CloseButton = styled.div`
  cursor: pointer;
  
    width: 7.500vw;

    @media (min-width: 30em) {
      width: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.667vw;
    }

    @media (min-width: 90em) {
      width: 24px;
    }
  
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.667vw;
    }

    @media (min-width: 90em) {
      height: 24px;
    }
  
`

const BottomSpacer = styled.div`
  width: 100%;
  
    height: 20.000vw;

    @media (min-width: 30em) {
      height: 23.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 0;
    }

    @media (min-width: 90em) {
      height: 0;
    }
  
`

const DesktopLogoContainer = styled.div`
  flex-grow: 1;
`

export interface ModalProps {
  open: boolean
  title?: ReactNode
  onClose?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement> | Event) => void
  onOpen?: () => void
  children: string | JSX.Element | JSX.Element[]
  allowClose?: boolean
  showHeader?: boolean
  allowCloseOnBackgroundClick?: boolean
  portalKey?: string
}

interface ModalState {
  isOpen: boolean
  opacity: number
  isClosing: boolean
  isOpening: boolean
  closeHovering: boolean
}

const DEFAULT_STATE: ModalState = {
  isOpen: false,
  opacity: 0.8,
  isClosing: false,
  isOpening: false,
  closeHovering: false,
}

export function Modal({ open, title, onClose, children, allowClose = true, onOpen, showHeader = true, allowCloseOnBackgroundClick = true, portalKey }: ModalProps): JSX.Element {

  const [state, setState] = useState<ModalState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const theme = useTheme()

  const _handleClose = (): void => {
    if (open && onClose) {
      onClose()
    }
  }

  const _handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (open && allowCloseOnBackgroundClick && onClose) {
      onClose()
    }
  }

  const modalAfterOpen = (): void => {
    if (state.isOpening) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, opacity: 1, isOpening: false }))
      })
      onOpen?.()
    }
  }

  const modalBeforeClose = (): Promise<void> => {
    if (state.isClosing) {
      return new Promise<void>((resolve): void => {
        setState((prevState) => ({ ...prevState, opacity: 0, isClosing: false }))
        setTimeout(resolve, TRANSITION_DURATION)
      })
    } else {
      return new Promise<void>((resolve): void => {
        setTimeout(resolve, TRANSITION_DURATION)
      })
    }
  }

  useEffect(() => {
    if (open && !state.isOpen) {
      setState((prevState) => ({ ...prevState, isOpen: true, isOpening: true }))
    } else if (!open && state.isOpen) {
      setState((prevState) => ({ ...prevState, isOpen: false, isClosing: true }))
    }
  }, [open])

  const style = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
    ? { opacity: state.opacity, transition: '0.5s all ease-in-out' }
    : { background: SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.25) }

  const modal = (
    <StyledModal
      isOpen={state.isOpen}
      backgroundProps={{ style }}
      // beforeOpen={modalBeforeOpen}
      afterOpen={modalAfterOpen}
      beforeClose={modalBeforeClose}
      // afterClose={modalAfterClose}
      onBackgroundClick={_handleBackgroundClick}
      allowScroll={false}
      onEscapeKeydown={_handleClose}>
      <ModalContainer opacity={state.opacity}>
        <If condition={showHeader}>
          <Header>
            <If condition={!title}>
              <SidePlaceholder />
            </If>
            <DesktopLogoContainer>
              <Choose>
                <When condition={!!title}>
                  <Heading variant='h3' className='modal-heading'>
                    {title}
                  </Heading>
                </When>
                <Otherwise>
                  <IconContainer>
                    <Icon icon={LocalIconEnums.FTN_LOGO_FULL} color={theme.colors.green.watercourse} />
                  </IconContainer>
                </Otherwise>
              </Choose>
            </DesktopLogoContainer>

            <CloseContainer>
              <If condition={allowClose}>
                <CloseButton
                  onMouseEnter={(): void => setState((prevState) => ({ ...prevState, closeHovering: true }))}
                  onMouseLeave={(): void => setState((prevState) => ({ ...prevState, closeHovering: false }))}
                  onClick={_handleClose}>
                  <Icon
                    color={state.closeHovering ? theme.colors.green.bottleGreen : theme.colors.black.pureBlack}
                    icon={LocalIconEnums.CLOSE} />
                </CloseButton>
              </If>
            </CloseContainer>
          </Header>
        </If>
        <OuterContainer>
          <ContentContainer>
            <ScrollContainer>
              {children}
            </ScrollContainer>
            <BottomSpacer />
          </ContentContainer>
        </OuterContainer>
      </ModalContainer>
    </StyledModal>
  )

  const target = usePortal('root-modal')
  if (target) {
    return ReactDOM.createPortal(modal, target, portalKey)
  }
  return modal

}
