import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/index'
import { Heading, Link, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'
import { PageHorizontalAlignment } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'

const Container = styled.div<{ backgroundColor: string, foregroundColor: string, alignment?: PageHorizontalAlignment, containsDesc: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  width: 100%;
  justify-content: center;
  height: 100%;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  text-decoration: none;
  

`

const LinkContainer = styled.a<{ backgroundColor: string, foregroundColor: string, alignment?: PageHorizontalAlignment, containsDesc: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  width: 100%;
  justify-content: center;
  height: 100%;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  text-decoration: none;
  

`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 2.222vw;
    }

    @media (min-width: 90em) {
      padding: 32px;
    }
  


  .block-title {
    white-space: normal;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    align-items: flex-end;
    
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.667vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 24px;
    }
  
  }

  .block-content {
    white-space: normal;
  }

  .block-link {
    font-weight: 700;
  }

  .block-link-container {
    width: 336px;
  }

`

const ImageContainer = styled.div`
  width: 100%;
  
    height: 68.750vw;

    @media (min-width: 30em) {
      height: 118.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 26.389vw;
    }

    @media (min-width: 90em) {
      height: 380px;
    }
  
`

export interface PageTextBlockProps {
  block: PageBlockFragment
}

export function PageTextBlock({ block }: PageTextBlockProps): JSX.Element {

  const { href, horizontalAlignment, description, foregroundColor, title, link, backgroundColor, image } = block

  const navigate = useNavigate()
  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement>):void => {
    e.preventDefault()
    navigate(href)
  }

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  const $innerElement = (
    <>
      <If condition={!!image}>
        <ImageContainer>
          <ResponsiveImage image={image} />
        </ImageContainer>
      </If>
      <If condition={!!description || !!link?.title}>
        <ContentContainer>
          <Heading
            variant={description ? 'h5' : 'h1'}
            align={alignment}
            color={foregroundColor}
            className='block-title'>
            {title}
          </Heading>
          <If condition={!!description}>
            <HTML color={foregroundColor} align={alignment} className='block-content'>
              {description}
            </HTML>
          </If>
          <If condition={!!link}>
            <Title variant='t8'>
              <Link
                href={link.url || '#'}
                className='block-link'
                decoration='underline'>
                {link.title}
              </Link>
            </Title>
          </If>
        </ContentContainer>
      </If>
    </>
  )

  return (
    <Choose>
      <When condition={!!href}>
        <LinkContainer
          href={href}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          alignment={horizontalAlignment || PageHorizontalAlignment.CENTER}
          containsDesc={description}
          onClick={_handleClick}>
          {$innerElement}
        </LinkContainer>
      </When>
      <Otherwise>
        <Container
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          alignment={horizontalAlignment || PageHorizontalAlignment.CENTER}
          containsDesc={description}>
          {$innerElement}
        </Container>
      </Otherwise>
    </Choose>

  )

}
