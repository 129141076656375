import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'

import { HTML } from './HTML'

const Container = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.white.pureWhite}; 
`
const TitleContainer = styled.div<{ backgroundColor: string }>`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundColor }) => backgroundColor}; 
  
  
    height: 13.750vw;

    @media (min-width: 30em) {
      height: 17.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.889vw;
    }

    @media (min-width: 90em) {
      height: 56px;
    }
  
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
`
const ImageContainer = styled.div`
  flex-shrink: 0;

  
    display: none;

    @media (min-width: 30em) {
      display: flex;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  
    width: 8.125vw;

    @media (min-width: 30em) {
      width: 8.125vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.806vw;
    }

    @media (min-width: 90em) {
      width: 26px;
    }
  
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.667vw;
    }

    @media (min-width: 90em) {
      height: 24px;
    }
  
  
    margin: 5.000vw;

    @media (min-width: 30em) {
      margin: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 16px;
    }
  
`
const ImageTitleContainer = styled.div`
  flex-grow: 0;
  
    padding: 0 10.000vw;

    @media (min-width: 30em) {
      padding: 0 10.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 2.222vw;
    }

    @media (min-width: 90em) {
      padding: 0 32px;
    }
  
  .image-header {
    margin: 0;
  }
`

const DescriptionContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: ${(props): string => props.theme.colors.white.fantasy};
  
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 16px;
    }
  
  
    gap: 3.125vw;

    @media (min-width: 30em) {
      gap: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.694vw;
    }

    @media (min-width: 90em) {
      gap: 10px;
    }
  
`

function PageTitleImageBlock({ block }: PageTitleBlockProps): JSX.Element {
  const { image, foregroundColor, backgroundColor, title } = block

  return (
    <Container backgroundColor={backgroundColor}>
      <ImageContainer>
        <ResponsiveImage image={image} />
      </ImageContainer>
      <ImageTitleContainer>
        <Heading
          variant='h3'
          align='center'
          className='image-header'
          color={foregroundColor}>
          {title}
        </Heading>
      </ImageTitleContainer>
    </Container>
  )
}

function PageTitleTextBlock({ block }: PageTitleBlockProps): JSX.Element {
  const { description, foregroundColor, backgroundColor, title } = block

  return (
    <Container>
      <TitleContainer
        backgroundColor={backgroundColor}>
        <Heading
          variant='h5'
          align='center'
          color={foregroundColor}>
          {title}
        </Heading>
      </TitleContainer>
      <If condition={!!description}>
        <DescriptionContainer
          color={backgroundColor}>
          <HTML align='center'>
            {description}
          </HTML>
        </DescriptionContainer>
      </If>
    </Container>
  )
}

export interface PageTitleBlockProps {
  block: PageBlockFragment
}

export function PageTitleBlock({ block }: PageTitleBlockProps): JSX.Element {

  const { image, title } = block

  return (
    <Choose>
      <When condition={!!image && !!title}>
        <PageTitleImageBlock block={block} />
      </When>
      <Otherwise>
        <PageTitleTextBlock block={block} />
      </Otherwise>
    </Choose>
  )
}
