import React, { RefObject } from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  
    margin: 2.500vw 0 0;

    @media (min-width: 30em) {
      margin: 4.688vw 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  ;
`

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.div`
  font-family: open-sans;
  
    font-weight: 400;

    @media (min-width: 30em) {
      font-weight: 600;
    }

    @media (min-width: 30.0625em) {
      font-weight: 600;
    }

    @media (min-width: 90em) {
      font-weight: 600;
    }
  ;
  
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  ;
  
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.250vw;
    }

    @media (min-width: 90em) {
      line-height: 18px;
    }
  ;
  color: ${(props): string => props.theme.colors.grey.stormDust};
`

const Error = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-family: open-sans;
  font-weight: 500;
  color: ${(props): string => props.theme.colors.red.cinnabar};
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
  
    margin: -1.250vw 0 5.625vw;

    @media (min-width: 30em) {
      margin: -1.250vw 0 5.625vw;
    }

    @media (min-width: 30.0625em) {
      margin: -0.278vw 0 1.250vw;
    }

    @media (min-width: 90em) {
      margin: -4px 0 18px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.111vw;
    }

    @media (min-width: 90em) {
      height: 16px;
    }
  
`

export interface InputWrapperProps {
  label?: string
  error?: string
  showLabel?: boolean
  required?: boolean
  children: JSX.Element
  className?: string
}

export const InputWrapper = React.forwardRef(({ label, error = '', showLabel = true, required = false, children, className }: InputWrapperProps, ref: RefObject<HTMLDivElement>): JSX.Element => {

  return (
    <Container className={className} ref={ref}>

      <If condition={showLabel}>
        <TopContainer>
          <If condition={!!label}>
            <Label>
              {label} {(required) ? '*' : ''}
            </Label>
          </If>
        </TopContainer>
      </If>

      {children}

      <If condition={!!error}>
        <Error>
          {error}
        </Error>
      </If>
    </Container>
  )

})

InputWrapper.displayName = 'InputWrapper'
