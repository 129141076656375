import React from 'react'

import styled from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'

const PageNotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 3.125vw;

    @media (min-width: 30em) {
      gap: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.694vw;
    }

    @media (min-width: 90em) {
      gap: 10px;
    }
  

  
    height: 15.000vw;

    @media (min-width: 30em) {
      height: 15.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.333vw;
    }

    @media (min-width: 90em) {
      height: 48px;
    }
  
  
    padding: 2.500vw 5.000vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  


  background-color: ${(props): string => props.theme.colors.green.watercourse};
  .icon {
    
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.083vw;
    }

    @media (min-width: 90em) {
      width: 30px;
    }
  
    
    height: 7.813vw;

    @media (min-width: 30em) {
      height: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.736vw;
    }

    @media (min-width: 90em) {
      height: 25px;
    }
  
  }
  .text{
    padding: 0;
    margin: 0;
    font-weight: 100;
  }
`

export interface PageNotificationProps {
  text: string
  icon?: LocalIconEnums
}

export function PageNotification({ text, icon }: PageNotificationProps): JSX.Element {
  return (
    <PageNotificationWrapper>
      <If condition={!!icon}>
        <Icon className='icon' color={theme.colors.white.pureWhite} icon={icon} />
      </If>

      <Paragraph variant='p2' color={theme.colors.white.pureWhite} className='text' >{text}</Paragraph>
    </PageNotificationWrapper>
  )
}
