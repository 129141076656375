import React from 'react'

import styled, { css } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type ParagraphVariant = 'p1' | 'p2' | 'p3'
export type ParagraphAlignment = 'left' | 'center' | 'right' | 'justify'
export type ParagraphTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type ParagraphDecorations = 'none' | 'underline' | 'overline' | 'line-through' | 'italic'

const P = styled.div<{ align: string, decoration: string, transform: string, color?: string, bold: boolean, variant: string, display: string }>`
  font-family: open-sans;
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  display: ${(props): string => props.display};
  text-transform: ${(props): string => props.transform};
  font-weight: ${(props): string => props.bold ? '700' : '400'};

  ${props => props.variant === 'p1' && css`
    font-family: open-sans;
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
    
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
  `}

  ${props => props.variant === 'p2' && css`
    font-family: open-sans;
    
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
    
    line-height: 5.313vw;

    @media (min-width: 30em) {
      line-height: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.250vw;
    }

    @media (min-width: 90em) {
      line-height: 18px;
    }
  
  `}
  
  ${props => props.variant === 'p3' && css`
    font-family: open-sans;
    
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
    
    line-height: 5.000vw;

    @media (min-width: 30em) {
      line-height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.111vw;
    }

    @media (min-width: 90em) {
      line-height: 16px;
    }
  
  `}
`
export interface ParagraphProps {
  children: React.ReactNode
  variant?: ParagraphVariant
  align?: ParagraphAlignment
  transform?: ParagraphTransforms
  decoration?: ParagraphDecorations
  display?: string
  color?: string
  skeleton?: boolean
  className?: string
  bold?: boolean
  onClick?: () => void
}

export function Paragraph({ children, variant = 'p1', align = 'left', transform = 'none', decoration = 'none', color, className, onClick, bold = false, display = 'block' }: ParagraphProps): JSX.Element {

  return <P
    onClick={onClick}
    align={align}
    display={display}
    variant={variant}
    decoration={decoration}
    transform={transform}
    color={color}
    className={className}
    bold={bold}>
    {children}
  </P>

}
