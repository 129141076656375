import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'
import { PageHorizontalAlignment, PageSize } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'
import { DeviceContainer } from '@utility/DeviceContainer'

const LinkedContainer = styled.a<{ backgroundColor: string, foregroundColor: string, alignment?: PageHorizontalAlignment }>`

  display: flex;
  flex-direction: column;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  align-items: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  width: 100%;
  justify-content: space-between;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  text-decoration: none;
  
    height: 100%;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
  
    padding: 7.500vw 12.500vw 7.500vw 12.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  

`

const Container = styled.div<{ backgroundColor: string, foregroundColor: string, alignment?: PageHorizontalAlignment }>`

  display: flex;
  flex-direction: column;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  align-items: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  width: 100%;
  justify-content: space-between;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  text-decoration: none;
  
    height: 100%;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
  
    padding: 7.500vw 12.500vw 7.500vw 12.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  

`

const ImageContainer = styled.div<{ size: PageSize }>`
  ${(props): string => {
    if (props.size === PageSize.LARGE) {
      return `
        
    height: 20.000vw;

    @media (min-width: 30em) {
      height: 40.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 8.889vw;
    }

    @media (min-width: 90em) {
      height: 128px;
    }
  
        
    width: 20.000vw;

    @media (min-width: 30em) {
      width: 40.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 8.889vw;
    }

    @media (min-width: 90em) {
      width: 128px;
    }
  
      `
    } else if (props.size === PageSize.MEDIUM) {
      return `
        
    height: 10.000vw;

    @media (min-width: 30em) {
      height: 20.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.444vw;
    }

    @media (min-width: 90em) {
      height: 64px;
    }
  
        
    width: 10.000vw;

    @media (min-width: 30em) {
      width: 20.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 4.444vw;
    }

    @media (min-width: 90em) {
      width: 64px;
    }
  
      `
    }
    return `
      
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.222vw;
    }

    @media (min-width: 90em) {
      height: 32px;
    }
  
      
    width: 20.000vw;

    @media (min-width: 30em) {
      width: 40.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 8.889vw;
    }

    @media (min-width: 90em) {
      width: 128px;
    }
  
    `
  }}
  
  flex-shrink: 0;
`

const TitleContainer = styled.div`
  white-space: normal;

  .block-title {
    
    margin: 0.000vw;

    @media (min-width: 30em) {
      margin: 0.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.000vw;
    }

    @media (min-width: 90em) {
      margin: 0px;
    }
  
  }
  
`

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;

  .block-content {
    white-space: normal;
  }

`

export interface PageIconBlockProps {
  block: PageBlockFragment
}

export function PageIconBlock({ block }: PageIconBlockProps): JSX.Element {

  const { horizontalAlignment, backgroundColor, foregroundColor, href, image, description, imageSize, title } = block

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  return (
    <Choose>
      <When condition={!!href}>
        <LinkedContainer
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          alignment={horizontalAlignment || PageHorizontalAlignment.CENTER}
          href={href || ''}>
          <DeviceContainer desktop tablet>
            <If condition={!!image}>
              <ImageContainer size={imageSize}>
                <ResponsiveImage image={image} objectFit='contain' />
              </ImageContainer>
            </If>
          </DeviceContainer>
          <TitleContainer>
            <Heading
              variant={!!image && imageSize === 'MEDIUM'
                ? 'h3'
                : !!image && imageSize === 'LARGE'
                  ? 'h5'
                  : 'h1'}
              align={alignment}
              color={foregroundColor}
              className='block-title'>
              {title}
            </Heading>
          </TitleContainer>
          <If condition={!!description}>
            <ContentContainer>
              <HTML color={foregroundColor} align={alignment} className='block-content'>
                {description}
              </HTML>
            </ContentContainer>
          </If>
        </LinkedContainer>
      </When>
      <Otherwise>
        <Container
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          alignment={horizontalAlignment || PageHorizontalAlignment.CENTER}>
          <If condition={!!image}>
            <ImageContainer size={imageSize}>
              <ResponsiveImage image={image} objectFit='contain' />
            </ImageContainer>
          </If>
          <TitleContainer>
            <Heading
              variant={!!image && imageSize === 'MEDIUM'
                ? 'h3'
                : !!image && imageSize === 'LARGE'
                  ? 'h5'
                  : 'h1'}
              align={alignment}
              color={foregroundColor}
              className='block-title'>
              {title}
            </Heading>
          </TitleContainer>
          <If condition={!!description}>
            <ContentContainer>
              <HTML color={foregroundColor} align={alignment} className='block-content'>
                {description}
              </HTML>
            </ContentContainer>
          </If>
        </Container>
      </Otherwise>
    </Choose>
  )

}
