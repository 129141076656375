import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue, theme } from '@components/Theme'

export const ProductLabels = styled.div`
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  display: flex;
  top: 0;
  left: 0;
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.278vw;
    }

    @media (min-width: 90em) {
      gap: 4px;
    }
  
`

export const ProductLabel = styled.div<{backgroundColor: string, color: string}>`
  font-family: open-sans;
  color: ${props => (props.color) ? props.color : theme.colors.white.fantasy};
  
    padding: 1.250vw 2.500vw;

    @media (min-width: 30em) {
      padding: 1.250vw 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 4px 8px;
    }
  
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
  background-color: ${props => (props.backgroundColor) ? props.backgroundColor : theme.colors.white.fantasy};
  font-weight: 600;
  
    line-height: 5.313vw;

    @media (min-width: 30em) {
      line-height: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.250vw;
    }

    @media (min-width: 90em) {
      line-height: 18px;
    }
  
`
