import React from 'react'

import ReactPlayer from 'react-player'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, PageMediaSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;
  
    padding: 7.500vw 0.000vw 14.375vw;

    @media (min-width: 30em) {
      padding: 24.063vw 0.000vw 22.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 5.347vw 0.000vw 5.000vw;
    }

    @media (min-width: 90em) {
      padding: 77px 0px 72px;
    }
  

`
const TitleContainer = styled.div`
  width: 100%;
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
    
`
const SubTitleContainer = styled.div`
  width: 100%;
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  

`
const DescriptionContainer = styled.div`
  width: 100%;
  
    margin-bottom: 12.500vw;

    @media (min-width: 30em) {
      margin-bottom: 29.375vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 6.528vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 94px;
    }
  
  
    padding: 0 5.000vw;

    @media (min-width: 30em) {
      padding: 0.000vw 43.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.000vw 9.722vw;
    }

    @media (min-width: 90em) {
      padding: 0px 140px;
    }
  

`
const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export interface PageMediaSectionProps {
  section: PageMediaSectionFragment
}

export function PageMediaSection({ section }: PageMediaSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum

  return (
    <Container>
      <TitleContainer>
        <Heading variant='h1' align='center'>{section.heading}</Heading>
      </TitleContainer>
      <SubTitleContainer>
        <Heading variant={MOBILE === appData.app.deviceType ? 'h5' : 'h2'} align='center'>{section.subTitle}</Heading>
      </SubTitleContainer>
      <DescriptionContainer>
        <HTML align='center'>{section.description}</HTML>
      </DescriptionContainer>
      <MediaContainer>
        <ReactPlayer
          width={MOBILE === appData.app.deviceType ? '288px' : '960px'}
          height={MOBILE === appData.app.deviceType ? '180px' : '542px'}
          url={section.video}>
        </ReactPlayer>
      </MediaContainer>
    </Container>
  )
}
