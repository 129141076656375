import React from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import styled, { css, CSS, useTheme } from 'styled-components'

import { SmallLoader } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useGetApiEnumQuery } from '@hooks/api/index'
import { InputWrapper, FormContext, FormContextProperties } from '@molecules/inputs/index'

export const Container = styled.div<{ direction: 'vertical' | 'horizontal' }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props): string => props.direction === 'vertical' ? 'column' : 'row'};
  
    gap: 3.125vw;

    @media (min-width: 30em) {
      gap: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.694vw;
    }

    @media (min-width: 90em) {
      gap: 10px;
    }
  
`

export const ItemContainer = styled.div<{ loading: boolean, isVertical: boolean }>`

  cursor: ${(props): string => props.loading ? 'inherit' : 'pointer'};
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props): string => props.isVertical ? '100%' : 'auto'};
  flex-shrink: 1; 
`

const ButtonOuterContainer = styled.div<{ selected: boolean, disabled: boolean }>`

  border-style: solid;
  border-color: ${(props): string => props.theme.colors.grey.silver};
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.111vw;
    }

    @media (min-width: 90em) {
      width: 16px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.111vw;
    }

    @media (min-width: 90em) {
      height: 16px;
    }
  
  
    padding: 0.625vw;

    @media (min-width: 30em) {
      padding: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.139vw;
    }

    @media (min-width: 90em) {
      padding: 2px;
    }
  
  
    margin: 1.875vw 0.313vw;

    @media (min-width: 30em) {
      margin: 1.250vw 0.625vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.208vw 0;
    }

    @media (min-width: 90em) {
      margin: 3px 0;
    }
  
  
    border-radius: 4.375vw;

    @media (min-width: 30em) {
      border-radius: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.972vw;
    }

    @media (min-width: 90em) {
      border-radius: 14px;
    }
  
  
    border-width: 0.313vw;

    @media (min-width: 30em) {
      border-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-width: 1px;
    }
  

  ${(props): CSS => {
    if (props.selected) {
      return css`
        border-color: ${(props): string => props.theme.colors.green.greenVogue};
        
    border-width: 1.250vw;

    @media (min-width: 30em) {
      border-width: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.278vw;
    }

    @media (min-width: 90em) {
      border-width: 4px;
    }
  
      `
    }
    if (props.disabled) {
      return css`
        border-color: ${(props): string => props.theme.colors.white.romance};
      `
    }
  }}

`
const ItemLabel = styled.div<{ color: string, isVertical: boolean }>`

  position: relative;
  font-family: open-sans;
  color: ${(props): string => props.color};
  width: ${(props): string => props.isVertical ? '100%' : 'auto'};
  
    font-weight: 400;

    @media (min-width: 30em) {
      font-weight: 400;
    }

    @media (min-width: 30.0625em) {
      font-weight: 400;
    }

    @media (min-width: 90em) {
      font-weight: 400;
    }
  ;
  
    margin-left: 1.563vw;

    @media (min-width: 30em) {
      margin-left: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-left: 5px;
    }
  
  
    top: 0.000vw;

    @media (min-width: 30em) {
      top: 0.000vw;
    }

    @media (min-width: 30.0625em) {
      top: 0.000vw;
    }

    @media (min-width: 90em) {
      top: 0px;
    }
  
  
    font-size: 4.375vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  

`

const LoadingContainer = styled.div`
  position: absolute;
  
    right: 3.750vw;

    @media (min-width: 30em) {
      right: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      right: 0.833vw;
    }

    @media (min-width: 90em) {
      right: 12px;
    }
  
  
    top: 3.750vw;

    @media (min-width: 30em) {
      top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      top: 0.833vw;
    }

    @media (min-width: 90em) {
      top: 12px;
    }
  
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
`

interface Values {
  value?: string | number
  onChange?: (value: string | number) => void
}

interface RadioInputInnerProps extends RadioFormInputProps {
  control: Values
  meta: Meta
  form: FormInstance
}

function RadioInner(props: RadioInputInnerProps): JSX.Element {

  const { label, showLabel = true, variant = 'vertical', options, loading, apiEnum, disabled, rules, control, meta, form, className, wrapperClassName } = props
  const { data, loading: apiLoading } = useGetApiEnumQuery({ variables: { enum: apiEnum }, skip: !apiEnum })

  const theme = useTheme()

  let availableOptions: RadioOption[] = options ?? []
  if (data?.enum?.values) {
    availableOptions = data.enum.values.map((enumVal) => ({ title: enumVal.title, value: enumVal.value }))
  }

  const required = !!(
    rules &&
    rules.some(rule => {
      if (rule && typeof rule === 'object' && rule.required) {
        return true
      }
      if (typeof rule === 'function') {
        const ruleEntity = rule(form)
        return ruleEntity && ruleEntity.required
      }
      return false
    })
  )
  const error = meta.errors?.[0]
  const { value, onChange } = control

  const _handleChange = (value: string | number, locallyDisabled: boolean) => {
    if (disabled || loading || apiLoading || locallyDisabled) {
      return
    }
    const newValue = value
    onChange(newValue)
  }

  let option: RadioOption
  const labelColor = disabled || loading || apiLoading ? theme.colors.white.romance : theme.colors.green.bottleGreen

  return (
    <InputWrapper
      required={required}
      label={label}
      showLabel={showLabel}
      error={error}
      className={wrapperClassName}>
      <FormContext.Consumer>
        {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
          <Container direction={variant} className={className}>
            <For each='option' of={availableOptions}>
              <ItemContainer
                isVertical={variant === 'vertical'}
                loading={disabled || loading || apiLoading || formLoading || formDisabled}
                key={option.value.toString()}
                data-selected={option.value === value}
                data-disabled={disabled || option.disabled}
                onClick={() => _handleChange(option.value, option.disabled)}>
                <ButtonOuterContainer
                  disabled={disabled || loading || apiLoading || option.disabled}
                  selected={option.value === value}>
                </ButtonOuterContainer>
                <ItemLabel color={labelColor} isVertical={variant === 'vertical'}>{option.title}</ItemLabel>
              </ItemContainer>
            </For>
            <If condition={loading || apiLoading || formLoading}>
              <LoadingContainer>
                <SmallLoader
                  color={theme.colors.grey.gallery} />
              </LoadingContainer>
            </If>
          </Container>
        )}
      </FormContext.Consumer>
    </InputWrapper>
  )
}

export interface RadioOption {
  title: React.ReactNode
  value: string | number
  disabled?: boolean
}

export interface RadioFormInputProps {
  rules?: Rule[]
  label?: string
  showLabel?: boolean
  name: string
  loading?: boolean
  disabled?: boolean
  variant?: 'vertical' | 'horizontal'
  apiEnum?: string
  options?: RadioOption[]
  wrapperClassName?: string
  className?: string
}

export function RadioInput(props: RadioFormInputProps): JSX.Element {

  const { name, rules } = props

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => <RadioInner {...props} control={control} meta={meta} form={form} />}
    </Field>
  )

}
