import { v4 } from 'uuid'

import { LocalIconEnums } from '@atoms/images'

export enum NavItemTypeEnum {
  NAV = 'NAV',
  CATEGORY = 'CATEGORY',
}

export interface NavItem {
  id: string
  title: string
  path: string
  sub: Array<NavItem>
  icon?: LocalIconEnums
  type: NavItemTypeEnum
  shouldFetchSubCategories?: boolean
}

export const navigation: NavItem[] = [{
  id: v4(),
  title: 'Home',
  path: '/',
  sub: [],
  icon: LocalIconEnums.HOME_1,
  type: NavItemTypeEnum.NAV,
}, {
  id: v4(),
  title: 'Shop by category',
  path: '',
  type: NavItemTypeEnum.NAV,
  sub: [],
}, {
  id: v4(),
  title: 'Deals',
  path: '/specials',
  type: NavItemTypeEnum.NAV,
  sub: [],
},
 {
  id: v4(),
  title: 'My FtN Shop',
  path: '/my-ftn-shop',
  type: NavItemTypeEnum.NAV,
  sub: [],
},{
  id: v4(),
  title: 'New Products',  
  path: '/new-products',
  type: NavItemTypeEnum.NAV,
  sub: [],
},{
  id: v4(),
  title: 'Account',
  path: '/me',
  sub: [],
  icon: LocalIconEnums.HOME_1,
  type: NavItemTypeEnum.NAV,
}, {
  id: v4(),
  title: 'Orders',
  path: '/me/orders',
  type: NavItemTypeEnum.NAV,
  sub: [],
}, {
  id: v4(),
  title: 'Help',
  path: '/faq',
  type: NavItemTypeEnum.NAV,
  sub: [],
}]
