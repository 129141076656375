import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Button, Paragraph, ResponsiveImage } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SpecialsChildCategoryFragment } from '@hooks/api'
import { FlexDeviceContainer } from '@utility/DeviceContainer'

const Container = styled.a`
  display: flex;
  flex-direction: column;
  
    width: 48.125vw;

    @media (min-width: 30em) {
      width: 90.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 20.139vw;
    }

    @media (min-width: 90em) {
      width: 290px;
    }
  
  
    height: 62.500vw;

    @media (min-width: 30em) {
      height: 87.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 19.444vw;
    }

    @media (min-width: 90em) {
      height: 280px;
    }
  
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  text-decoration: none;
`

const ImageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  img {
    outline: 7px solid white;
    outline-offset: -7px;
  }
  picture {
    outline: 7px solid white;
    outline-offset: -7px;
  }
`

const ContentContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 16px;
    }
  
  
    height: 37.500vw;

    @media (min-width: 30em) {
      height: 43.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 9.722vw;
    }

    @media (min-width: 90em) {
      height: 140px;
    }
  

  .title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    white-space: normal;
  }
  
`

const ButtonContainer = styled.div`
  width: 100%;
  
    padding: 0.000vw;

    @media (min-width: 30em) {
      padding: 0 14.063vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 3.125vw;
    }

    @media (min-width: 90em) {
      padding: 0 45px;
    }
  
`

export interface SpecialCardProps {
  category: SpecialsChildCategoryFragment
}

export function SpecialCard({ category }: SpecialCardProps): JSX.Element {

  const navigate = useNavigate()

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(category.canonicalUrl)
  }

  return (
    <Container href={category.canonicalUrl} onClick={_handleClick}>
      <ImageContainer>
        <ResponsiveImage image={category.coverImage} objectFit='contain' />
      </ImageContainer>
      <ContentContainer>
        <FlexDeviceContainer mobile>
          <Paragraph bold align='center' className='title' variant='p3'>{category.name}</Paragraph>
        </FlexDeviceContainer>
        <FlexDeviceContainer tablet desktop>
          <Paragraph bold align='center' className='title'>{category.name}</Paragraph>
        </FlexDeviceContainer>
        <ButtonContainer>
          <Button title='SHOP DEAL' href={category.canonicalUrl} variant='primary' size='medium' fullWidth/>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )

}
