import React from 'react'

import styled from 'styled-components'

import { Loader, Heading } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    padding: 15.625vw;

    @media (min-width: 30em) {
      padding: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      padding: 3.472vw;
    }

    @media (min-width: 90em) {
      padding: 50px;
    }
  
  
    width: 100vw;

    @media (min-width: 30em) {
      width: 100vw;
    }

    @media (min-width: 30.0625em) {
      width: 41.667vw;
    }

    @media (min-width: 90em) {
      width: 600px;
    }
  

  .modal-loading-title {
    
    margin: 3.125vw;

    @media (min-width: 30em) {
      margin: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 10px;
    }
  
  }
`

export interface ModalLoaderProps {
  message?: string
}

export function ModalLoader({ message }: ModalLoaderProps): JSX.Element {

  return (
    <LoadingContainer>
      <Loader />
      <If condition={!!message}>
        <Heading variant='h4' className='modal-loading-title'>{message}</Heading>
      </If>
    </LoadingContainer>
  )

}
