import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ textColor: string, backgroundColor: string }>`

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: milliard;
  font-weight: 600;

  color: ${(props): string => props.textColor};
  background-color: ${(props): string => props.backgroundColor};

  
    border-radius: 1.250vw;

    @media (min-width: 30em) {
      border-radius: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.278vw;
    }

    @media (min-width: 90em) {
      border-radius: 4px;
    }
  
  
    font-size: 4.375vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
  
    padding: 1.875vw 2.500vw;

    @media (min-width: 30em) {
      padding: 1.875vw 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.417vw 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 6px 8px;
    }
  

`

const TextContiner = styled.div`
  
    margin-right: 2.500vw;

    @media (min-width: 30em) {
      margin-right: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-right: 8px;
    }
  
`

const IconContainer = styled.div`
  
    width: 7.500vw;

    @media (min-width: 30em) {
      width: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.667vw;
    }

    @media (min-width: 90em) {
      width: 24px;
    }
  
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.667vw;
    }

    @media (min-width: 90em) {
      height: 24px;
    }
  
`

export interface ChipProps {
  title: string
  onRemove: () => void
  textColor?: string
  backgroundColor?: string
}

export function Chip({ title, onRemove, textColor, backgroundColor }: ChipProps): JSX.Element {

  const theme = useTheme()
  if (!textColor) {
    textColor = theme.colors.white.floralWhite
  }
  if (!backgroundColor) {
    backgroundColor = theme.colors.green.goldenrod
  }

  return (
    <Container textColor={textColor} backgroundColor={backgroundColor}>
      <TextContiner>
        {title}
      </TextContiner>
      <IconContainer onClick={onRemove}>
        <Icon color={textColor} icon={LocalIconEnums.SMALL_CROSS} />
      </IconContainer>
    </Container>
  )

}
