import React, { ReactNode } from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TitleContainer = styled.div`
  flex-shrink: 0;
  
    margin: 2.500vw 0;

    @media (min-width: 30em) {
      margin: 2.500vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.556vw 0;
    }

    @media (min-width: 90em) {
      margin: 8px 0;
    }
  
`

const Seperator = styled.div<{ $color: string }>`
  flex-grow: 1;
  background: ${(props): string => props.$color};
  
    height: 0.313vw;

    @media (min-width: 30em) {
      height: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.069vw;
    }

    @media (min-width: 90em) {
      height: 1px;
    }
  
  
    margin: 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 0 10px;
    }
  
`

const SingleSeperator = styled.div<{ fullWidth: boolean }>`
  background: ${(props): string => props.color};
  ${({ fullWidth }) => fullWidth
    ? ResponsivePXValue('width', { mobile: 'CALC(100% - 20px)', tablet: 'CALC(100% - 40px)', desktop: 'CALC(100% - 60px)' })
    : ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '100%' })
}
  
    height: 0.313vw;

    @media (min-width: 30em) {
      height: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.069vw;
    }

    @media (min-width: 90em) {
      height: 1px;
    }
  
`

export interface DividerProps {
  children?: ReactNode
  color?: string
  className?: string
  singleLineFullWidth?: boolean
}

export function Divider({ children, color, className, singleLineFullWidth = true }: DividerProps): JSX.Element {

  const theme = useTheme()

  const sepColor = color || theme.colors.grey.athens

  return (
    <Container className={className}>
      <Choose>
        <When condition={!!children}>
          <Seperator $color={sepColor} />
          <TitleContainer>{children}</TitleContainer>
          <Seperator $color={sepColor} />
        </When>
        <Otherwise>
          <SingleSeperator color={sepColor} fullWidth={singleLineFullWidth}/>
        </Otherwise>
      </Choose>
    </Container>
  )

}
