import { Heading, Paragraph, ResponsiveImage, Title } from '@atoms/index';
import { ResponsivePXValue } from '@components/Theme';
import { PageBlockFragment } from '@hooks/api';
import { BlockTag, PageHorizontalAlignment } from '@uctypes/api/globalTypes';
import React from 'react';
import styled from 'styled-components';
import { HTML } from './HTML';
import { useNavigate } from 'react-router';

const LinkWrapper = styled.a`
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ImageContainer = styled.div`
    width: 100%;
    
    height: 23.438vw;

    @media (min-width: 30em) {
      height: 62.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 13.889vw;
    }

    @media (min-width: 90em) {
      height: 200px;
    }
  
    flex-shrink: 0;
    
    border-top-left-radius: 1.563vw;

    @media (min-width: 30em) {
      border-top-left-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-top-left-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-top-left-radius: 5px;
    }
  
    
    border-top-right-radius: 1.563vw;

    @media (min-width: 30em) {
      border-top-right-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-top-right-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-top-right-radius: 5px;
    }
  
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
    
    padding: 2.500vw 1.563vw;

    @media (min-width: 30em) {
      padding: 3.438vw 6.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.764vw 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 11px 20px;
    }
  
    .title {
        margin: 0;
        font-weight: 400;
    }
`

const TagContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const BlogTag = styled.div<{ bgColor: string }>`
    background-color: ${props => props.bgColor};
    
    padding: 1.250vw 3.750vw;

    @media (min-width: 30em) {
      padding: 1.250vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 4px 12px;
    }
  
    
    border-radius: 1.563vw;

    @media (min-width: 30em) {
      border-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-radius: 5px;
    }
  
`


interface PageBlockProps {
    block: PageBlockFragment
}

export function PageBlogCardBlock({ block }: PageBlockProps): JSX.Element {

    const { title, image, description, horizontalAlignment, foregroundColor, href, tags } = block
    const navigate = useNavigate()

    const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

    const _handleLinkClick = () => {
        navigate(`/${href}`)
    }  

    let tag: BlockTag
    return (
            <Container onClick={_handleLinkClick}>
                <LinkWrapper href={href} onClick={_handleLinkClick}>
                    <ImageContainer>
                        <ResponsiveImage image={image} objectFit='cover'/>
                    </ImageContainer>
                </LinkWrapper>
                <ContentContainer>
                    <If condition={!!tags}>
                        <TagContainer>
                            <For each='tag' of={tags}>
                                <BlogTag bgColor={tag.backgroundColor}>
                                    <Title variant='t6' color={tag.foregroundColor}>
                                        {tag.title}
                                    </Title>
                                </BlogTag>
                            </For>
                        </TagContainer>
                    </If>
                    <Heading variant='h3' align={alignment} color={foregroundColor} className='title'>{title}</Heading>
                </ContentContainer>
            </Container>
    )
}
  