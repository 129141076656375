import React, { useState, useEffect } from 'react'

import { camelCase } from 'change-case'
import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Heading, Icon, LocalIconEnums, Paragraph, Seperator } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, AggregationFragment, AggregationOptionFragment } from '@hooks/api/index'
import useIsomorphicLayoutEffect from '@hooks/UseIsomorphicLayoutEffect'
import { SiteHelper } from '@lib/SiteHelper'
import { AggregationTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductFilterInputs } from './ProductFilters'

const Container = styled.div`
  width: 100%;
  
    padding: 0 5.000vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
  position: relative;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.944vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 28px;
    }
  


  .seperator {
    position: revert;
    flex-shrink: 1;
  }

  .heading {
    flex: none;
  }

  .seeAllBtn {
    flex: none;
  }

`

const ControlContainer = styled.div`
  display: flex;
  
    gap: 3.125vw;

    @media (min-width: 30em) {
      gap: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.694vw;
    }

    @media (min-width: 90em) {
      gap: 10px;
    }
  
  align-items: center;

`

const Embla = styled.div`
  
    overflow: hidden;

    @media (min-width: 30em) {
      overflow: hidden;
    }

    @media (min-width: 30.0625em) {
      overflow: hidden;
    }

    @media (min-width: 90em) {
      overflow: hidden;
    }
  
  width: 100%;
`

const EmblaContainer = styled.div`
  display: flex;
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const EmblaSlide = styled.div`
  user-select: none;
  display: flex;
  
    flex: 0 0 auto;

    @media (min-width: 30em) {
      flex: 0 0 auto;
    }

    @media (min-width: 30.0625em) {
      flex: 0 0 auto;
    }

    @media (min-width: 90em) {
      flex: 0 0 auto;
    }
  
`

const ValueChip = styled.div<{ active: boolean }>`
  
    padding: 2.500vw 5.000vw;

    @media (min-width: 30em) {
      padding: 3.750vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 12px 24px;
    }
  
  background-color: ${({ theme, active }): string => active ? theme.colors.green.bottleGreen : theme.colors.brown.fantasy};
  
    border-radius: 1.250vw;

    @media (min-width: 30em) {
      border-radius: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.278vw;
    }

    @media (min-width: 90em) {
      border-radius: 4px;
    }
  
  flex: none;
  cursor: pointer;
`

const LeftControl = styled.div<{ visible: boolean }>`
  cursor: pointer;
  user-select: none;  

  ${({ visible }): string => visible
    ? ResponsivePXValue('display', { mobile: 'block', tablet: 'block', desktop: 'block' })
    : ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'none' })}

  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.5)};
  
    position: static;

    @media (min-width: 30em) {
      position: absolute;
    }

    @media (min-width: 30.0625em) {
      position: absolute;
    }

    @media (min-width: 90em) {
      position: absolute;
    }
  
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: initial;

    @media (min-width: 30em) {
      top: 63%;
    }

    @media (min-width: 30.0625em) {
      top: 63%;
    }

    @media (min-width: 90em) {
      top: 63%;
    }
  
  
    left: initial;

    @media (min-width: 30em) {
      left: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      left: 1.389vw;
    }

    @media (min-width: 90em) {
      left: 20px;
    }
  
  
  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.9)};
  }
`
const LeftControlDesktop = styled.div<{ visible: boolean, isDesktop: boolean }>`
  cursor: pointer;
  user-select: none;  

  ${({ visible, isDesktop }): string => visible && isDesktop
    ? ResponsivePXValue('display', { mobile: 'block', tablet: 'block', desktop: 'block' })
    : ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'none' })}

  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.5)};
  
    position: static;

    @media (min-width: 30em) {
      position: absolute;
    }

    @media (min-width: 30.0625em) {
      position: absolute;
    }

    @media (min-width: 90em) {
      position: absolute;
    }
  
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: initial;

    @media (min-width: 30em) {
      top: 63%;
    }

    @media (min-width: 30.0625em) {
      top: 63%;
    }

    @media (min-width: 90em) {
      top: 63%;
    }
  
  
    left: initial;

    @media (min-width: 30em) {
      left: -6.250vw;
    }

    @media (min-width: 30.0625em) {
      left: -1.389vw;
    }

    @media (min-width: 90em) {
      left: -20px;
    }
  
  
  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.9)};
  }
`
const RightControl = styled.div<{ visible: boolean }>`
  cursor: pointer;
  user-select: none;

  ${({ visible }): string => visible
    ? ResponsivePXValue('display', { mobile: 'block', tablet: 'block', desktop: 'block' })
    : ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'none' })}

  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.5)};
  
    position: static;

    @media (min-width: 30em) {
      position: absolute;
    }

    @media (min-width: 30.0625em) {
      position: absolute;
    }

    @media (min-width: 90em) {
      position: absolute;
    }
  
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: initial;

    @media (min-width: 30em) {
      top: 63%;
    }

    @media (min-width: 30.0625em) {
      top: 63%;
    }

    @media (min-width: 90em) {
      top: 63%;
    }
  
  
    right: initial;

    @media (min-width: 30em) {
      right: -6.250vw;
    }

    @media (min-width: 30.0625em) {
      right: -1.389vw;
    }

    @media (min-width: 90em) {
      right: -20px;
    }
  

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.9)};
  }
`

const RightControlDesktop = styled.div<{ visible: boolean, isDesktop: boolean }>`
  cursor: pointer;
  user-select: none;

  ${({ visible, isDesktop }): string => visible && isDesktop
    ? ResponsivePXValue('display', { mobile: 'block', tablet: 'block', desktop: 'block' })
    : ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'none' })}

  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.5)};
  
    position: static;

    @media (min-width: 30em) {
      position: absolute;
    }

    @media (min-width: 30.0625em) {
      position: absolute;
    }

    @media (min-width: 90em) {
      position: absolute;
    }
  
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: initial;

    @media (min-width: 30em) {
      top: 63%;
    }

    @media (min-width: 30.0625em) {
      top: 63%;
    }

    @media (min-width: 90em) {
      top: 63%;
    }
  
  
    right: initial;

    @media (min-width: 30em) {
      right: -6.250vw;
    }

    @media (min-width: 30.0625em) {
      right: -1.389vw;
    }

    @media (min-width: 90em) {
      right: -20px;
    }
  

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.9)};
  }
`

export interface ShopByValuesFiltersProps {
  values: AggregationFragment[]
  filters: ProductFilterInputs
  loading: boolean
  onFilterChange?: (filters: ProductFilterInputs) => void
  onLayoutUpdate?: () => void
}

interface ShopByValuesFiltersState {
  prevDisabled: boolean
  nextDisabled: boolean
  currentIndex: number
  displayButtons: boolean
  loadingFilter: { [k: string]: string } | null
}

const DEFAULT_STATE: ShopByValuesFiltersState = {
  prevDisabled: true,
  nextDisabled: false,
  currentIndex: 0,
  displayButtons: false,
  loadingFilter: null,
}

export function ShopByValuesFilters({ values, onFilterChange, loading, filters, onLayoutUpdate }: ShopByValuesFiltersProps): JSX.Element {

  const [{ prevDisabled, nextDisabled }, setState] = useState<ShopByValuesFiltersState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum

  const { colors } = useTheme()
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    slidesToScroll: 1,
    containScroll: 'trimSnaps',
    dragFree: true,
  })

  const _handleGoLeft = (): void => emblaApi.canScrollPrev() && emblaApi.scrollPrev()

  const _handleGoRight = (): void => emblaApi.canScrollNext() && emblaApi.scrollNext()

  const _handleFilter = (attributeCode: string, option: string): void => {
    if (loading) {
      return
    }
    setState((prevState) => update(prevState, {
      loadingFilter: { $set: { [attributeCode]: option } },
    }))
    const group = values?.find((group) => group.attributeCode === attributeCode)
    const isMulti = group?.type === AggregationTypeEnum.MULTI_SELECT
    const newFilters = { ...filters }
    if (isMulti) {
      if (!newFilters[camelCase(attributeCode)]) {
        newFilters[camelCase(attributeCode)] = {
          in: [option],
        }
      } else if (newFilters[camelCase(attributeCode)].in.includes(option)) {
        newFilters[camelCase(attributeCode)].in.splice(newFilters[camelCase(attributeCode)].in.indexOf(option), 1)
        if (!newFilters[camelCase(attributeCode)].in.length) {
          delete newFilters[camelCase(attributeCode)]
        }
      } else {
        newFilters[camelCase(attributeCode)].in.push(option)
      }
    } else {
      if (newFilters?.[camelCase(attributeCode)]?.in?.[0] === option) {
        delete newFilters[camelCase(attributeCode)]
      } else {
        newFilters[camelCase(attributeCode)] = {
          in: [option],
        }
      }
    }
    onFilterChange(newFilters)
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        const prevDisabled = !emblaApi.canScrollPrev()
        const nextDisabled = !emblaApi.canScrollNext()
        const currentIndex = emblaApi.selectedScrollSnap()
        setState((prevState) => update(prevState, {
          prevDisabled: { $set: prevDisabled },
          nextDisabled: { $set: nextDisabled },
          currentIndex: { $set: currentIndex },
        }))
      })
    }
  }, [emblaApi])

  useIsomorphicLayoutEffect(() => {
    onLayoutUpdate?.()
  }, [])

  let valueType: AggregationFragment
  let valueOption: AggregationOptionFragment

  return (
    <Container>
      <HeaderContainer>
        <Heading color={colors.green.bottleGreen} variant={appData.app.deviceType === MOBILE ? 'h3' : 'h4'} className='heading'>
          Shop by values
        </Heading>
        <Seperator align='horizontal' className='seperator' color={colors.grey.gallery} />
        <DeviceContainer mobile>
          <ControlContainer>
            <LeftControl onClick={_handleGoLeft} visible={!prevDisabled}>
              <Icon icon={LocalIconEnums.CHEVRON_LEFT} color={colors.white.pureWhite} />
            </LeftControl>
            <RightControl onClick={_handleGoRight} visible={!nextDisabled}>
              <Icon icon={LocalIconEnums.CHEVRON_RIGHT} color={colors.white.pureWhite} />
            </RightControl>
          </ControlContainer>
        </DeviceContainer>
      </HeaderContainer>

      <Embla ref={emblaRef}>
        <EmblaContainer>
          <For each='valueType' of={values}>
            <For each='valueOption' of={valueType?.options} key={valueType?.attributeCode}>
              <EmblaSlide key={valueOption?.label}>
                <ValueChip
                  onClick={() => _handleFilter(valueType.attributeCode, valueOption.value)}
                  active={filters?.[camelCase(valueType.attributeCode)]?.in?.includes(valueOption.value)}>
                  <Paragraph
                    color={filters?.[camelCase(valueType.attributeCode)]?.in?.includes(valueOption.value) ? colors.white.pureWhite : colors.green.bottleGreen}
                    variant='p1'
                    bold={true}>
                    {valueOption?.label}
                  </Paragraph>
                </ValueChip>
              </EmblaSlide>
            </For>
          </For>
        </EmblaContainer>
      </Embla>
      <LeftControlDesktop onClick={_handleGoLeft} visible={!prevDisabled} isDesktop={!(appData.app.deviceType === MOBILE)}>
        <Icon icon={LocalIconEnums.CHEVRON_LEFT} color={colors.white.pureWhite} />
      </LeftControlDesktop>
      <RightControlDesktop onClick={_handleGoRight} visible={!nextDisabled} isDesktop={!(appData.app.deviceType === MOBILE)}>
        <Icon icon={LocalIconEnums.CHEVRON_RIGHT} color={colors.white.pureWhite} />
      </RightControlDesktop>
    </Container>

  )

}
