import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'
import { PageNotificationSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'

const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  
    padding: 5.000vw 5.000vw 0.313vw;

    @media (min-width: 30em) {
      padding: 5.000vw 5.000vw 0.313vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 1.111vw 0.069vw;
    }

    @media (min-width: 90em) {
      padding: 16px 16px 1px;
    }
  
`

export interface PageNotificationSectionProps {
  section: PageNotificationSectionFragment
}

export function PageNotificationSection({ section }: PageNotificationSectionProps): JSX.Element {

  return (
    <Container backgroundColor={section.backgroundColor}>
      <HTML color={section.foregroundColor} align='center'>
        {section.markup}
      </HTML>
    </Container>
  )

}
