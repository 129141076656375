import React from 'react'

import styled, { useTheme } from 'styled-components'

import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Icon, LocalIconEnums } from '@atoms/images'
import { ModalFormContainer } from '@atoms/index'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, useCreateProductReviewMutation, useProductReviewMetaQuery } from '@hooks/api/index'
import { Modal } from '@molecules/content'
import { ReviewForm, ReviewFormData } from '@molecules/forms'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
    margin: 0 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 0 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 12px 0;
    }
  
`

const CloseContainer = styled.div`
  position: absolute;
  
    top: 0;

    @media (min-width: 30em) {
      top: 0;
    }

    @media (min-width: 30.0625em) {
      top: 0;
    }

    @media (min-width: 90em) {
      top: 0;
    }
  
  
    right: 0;

    @media (min-width: 30em) {
      right: 0;
    }

    @media (min-width: 30.0625em) {
      right: 0;
    }

    @media (min-width: 90em) {
      right: 0;
    }
  
`

const CloseButton = styled.div`
  cursor: pointer;
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
`

export interface RatingModalProps {
  open: boolean
  sku?: string
  onClose?: () => void
  onSuccess?: () => void
  onError?: (e: Error) => void
}

export function RatingModal({ open, sku, onClose, onSuccess, onError }: RatingModalProps): JSX.Element {

  const theme = useTheme()

  const { data: reviewMetaData, loading: metaLoading } = useProductReviewMetaQuery()
  const [createReview, { loading: createReviewLoading }] = useCreateProductReviewMutation()
  const { data: customerData } = useCustomerQuery()

  const _handleCreateReview = async (data: ReviewFormData): Promise<void> => {

    if (customerData?.currentCustomer?.customerType !== CustomerTypeEnum.REGISTERED) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }

    const ratingMeta = reviewMetaData.productReviewRatingsMetadata.items.find((item) => item.name === 'Rating')
    const id = ratingMeta.id
    const valueId = ratingMeta.values.find((item) => {
      return parseInt(item.value) * 20 === data.rating
    }).valueId

    try {
      await createReview({
        variables: {
          input: {
            sku,
            nickname: data.nickname,
            summary: '-',
            text: data.description,
            ratings: [{
              id,
              valueId,
            }],
          },
        },
      })
      onSuccess()
    } catch (e) {
      if (e instanceof Error) {
        onError(e)
      }
    }
  }

  return (
    <Modal open={open} onClose={onClose} showHeader={false}>
      <ModalFormContainer>
        <Header>
          <Heading variant='h3'>Write a review</Heading>
          <CloseContainer>
            <CloseButton onClick={onClose}>
              <Icon color={theme.colors.black.pureBlack} icon={LocalIconEnums.CLOSE} />
            </CloseButton>
          </CloseContainer>
        </Header>
        <ReviewForm loading={metaLoading || createReviewLoading} onSuccess={_handleCreateReview} />
      </ModalFormContainer>
    </Modal>
  )

}
