import React, { useMemo } from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Button, ButtonColors } from '@atoms/buttons'
import { Icon, LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useRemoveItemFromCartMutation, CartItemFragment, useCustomerQuery, useAddProductToWishlistMutation, BundleCartItemFragment, CartFragment } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { CustomerTypeEnum, ProductStockStatus } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    padding: 6.250vw 0;

    @media (min-width: 30em) {
      padding: 6.250vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 1.389vw 0;
    }

    @media (min-width: 90em) {
      padding: 20px 0;
    }
  
  ${(props): string => ResponsivePXValue('border-bottom', `1px solid ${props.theme.colors.grey.gallery}`)};
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  
    width: 15.625vw;

    @media (min-width: 30em) {
      width: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.472vw;
    }

    @media (min-width: 90em) {
      width: 50px;
    }
  
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.472vw;
    }

    @media (min-width: 90em) {
      height: 50px;
    }
  
  
    margin-right: 1.563vw;

    @media (min-width: 30em) {
      margin-right: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-right: 5px;
    }
  
`

const InfoContainer = styled.div`
  flex-grow: 1;
`

const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  

  .elipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    
    -webkit-line-clamp: 2;

    @media (min-width: 30em) {
      -webkit-line-clamp: 3;
    }

    @media (min-width: 30.0625em) {
      -webkit-line-clamp: 3;
    }

    @media (min-width: 90em) {
      -webkit-line-clamp: 3;
    }
  
    
    padding-bottom: 0.625vw;

    @media (min-width: 30em) {
      padding-bottom: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      padding-bottom: 0.139vw;
    }

    @media (min-width: 90em) {
      padding-bottom: 2px;
    }
  
    margin: 0;
  }
`

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Buttons = styled.div`
  flex-grow: 1;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const RemoveWishlistContainer = styled.div`
  display: flex;
  align-items: center;

`

const CartTagIcon = styled.div`
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
`

export interface ProductDropDownCartCardProps {
  cart: CartFragment
  cartItem: CartItemFragment
  forAdded?: boolean
  shouldClose: () => void
}

export function ProductDropDownCartCard({ cartItem, cart, forAdded = false, shouldClose }: ProductDropDownCartCardProps): JSX.Element {

  const { cartId } = useCartId()
  const { data: customerData } = useCustomerQuery()
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()
  const [addProductToWishlist, { loading: addWishlistLoading }] = useAddProductToWishlistMutation()
  const theme = useTheme()
  const navigate = useNavigate()

  const _handleRemove = async (): Promise<void> => {
    await removeItemFromCart({
      variables: {
        cartId,
        cartItemUid: cartItem.uid,
      },
      optimisticResponse: () => {
        return {
          removeItemFromCart: {
            cart: {
              ...cart,
              items: [...cart.items.filter((itm) => itm.uid !== cartItem.uid)],
            },
            __typename: 'RemoveItemFromCartOutput',
          },
        }
      },
    })
  }

  const _handleMoveToWishlist = async (): Promise<void> => {
    await addProductToWishlist({
      variables: {
        wishlistId: customerData.currentCustomer.wishlists[0].id,
        sku: cartItem.product.sku,
        quantity: 1,
      },
    })
  }

  const _handleGoToProduct = (): void => {
    shouldClose()
    navigate(`/${cartItem.product.canonicalUrl}`)
  }

  const buttonColors: ButtonColors = {
    color: theme.colors.grey.gunSmoke,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.red.cinnabar,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.gallery,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  }

  const price = SiteHelper.formatCurrency(cartItem.prices.rowTotalIncludingTax)

  const extraLabels: string[] = useMemo(() => {
    const responseItems: string[] = []
    if (cartItem.__typename === 'ConfigurableCartItem') {
      cartItem.configurableOptions.forEach((option) => {
        responseItems.push(`${option.optionLabel}: ${option.valueLabel}`)
      })
    } else if (cartItem.__typename === 'BundleCartItem') {
      responseItems.push(`${(cartItem as BundleCartItemFragment).bundleOptions.reduce((total, item) => total + item.values.reduce((valTotal, val) => valTotal + val.quantity, 0), 0)} Items`)
    }
    return responseItems
  }, [cartItem])

  return (
    <Container>
      <ImageContainer onClick={_handleGoToProduct}>
        <ResponsiveImage image={cartItem.product.coverImage} objectFit='contain' />
      </ImageContainer>
      <InfoContainer>
        <TopContainer>
          <Paragraph className='elipsis' variant='p1'>
            {cartItem.product.name}{extraLabels.length ? ` (${extraLabels.join(', ')})` : ''}
          </Paragraph>
          <If condition={cartItem.product.stockStatus === ProductStockStatus.OUT_OF_STOCK}>
            <Paragraph variant='p3' color={theme.colors.red.cinnabar}>
              Out of stock
            </Paragraph>
          </If>
          <Paragraph variant='p2' className='price'>{price}</Paragraph>
        </TopContainer>
        <BottomContainer>
          <Buttons>
            <Choose>
              <When condition={forAdded} >
                <CartTagIcon>
                  <Icon color={theme.colors.green.watercourse} icon={LocalIconEnums.DOUBLE_TICK} />
                </CartTagIcon>
              </When>
              <Otherwise>
                <RemoveWishlistContainer>
                  <Button
                    onClick={_handleRemove}
                    colors={buttonColors}
                    className='cart-item-buttons'
                    loading={removeLoading}
                    disabled={addWishlistLoading}
                    icon={LocalIconEnums.TRASH_OUTLINE} />
                  <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
                    <Button
                      onClick={_handleMoveToWishlist}
                      colors={buttonColors}
                      className='cart-item-buttons'
                      disabled={removeLoading}
                      loading={addWishlistLoading}
                      icon={LocalIconEnums.HEART_OUTLINE} />
                  </If>
                </RemoveWishlistContainer>
              </Otherwise>
            </Choose>

          </Buttons>
          <Paragraph variant='p2'>Qty: {cartItem.quantity}</Paragraph>
        </BottomContainer>
      </InfoContainer>

    </Container>
  )

}
