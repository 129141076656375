import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'
import { PageHorizontalAlignment } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'

const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  
    min-height: 68.750vw;

    @media (min-width: 30em) {
      min-height: 68.750vw;
    }

    @media (min-width: 30.0625em) {
      min-height: 15.278vw;
    }

    @media (min-width: 90em) {
      min-height: 220px;
    }
  
  
    height: initial;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  ;
  
    align-items: initial;

    @media (min-width: 30em) {
      align-items: flex-start;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-start;
    }

    @media (min-width: 90em) {
      align-items: flex-start;
    }
  
  background-color: ${({ backgroundColor }): string => backgroundColor};
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  
    height: initial;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
  
    width: 100%;

    @media (min-width: 30em) {
      width: 45%;
    }

    @media (min-width: 30.0625em) {
      width: 45%;
    }

    @media (min-width: 90em) {
      width: 45%;
    }
  
  
    max-width: initial;

    @media (min-width: 30em) {
      max-width: initial;
    }

    @media (min-width: 30.0625em) {
      max-width: 24.306vw;
    }

    @media (min-width: 90em) {
      max-width: 350px;
    }
  
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
    justify-content: initial;

    @media (min-width: 30em) {
      justify-content: center;
    }

    @media (min-width: 30.0625em) {
      justify-content: center;
    }

    @media (min-width: 90em) {
      justify-content: center;
    }
  
  
    align-items: flex-start;

    @media (min-width: 30em) {
      align-items: flex-start;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-start;
    }

    @media (min-width: 90em) {
      align-items: flex-start;
    }
  
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  
    padding: 7.500vw 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

const TitleContainer = styled.div`
  .blockTitle{
    margin-top: 0;
  }
`

const DescriptionContainer = styled.div`
`

export interface PageBannerBlockProps {
  block: PageBlockFragment
}

export function PageBannerBlock({ block }: PageBannerBlockProps): JSX.Element {

  const { backgroundColor, foregroundColor, image, title, description, horizontalAlignment } = block

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  return (
    <Container backgroundColor={backgroundColor}>
      <ImageContainer>
        <ResponsiveImage image={image} objectFit='cover' />
      </ImageContainer>
      <ContentContainer>
        <TitleContainer>
          <Heading variant='h3' color={foregroundColor} align={alignment} className='blockTitle'>
            {title}
          </Heading>
        </TitleContainer>
        <DescriptionContainer>
          <HTML color={foregroundColor} align={alignment} className='description'>
            {description}
          </HTML>
        </DescriptionContainer>
      </ContentContainer>

    </Container>
  )

}
