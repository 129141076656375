import React from 'react'

import styled from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsiveImage } from '@components/atoms'
import { ResponsivePXValue } from '@components/Theme'
import { ProductSearchFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'

const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
    min-height: 13.125vw;

    @media (min-width: 30em) {
      min-height: 13.125vw;
    }

    @media (min-width: 30.0625em) {
      min-height: 2.917vw;
    }

    @media (min-width: 90em) {
      min-height: 42px;
    }
  
  
    margin: 0.625vw 0 0 0;

    @media (min-width: 30em) {
      margin: 0.625vw 0 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.139vw 0 0 0;
    }

    @media (min-width: 90em) {
      margin: 2px 0 0 0;
    }
  
  width: 100%;
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  
    width: 15.625vw;

    @media (min-width: 30em) {
      width: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.472vw;
    }

    @media (min-width: 90em) {
      width: 50px;
    }
  
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
  
`

const ContentContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  
    padding: 1.875vw;

    @media (min-width: 30em) {
      padding: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.417vw;
    }

    @media (min-width: 90em) {
      padding: 6px;
    }
  

  white-space: normal;

  .title {
    color: ${(props): string => props.theme.colors.black.pureBlack};
  }

`

export interface ProductSearchItemProps {
  item: ProductSearchFragment
  onSelect?: (path: string) => void
}

export function ProductSearchItem({ item, onSelect }: ProductSearchItemProps): JSX.Element {

  const _handlClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    onSelect?.(`/${item.canonicalUrl}`)
  }

  let price = SiteHelper.formatCurrency(item.priceRange.minimumPrice.finalPrice)
  if (item.priceRange.maximumPrice.finalPrice.value !== item.priceRange.minimumPrice.finalPrice.value) {
    price = `${SiteHelper.formatCurrency(item.priceRange.minimumPrice.finalPrice)}`
  }

  return (
    <Container href={`/${item.canonicalUrl}`} onClick={_handlClick}>

      <ImageContainer>
        <ResponsiveImage image={item.image} objectFit='contain' />
      </ImageContainer>
      <ContentContainer>
        <Paragraph variant='p2' >{item.name}</Paragraph>
        <Paragraph variant='p1' bold >{price}</Paragraph>
      </ContentContainer>

    </Container>
  )

}
