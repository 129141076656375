import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { SiteHelper } from '@lib/SiteHelper'

const Container = styled.div`
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.deYork, 0.15)};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.green.greenVogue};
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  .upsell-title {
    margin: 0;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.278vw;
    }

    @media (min-width: 90em) {
      gap: 4px;
    }
  
  
    padding: 4.688vw;

    @media (min-width: 30em) {
      padding: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.042vw;
    }

    @media (min-width: 90em) {
      padding: 15px;
    }
  

  line-height: 1.2;
  font-family: open-sans;
  font-weight: 400;

  
    font-size: 4.375vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  

  color: ${(props): string => props.theme.colors.green.watercourse};

  .text {
    margin: 0;
  }
`

const OrContainer = styled.div`
  font-family: open-sand;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props): string => props.theme.colors.green.watercourse};
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    padding: 0 3.125vw;

    @media (min-width: 30em) {
      padding: 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0.694vw;
    }

    @media (min-width: 90em) {
      padding: 0 10px;
    }
  
`

const Strong = styled.span`
  font-weight: 600;
`

const IconContainer = styled.div`
  flex-shrink: 0;
  float: left;
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.083vw;
    }

    @media (min-width: 90em) {
      width: 30px;
    }
  
  
    height: 9.375vw;

    @media (min-width: 30em) {
      height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.083vw;
    }

    @media (min-width: 90em) {
      height: 30px;
    }
  
`

export function CartUpSell(): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <Header>
        <Paragraph variant='p3' className='upsell-title' color={theme.colors.white.pureWhite}>Purchase R150 or more for</Paragraph>
      </Header>
      <Content>
        <Card>
          <IconContainer>
            <Icon icon={LocalIconEnums.CARBON_NEUTRAL} color={theme.colors.green.watercourse} />
          </IconContainer>
          <Strong>FREE</Strong> <span>carbon</span> <span>neutral</span> <span>deliveries</span>
        </Card>
        <OrContainer>
          OR
        </OrContainer>
        <Card>
          <IconContainer>
            <Icon icon={LocalIconEnums.SHOP} color={theme.colors.green.watercourse} />
          </IconContainer>
          <Strong>FREE</Strong> <span>pargo</span> <span>or</span> <span>in-store</span> <span>collection</span>
        </Card>
      </Content>
    </Container>
  )

}
