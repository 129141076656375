import React from 'react'

import styled, { useTheme } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const StepElement = styled.div<{ backgroundColor: string, foregroundColor: string, borderColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  
    padding: 0 7.500vw;

    @media (min-width: 30em) {
      padding: 0 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 0 24px;
    }
  
  
    height: 10.000vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
  
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.borderColor}`)}

  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};

  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
  font-weight: 600;
  font-family: open-sans;
  
`

export interface StepProps {
  children: JSX.Element | JSX.Element[] | string
  active?: boolean
  step: string
  backgroundColor?: string
  foregroundColor?: string
  borderColor?: string
  onClick?: () => void
}

export function Step({ children, active = false, backgroundColor, foregroundColor, borderColor, onClick }: StepProps): JSX.Element {

  return (
    <StepElement
      onClick={onClick}
      className='step'
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      borderColor={borderColor}>
      {children}
    </StepElement>
  )

}

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  .step:first-child {
    
    border-radius: 0 5.000vw 5.000vw 0;

    @media (min-width: 30em) {
      border-radius: 0 6.250vw 6.250vw 0;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0 1.389vw 1.389vw 0;
    }

    @media (min-width: 90em) {
      border-radius: 0 20px 20px 0;
    }
  
  }
  
`

export interface StepsProps {
  children: JSX.Element | JSX.Element[]
  currentStep: string
  onChange: (step: string) => void
}

export function Steps({ children = [], currentStep, onChange }: StepsProps): JSX.Element {

  const theme = useTheme()

  const colors = {
    activeColors: {
      backgroundColor: theme.colors.green.magicMint,
      foregroundColor: theme.colors.green.greenVogue,
      borderColor: theme.colors.green.magicMint,
    },
    inactiveColors: {
      backgroundColor: theme.colors.white.pureWhite,
      foregroundColor: theme.colors.grey.silver,
      borderColor: theme.colors.grey.gallery,
    },
  }

  const $actualChildren = Array.isArray(children) ? children.flat() : [children]
  const stepIndex = $actualChildren.findIndex(($child) => $child.props.step === currentStep)

  const $children = React.Children.map($actualChildren, ($child, childIndex) => {
    let newProps = {
      ...$child.props,
      onClick: () => onChange($child.props.step),
    }
    if (childIndex === stepIndex) {
      newProps.active = true
    }
    if (childIndex <= stepIndex) {
      newProps = {
        ...newProps,
        ...colors.activeColors,
      }
    } else {
      newProps = {
        ...newProps,
        ...colors.inactiveColors,
      }
    }
    return React.cloneElement($child, newProps)
  })

  return (
    <Container>
      {$children.reverse()}
    </Container>
  )

}
