import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'

import { HTML } from './HTML'

const Container = styled.div`
  width: 100%;
  height: 100%;  

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  `

const ContentContainer = styled.div <{ backgroundColor: string, foregroundColor: string }>`
  
    padding: 4.688vw;

    @media (min-width: 30em) {
      padding: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.042vw;
    }

    @media (min-width: 90em) {
      padding: 15px;
    }
  
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
   
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
`
const TitleContainer = styled.div`
  
    bottom: 4.688vw;

    @media (min-width: 30em) {
      bottom: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      bottom: 1.042vw;
    }

    @media (min-width: 90em) {
      bottom: 15px;
    }
  
  
    left: 9.375vw;

    @media (min-width: 30em) {
      left: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      left: 2.083vw;
    }

    @media (min-width: 90em) {
      left: 30px;
    }
  

  display: flex;
  align-items: flex-start;
  position: relative;
`
const TextBlockTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  
    padding: 3.125vw 4.688vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.111vw 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 0 16px 20px;
    }
  
`

const ImageContainer = styled.div`
  
    width: 4.688vw;

    @media (min-width: 30em) {
      width: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.042vw;
    }

    @media (min-width: 90em) {
      width: 15px;
    }
  

  align-items: center;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;

  p {
    margin: 0;
  }
`

export interface PageDetailsBlockProps {
  block: PageBlockFragment
}

export function PageDetailsImageBlock({ block }: PageDetailsBlockProps): JSX.Element {

  const { title, secondTitle, image, secondImage, description, backgroundColor, foregroundColor } = block

  return (
    <Container>
      <ContentContainer backgroundColor={backgroundColor} foregroundColor={foregroundColor}>
        <If condition={!!image}>
          <ImageContainer>
            <ResponsiveImage image={image} objectFit='contain' text-align='center' />
          </ImageContainer>
          <TitleContainer>
            <Heading
              variant='h8'
              align='center'
              decoration='none'>
              {title}
            </Heading>
          </TitleContainer>
        </If>
        <If condition={!!secondImage}>
          <ImageContainer>
            <ResponsiveImage image={secondImage} objectFit='contain' text-align='center' />
          </ImageContainer>
          <TitleContainer>
            <Heading
              variant='h8'
              align='center'
              decoration='none'>
              {secondTitle}
            </Heading>
          </TitleContainer>
        </If>
        <If condition={!!description}>
          <DescriptionContainer>
            <HTML color={foregroundColor}>
              {description}
            </HTML>
          </DescriptionContainer>
        </If>
      </ContentContainer>
    </Container>
  )
}

export function PageDetailsTextBlock({ block } : PageDetailsBlockProps): JSX.Element {
  const { title, description, backgroundColor, foregroundColor } = block

  return (
    <Container>
      <If condition={!!title}>
        <TextBlockTitleContainer>
          <Heading
            variant='h5'
            align='left'
            decoration='none'
            color={foregroundColor}>
            {title}
          </Heading>
        </TextBlockTitleContainer>
      </If>
      <ContentContainer backgroundColor={backgroundColor} foregroundColor={foregroundColor}>
        <If condition={!!description}>
          <DescriptionContainer>
            <HTML align='left'>
              {description}
            </HTML>
          </DescriptionContainer>
        </If>
      </ContentContainer>
    </Container>
  )
}

export function PageDetailsBlock({ block }: PageDetailsBlockProps): JSX.Element {

  const { title, secondTitle, image, secondImage, description } = block

  return (
    <Choose>
      <When condition={title && secondTitle && !!image && !!secondImage && title && description}>
        <PageDetailsImageBlock block={block} />
      </When>
      <Otherwise>
        <PageDetailsTextBlock block={block} />
      </Otherwise>
    </Choose>
  )
}
