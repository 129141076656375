import { ResponsivePXValue } from '@components/Theme'
import React from 'react'
import { useRef, useState } from 'react'
import update from 'react-addons-update'
import { useIsomorphicLayoutEffect } from 'react-spring'
import styled from 'styled-components'

const Container = styled.div<{ middlePoint: { x: number, y: number } }>`
  position: relative;
  width: 100%;
  
    height: 100vh;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
  flex-grow: 1;

  .loader-wrapper {
    position: absolute;
    top: ${({ middlePoint }) => middlePoint.y}px;
    left: ${({ middlePoint }) => middlePoint.x}px;
    transform: translate(-50%, -60%);
  }
`

interface LoadingContainerProps {
  children: JSX.Element 
  className?: string

}
interface LoadingContainerState {
  x: number
  y: number
}

const DEFAULT_STATE: LoadingContainerState = {
  x: 0,
  y: 0,
}

export function LoadingContainer({ children }: LoadingContainerProps): JSX.Element {

  const [state, setState] = useState<LoadingContainerState>({ ...DEFAULT_STATE })
  const ref = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const middleX = Math.round(rect.width / 2)
      const middleY = Math.round(rect.height / 2)
      setState((prevState) => update(prevState, {
        x: { $set: middleX },
        y: { $set: middleY },
      }))
    }
  }, [])

  return (
    <Container middlePoint={state} ref={ref}>
      <div className="loader-wrapper">
        {children}
      </div>
    </Container>
  )
}
