import React, { useMemo } from 'react'

import styled, { useTheme } from 'styled-components'

import { Card } from '@atoms/layout'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { BundleProductDetailsFragment } from '@hooks/api'

const BundleSelections = styled.div`
  .product-info-card {
    
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw 7.500vw 5.000vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw 1.667vw 1.111vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px 24px 16px 24px;
    }
  
  }
`

const BundleGroup = styled.div`
  
    padding: 2.500vw 0;

    @media (min-width: 30em) {
      padding: 2.500vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw 0;
    }

    @media (min-width: 90em) {
      padding: 8px 0;
    }
  
  .items-title {
     
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 8px;
    }
  
  }
`

const BundleItem = styled.div`
  
    margin-bottom: 1.250vw;

    @media (min-width: 30em) {
      margin-bottom: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.278vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 4px;
    }
  
  
    padding: 1.250vw 0;

    @media (min-width: 30em) {
      padding: 1.250vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw 0;
    }

    @media (min-width: 90em) {
      padding: 4px 0;
    }
  
  display: flex;
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.278vw;
    }

    @media (min-width: 90em) {
      gap: 4px;
    }
  
  .quantity {
    flex-shrink: 0;
  }
  .title {
    flex-grow: 1;
  }
  .price {
    flex-shrink: 0;
  }
`

export interface BundleSummaryProps {
  selectedOptions: { optionId: number, valueId: number, quantity: number }[]
  product: BundleProductDetailsFragment
}

export function BundleSummary({ product, selectedOptions }: BundleSummaryProps): JSX.Element {

  const theme = useTheme()
  const currentSelection: { selections: { title: string, selections: { title: string, quantity: number, price: number }[] }[], price: number, regularPrice: number } = useMemo(() => {
    const selections: { title: string, selections: { title: string, quantity: number, price: number }[] }[] = []
    let price = 0
    let regularPrice = 0
    product.items.forEach((item) => {
      const optionGroupItems = selectedOptions.filter((opt) => opt.optionId === item.optionId)
      if (optionGroupItems.length) {
        let currentSelectionGroup = selections.find((selGr) => selGr.title.trim() === item.title.trim())
        if (!currentSelectionGroup) {
          currentSelectionGroup = {
            title: item.title.trim(),
            selections: [],
          }
          selections.push(currentSelectionGroup)
        }
        item.options.forEach((opt) => {
          const selectedOption = optionGroupItems.find((ogi) => ogi.valueId === opt.id)
          if (selectedOption) {
            const itemPrice = ((opt.product.priceRange.minimumPrice.finalPrice.value / 100) * (100 - item.priceRange.minimumPrice.discount.percentOff)) * selectedOption.quantity
            const itemRegularPrice = opt.product.priceRange.minimumPrice.regularPrice.value * selectedOption.quantity
            price += itemPrice
            regularPrice += itemRegularPrice
            currentSelectionGroup.selections.push({
              title: opt.product.name,
              quantity: selectedOption.quantity,
              price: itemPrice,
            })
          }
        })
      }
    })
    return { selections, price, regularPrice }
  }, [product.items, selectedOptions])

  let group: { id: number, title: string, selections: { id: number, title: string, quantity: number, price: number }[] }
  let item: { id: number, title: string, quantity: number, price: number }

  return (
    <BundleSelections>
      <Card className='product-info-card'>
        <For each='group' of={currentSelection.selections}>
          <BundleGroup key={group.title}>
            <Heading variant='h5' className='items-title' color={theme.colors.black.pureBlack}>
              {group.title}
            </Heading>
            <For each='item' of={group.selections}>
              <BundleItem key={item.title}>
                <Paragraph bold className='quantity'>{isNaN(item.quantity) ? '? x ' : `${item.quantity} x `}</Paragraph>
                <Paragraph className='title'>{item.title}</Paragraph>
                <Paragraph bold className='price'>{isNaN(item.price) ? 'R??' : `R${item.price.toFixed(2)}`}</Paragraph>
              </BundleItem>
            </For>
          </BundleGroup>
        </For>
      </Card>
    </BundleSelections>
  )

}
