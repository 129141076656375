
import React from 'react'

import loadable from '@loadable/component'
import { LoadingContainer } from '@atoms/misc'
import { Loader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/TermsAndConditions')

const TermsAndConditions = loadable(() => import(/* webpackChunkName: "ingredients" */'@pages/TermsAndConditions'), {
  resolveComponent: (components) => components.TermsAndConditions,
})

export function TermsAndConditionsAsync(): JSX.Element {

  return <TermsAndConditions fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
