import React from 'react'

import styled, { css, useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { PageBannerLayoutEnum, PageHorizontalAlignment, PageLink } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { HTML } from './HTML'

const Container = styled.div<{ layout: PageBannerLayoutEnum }>`
  width: 100%;
  
    height: initial;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: fit-content;
    }

    @media (min-width: 90em) {
      height: fit-content;
    }
  
  display: flex;
  ${(props): string => ResponsivePXValue('flex-direction', {
    mobile: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'column' : 'column-reverse',
    tablet: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'column' : 'column-reverse',
    desktop: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'row' : 'row-reverse',
  })}
`

const ImageContainer = styled.div`
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 50%;
    }

    @media (min-width: 90em) {
      width: 50%;
    }
  
  
    height: 100%;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: auto;
    }

    @media (min-width: 90em) {
      height: auto;
    }
  
`

const ContentContainer = styled.div<{ backgroundColor: string, foregroundColor: string, alignment: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }): string => alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  justify-content: center;
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 50%;
    }

    @media (min-width: 90em) {
      width: 50%;
    }
  
  
    height: 100%;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: auto;
    }

    @media (min-width: 90em) {
      height: auto;
    }
  
  
    padding: 7.500vw 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 2.222vw;
    }

    @media (min-width: 90em) {
      padding: 32px;
    }
  
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  color: ${(props): string => props.foregroundColor};
  background-color: ${(props): string => props.backgroundColor};

  .align {
    
    line-height: 11.250vw;

    @media (min-width: 30em) {
      line-height: 13.750vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 3.056vw;
    }

    @media (min-width: 90em) {
      line-height: 44px;
    }
  
  }
`

const SubTitleContainer = styled.div`

`

const ButtonContainer = styled.div`
  display: flex;
  
    align-items: center;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-start;
    }

    @media (min-width: 90em) {
      align-items: flex-start;
    }
  
  justify-content: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

export interface PageSplitLargeImageBannerProps {
  banner: PageBannerFragment
}

export function PageSplitLargeImageBanner({ banner }: PageSplitLargeImageBannerProps): JSX.Element {

  const theme = useTheme()
  const { title, titleAlignment, buttonStyle, layout, description, links, mobileImage, image, subTitle } = banner
  const foregroundColor = banner.foregroundColor || theme.colors.green.bottleGreen
  const backgroundColor = banner.backgroundColor || theme.colors.white.pureWhite

  const alignment = titleAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : titleAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  const buttonColors = {
    color: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.white.pureWhite,
    backgroundColor: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? backgroundColor : theme.colors.black.pureBlack,
    borderColor: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.black.pureBlack,
    hoverColor: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? backgroundColor : theme.colors.white.pureWhite,
    hoverBackgroundColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.88),
    hoverBorderColor: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? backgroundColor : SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.88),
    disabledColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.white.pureWhite,
    disabledBackgrondColor: buttonStyle === 'SOLID' ? SiteHelper.getOpaqueColor(foregroundColor, 0.45) : buttonStyle === 'INVERTED' ? SiteHelper.getOpaqueColor(backgroundColor, 0.45) : SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.45),
    disabledBorderColor: buttonStyle === 'SOLID' ? SiteHelper.getOpaqueColor(foregroundColor, 0.45) : buttonStyle === 'INVERTED' ? SiteHelper.getOpaqueColor(backgroundColor, 0.45) : SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.45),
  }

  let link: PageLink
  let index: number

  return (
    <Container layout={layout}>
      <ImageContainer>
        <DeviceContainer desktop tablet>
          <ResponsiveImage image={image} objectFit='cover' />
        </DeviceContainer>
        <DeviceContainer mobile>
          <ResponsiveImage image={mobileImage || image} objectFit='cover' />
        </DeviceContainer>
      </ImageContainer>
      <ContentContainer foregroundColor={foregroundColor} backgroundColor={backgroundColor} alignment={titleAlignment}>
        <Heading
          variant='h1'
          color={foregroundColor}
          align={alignment}>
          {title}
        </Heading>
        <If condition={!!subTitle}>
          <SubTitleContainer>
            <Paragraph
              variant='p1'>
              {subTitle}
            </Paragraph>
          </SubTitleContainer>
        </If>
        <HTML color={foregroundColor}>
          {description}
        </HTML>
        <If condition={!!links}>
          <ButtonContainer>
            <For each='link' index='index' of={links || []}>
              <Button title={link.title} href={link.url} key={index} colors={buttonColors} />
            </For>
          </ButtonContainer>
        </If>
      </ContentContainer>
    </Container>
  )

}
