import React from 'react'

import styled from 'styled-components'

import { Paragraph, Skeleton, SkeletonNode } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { StockInfoFragment, WarehouseFragment } from '@hooks/api/index'
import { ProductStockStatus } from '@uctypes/api/globalTypes'

const StockContainer = styled.div`
  font-family: 'open-sans';
  display: flex;
  align-items: center;
  
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 16.875vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.750vw;
    }

    @media (min-width: 90em) {
      height: 54px;
    }
  
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const StockItem = styled.div`
  font-family: 'open-sans';
  font-weight: 600;
  background-color: ${(props): string => props.theme.colors.grey.athens};
  color: ${(props): string => props.theme.colors.grey.stormDust};

  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    padding: 1.250vw;

    @media (min-width: 30em) {
      padding: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 8px;
    }
  
`

interface ProductStockProps {
  sources: StockInfoFragment['sources']
  stockStatus: ProductStockStatus
  warehouse: WarehouseFragment
}

export function ProductStock({ sources, stockStatus, warehouse }: ProductStockProps): JSX.Element {

  if (sources.length === 0) {
    return (
      <StockContainer>
        <Skeleton direction='column' justify='flex-start' align='flex-start' gap='28px' height='auto'>
          <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
            height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
            variWidth={{ mobile: '30%-45%', tablet: '25%', desktop: '20%-50%' }}
          />
        </Skeleton>
      </StockContainer>
    )
  }

  let warehouseIndex: number

  return (
    <>
      <StockContainer>
        <Paragraph variant='p2' bold>
        Stock:
        </Paragraph>
        <For each='warehouse' of={sources || []} index='warehouseIndex'>
          <StockItem key={warehouseIndex}>
            {warehouse.location} ({stockStatus === ProductStockStatus.IN_STOCK ? warehouse.qty : 0})
          </StockItem>
        </For>
      </StockContainer>
    </>
  )

}
