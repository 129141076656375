import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'
import Modal from 'styled-react-modal'

import { Button, Loader, Icon, LocalIconEnums, Heading, Paragraph } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useCustomerQuery, useUpdateCustomerEmailMutation, useUpdateCustomerMutation, userequestPasswordResetEmailMutation } from '@hooks/api/index'
import { Form, TextInput, useForm } from '@molecules/inputs'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerUpdateInput } from '@uctypes/api/globalTypes'

const Container = styled.div``

const ModalFormContainer = styled.div`
  background-color: ${theme.colors.white.pureWhite};
  align-self: flex-end;

  
    padding: 5.313vw 7.500vw 14.063vw;

    @media (min-width: 30em) {
      padding: 5.313vw 7.500vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.181vw 1.667vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 17px 24px 24px;
    }
   

  ${theme.desktop} {
    
    width: 156.250vw;

    @media (min-width: 30em) {
      width: 156.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 34.722vw;
    }

    @media (min-width: 90em) {
      width: 500px;
    }
  
    align-self: inherit;
  }

  .heading {
    
    margin-top: 0;

    @media (min-width: 30em) {
      margin-top: 0;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0;
    }

    @media (min-width: 90em) {
      margin-top: 0;
    }
  
  }

  .modal-text {
    
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
  }

  .input {
    
    margin-bottom: 6.250vw;

    @media (min-width: 30em) {
      margin-bottom: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.389vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 20px;
    }
  
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  
    gap: 7.500vw;

    @media (min-width: 30em) {
      gap: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.667vw;
    }

    @media (min-width: 90em) {
      gap: 24px;
    }
  
  
    margin: 5.000vw 0 0 0;

    @media (min-width: 30em) {
      margin: 5.000vw 0 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0 0 0;
    }

    @media (min-width: 90em) {
      margin: 16px 0 0 0;
    }
  
`

const ButtonsLeft = styled.div`
  display: flex;
  justify-content: flex-start;

  
    gap: 7.500vw;

    @media (min-width: 30em) {
      gap: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.667vw;
    }

    @media (min-width: 90em) {
      gap: 24px;
    }
  
`

const ButtonsRight = styled.div``

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
    margin: 0 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 0 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 12px 0;
    }
  
`

const CloseContainer = styled.div`
  position: absolute;
  
    top: 0;

    @media (min-width: 30em) {
      top: 0;
    }

    @media (min-width: 30.0625em) {
      top: 0;
    }

    @media (min-width: 90em) {
      top: 0;
    }
  
  
    right: 0;

    @media (min-width: 30em) {
      right: 0;
    }

    @media (min-width: 30.0625em) {
      right: 0;
    }

    @media (min-width: 90em) {
      right: 0;
    }
  
`

const CloseButton = styled.div`
  cursor: pointer;
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
`

interface PasswordInputInterface {
  password: string
}

interface CustomerUpdateInputInterface extends CustomerUpdateInput {
  email?: string
  mobile?: string
  password?: string
}

interface ProfileFormState {
  open: boolean
  formUpdated: boolean
  customerData: CustomerUpdateInputInterface
}

const DEFAULT_STATE: ProfileFormState = {
  open: false,
  formUpdated: false,
  customerData: {},
}

export function ProfileForm(): JSX.Element {

  const { data: customerData, loading: customerLoading, refetch: refetchCustomer } = useCustomerQuery()
  const [updateCustomer, { loading: updateCustomerDataLoading }] = useUpdateCustomerMutation()
  const [updateCustomerEmail, { loading: updateCustomerEmailLoading }] = useUpdateCustomerEmailMutation()
  const [requestPasswordResetEmail, { loading: resetLoading }] = userequestPasswordResetEmailMutation()

  const [state, setState] = useState<ProfileFormState>({ ...DEFAULT_STATE })

  const { addToast } = useSimpleToasts()
  const profileForm = useForm()
  const passwordForm = useForm()

  const _sendUpdates = async (data: CustomerUpdateInputInterface) => {
    try {
      await updateCustomer({
        variables: {
          input: data,
        },
      })
      _handleClose()
      addToast({
        message: 'Updated successfully',
        appearance: 'success',
      })
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleFormUpdated = () => {
    setState((prevState) => update(prevState, {
      formUpdated: {
        $set: true,
      },
    }))
  }

  const _handleProfileSubmit = (input: CustomerUpdateInputInterface) => {
    if (customerData.currentCustomer.email !== input.email) {
      setState((prevState) => update(prevState, {
        open: { $set: true },
        customerData: { $set: input },
      }))
    } else {
      const data = input
      delete input.email
      delete input.mobile
      _sendUpdates(data)
    }
  }

  const _handlePasswordSubmit = async (input: PasswordInputInterface) => {
    try {
      await updateCustomerEmail({
        variables: {
          email: state.customerData.email,
          password: input.password,
        },
      })
      _handleClose()
      const customerData = state.customerData
      delete customerData.email
      await _sendUpdates(customerData)
      addToast({
        message: 'Updated successfully',
        appearance: 'success',
      })
    } catch (e) {
      refetchCustomer()
      profileForm.setFieldsValue({
        firstname: customerData.currentCustomer?.firstname,
        lastname: customerData.currentCustomer?.lastname,
        email: customerData.currentCustomer?.email,
      })
      addToast({
        message: e.message,
        appearance: 'error',
      })
      _handleClose()
    }

  }

  const _handleSendPasswordResetEmail = async () => {
    try {
      await requestPasswordResetEmail({
        variables: {
          email: customerData.currentCustomer.email,
        },
      })
      addToast({
        message: 'Password reset email sent',
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _handleClose = () => {
    setState((prevState) => update(prevState, {
      open: {
        $set: false,
      },
    }))
  }

  useEffect(() => {
    if (customerData?.currentCustomer?.email) {
      profileForm.setFieldsValue({
        firstname: customerData.currentCustomer?.firstname,
        lastname: customerData.currentCustomer?.lastname,
        email: customerData.currentCustomer?.email,
        mobile: customerData.currentCustomer?.mobile,
      })
    }
  }, [customerData?.currentCustomer])

  return (
    <Container>
      <Choose>
        <When condition={customerLoading}>
          <Loader />
        </When>
        <Otherwise>
          <Modal isOpen={state.open}>
            <ModalFormContainer>
              <Header>
                <Heading className='heading' variant='h3'>Confirm updates</Heading>
                <CloseContainer>
                  <CloseButton onClick={_handleClose}>
                    <Icon color={theme.colors.black.pureBlack} icon={LocalIconEnums.CLOSE} />
                  </CloseButton>
                </CloseContainer>
              </Header>
              <Form form={passwordForm} onFinish={_handlePasswordSubmit}>
                <Paragraph variant='p2' className='modal-text'>Please enter your password to confirm updates to your email address.</Paragraph>
                <TextInput variant='password' showLabel={true} label='Password' name='password' placeholder='Enter password' rules={[{ required: true, message: 'Please enter your password' }]} wrapperClassName="input" />
                <Button variant='primary' title='CONFIRM UPDATES' fullWidth loading={updateCustomerEmailLoading} onClick={() => passwordForm.submit()} className="submit" />
              </Form>
            </ModalFormContainer>
          </Modal>

          <Form form={profileForm} onChange={_handleFormUpdated} onFinish={_handleProfileSubmit}>

            <TextInput variant='text' showLabel={true} label='First name' name='firstname' rules={[{ required: true, message: 'Please enter a valid first name', pattern: /^[a-zA-Z\s-]+$/ }]} className="input" />
            <TextInput variant='text' showLabel={true} label='Last name' name='lastname' rules={[{ required: true, message: 'Please enter a valid last name', pattern: /^[a-zA-Z\s-]+$/ }]} className="input" />
            <TextInput variant='email' showLabel={true} label='Email' name='email' rules={[{ required: true, message: 'Please enter your email address' }]} className="input" />
            <TextInput disabled variant='phone' showLabel={true} label='Mobile Number' name='mobile' className="input" />

            <ButtonWrapper>
              <ButtonsLeft>
                <Button variant='primary' disabled={!state.formUpdated} className='form-button' title='UPDATE' loading={updateCustomerDataLoading} onClick={() => profileForm.submit()} />
              </ButtonsLeft>
              <ButtonsRight>
                <Button variant='text' className='form-button' title='Password reset' loading={resetLoading} onClick={() => _handleSendPasswordResetEmail()} />
              </ButtonsRight>
            </ButtonWrapper>

          </Form>
        </Otherwise>
      </Choose>
    </Container>

  )
}
