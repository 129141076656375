import React, { useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Loader } from '@atoms/notifications'
import { ResponsivePXValue } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useRoutingQuery } from '@hooks/api/index'
import useIsomorphicLayoutEffect from '@hooks/UseIsomorphicLayoutEffect'
import { PageFallbackComponent } from '@organisms/content'
import { BaseCategory } from '@pages/BaseCategory'
import { Category } from '@pages/Category'
import { CmsPage } from '@pages/CmsPage'
import { NotFound } from '@pages/NotFound'
import { Product } from '@pages/Product'
import { Specials } from '@pages/Specials'
import { EntityTypeEnum } from '@uctypes/api/globalTypes'

const LoadingContainer = styled.div<{ middlePoint: { x: number, y: number } }>`
    position: relative;
    width: 100%;
    
    height: 100vh;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
    flex-grow:1;

    .loader {
      position: absolute;
      top: ${({ middlePoint }) => middlePoint.y}px;
      left: ${({ middlePoint }) => middlePoint.x}px;
      transform: translate(-50%, -60%);
    }
`

interface LoadingContainerState {
  x: number
  y: number
}

const DEFAULT_STATE: LoadingContainerState = {
  x: 0,
  y: 0,
}

export function Magento(): JSX.Element {

  const location = useLocation()
  const path = location.pathname
  // TODO: Add service worker to get layot for well known routes
  const { data: routeData, loading: routeLoading, error: routeError, refetch } = useRoutingQuery({ variables: { path } })
  const ref = useRef<HTMLDivElement>(null)
  const events = useEvents()
  const [state, setState] = useState<LoadingContainerState>({ ...DEFAULT_STATE })

  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const middleX = Math.round(rect.width / 2)
      const middleY = Math.round(rect.height / 2)
      setState((prevState) => update(prevState, {
        x: { $set: middleX },
        y: { $set: middleY },
      }))
    }
  }, [routeData])

  const entityType: EntityTypeEnum | null = routeData?.routing?.entityType || null

  useEffect(() => {
    if (routeData?.routing?.entityType) {
      switch (routeData.routing.entityType) {
        case EntityTypeEnum.DEALS:
        case EntityTypeEnum.BASE_CATEGORY:
          events.hasViewedPage({
            url: location.pathname,
            title: routeData.routing.baseCategory?.name,
            type: routeData.routing.entityType,
          })
          break
        case EntityTypeEnum.CATEGORY:
          events.hasViewedPage({
            url: location.pathname,
            title: routeData.routing.category?.name,
            type: routeData.routing.entityType,
          })
          break
        case EntityTypeEnum.BRAND:
          events.hasViewedPage({
            url: location.pathname,
            title: routeData.routing.brand?.name,
            type: routeData.routing.entityType,
          })
          break
        case EntityTypeEnum.CONTENT:
          events.hasViewedPage({
            url: location.pathname,
            title: routeData.routing.page?.title,
            type: routeData.routing.entityType,
          })
          break
        case EntityTypeEnum.INGREDIENT:
          events.hasViewedPage({
            url: location.pathname,
            title: routeData.routing.ingredient?.name,
            type: routeData.routing.entityType,
          })
          break
        case EntityTypeEnum.PRODUCT:
          events.hasViewedPage({
            url: location.pathname,
            title: routeData.routing.product?.name,
            type: routeData.routing.entityType,
          })
          break
      }

    }
  }, [routeData?.routing?.entityType])

  return (
    <ErrorBoundary FallbackComponent={PageFallbackComponent}>
      <Choose>
        <When condition={!!routeError}>
          <PageFallbackComponent error={routeError} resetErrorBoundary={() => refetch()} />
        </When>
        <When condition={entityType === EntityTypeEnum.BASE_CATEGORY}>
          <BaseCategory baseCategory={routeData?.routing?.baseCategory} loading={routeLoading} />
        </When>
        <When condition={entityType === EntityTypeEnum.CATEGORY}>
          <Category category={routeData?.routing?.category} loading={routeLoading} />
        </When>
        <When condition={entityType === EntityTypeEnum.INGREDIENT}>
          <Category category={routeData?.routing?.ingredient} loading={routeLoading} />
        </When>
        <When condition={entityType === EntityTypeEnum.BRAND}>
          <Category category={routeData?.routing?.brand} loading={routeLoading} />
        </When>
        <When condition={entityType === EntityTypeEnum.DEALS}>
          <Specials category={routeData?.routing?.deals} loading={routeLoading} />
        </When>
        <When condition={entityType === EntityTypeEnum.PRODUCT}>
          <Product product={routeData?.routing?.product} loading={routeLoading} />
        </When>
        <When condition={entityType === EntityTypeEnum.CONTENT}>
          <CmsPage cmsPage={routeData?.routing?.page} loading={routeLoading} />
        </When>
        <Otherwise>
          <Choose>
            <When condition={routeLoading}>
              <LoadingContainer middlePoint={state} ref={ref}>
                <Loader className='loader'/>
              </LoadingContainer>
            </When>
            <Otherwise>
              <NotFound />
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </ErrorBoundary>
  )

}
