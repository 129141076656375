import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Loader } from '@atoms/notifications'
import { ResponsivePXValue } from '@components/Theme'
import { CartFragment, CheckoutFragment, useGetStoreConfigQuery } from '@hooks/api'

export interface PargoLocation {
  address1: string
  address2: string
  addressSms: string
  businessHours: string
  city: string
  distance: number
  latitude: number
  longitude: number
  pargoPointCode: string
  phoneNumber: string
  photo: string
  postalcode: string
  province: string
  storeName: string
  storeOwner: string
  suburb: string
}

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const IframeContainer = styled.div`
  width: 100%;
  
    height: 125.000vw;

    @media (min-width: 30em) {
      height: 125.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 41.667vw;
    }

    @media (min-width: 90em) {
      height: 600px;
    }
  
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export interface PargoSelectProps {
  checkout: CheckoutFragment
  cart: CartFragment
  onSelect: (location: PargoLocation) => void
}

interface PargoSelectState {
  redirect: string
  isLoaded: boolean
}

const DEFAULT_STATE: PargoSelectState = {
  redirect: '',
  isLoaded: false,
}

export function PargoSelect({ checkout, cart, onSelect }: PargoSelectProps): JSX.Element {

  const [state, setState] = useState<PargoSelectState>({ ...DEFAULT_STATE })
  const { data: storeData } = useGetStoreConfigQuery()

  const _handleLoad = (): void => {
    setState((prevState) => update(prevState, {
      isLoaded: {
        $set: true,
      },
    }))
  }

  useEffect(() => {
    const deliveryAddress = cart?.shippingAddresses?.[0]
    if (deliveryAddress) {
      const addressString = `${deliveryAddress.street.join(', ')}${deliveryAddress.suburb ? `, ${deliveryAddress.suburb}` : ', '}${deliveryAddress.city}, ${deliveryAddress.postcode}`
      const redirect = `${storeData?.storeConfig?.carriersPargoMapUrl}&address=${encodeURI(addressString)}`
      setState((prevState) => update(prevState, {
        redirect: {
          $set: redirect,
        },
      }))
    }
  }, [cart?.shippingAddresses])

  useEffect(() => {
    const selectPargoPoint = (item: any): void => {
      if (item?.data?.pargoPointCode) {
        onSelect(item.data)
      }
    }
    window.addEventListener('message', selectPargoPoint, false)
    return () => window.removeEventListener('message', selectPargoPoint, false)
  }, [])

  return (
    <Container>
      <If condition={!!state.redirect}>
        <IframeContainer>
          <iframe
            onLoad={_handleLoad}
            src={state.redirect}
            sandbox='allow-forms allow-scripts allow-same-origin'
            width='100%'
            height='100%' />
        </IframeContainer>
      </If>
      <If condition={!state.isLoaded}>
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      </If>
    </Container>
  )

}
