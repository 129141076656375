import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Card } from '@atoms/layout'
import { Tag } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CartFragment, CheckoutFragment } from '@hooks/api/index'
import { Table, Row, Cell } from '@molecules/tables'
import { CarrierTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;
  
    padding: 1.563vw 0;

    @media (min-width: 30em) {
      padding: 1.563vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw 0;
    }

    @media (min-width: 90em) {
      padding: 5px 0;
    }
  
  .tag {
    width: fit-content;
    
    margin: 0 0 1.563vw;

    @media (min-width: 30em) {
      margin: 0 0 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.347vw;
    }

    @media (min-width: 90em) {
      margin: 0 0 5px;
    }
  
  }
`

export interface CheckoutInfoProps {
  cart: CartFragment
  checkout: CheckoutFragment
  loading?: boolean
}

export function CheckoutInfo({ cart, checkout, loading = false }: CheckoutInfoProps): JSX.Element {

  const deliveryAddress = cart?.shippingAddresses?.[0]
  const paymentMethod = cart.availablePaymentMethods.find((method) => method.id === checkout.paymentInfo?.paymentMethod)?.title || ''
  const deliveryMethod = deliveryAddress?.availableShippingMethods?.find((method) => method.rateUid === checkout.deliveryInfo.rateUid)?.carrierTitle || ''
  const isCollection = deliveryAddress?.availableShippingMethods?.find((method) => method.rateUid === checkout.deliveryInfo.rateUid)?.carrierType === CarrierTypeEnum.COLLECT
  const deliveryTitle = isCollection ? 'Collection Address' : 'Delivery Address'
  const $deliveryAddress = isCollection
    ? checkout.deliveryInfo.requiresPargoLocation
      ? (<><div>{checkout.deliveryInfo.pargoLocation.storeName}</div><div>{checkout.deliveryInfo.pargoLocation.addressSms}</div>{checkout.deliveryInfo.pargoLocation.businessHours.split(',').map((hours) => <div key={hours}>{hours}</div>)}</>)
      : (<>{deliveryAddress?.availableShippingMethods?.find((method) => method.rateUid === checkout.deliveryInfo.rateUid)?.methodTitle}<br />Open:<br />Mon - Fri: 8am - 8pm<br />Sat - Sun: 8am - 10pm</>) || ''
    : (<>{deliveryAddress.street.join(', ')}<br />{deliveryAddress.suburb}<br />{deliveryAddress.city}<br />{deliveryAddress.postcode}</>)

  return (
    <Container>
      <Card>
        <Table>
          <Row align='start'>
            <Cell><Tag variant='t2' className='tag'>Payment Method</Tag></Cell>
            <Cell align='end'><Tag variant='t2' className='tag' align='right'>{paymentMethod}</Tag></Cell>
          </Row>
          <Row align='start'>
            <Cell><Tag variant='t2' className='tag'>Delivery Method</Tag></Cell>
            <Cell align='end'><Tag variant='t2' className='tag' align='right'>{deliveryMethod}</Tag></Cell>
          </Row>
          <Row align='start'>
            <Cell><Tag variant='t2' className='tag'>{deliveryTitle}</Tag></Cell>
            <Cell align='end'><Tag variant='t2' className='tag' align='right'>{$deliveryAddress}</Tag></Cell>
          </Row>
        </Table>
      </Card>
    </Container>
  )

}
