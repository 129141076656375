import React from 'react'

import styled from 'styled-components'

import { Button } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { PageBannerLayoutEnum, PageLink } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { HTML } from './HTML'

const Container = styled.div<{ layout: PageBannerLayoutEnum, backgroundColor: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props): string => ResponsivePXValue('flex-direction', {
    mobile: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'row' : 'row-reverse',
    tablet: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'row' : 'row-reverse',
    desktop: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'row' : 'row-reverse',
  })}
  background-color: ${(props): string => props.backgroundColor};
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
`

const ImageContainer = styled.div<{ layout: PageBannerLayoutEnum }>`
  flex-shrink: 0;
  
    width: 31.250vw;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 27.778vw;
    }

    @media (min-width: 90em) {
      width: 400px;
    }
  
  
    height: 31.250vw;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: 27.778vw;
    }

    @media (min-width: 90em) {
      height: 400px;
    }
  
`

const ContentContainer = styled.div<{ foregroundColor: string }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
    align-items: flex-start;

    @media (min-width: 30em) {
      align-items: flex-start;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-start;
    }

    @media (min-width: 90em) {
      align-items: flex-start;
    }
  
  
    justify-content: initial;

    @media (min-width: 30em) {
      justify-content: initial;
    }

    @media (min-width: 30.0625em) {
      justify-content: center;
    }

    @media (min-width: 90em) {
      justify-content: center;
    }
  
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 50%;
    }

    @media (min-width: 90em) {
      width: 50%;
    }
  
  
    height: initial;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
  
    padding: 3.125vw;

    @media (min-width: 30em) {
      padding: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw 2.222vw;
    }

    @media (min-width: 90em) {
      padding: 24px 32px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  color: ${(props): string => props.foregroundColor};

  .align {
    
    text-align: center;

    @media (min-width: 30em) {
      text-align: center;
    }

    @media (min-width: 30.0625em) {
      text-align: left;
    }

    @media (min-width: 90em) {
      text-align: left;
    }
  
  }

  .description {
    
    display: none;

    @media (min-width: 30em) {
      display: none;
    }

    @media (min-width: 30.0625em) {
      display: initial;
    }

    @media (min-width: 90em) {
      display: initial;
    }
  
  }

  .clamped {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  
    align-items: center;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-start;
    }

    @media (min-width: 90em) {
      align-items: flex-start;
    }
  
  
    justify-content: initial;

    @media (min-width: 30em) {
      justify-content: initial;
    }

    @media (min-width: 30.0625em) {
      justify-content: center;
    }

    @media (min-width: 90em) {
      justify-content: center;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

export interface PageSplitSmallImageBannerProps {
  banner: PageBannerFragment
}

export function PageSplitSmallImageBanner({ banner }: PageSplitSmallImageBannerProps): JSX.Element {

  const { links, layout, backgroundColor, image, foregroundColor, title, description } = banner

  const buttonColors = {
    color: foregroundColor,
    backgroundColor,
    borderColor: foregroundColor,
    hoverColor: foregroundColor,
    hoverBackgroundColor: backgroundColor,
    hoverBorderColor: foregroundColor,
    disabledColor: SiteHelper.getOpaqueColor(foregroundColor, 0.45),
    disabledBackgrondColor: backgroundColor,
    disabledBorderColor: SiteHelper.getOpaqueColor(foregroundColor, 0.45),
  }

  let link: PageLink
  let index: number

  return (
    <Container layout={layout} backgroundColor={backgroundColor}>
      <ImageContainer layout={layout}>
        <ResponsiveImage image={image} objectFit='cover' />
      </ImageContainer>
      <ContentContainer foregroundColor={foregroundColor}>
        <DeviceContainer desktop>
          <Heading variant='h2' className='align' color={foregroundColor}>{title}</Heading>
        </DeviceContainer>
        <DeviceContainer mobile tablet>
          <Heading variant='h5' className='clamped' color={foregroundColor}>{title}</Heading>
        </DeviceContainer>
        <DeviceContainer desktop>
          <HTML color={foregroundColor}>
            {description}
          </HTML>
        </DeviceContainer>
        <ButtonContainer>
          <For each='link' index='index' of={links || []}>
            <Button title={link.title} href={link.url} key={index} colors={buttonColors} target="_blank" />
          </For>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )

}
