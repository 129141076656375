import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { useActiveWidgetsQuery } from '@hooks/api'
import { HTML } from '@molecules/content'

const Container = styled.div<{ backgroundColor: string, foregroundColor: string }>`
  width: 100%;
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 8px;
    }
  
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  

  .html {
    width: fit-content;
    max-width: 100%;
    p:last-child {
      margin-bottom: 0;
    }
  }
`

const IconContainer = styled.div`
  
    width: 10.000vw;

    @media (min-width: 30em) {
      width: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.222vw;
    }

    @media (min-width: 90em) {
      width: 32px;
    }
  
  
    height: 10.000vw;

    @media (min-width: 30em) {
      height: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.222vw;
    }

    @media (min-width: 90em) {
      height: 32px;
    }
  
`

export function WidgetSitewideBanner(): JSX.Element {

  const { data } = useActiveWidgetsQuery()

  return (
    <If condition={!!data?.activeWidgets?.sitewideBanner}>
      <Container backgroundColor={data.activeWidgets.sitewideBanner.backgroundColor} foregroundColor={data.activeWidgets.sitewideBanner.foregroundColor}>
        <If condition={!!data.activeWidgets.sitewideBanner.icon}>
          <IconContainer>
            <ResponsiveImage image={data.activeWidgets.sitewideBanner.icon} />
          </IconContainer>
        </If>
        <HTML className='html' color={data.activeWidgets.sitewideBanner.foregroundColor}>{data.activeWidgets.sitewideBanner.content}</HTML>
      </Container>
    </If>
  )

}
