import React, { useRef, useState } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Icon, LocalIconEnums } from '@atoms/index'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageTimetableFragment, PageTimetableGroupFragment, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { PageTimetable } from './PageTimetable'

const Container = styled.div`
    width: 100%;
    cursor: pointer;
`

const TitleContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.green.swansDown};
    
    padding: 2.500vw 5.000vw ;

    @media (min-width: 30em) {
      padding: 2.500vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 16px;
    }
  
    
    display: flex;

    @media (min-width: 30em) {
      display: flex;
    }

    @media (min-width: 30.0625em) {
      display: block;
    }

    @media (min-width: 90em) {
      display: block;
    }
  
    
    align-items: center;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: initial;
    }

    @media (min-width: 90em) {
      align-items: initial;
    }
  
    
    justify-content: space-between;

    @media (min-width: 30em) {
      justify-content: space-between;
    }

    @media (min-width: 30.0625em) {
      justify-content: initial;
    }

    @media (min-width: 90em) {
      justify-content: initial;
    }
  
    /* display: flex;
    justify-content: center;
    align-items: center; */
`

const TimeTablesBlocksContainer = styled.div`
    display: flex;
    
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  

`

const IconContainer = styled.div`
    
    width: 7.500vw;

    @media (min-width: 30em) {
      width: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.667vw;
    }

    @media (min-width: 90em) {
      width: 24px;
    }
  ;
`

const Grid = styled.div <{ open: boolean }>`
    display: grid;
    grid-auto-rows: 1fr;
    width: 100%;
    
    grid-template-columns: 1fr;

    @media (min-width: 30em) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 30.0625em) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 90em) {
      grid-template-columns: repeat(3, 1fr);
    }
  
    background-color: ${({ theme }) => theme.colors.white.pampas};  
    grid-gap: 2px;
    max-height: ${({ open }): string => open ? '4000px' : '0px'};
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
`

interface PageTimetableGroupState {
  open: boolean
}
const DEFAULT_STATE: PageTimetableGroupState = {
  open: true,
}

export interface PageTimetableGroupProps {
  timetableGroup: PageTimetableGroupFragment
}

export function PageTimetableGroup({ timetableGroup }: PageTimetableGroupProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { colors } = useTheme()
  const { MOBILE } = DeviceTypeEnum
  const [state, setState] = useState<PageTimetableGroupState>({ ...DEFAULT_STATE })

  const ref: React.MutableRefObject<HTMLDivElement> = useRef(null)

  const _toggleOpen = (): void => {
    state.open === false &&
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

    setState((prevState) => update(prevState, { open: { $set: !prevState.open } }))
  }

  let timetable!: PageTimetableFragment
  let index!: number
  return (
    <Container>
      <If condition={!!timetableGroup.title}>
        <DeviceContainer mobile>
          <TitleContainer onClick={_toggleOpen} ref={ref}>
            <Heading variant='h3' color={colors.green.greenVogue}>{timetableGroup.title}</Heading>
            <IconContainer>
              <Choose>
                <When condition={!!state.open}>
                  <Icon icon={LocalIconEnums.CHEVRON_UP} color={colors.green.greenVogue} />
                </When>
                <Otherwise>
                  <Icon icon={LocalIconEnums.CHEVRON_DOWN} color={colors.green.greenVogue} />
                </Otherwise>
              </Choose>
            </IconContainer>
          </TitleContainer>
        </DeviceContainer>
        <DeviceContainer tablet desktop>
          <TitleContainer>
            <Heading variant={appData.app.deviceType === MOBILE ? 'h3' : 'h1'} color={colors.green.greenVogue}>{timetableGroup.title}</Heading>
          </TitleContainer>
        </DeviceContainer>
      </If>
      <Grid open={state.open}>
        <For each="timetable" index="index" of={timetableGroup.timetables}>
          <PageTimetable key={timetable.id} timetable={timetable}/>
        </For>
      </Grid>
    </Container>
  )
}
