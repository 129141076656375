import React, { Ref } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Icon, LocalIconEnums } from '@atoms/index'
import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Item = styled.a`
  text-decoration: none;
  
    width: auto;

    @media (min-width: 30em) {
      width: 33%;
    }

    @media (min-width: 30.0625em) {
      width: 33%;
    }

    @media (min-width: 90em) {
      width: 33%;
    }
  

  .item {
    
    padding: 2.500vw 5.000vw;

    @media (min-width: 30em) {
      padding: 0.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.000vw;
    }

    @media (min-width: 90em) {
      padding: 0px;
    }
  
    
    cursor: none;

    @media (min-width: 30em) {
      cursor: none;
    }

    @media (min-width: 30.0625em) {
      cursor: pointer;
    }

    @media (min-width: 90em) {
      cursor: pointer;
    }
  
    font-weight: 600;
  }
`

export interface ItemLinkProps {
  content: string
  url: string
}

export function ItemLink({ content, url }: ItemLinkProps): JSX.Element {

  return (
    <Item href={`/${url}`}>
      <Paragraph
        variant="p1"
        className='item'>
        {content}
      </Paragraph>
    </Item>
  )
}

const SectionContainer = styled.div`
  display: flex;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    gap: 0.625vw;

    @media (min-width: 30em) {
      gap: 0.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.000vw;
    }

    @media (min-width: 90em) {
      gap: 0px;
    }
  
  
    padding: initial;

    @media (min-width: 30em) {
      padding: 5.000vw 0.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 0.000vw;
    }

    @media (min-width: 90em) {
      padding: 16px 0px;
    }
  
  ${(props): string => ResponsivePXValue('background-color', { mobile: props.theme.colors.misc.transparent, tablet: props.theme.colors.white.pureWhite, desktop: props.theme.colors.white.pureWhite })}

  .letter {
    font-weight: 600;
  }
`
const LetterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
    justify-content: space-between;

    @media (min-width: 30em) {
      justify-content: center;
    }

    @media (min-width: 30.0625em) {
      justify-content: center;
    }

    @media (min-width: 90em) {
      justify-content: center;
    }
  
  
    padding: 2.500vw 5.000vw;

    @media (min-width: 30em) {
      padding: 8.125vw 12.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.806vw 2.778vw;
    }

    @media (min-width: 90em) {
      padding: 26px 40px;
    }
  
  box-sizing: border-box;
  
    width: auto;

    @media (min-width: 30em) {
      width: 34.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 7.639vw;
    }

    @media (min-width: 90em) {
      width: 110px;
    }
  
  background-color: ${({ theme }) => theme.colors.white.pureWhite};
`

const IconContainer = styled.div`
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: initial;
    }

    @media (min-width: 90em) {
      height: initial;
    }
  
  
    width: 7.500vw;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
`

const Items = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex: 1;
  
    align-items: flex-start;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: center;
    }

    @media (min-width: 90em) {
      align-items: center;
    }
  
  flex-direction: column;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    flex-wrap: nowrap;

    @media (min-width: 30em) {
      flex-wrap: wrap;
    }

    @media (min-width: 30.0625em) {
      flex-wrap: wrap;
    }

    @media (min-width: 90em) {
      flex-wrap: wrap;
    }
   
  
    margin-left: 0.000vw;

    @media (min-width: 30em) {
      margin-left: 17.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 3.889vw;
    }

    @media (min-width: 90em) {
      margin-left: 56px;
    }
  
  background-color: ${({ theme }) => theme.colors.white.pureWhite};  
  max-height: ${({ isOpen }): string => isOpen ? '4000px' : '0px'};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
   
  & > div {
    display: flex;
    
    padding: 1.250vw 0.000vw;

    @media (min-width: 30em) {
      padding: 0.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.000vw;
    }

    @media (min-width: 90em) {
      padding: 0px;
    }
  
    
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
    
    flex-wrap: nowrap;

    @media (min-width: 30em) {
      flex-wrap: wrap;
    }

    @media (min-width: 30.0625em) {
      flex-wrap: wrap;
    }

    @media (min-width: 90em) {
      flex-wrap: wrap;
    }
   
    
    flex-grow: 0;

    @media (min-width: 30em) {
      flex-grow: 1;
    }

    @media (min-width: 30.0625em) {
      flex-grow: 1;
    }

    @media (min-width: 90em) {
      flex-grow: 1;
    }
   
  }
  .item{
    
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  }
`

export interface ListingSectionProps {
  title: React.ReactNode
  children: React.ReactNode
  id: string
  isOpen?: boolean
  onChange?: () => void
}

export const ListingSection = React.forwardRef(({ title, children, id, isOpen, onChange }: ListingSectionProps, ref: Ref<HTMLDivElement>): JSX.Element => {

  const theme = useTheme()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  return (
    <SectionContainer ref={ref}>
      <DeviceContainer mobile>
        <LetterContainer onClick={onChange}>
          <Heading
            variant='h1'
            color={theme.colors.green.greenVogue}
            transform='capitalize'
            className='letter'>
            {title}
          </Heading>
          <IconContainer>
            <Choose>
              <When condition={isOpen}>
                <Icon icon={LocalIconEnums.CHEVRON_UP} color={theme.colors.green.greenVogue} />
              </When>
              <Otherwise>
                <Icon icon={LocalIconEnums.CHEVRON_DOWN} color={theme.colors.green.greenVogue} />
              </Otherwise>
            </Choose>
          </IconContainer>
        </LetterContainer>
      </DeviceContainer>
      <DeviceContainer tablet desktop>
        <LetterContainer>
          <Heading
            variant='h1'
            color={theme.colors.green.greenVogue}
            transform='capitalize'
            className='letter'>
            {title}
          </Heading>
        </LetterContainer>
      </DeviceContainer>
      <Choose>
        <When condition={appData.app.deviceType === DeviceTypeEnum.MOBILE}>
          <Items isOpen={isOpen}>
            <div>
              {children}
            </div>
          </Items>
        </When>
        <Otherwise>
          <Items isOpen={true}>
            <div>
              {children}
            </div>
          </Items>
        </Otherwise>
      </Choose>
    </SectionContainer>
  )
})

ListingSection.displayName = 'ListingSection'

const Container = styled.div`
  
    display: flex;

    @media (min-width: 30em) {
      display: flex;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: colum;
    }

    @media (min-width: 30.0625em) {
      flex-direction: column;
    }

    @media (min-width: 90em) {
      flex-direction: column;
    }
  
  
    width: 100.000vw;

    @media (min-width: 30em) {
      width: 385.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 85.556vw;
    }

    @media (min-width: 90em) {
      width: 1232px;
    }
  
  
    margin-bottom: 7.813vw;

    @media (min-width: 30em) {
      margin-bottom: 31.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 6.944vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 100px;
    }
  
  
    gap: 0.156vw;

    @media (min-width: 30em) {
      gap: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.278vw;
    }

    @media (min-width: 90em) {
      gap: 4px;
    }
  
`

export interface ListingProps {
  children: JSX.Element[] | JSX.Element
  onTabsChange: (active: string[]) => void
  tabs: string[]
}

export function Listing({ children, onTabsChange, tabs }: ListingProps): JSX.Element {

  const _handleTabChanged = (id: string): void => {

    const newTabs = [...tabs]

    if (tabs.includes(id)) {
      newTabs.splice(newTabs.indexOf(id), 1)
      onTabsChange(newTabs)
    } else {
      onTabsChange([id])
    }
  }

  const $tabs = React.Children.map(children, ($child) => {
    if ($child?.props?.id) {
      let isOpen = false
      if (tabs.includes($child.props.id)) {
        isOpen = true
      }
      return React.cloneElement($child, { ...$child.props, isOpen, onChange: () => _handleTabChanged($child.props.id) })
    }

    return $child
  })

  return (
    <Container>
      {$tabs}
    </Container>
  )

}
