import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;

  background-color: ${(props): string => props.theme.colors.green.bottleGreen};
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
  
`

const TitleContainer = styled.div`
  position: relative;
  font-weight: 600;
  font-family: open-sans;

  color: ${(props): string => props.theme.colors.white.pureWhite};

  
    left: 1.563vw;

    @media (min-width: 30em) {
      left: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      left: 0.347vw;
    }

    @media (min-width: 90em) {
      left: 5px;
    }
  
  
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.111vw;
    }

    @media (min-width: 90em) {
      font-size: 16px;
    }
  
`

const IconContainer = styled.div`
  position: relative;
  
    top: -0.313vw;

    @media (min-width: 30em) {
      top: -0.313vw;
    }

    @media (min-width: 30.0625em) {
      top: -0.069vw;
    }

    @media (min-width: 90em) {
      top: -1px;
    }
  
  
    width: 12.500vw;

    @media (min-width: 30em) {
      width: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.778vw;
    }

    @media (min-width: 90em) {
      width: 40px;
    }
  
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
  
`

// TODO Part of checkout so leaving here
export function CartButton(): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <TitleContainer>ADD TO</TitleContainer>
      <IconContainer>
        <Icon icon={LocalIconEnums.CART} color={theme.colors.white.pureWhite} />
      </IconContainer>
    </Container>
  )

}
