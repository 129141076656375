import React from 'react'

import styled from 'styled-components'

import { Skeleton, SkeletonNode } from '@atoms/layout'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CategoryFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'

const Container = styled.div`
  
    margin-top: 0;

    @media (min-width: 30em) {
      margin-top: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-top: 16px;
    }
  
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0;
    }

    @media (min-width: 90em) {
      padding: 0;
    }
  
  .title {
    
    margin: 0 0 2.500vw 0;

    @media (min-width: 30em) {
      margin: 0 0 5.000vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 1.111vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 16px 0;
    }
  
  }
  .html {
    /* p:not(:first-child) {
      display: none;
    }
    
    max-height: fit-content;

    @media (min-width: 30em) {
      max-height: 46.875vw;
    }

    @media (min-width: 30.0625em) {
      max-height: 10.417vw;
    }

    @media (min-width: 90em) {
      max-height: 150px;
    }
  
    overflow: auto; */
    text-align: left !important;

    p {
      text-align: left !important;
    }
    a {
      text-decoration: none;
      
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
      font-weight: 700;
      color: ${(props): string => props.theme.colors.black.pureBlack};
    }
    a:hover {
      text-decoration: underline;
      
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
      font-weight: 700;
    }
  }
`

export interface CategoryHeaderProps {
  category?: CategoryFragment
}

export function CategoryHeader({ category }: CategoryHeaderProps): JSX.Element {

  if (!category) {
    return (
      <Skeleton
        color='pampas'
        direction='column'
        justify='flex-start'
        gap={{ mobile: '8px', tablet: '16px', desktop: '16px' }}
        padding={{ mobile: '16px', tablet: '16px 2px', desktop: '16px 2px' }}
        height={{ mobile: '120px', tablet: '150px', desktop: '130px' }}
      >
        <SkeletonNode
          width={{ mobile: '100%', tablet: '30%', desktop: '30%' }}
          height={{ mobile: '20px', tablet: '54px', desktop: '30px' }} />
        <SkeletonNode
          width={{ mobile: '140px', tablet: '100%', desktop: '100%' }}
          height={{ mobile: '20px', tablet: '30px', desktop: '20px' }} />
        <SkeletonNode
          width={{ mobile: '140px', tablet: '100%', desktop: '60%' }}
          height={{ mobile: '20px', tablet: '30px', desktop: '20px' }} />
      </Skeleton>
    )
  }

  return (
    <Container>
      <Heading variant='h1' className='title' >{category?.name}</Heading>
      <HTML className='html'>
        {category?.description}
      </HTML>
    </Container>
  )

}
