import React from 'react'

import loadable from '@loadable/component'
import { LoadingContainer } from '@atoms/misc'
import { Loader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/CategoryWrapper')

const CategoryWrapper = loadable(() => import(/* webpackChunkName: "category-wrapper" */'@pages/CategoryWrapper'), {
  resolveComponent: (components) => components.CategoryWrapper,
})

export function CategoryWrapperAsync(): JSX.Element {

  return <CategoryWrapper fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
