import React, { useEffect } from 'react'

import { sentenceCase } from 'change-case'
import styled from 'styled-components'

import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Button, Icon, LocalIconEnums, Heading, Paragraph, Message } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useCustomerQuery } from '@hooks/api/index'
import { CheckBoxInput, Form, LogInModalDisplayType, LoginModalDisplayStatus, useForm } from '@molecules/index'
import { SsoProviderEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;

  .form-button {
    width: 100%;
    
    margin: 3.750vw 0 0 0;

    @media (min-width: 30em) {
      margin: 3.750vw 0 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.833vw 0 0 0;
    }

    @media (min-width: 90em) {
      margin: 12px 0 0 0;
    }
  
  }

  .platform-button {
    
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 8px;
    }
  
    width: 100%;
  }

  .title {
    
    margin: 0 0 4.375vw 0;

    @media (min-width: 30em) {
      margin: 0 0 4.375vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.972vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 14px 0;
    }
  
  }

  .input {
    
    margin-bottom: 2.813vw;

    @media (min-width: 30em) {
      margin-bottom: 2.813vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.625vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 9px;
    }
  
  }

  .label {
    
    margin-bottom: 3.438vw;

    @media (min-width: 30em) {
      margin-bottom: 3.438vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.764vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 11px;
    }
  
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: ${(props): string => props.theme.colors.grey.pampas};

  
    gap: 1.875vw;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
  
    border-top-width: 0.313vw;

    @media (min-width: 30em) {
      border-top-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-top-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-top-width: 1px;
    }
  
  
    height: 21.250vw;

    @media (min-width: 30em) {
      height: 21.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.722vw;
    }

    @media (min-width: 90em) {
      height: 68px;
    }
  
  
    margin-bottom: -7.500vw;

    @media (min-width: 30em) {
      margin-bottom: -7.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: -1.667vw;
    }

    @media (min-width: 90em) {
      margin-bottom: -24px;
    }
  
  
    margin-top: 5.000vw;

    @media (min-width: 30em) {
      margin-top: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-top: 16px;
    }
  

`
const SubscribeInput = styled.input`
  
    height: 100%;

    @media (min-width: 30em) {
      height: 100%;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
`

const SubscribeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.grey.pampas};
  
    height: 11.875vw;

    @media (min-width: 30em) {
      height: 11.875vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.639vw;
    }

    @media (min-width: 90em) {
      height: 38px;
    }
  
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  
  .input {
    background-color: ${(props): string => props.theme.colors.misc.transparent};
    border: 0;
    margin: 0;
    
    width: 110.938vw;

    @media (min-width: 30em) {
      width: 110.938vw;
    }

    @media (min-width: 30.0625em) {
      width: 24.653vw;
    }

    @media (min-width: 90em) {
      width: 355px;
    }
  
  }

  .icons {
    
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
    
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  }
`

export interface SubscribeFormProps {
  onSubscribe: (email: string) => void
  onSetDisplayType: (displayType: LogInModalDisplayType) => void
  displayStatus: LoginModalDisplayStatus
  errorMessage?: string
  provider: SsoProviderEnum
}

export function SubscribeForm({ onSubscribe, onSetDisplayType, displayStatus, errorMessage, provider }: SubscribeFormProps): JSX.Element {

  const form = useForm()
  const { data: customerData } = useCustomerQuery()

  useEffect(() => {
    if (customerData?.currentCustomer?.isSubscribed) {
      ModalPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.LOG_IN)
    }
    if (customerData?.currentCustomer?.email) {
      form.setFieldValue('email', customerData?.currentCustomer?.email)
    }
  }, [customerData?.currentCustomer])

  let providerName = 'Email'
  if (provider) {
    providerName = sentenceCase(provider)
  }

  const buttonTitle = displayStatus === LoginModalDisplayStatus.INPUT || displayStatus === LoginModalDisplayStatus.LOADING
    ? 'SUBSCRIBE'
    : ''
  const buttonIcon = displayStatus === LoginModalDisplayStatus.SUCCESS
    ? LocalIconEnums.CHECK
    : false

  const loading = displayStatus === LoginModalDisplayStatus.LOADING
  const disabled = displayStatus === LoginModalDisplayStatus.SUCCESS

  return (
    <Container>
      <Heading className="title" variant='h3' >Thanks for joining Faithful to Nature</Heading>
      <Form form={form} onFinish={onSubscribe} loading={loading} disabled={disabled}>
        <Paragraph color={theme.colors.grey.stormDust} variant='p2' className='label'>{"You've signed up with " + providerName + ':'}</Paragraph>
        <SubscribeInputWrapper>
          <Choose>
            <When condition={provider === SsoProviderEnum.GOOGLE || provider === SsoProviderEnum.GOOGLE_ONE_TAP}>
              <Icon className='icons' icon={LocalIconEnums.GOOGLE} />
            </When>
            <When condition={provider === SsoProviderEnum.FACEBOOK}>
              <Icon className='icons' icon={LocalIconEnums.FACEBOOK} />
            </When>
            <Otherwise>
              <Icon className='icons' icon={LocalIconEnums.EMAIL} />
            </Otherwise>
          </Choose>
          <SubscribeInput
            readOnly
            name='email'
            className='input'
            value={customerData?.currentCustomer?.email}
            placeholder="email" />
          <Icon className='icons' icon={LocalIconEnums.CHECK} color={theme.colors.green.sushi} />
        </SubscribeInputWrapper>
        <CheckBoxInput
          name='subscribe'
          options={[{ value: true, title: 'Subscribe to newsletter for unbeatable discounts, great new products and conscious living tips.' }]}
          rules={[{ required: true, message: 'Please tick.' }]}
          showLabel={true} />
        <If condition={displayStatus === LoginModalDisplayStatus.ERROR}>
          <Message
            wrapperClassName='messageClass'
            backgroundColor={theme.colors.pink.bridesmaid}
            color={theme.colors.red.cinnabar}
            message={errorMessage} />
        </If>
        <Button
          loading={loading}
          variant='primary'
          size='medium'
          fullWidth
          className='form-button'
          title={buttonTitle}
          icon={buttonIcon}
          onClick={() => disabled ? null : form.submit()} />
      </Form>
      <Footer>
        <Paragraph display='inline' align='center' variant='p2'>You can unsubscribe at any time</Paragraph>
      </Footer>
    </Container>
  )

}
