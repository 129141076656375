import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { DotPagination } from '@atoms/controls'
import { ResponsiveImage } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsImageFragment } from '@hooks/api/index'
import { ProductImageGalleryModal } from '@molecules/modals/ProductImageGalleryModal'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .controls {
    
    margin: 6.250vw 0;

    @media (min-width: 30em) {
      margin: 6.250vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.389vw 0;
    }

    @media (min-width: 90em) {
      margin: 20px 0;
    }
  
  }
`

const Embla = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  
    height: 62.500vw;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: initial;
    }

    @media (min-width: 90em) {
      height: initial;
    }
  
`

const EmblaContainer = styled.div`
  position: relative;
  display: flex;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const EmblaSlide = styled.div`
  position: relative;
  user-select: none;
  
    flex: 0 0 100%;

    @media (min-width: 30em) {
      flex: 0 0 100%;
    }

    @media (min-width: 30.0625em) {
      flex: 0 0 100%;
    }

    @media (min-width: 90em) {
      flex: 0 0 100%;
    }
  
  
    height: 62.500vw;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: initial;
    }

    @media (min-width: 90em) {
      height: initial;
    }
  
`

export interface ProductMobileImagesProps {
  gallery: ProductDetailsImageFragment[]
}

interface ProductMobileImagesState {
  scrollOffset: number
  modalOpen: boolean
}

const DEFAULT_STATE: ProductMobileImagesState = {
  scrollOffset: 0,
  modalOpen: false,
}

export function ProductMobileImages({ gallery }: ProductMobileImagesProps): JSX.Element {

  const [state, setState] = useState<ProductMobileImagesState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel()

  const images: ProductDetailsImageFragment[] = gallery

  const _handleModalClose = (): void => {
    setState((prevState) => update(prevState, {
      modalOpen: { $set: false },
    }))
  }

  const _handleModalOpen = (): void => {
    setState((prevState) => update(prevState, {
      modalOpen: { $set: true },
    }))
  }

  const _handleChange = (index: number): void => {
    setState((prevState) => update(prevState, {
      scrollOffset: { $set: index },
    }))
  }

  const onSelect = () => {
    setState((prevState) => update(prevState, {
      scrollOffset: { $set: emblaApi.selectedScrollSnap() },
    }))
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(state.scrollOffset)
    }
  }, [state.scrollOffset])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(state.scrollOffset)
      emblaApi.on('select', onSelect)
    }
  }, [emblaApi])

  let image: ProductDetailsImageFragment
  let imageIndex: number

  return (
    <>
      <ProductImageGalleryModal
        index={state.scrollOffset}
        onIndexChange={_handleChange}
        images={images}
        open={state.modalOpen}
        onClose={_handleModalClose} />
      <Container>
        <Embla ref={emblaRef}>
          <EmblaContainer>
            <For each='image' index='imageIndex' of={images}>
              <EmblaSlide
                key={`${image.id}-${imageIndex}`}
                onClick={_handleModalOpen}>
                <ResponsiveImage image={image} objectFit='contain' />
              </EmblaSlide>
            </For>
          </EmblaContainer>
        </Embla>
        <DotPagination
          className='controls'
          currentIndex={state.scrollOffset}
          total={images.length}
          onChange={_handleChange} />
      </Container>
    </>
  )

}
