import React from 'react'

import styled from 'styled-components'

import { IconCard } from '@atoms/layout'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageIconFragment, PageIconSectionFragment } from '@hooks/api/index'

const Container = styled.div`
  width: 100%; 
`

const HeadingContainer = styled.div`
  
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 2.778vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 40px;
    }
  
  
    padding: 3.750vw 0;

    @media (min-width: 30em) {
      padding: 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 0;
    }

    @media (min-width: 90em) {
      padding: 12px 0;
    }
  
  background-color: ${(props): string => props.theme.colors.white.fantasy};
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.667vw;
    }

    @media (min-width: 90em) {
      gap: 24px;
    }
  
`
export interface PageIconSectionProps {
  section: PageIconSectionFragment
}

export function PageIconSection({ section }: PageIconSectionProps): JSX.Element {

  let icon!: PageIconFragment

  return (
    <Container>
      <HeadingContainer>
        <Heading variant='h2' align='center' >{section.title}</Heading>
      </HeadingContainer>
      <IconContainer>
        <For each='icon' of={section.icons}>
          <IconCard
            key={icon.id}
            title={icon.title}
            href={icon.href}
            image={icon.icon}
            titleColour={section.foregroundColor}
            backgroundColor={section.backgroundColor} />
        </For>
      </IconContainer>
    </Container>
  )

}
