import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PaymentMethodFragment } from '@hooks/api/index'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    border-radius: 0;

    @media (min-width: 30em) {
      border-radius: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.694vw;
    }

    @media (min-width: 90em) {
      border-radius: 10px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 4.167vw;
    }

    @media (min-width: 90em) {
      width: 60px;
    }
  
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
  
    border-radius: 3.125vw;

    @media (min-width: 30em) {
      border-radius: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.903vw;
    }

    @media (min-width: 90em) {
      border-radius: 13px;
    }
  
  ${(props): string => {
    if (props.active) {
      return `
        
    border-width: 1.250vw;

    @media (min-width: 30em) {
      border-width: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.347vw;
    }

    @media (min-width: 90em) {
      border-width: 5px;
    }
  
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    
    border-width: 0.313vw;

    @media (min-width: 30em) {
      border-width: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.139vw;
    }

    @media (min-width: 90em) {
      border-width: 2px;
    }
  
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  
    align-items: flex-start;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: center;
    }

    @media (min-width: 90em) {
      align-items: center;
    }
  
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
`

const InformationContainer = styled.div`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const LogosContainer = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  align-items: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
  
    margin-top: 2.500vw;

    @media (min-width: 30em) {
      margin-top: initial;
    }

    @media (min-width: 30.0625em) {
      margin-top: initial;
    }

    @media (min-width: 90em) {
      margin-top: initial;
    }
  
  .large-logo {
    
    height: 7.813vw;

    @media (min-width: 30em) {
      height: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.736vw;
    }

    @media (min-width: 90em) {
      height: 25px;
    }
  ;
  }
`

const LogoContainer = styled.div`
  
    height: 3.125vw;

    @media (min-width: 30em) {
      height: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.694vw;
    }

    @media (min-width: 90em) {
      height: 10px;
    }
  ;
  svg {
    width: auto;
  }
`

export interface PaymentMethodRowProps {
  paymentMethod: PaymentMethodFragment
  selected: boolean
  onSelect: () => void
}

export function PaymentMethodRow({ selected, paymentMethod, onSelect }: PaymentMethodRowProps): JSX.Element {

  const { colors } = useTheme()

  const paymentLogos = {
    creditCard: [{
      large: false,
      logo: LocalIconEnums.PAYMENT_VISA_COLOR,
    },
    {
      large: true,
      logo: LocalIconEnums.PAYMENT_MASTER_CARD_COLOR,
    },
    {
      large: true,
      logo: LocalIconEnums.PAYMENT_AMERICAN_EXPRESS_COLOR,
    }],
    ozow: [{
      large: true,
      logo: LocalIconEnums.OZOW,
    }],
    bankTransfer: [{
      large: true,
      logo: LocalIconEnums.PAYMENT_EFT_COLOR,
    }],
  }

  let index: number

  return (
    <Container active={selected}>
      <If condition={!!onSelect}>
        <CircleContainer onClick={onSelect} >
          <Circle active={selected} />
        </CircleContainer>
      </If>
      <ContentContainer onClick={onSelect}>
        <InformationContainer>
          <Title variant='t8'>
            {paymentMethod.title}
          </Title>
          <Paragraph color={colors.grey.stormDust}>
            <If condition={paymentMethod.id === 'peachpayments_s2s'}>
            Pay with a Visa, Mastercard or American Express credit or debit card.
            </If>
            <If condition={paymentMethod.id === 'ozow'}>
            Pay instantly using Ozow Instant EFT
            </If>
            <If condition={paymentMethod.id === 'banktransfer'}>
            Pay using bank transfer
            </If>
          </Paragraph>
        </InformationContainer>
        <LogosContainer>
          <If condition={paymentMethod.id === 'peachpayments_s2s'}>
            <For each='logo' index='index' of={paymentLogos.creditCard}>
              <LogoContainer key={index} className={paymentLogos.creditCard[index].large ? 'large-logo' : ''}>
                <Icon icon={paymentLogos.creditCard[index].logo} color={selected ? undefined : colors.grey.gunSmoke } />
              </LogoContainer>
            </For>
          </If>

          <If condition={paymentMethod.id === 'ozow'}>
            <For each='logo' index='index' of={paymentLogos.ozow}>
              <LogoContainer key={index} className={paymentLogos.ozow[index].large ? 'large-logo' : ''}>
                <Icon icon={paymentLogos.ozow[index].logo} color={selected ? undefined : colors.grey.gunSmoke } />
              </LogoContainer>
            </For>
          </If>

          <If condition={paymentMethod.id === 'banktransfer'}>
            <For each='logo' index='index' of={paymentLogos.bankTransfer}>
              <LogoContainer key={index} className={paymentLogos.bankTransfer[index].large ? 'large-logo' : ''}>
                <Icon icon={paymentLogos.bankTransfer[index].logo} color={selected ? undefined : colors.grey.gunSmoke } />
              </LogoContainer>
            </For>
          </If>
        </LogosContainer>
      </ContentContainer>
    </Container>
  )

}
