import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { Divider, Skeleton, SkeletonNode } from '@atoms/layout'
import { Link, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBannerFragment, useGetAppQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { DeviceTypeEnum, PageBannerLayoutEnum, PageLink } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { HTML } from './HTML'

const Container = styled.div<{ layout: PageBannerLayoutEnum, bgColor: string }>`
  
    position: initial;

    @media (min-width: 30em) {
      position: relative;
    }

    @media (min-width: 30.0625em) {
      position: relative;
    }

    @media (min-width: 90em) {
      position: relative;
    }
  
  width: 100%;
  
    height: 45.000vw;

    @media (min-width: 30em) {
      height: 177.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 39.444vw;
    }

    @media (min-width: 90em) {
      height: 568px;
    }
  
  display: flex;
  ${(props): string => props.layout === PageBannerLayoutEnum.IMAGE_LEFT 
  ? ResponsivePXValue('flex-direction', { mobile: 'row', tablet: 'column', desktop: 'column' }) 
  : ResponsivePXValue('flex-direction', { mobile: 'row-reverse', tablet: 'column', desktop: 'column' })}

  
    flex-direction: row;

    @media (min-width: 30em) {
      flex-direction: column;
    }

    @media (min-width: 30.0625em) {
      flex-direction: column;
    }

    @media (min-width: 90em) {
      flex-direction: column;
    }
  
  
    align-items: center;

    @media (min-width: 30em) {
      align-items: initial;
    }

    @media (min-width: 30.0625em) {
      align-items: initial;
    }

    @media (min-width: 90em) {
      align-items: initial;
    }
  
  background-color: ${(props): string => props.bgColor};
`

const ImageContainer = styled.div`
  
    width: 43.750vw;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 100%;
    }

    @media (min-width: 90em) {
      width: 100%;
    }
  
  
    height: 100%;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  

  flex-shrink: 0;

`

const ContentContainer = styled.div<{ backgroundColor: string, foregroundColor: string, layout: PageBannerLayoutEnum, isMobile: boolean }>`
  
    position: initial;

    @media (min-width: 30em) {
      position: initial;
    }

    @media (min-width: 30.0625em) {
      position: absolute;
    }

    @media (min-width: 90em) {
      position: absolute;
    }
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  
  ${(props): string => props.layout === PageBannerLayoutEnum.IMAGE_RIGHT ? ResponsivePXValue('left', { tablet: '0', desktop: '0' }) : ResponsivePXValue('right', { tablet: '0', desktop: '0' })}
  ${(props): string => props.layout === PageBannerLayoutEnum.IMAGE_RIGHT ? ResponsivePXValue('left', { tablet: '0', desktop: '0' }) : ResponsivePXValue('right', { tablet: '0', desktop: '0' })}
  
    width: initial;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: 27.778vw;
    }

    @media (min-width: 90em) {
      width: 400px;
    }
  
  
    height: fit-content;

    @media (min-width: 30em) {
      height: initial;
    }

    @media (min-width: 30.0625em) {
      height: 33.889vw;
    }

    @media (min-width: 90em) {
      height: 488px;
    }
  
  
    margin: initial;

    @media (min-width: 30em) {
      margin: initial;
    }

    @media (min-width: 30.0625em) {
      margin: 2.778vw;
    }

    @media (min-width: 90em) {
      margin: 40px;
    }
  
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    gap: initial;

    @media (min-width: 30em) {
      gap: initial;
    }

    @media (min-width: 30.0625em) {
      gap: 2.778vw;
    }

    @media (min-width: 90em) {
      gap: 40px;
    }
  
  color: ${(props): string => props.foregroundColor};
  background-color: ${(props): string => props.backgroundColor};
  
    border-radius: 1.250vw;

    @media (min-width: 30em) {
      border-radius: initial;
    }

    @media (min-width: 30.0625em) {
      border-radius: initial;
    }

    @media (min-width: 90em) {
      border-radius: initial;
    }
  
  
    flex: 1;

    @media (min-width: 30em) {
      flex: initial;
    }

    @media (min-width: 30.0625em) {
      flex: initial;
    }

    @media (min-width: 90em) {
      flex: initial;
    }
  

  .mobileTitle {
    
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: initial;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: initial;
    }

    @media (min-width: 90em) {
      margin-bottom: initial;
    }
  
  }

  .divider {
    ${(props): string => props.isMobile 
    && ResponsivePXValue('display', { mobile: 'none' })}
  }

`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

export interface PageFullImageBannerProps {
  banner: PageBannerFragment
}

export function PageFullImageBanner({ banner }: PageFullImageBannerProps): JSX.Element {

  const theme = useTheme()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum
  const secondaryBackgroundColor = banner.secondaryBackgroundColor || theme.colors.white.pureWhite


  if (!banner) {
    return (
      <Skeleton color='pureWhite' justify='flex-start' padding={{ mobile: '10px', tablet: '24px', desktop: '24px' }} gap='24px'>
        <SkeletonNode
          color='gallery'
          shape='rounded'
          height={{ mobile: '320px', tablet: '488px', desktop: '400px' }}
          width={{ mobile: '100%', tablet: '488px', desktop: '400px' }}
        />
        <Skeleton loop={8} direction='column' justify='space-between' align='flex-start'>
          <SkeletonNode
            color='gallery'
            shape='rounded'
            height={{ mobile: '24px', tablet: '24px', desktop: '24px' }}
            variWidth={{ mobile: '50%-90%', tablet: '50%-90%', desktop: '50%-90%' }}/>
        </Skeleton>
      </Skeleton>
    )
  }

  const buttonColors = {
    color: banner.foregroundColor,
    backgroundColor: banner.backgroundColor,
    borderColor: banner.foregroundColor,
    hoverColor: banner.foregroundColor,
    hoverBackgroundColor: banner.backgroundColor,
    hoverBorderColor: banner.foregroundColor,
    disabledColor: SiteHelper.getOpaqueColor(banner.foregroundColor, 0.45),
    disabledBackgrondColor: banner.backgroundColor,
    disabledBorderColor: SiteHelper.getOpaqueColor(banner.foregroundColor, 0.45),
  }

  let link: PageLink
  let index: number
  
  const titleAlignment = banner.titleAlignment === 'LEFT'
    ? 'left'
    : banner.titleAlignment === 'RIGHT'
      ? 'right'
      :'center'

  const subTtitleAlignment = banner.subTitleAlignment === 'LEFT'
    ? 'left'
    : banner.subTitleAlignment === 'RIGHT'
      ? 'right'
      : 'center'

  return (
    <Container layout={banner.layout} bgColor={secondaryBackgroundColor}>
      <ImageContainer>
        <DeviceContainer desktop tablet>
          <ResponsiveImage image={banner.image} objectFit='cover' />
        </DeviceContainer>
        <DeviceContainer mobile>
          <ResponsiveImage image={banner.mobileImage ? banner.mobileImage : banner.image} objectFit='cover' />
        </DeviceContainer>
      </ImageContainer>
      <ContentContainer foregroundColor={banner.foregroundColor} backgroundColor={banner.backgroundColor} layout={banner.layout} isMobile={appData.app.deviceType === MOBILE}>
        <DeviceContainer desktop tablet>
          <Title variant='t1' className='title' align={titleAlignment} color={banner.foregroundColor}>{banner.title}</Title>
        </DeviceContainer>
        <DeviceContainer mobile>
          <Paragraph variant='p2' color={banner.foregroundColor} align={titleAlignment} className='mobileTitle' bold>{banner.title}</Paragraph>
        </DeviceContainer>
        <Divider color={banner.foregroundColor} className='divider'/>

        <If condition={!!banner.subTitle}>
          <DeviceContainer desktop tablet>
            <Title variant='t8' className='sub-title' align={subTtitleAlignment} color={banner.foregroundColor}>{banner.subTitle}</Title>
          </DeviceContainer>
        </If>
        <If condition={!!banner.description}>
          <HTML color={banner.foregroundColor}>
            {banner.description}
          </HTML>
        </If>
        <ButtonContainer>
          <DeviceContainer desktop tablet>
            <For each='link' index='index' of={banner.links || []}>
              <Button title={link.title} href={link.url} key={index} colors={buttonColors} />
            </For>
          </DeviceContainer>
          <DeviceContainer mobile>
            <For each='link' index='index' of={banner.links || []}>
              <Link href={link.url} key={index} decoration='underline' color={banner.foregroundColor}>
                {link.title}
              </Link>
            </For>
          </DeviceContainer>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )

}
