import React from 'react'

import loadable from '@loadable/component'
import { LoadingContainer } from '@atoms/misc'
import { Loader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/MyFtnShop')

const MyFtnShop = loadable(() => import(/* webpackChunkName: "myFtnShop" */'@pages/MyFtnShop'), {
  resolveComponent: (components) => components.MyFtnShop,
})

export function MyFtnShopAsync(): JSX.Element {

  return <MyFtnShop fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
