import { APP_DEFAULT_STATE } from "@api/local/AppPlugin"
import { PageBlockFragment, useGetAppQuery } from "@hooks/api"
import React from "react"
import { HTML } from "./HTML"
import { Link } from "@atoms/typography/Link"
import styled from "styled-components"
import { ResponsivePXValue } from "@components/Theme"
import { MobileOSTypeEnum } from "@uctypes/api/globalTypes"

const Container = styled.div<{ bgColor: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
    width: 100%;
    background-color: ${(props): string => props.bgColor};
    
    padding: 6.250vw 3.750vw 6.250vw 3.750vw;

    @media (min-width: 30em) {
      padding: 6.250vw 3.750vw 6.250vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.389vw 0.833vw 1.389vw 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 20px 12px 20px 12px;
    }
  
`

interface PageMobileAppMessageBlockProps {
    block: PageBlockFragment
}

export function PageMobileAppMessageBlock({ block }: PageMobileAppMessageBlockProps): JSX.Element {
    
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isApp = appData.app.isNativeApp
    return (
        <If condition={isApp}>
            <Container bgColor={block.backgroundColor}>
                <HTML color={block.foregroundColor} align='center'>
                    {block.description}
                </HTML>
                <Link href={block.link.url} bold decoration='underline' color={block.foregroundColor}>
                    {block.link.title}
                </Link>
            </Container>
        </If>
    )
}