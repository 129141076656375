import React, { } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Heading, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageProductSliderSectionFragment, ProductListFragment, useGetAppQuery } from '@hooks/api/index'
import { ProductSlider } from '@organisms/index'
import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { DeviceTypeEnum } from '@simple/admin-ui/build/types/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
    gap: initial;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  width: 100%;

  .block-title {
    
    margin: 0 3.750vw;

    @media (min-width: 30em) {
      margin: 0 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }

  .block-paragraph {
    
    margin: 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }

`

export interface PageProductSliderSectionProps {
  section: PageProductSliderSectionFragment
}

export function PageProductSliderSection({ section }: PageProductSliderSectionProps): JSX.Element {

  const navigate = useNavigate()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const _handleViewAll = () => {
    navigate(section.category.canonicalUrl)
  }

  const titleAlignment = section.titleAlignment === 'LEFT'
    ? 'left'
    : section.titleAlignment === 'RIGHT'
      ? 'right'
      :'center'
  const category = section.category
  const products = category?.products?.items || section.products || []
  const totalCount = category ? category.products.totalCount : products.length

  return (
    <Container>
      <If condition={!!section.title}>
        <Title 
          variant={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 't3' : 't1'} 
          className='block-title' 
          align={titleAlignment}>
          {section.title}
        </Title>
      </If>
      <ProductSlider
        showCount={false}
        title={section.subTitle}
        total={totalCount}
        onViewAll={category ? _handleViewAll : undefined}
        products={products as ProductListFragment[]} />
    </Container>
  )

}
