import React, { MouseEventHandler, useRef } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'
import { useLinkClickHandlers } from '@hooks/UseLinkClickHandlers'

const Container = styled.div<{ color?: string, align: 'left' | 'right' | 'center' }>`
  text-align: ${(props): string => props.align ? props.align : 'left'};
  width: 100%;

  > * {
    color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  }

  p {
    font-family: open-sans;
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
    
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
  }

  iframe {
    width: 100%;
  }
`

export interface HTMLProps {
  children: string
  color?: string
  className?: string
  align?: 'left' | 'right' | 'center'
}

export function HTML({ children, color, className, align }: HTMLProps): JSX.Element {

  const ref = useRef<HTMLDivElement>(null)
  useLinkClickHandlers(ref)

  return (
    <Container
      className={className}
      align={align}
      color={color}
      dangerouslySetInnerHTML={{ __html: children }}
      ref={ref} />
  )

}
