import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Paragraph, ResponsiveImage } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ProductListImageFragment } from '@hooks/api'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 3.750vw 10.000vw 3.750vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 2.222vw 0.833vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 12px 32px 12px 16px;
    }
  

`

const ImageContainer = styled.div`
  
    width: 27.500vw;

    @media (min-width: 30em) {
      width: 29.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 6.528vw;
    }

    @media (min-width: 90em) {
      width: 94px;
    }
  
  
    height: 27.500vw;

    @media (min-width: 30em) {
      height: 29.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 6.528vw;
    }

    @media (min-width: 90em) {
      height: 94px;
    }
  
  
    margin: 0.000vw 3.750vw;

    @media (min-width: 30em) {
      margin: 0.000vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.000vw 0.833vw;
    }

    @media (min-width: 90em) {
      margin: 0px 12px;
    }
  
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  flex-shrink: 0;

`

const InfoContainer = styled.div`
  display: flex;
  
    flex-direction: row;

    @media (min-width: 30em) {
      flex-direction: column;
    }

    @media (min-width: 30.0625em) {
      flex-direction: column;
    }

    @media (min-width: 90em) {
      flex-direction: column;
    }
  
  justify-content: center;
  
    justify-content: space-between;

    @media (min-width: 30em) {
      justify-content: flex-end;
    }

    @media (min-width: 30.0625em) {
      justify-content: flex-end;
    }

    @media (min-width: 90em) {
      justify-content: flex-end;
    }
  
  
    align-items: self-end;

    @media (min-width: 30em) {
      align-items: flex-end;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-end;
    }

    @media (min-width: 90em) {
      align-items: flex-end;
    }
  
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  overflow-wrap: break-word;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
  
    padding: 0.000vw 1.875vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  

  .title {
    
    flex-grow: 1;

    @media (min-width: 30em) {
      flex-grow: initial;
    }

    @media (min-width: 30.0625em) {
      flex-grow: initial;
    }

    @media (min-width: 90em) {
      flex-grow: initial;
    }
  
    
    width: 100%;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  }
`

export interface OrderItemPanelProps {
  name: string
  quantity: number
  slug: string
  coverImage: ProductListImageFragment
  price: string
}

export function OrderItemPanel({ name, quantity, slug, coverImage, price }: OrderItemPanelProps): JSX.Element {
  const navigate = useNavigate()

  const _handleLink = (href: string): void => {
    navigate(`/${href}`)
  }

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={coverImage} objectFit='contain' />
      </ImageContainer>
      <InfoContainer>
        <Paragraph variant='p1' bold={true} className='title' onClick={() => _handleLink(slug)}>
          {name}
        </Paragraph>
        <Paragraph variant='p1'>
          Qty: {quantity}
        </Paragraph>
        <Paragraph variant='p1' bold={true}>
          {price}
        </Paragraph>
      </InfoContainer>
    </Container>
  )

}
