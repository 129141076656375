import React from 'react'

import styled from 'styled-components'

import { Skeleton } from '@atoms/layout/Skeleton'
import { ResponsivePXValue } from '@components/Theme'
import { ProductCard } from '@molecules/store/ProductCard'

const Container = styled.div`
  width: 100%;
  position: relative;
  
    overflow: hidden;

    @media (min-width: 30em) {
      overflow: initial;
    }

    @media (min-width: 30.0625em) {
      overflow: initial;
    }

    @media (min-width: 90em) {
      overflow: initial;
    }
  
  
    padding: 3.750vw 0 3.750vw 5.000vw;

    @media (min-width: 30em) {
      padding: 0 ;
    }

    @media (min-width: 30.0625em) {
      padding: 0;
    }

    @media (min-width: 90em) {
      padding: 0;
    }
  
  
    margin-top: initial;

    @media (min-width: 30em) {
      margin-top: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-top: 8px;
    }
  

  .mobile-all {
    
    margin: 5.000vw 0 0;

    @media (min-width: 30em) {
      margin: 5.000vw 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0 0;
    }

    @media (min-width: 90em) {
      margin: 16px 0 0;
    }
  
    
    width: CALC(100% - 5.000vw);

    @media (min-width: 30em) {
      width: CALC(100% - 5.000vw);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100% - 1.111vw);
    }

    @media (min-width: 90em) {
      width: CALC(100% - 16px);
    }
  
  }
`

const Spacer = styled.div`
  position: relative;
`

const Header = styled.div`

  display: flex;
  align-items: center;
  
    mobile: flex-start;

    @media (min-width: 30em) {
      mobile: space-between;
    }

    @media (min-width: 30.0625em) {
      mobile: space-between;
    }

    @media (min-width: 90em) {
      mobile: space-between;
    }
  
  
    height: 17.500vw;

    @media (min-width: 30em) {
      height: 17.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.889vw;
    }

    @media (min-width: 90em) {
      height: 56px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  

  
    margin: 0 0 1.875vw;

    @media (min-width: 30em) {
      margin: 0 0 1.875vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 1.111vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 16px 0;
    }
  

  .seperator {
    flex-grow: 1;
    position: revert;
    
    display: none;

    @media (min-width: 30em) {
      display: none;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  }
  .filter-title {
    margin: 0;
    
    flex-shrink: initial;

    @media (min-width: 30em) {
      flex-shrink: initial;
    }

    @media (min-width: 30.0625em) {
      flex-shrink: 0;
    }

    @media (min-width: 90em) {
      flex-shrink: 0;
    }
  
  }
  .see-all {
    flex-shrink: 0;
    
    display: none;

    @media (min-width: 30em) {
      display: none;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  }

`

const CardContainer = styled.div`
  position: relative;
  display: flex;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  overflow: hidden;
`

export interface ProductSliderSkeletonProps {
  productCount: number
}

export function ProductSliderSkeleton({ productCount }: ProductSliderSkeletonProps): JSX.Element {

  let cardIndex: number

  return (
    <Container>
      <Header>
        <Skeleton
          height={{ mobile: '30px', tablet: '30px', desktop: '30px' }}
          padding={{ mobile: '8px', tablet: '8px', desktop: '8px' }}
          color='gallery'
          direction='row'
          align='flex-start'/>
      </Header>
      <Spacer/>
      <CardContainer>
        <For each='cardIndex' of={Array.from(Array(productCount).keys())}>
          <ProductCard key={cardIndex} />
        </For>
      </CardContainer>
    </Container>
  )

}
