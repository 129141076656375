import React from 'react'

import convert from 'color-convert'
import styled from 'styled-components'

import { Icon, LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { Card } from '@atoms/layout/Card'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useActiveWidgetsQuery } from '@hooks/api'
import { SiteHelper } from '@lib/SiteHelper'
import { HTML } from '@molecules/content/HTML'

const Container = styled.div`

    margin: 4.688vw 0;

    @media (min-width: 30em) {
      margin: 4.688vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.042vw 0;
    }

    @media (min-width: 90em) {
      margin: 15px 0;
    }
  
  .card {
    
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 12px;
    }
  
  }
`

const Content = styled.div`

  display: flex;
  align-items: center;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  

  .html {
    width: fit-content;
    max-width: 100%;
    p:last-child {
      margin-bottom: 0;
    }
  }

`

const IconContainer = styled.div`
  flex-shrink: 0;
  
    width: 10.938vw;

    @media (min-width: 30em) {
      width: 10.938vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.431vw;
    }

    @media (min-width: 90em) {
      width: 35px;
    }
  
  
    height: 10.938vw;

    @media (min-width: 30em) {
      height: 10.938vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.431vw;
    }

    @media (min-width: 90em) {
      height: 35px;
    }
  
`

export function UpSellWidget(): JSX.Element {

  const { data } = useActiveWidgetsQuery()

  return (
    <If condition={!!data?.activeWidgets?.productDetailsAddToCart}>
      <Container>
        <Card backgroundColor={data.activeWidgets.productDetailsAddToCart.contentBackgroundColor} className='card'>
          <Content>
            <IconContainer>
              <ResponsiveImage image={data.activeWidgets.productDetailsAddToCart.icon} />
            </IconContainer>
            <HTML className='html' color={data.activeWidgets.productDetailsAddToCart.contentForegroundColor}>{data.activeWidgets.productDetailsAddToCart.content}</HTML>
          </Content>
        </Card>
      </Container>
    </If>

  )

}
