
import React from 'react'

import loadable from '@loadable/component'

import { Loader, PageLoader } from '@atoms/notifications'
import { LoadingContainer } from '@atoms/misc'

import(/* webpackPrefetch: true */ '@pages/Home')

const Home = loadable(() => import(/* webpackChunkName: "home" */'@pages/Home'), {
  resolveComponent: (components) => components.Home,
})

export function HomeAsync(): JSX.Element {

  return <Home fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
