import React, { useEffect, useMemo, useRef } from 'react'

import { decode } from 'html-entities'
import { useSearchParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Rating } from '@atoms/controls'
import { Icon, LocalIconEnums } from '@atoms/index'
import { Card, Seperator, Skeleton, SkeletonNode } from '@atoms/layout'
import { Heading, Link, Paragraph, Tag } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { ProductAttributeFragment, ProductDetailsFragment, ProductIngredientFragment, WarehouseFragment } from '@hooks/api'
import AttributeHelper from '@lib/AttributeHelper'
import { SiteHelper } from '@lib/SiteHelper'
import { HTML } from '@molecules/content'
import { ProductReviews } from '@molecules/store'
import { ProductBrand, ProductImage, ProductStock, ProductValueAttributes } from '@organisms/product'

const Content = styled.div`
  
    padding-bottom: 0;

    @media (min-width: 30em) {
      padding-bottom: initial;
    }

    @media (min-width: 30.0625em) {
      padding-bottom: initial;
    }

    @media (min-width: 90em) {
      padding-bottom: initial;
    }
  
  .product-info-card {
    
    margin-top: 0;

    @media (min-width: 30em) {
      margin-top: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-top: 16px;
    }
  
    
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw 7.500vw 5.000vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw 1.667vw 1.111vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px 24px 16px 24px;
    }
  
  }
  .product-detail-card {
    
    padding: 0 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw 7.500vw 5.000vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw 1.667vw 1.111vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px 24px 16px 24px;
    }
  
  }
  .description-title {
    
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 8px;
    }
  
  }
  .short-description-title {
    
    margin-bottom: 2.500vw;

    @media (min-width: 30em) {
      margin-bottom: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 8px;
    }
  
  }
`

const ProductReviewsContainer = styled.div``

const InfoContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  
    padding-top: 5.000vw;

    @media (min-width: 30em) {
      padding-top: 0;
    }

    @media (min-width: 30.0625em) {
      padding-top: 0;
    }

    @media (min-width: 90em) {
      padding-top: 0;
    }
  
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  .text-item {
      
    margin-right: initial;

    @media (min-width: 30em) {
      margin-right: initial;
    }

    @media (min-width: 30.0625em) {
      margin-right: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-right: 5px;
    }
  
  }
`

const ContentContainer = styled.div`
  
    display: initial;

    @media (min-width: 30em) {
      display: flex;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  flex-direction: column;
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 100%;
    }

    @media (min-width: 90em) {
      width: 100%;
    }
  
  
  .title {
    
    margin: 0 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 0 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 12px 0;
    }
  
  }
`

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  border-style: solid;
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.472vw;
    }

    @media (min-width: 90em) {
      height: 50px;
    }
  
  
    padding: 5.000vw 0 5.000vw 0;

    @media (min-width: 30em) {
      padding: 5.000vw 0 5.000vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 0 1.111vw 0;
    }

    @media (min-width: 90em) {
      padding: 16px 0 16px 0;
    }
  
  
    gap: 1.875vw;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
  
    border-bottom-width: 0.313vw;

    @media (min-width: 30em) {
      border-bottom-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 1px;
    }
  
  border-color: ${(props): string => props.theme.colors.grey.athens};
`

const PointsContainer = styled.div`
  display: flex;
  align-items: center;
  
    gap: 6.250vw;

    @media (min-width: 30em) {
      gap: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.389vw;
    }

    @media (min-width: 90em) {
      gap: 20px;
    }
  
  .logo {
    
    height: 6.875vw;

    @media (min-width: 30em) {
      height: 6.875vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.528vw;
    }

    @media (min-width: 90em) {
      height: 22px;
    }
  
    
    width: 6.875vw;

    @media (min-width: 30em) {
      width: 6.875vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.528vw;
    }

    @media (min-width: 90em) {
      width: 22px;
    }
  
  }
`

const Strong = styled.span`
  font-weight: 700;
  
    position: relative;

    @media (min-width: 30em) {
      position: initial;
    }

    @media (min-width: 30.0625em) {
      position: initial;
    }

    @media (min-width: 90em) {
      position: initial;
    }
  
  
    right: 1.563vw;

    @media (min-width: 30em) {
      right: initial;
    }

    @media (min-width: 30.0625em) {
      right: initial;
    }

    @media (min-width: 90em) {
      right: initial;
    }
  
  
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: initial;
    }

    @media (min-width: 30.0625em) {
      line-height: initial;
    }

    @media (min-width: 90em) {
      line-height: initial;
    }
  
`

const SizeContainer = styled.div`
  border-style: solid;
  display: flex;
  align-items: center;
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 16.875vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.750vw;
    }

    @media (min-width: 90em) {
      height: 54px;
    }
  
  
    border-bottom-width: 0.313vw;

    @media (min-width: 30em) {
      border-bottom-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 1px;
    }
  
  border-color: ${(props): string => props.theme.colors.grey.athens};
`

const MarkupContainer = styled.div``

export interface ProductInformationProps {
  product: ProductDetailsFragment
  children?: React.ReactNode
}

export const ProductInformation = React.memo(
  function ProductInformation({ product, children }: ProductInformationProps): JSX.Element {

    const theme = useTheme()
    const reviewsRef: React.RefObject<HTMLDivElement> = useRef(null)
    const [searchParams] = useSearchParams()
    const section = searchParams.get('section')

    const attributes = useMemo(() => AttributeHelper.shared().getProductAttributes(product?.attributes || []), [product?.attributes])
    const rewardAmount = product?.amastyRewardsHighlights?.captionText || product?.amastyRewardsGuestHighlights?.captionText

    const _handleScroll = (): void => reviewsRef?.current?.scrollIntoView({ behavior: 'smooth' })

    let ingredientIndex: number
    let productAttribute: ProductAttributeFragment
    let productAttributeIndex: number
    let productIngredient: ProductIngredientFragment
    let warehouse!: WarehouseFragment

    useEffect(() => {

      const reviews = searchParams.get('section')
      !!reviews && reviewsRef.current && _handleScroll()

    }, [reviewsRef, searchParams])

    return (
      <>
        <Content>
          <Card className='product-detail-card'>
            <InfoContainer>

              <ProductImage
                gallery={product?.imageGallery || []}
                labels={product?.labels || []}
                product={product}
              />

              <ContentContainer>
                <Choose>
                  <When condition={!product?.name}>
                    <Skeleton className='title' direction='column' justify='flex-start' align='flex-start' height='auto'>
                      <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
                        height={{ mobile: '24px', tablet: '24px', desktop: '24px' }}
                        variWidth={{ mobile: '30%-45%', tablet: '25%', desktop: '20%-50%' }}
                      />
                    </Skeleton>
                  </When>
                  <Otherwise>
                    <Heading className='title' variant='h9' >{decode(product?.name)}</Heading>
                  </Otherwise>
                </Choose>

                <ProductBrand brand={product?.brand} />

                <RatingContainer>
                  <Rating rating={product?.ratingSummary.averageRating || 0} size='large' />
                  <Tag variant='t1' color={theme.colors.grey.stormDust}>({product?.reviewCount || 0})</Tag>
                </RatingContainer>

                <If condition={product?.amastyRewardsHighlights?.visible || product?.amastyRewardsGuestHighlights?.visible}>
                  <PointsContainer>
                    <Icon icon={LocalIconEnums.LOGO} color={theme.colors.green.watercourse} className='logo'/>
                    <Tag variant='t2' color={theme.colors.green.watercourse}> {`You will earn ${rewardAmount} Faithful Point for purchasing this product.`}</Tag>
                  </PointsContainer>
                </If>

                <For each='productAttribute' of={attributes} index='productAttributeIndex'>
                  <SizeContainer key={productAttributeIndex}>
                    <Tag variant='t2' color={theme.colors.green.bottleGreen}><Strong>{productAttribute.label}:</Strong> {productAttribute.value}</Tag>
                  </SizeContainer>
                </For>

                <If condition={product?.__typename === 'SimpleProduct'}>
                  <ProductStock
                    sources={product?.stockInfo?.sources || []}
                    stockStatus={product?.stockStatus}
                    warehouse={warehouse}
                  />
                </If>

                <If condition={!!product?.valueAttributes && !!product?.valueAttributes?.length}>
                  <ProductValueAttributes
                    valueAttributes={product?.valueAttributes}
                  />
                </If>

              </ContentContainer>
            </InfoContainer>
          </Card>

          <Seperator align='horizontal' />

          <If condition={!!children}>
            {children}
          </If>

          <If condition={!!product?.shortDescription}>
            <Card className='product-info-card'>
              <Heading variant='h5' className='short-description-title' color={theme.colors.black.pureBlack}>
              Why we love it
              </Heading>
              <Choose>
                <When condition={SiteHelper.isHTML(product?.shortDescription?.html)}>
                  <HTML>
                    {product?.shortDescription.html}
                  </HTML>
                </When>
                <Otherwise>
                  <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
                    <MarkupContainer style={{ whiteSpace: 'pre-line' }}>
                      {product?.shortDescription?.html}
                    </MarkupContainer>
                  </Paragraph>
                </Otherwise>
              </Choose>
            </Card>
          </If>

          <Seperator align='horizontal' />

          <If condition={!!product?.description}>
            <Card className='product-info-card'>
              <Heading variant='h5' className='description-title' color={theme.colors.black.pureBlack}>
              Description
              </Heading>
              <Choose>
                <When condition={SiteHelper.isHTML(product.description.html)}>
                  <HTML>
                    {product?.description.html}
                  </HTML>
                </When>
                <Otherwise>
                  <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
                    <MarkupContainer style={{ whiteSpace: 'pre-line' }}>
                      {product?.description.html}
                    </MarkupContainer>
                  </Paragraph>
                </Otherwise>
              </Choose>
            </Card>
          </If>

          <If condition={!!product?.ingredientList?.length}>
            <Card className='product-info-card'>
              <Heading variant='h5' className='description-title' color={theme.colors.black.pureBlack}>
              Learn more about these ingredients ({product?.ingredientList.length})
              </Heading>
              <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
                <For each='productIngredient' of={product?.ingredientList || []} index='ingredientIndex'>
                  <div key={ingredientIndex}>
                    <Choose>
                      <When condition={productIngredient.urlKey !== null}>
                        <Link href={`/ingredient/${productIngredient.urlKey}`} bold={false} display='inline-block'>{productIngredient.name}</Link>
                      </When>
                      <Otherwise>
                        {productIngredient.name}
                      </Otherwise>
                    </Choose>
                    <If condition={ingredientIndex < product?.ingredientList.length - 1}>
                      {', '}
                    </If>
                  </div>
                </For>
              </Paragraph>
            </Card>
          </If>
        </Content>

        <ProductReviewsContainer ref={reviewsRef}>
          <ProductReviews product={product} />
        </ProductReviewsContainer>
      </>
    )

  },
)
