import React, { useState } from 'react'

import styled from 'styled-components'

import { LayoutGrid, LayoutRow, LayoutCol } from '@atoms/layout'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { BreadcrumbFragment, SpecialsCategoryFragment, SpecialsChildCategoryFragment, PageSectionGroupFragment } from '@hooks/api'
import { BreadCrumbs, BreadCrumb } from '@molecules/navigation'
import { SpecialCard } from '@molecules/store/SpecialCard'
import { PageSectionGroup } from '@organisms/content'
import { SearchEngineOptimizationParams, SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const Container = styled.div`
  .content-row {
    
    gap: 0;

    @media (min-width: 30em) {
      gap: initial;
    }

    @media (min-width: 30.0625em) {
      gap: initial;
    }

    @media (min-width: 90em) {
      gap: initial;
    }
  
  }
  .main-heading {
    
    margin: 3.750vw;

    @media (min-width: 30em) {
      margin: 10.000vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 2.222vw 0;
    }

    @media (min-width: 90em) {
      margin: 32px 0;
    }
  
  }
`

const SectionContainer = styled.div`
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 2.222vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 32px;
    }
  
`

const SpecialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.389vw;
    }

    @media (min-width: 90em) {
      gap: 20px;
    }
  
  
    padding: 1.250vw;

    @media (min-width: 30em) {
      padding: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.417vw;
    }

    @media (min-width: 90em) {
      padding: 6px;
    }
  
  
    margin-bottom: 6.250vw;

    @media (min-width: 30em) {
      margin-bottom: 62.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 13.889vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 200px;
    }
  
`

export interface SpecialsProps {
  category: SpecialsCategoryFragment
  loading: boolean
}

interface SpecialsState {

}

const DEFAULT_STATE: SpecialsState = {}

export const Specials = React.memo(
  function Specials({ category }: SpecialsProps): JSX.Element {

    const [state, setState] = useState<SpecialsState>({ ...DEFAULT_STATE })

    let breadCrumb!: BreadcrumbFragment
    let section!: PageSectionGroupFragment
    const hasContent = !!category?.pageContent

    const seo: SearchEngineOptimizationParams = {
      name: 'Faithful to Nature Specials',
      title: 'Faithful to Nature Specials',
      meta: [], // <---- TODO
    }

    let special: SpecialsChildCategoryFragment

    return (
      <Container>
        <SearchEngineOptimization seo={seo} />
        <LayoutGrid>
          <LayoutRow>
            <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
              <BreadCrumbs>
                <BreadCrumb title='Home' href='/' />
                <For each='breadCrumb' of={category?.breadcrumbs || []}>
                  <BreadCrumb title={breadCrumb.categoryName} key={breadCrumb.categoryUrlKey} href={`/${breadCrumb.categoryUrlPath}`} />
                </For>
                <BreadCrumb title={category?.name} href={`/${category?.canonicalUrl}`} />
              </BreadCrumbs>
            </LayoutCol>
          </LayoutRow>
          <LayoutRow>
            <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
              <Choose>
                <When condition={hasContent}>
                  <SectionContainer>
                    <For each='section' of={category.pageContent.content.sectionGroups}>
                      <PageSectionGroup pageSectionGroup={section} key={section.id} />
                    </For>
                  </SectionContainer>
                </When>
                <Otherwise>
                  <Heading variant='h2' className='main-heading'>{category.name}</Heading>
                </Otherwise>
              </Choose>
            </LayoutCol>
          </LayoutRow>
          <LayoutRow className='content-row'>
            <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
              <SpecialsContainer>
                <For each='special' of={category.children}>
                  <SpecialCard category={special} />
                </For>
              </SpecialsContainer>
            </LayoutCol>
          </LayoutRow>
        </LayoutGrid>
      </Container>
    )

  },
)
