import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Link, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'

const Container = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  aspect-ratio : 1 / 1;
  
    width: CALC(100% - 7.500vw);

    @media (min-width: 30em) {
      width: CALC(100% - 7.500vw);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100% - 1.667vw);
    }

    @media (min-width: 90em) {
      width: CALC(100% - 24px);
    }
  
  
    margin: 3.750vw 0;

    @media (min-width: 30em) {
      margin: 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 12px 0;
    }
  
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  .block-title {
    max-width: 100%;
  }
  a {
    white-space: normal;
    margin: 0 auto;
  }

`

export interface PageBrandBlockProps {
  block: PageBlockFragment
}

export function PageBrandBlock({ block }: PageBrandBlockProps): JSX.Element {

  const { href, image, foregroundColor, title } = block

  const navigate = useNavigate()

  return (
    <Container
      onClick={() => href && navigate(href)}>
      <ImageContainer>
        <ResponsiveImage image={image} objectFit='contain' aspectRatio='1/1' />
      </ImageContainer>
      <ContentContainer>
        <Title variant='t8' align='center' className='block-title' color={foregroundColor}>
          <Link 
            href={href || '#'} 
            bold 
            decoration='underline'>
              {title}
          </Link>
        </Title>
      </ContentContainer>
    </Container>
  )

}
