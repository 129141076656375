import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Link, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const BreadCrumbElement = styled.div`
  
    margin: 0 1.875vw;

    @media (min-width: 30em) {
      margin: 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 0 8px;
    }
  
  
    font-size: 2.500vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  

  font-weight: 400;
  font-family: open-sans;
  &:hover {
    .breadcrumb-link {
      color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
  }
`
const Wrapper = styled.div``
export interface BreadCrumbProps {
  title: React.ReactNode
  href?: string | boolean
  color?: string
  onClick?: () => void
}

export function BreadCrumb({ title, href, color, onClick }: BreadCrumbProps): JSX.Element {

  const theme = useTheme()

  if (!color) {
    color = theme.colors.grey.stormDust
  }

  return (
    <BreadCrumbElement>
      <If condition={!!href}>
        <Link href={href as string} color={color} className='breadcrumb-link' onClick={onClick}>{title}</Link>
      </If>
      <If condition={!href}>
        <Paragraph variant='p3' color={color} className='breadcrumb-link' onClick={onClick}>{title}</Paragraph>
      </If>
    </BreadCrumbElement>
  )

}

const Container = styled.div`
  display: flex;
  align-items: center;
  
    height: 11.875vw;

    @media (min-width: 30em) {
      height: 19.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.306vw;
    }

    @media (min-width: 90em) {
      height: 62px;
    }
  
  overflow-x: scroll;
  > * {
    &:first-child {
      
    margin-left: 2.500vw;

    @media (min-width: 30em) {
      margin-left: 0;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0;
    }

    @media (min-width: 90em) {
      margin-left: 0;
    }
  
    }

    &:last-child {
      
    margin-right: 7.500vw;

    @media (min-width: 30em) {
      margin-right: 0;
    }

    @media (min-width: 30.0625em) {
      margin-right: 0;
    }

    @media (min-width: 90em) {
      margin-right: 0;
    }
  
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  --mask: linear-gradient(to right, 
    rgba(0,0,0, 1) 0,   
    rgba(0,0,0, 1) 90%, 
    rgba(0,0,0, 0) 95%, 
    rgba(0,0,0, 0) 0
  ) 100% 50% / 100% 100% repeat-x;

  -webkit-mask: var(--mask); 
  mask: var(--mask);

`

const Seperator = styled.div`
  font-family: open-sans;
  font-weight: 400;
  color: ${(props): string => props.theme.colors.grey.stormDust};
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
  
    margin-top: 0;

    @media (min-width: 30em) {
      margin-top: 0.938vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.208vw;
    }

    @media (min-width: 90em) {
      margin-top: 3px;
    }
  

`

export interface BreadCrumbsProps {
  children: JSX.Element[] | JSX.Element
  className?: string
}

export function BreadCrumbs({ children, className }: BreadCrumbsProps): JSX.Element {
  const $children: JSX.Element[] = []
  const $flattened = (Array.isArray(children) ? children.flat() : [children])
    .filter((child) => child)
  React.Children.forEach($flattened, ($child, index) => {
    $children.push(React.cloneElement($child, { ...$child.props, key: index }))
    if (index < $flattened.length - 1) {
      $children.push((
        <Wrapper key={`sep-${index}`} >
          <Seperator>&gt;</Seperator>
        </Wrapper>
      ))
    }
  })

  return (
    <Container className={className}>
      {$children}
    </Container>
  )

}
