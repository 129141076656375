import { createGlobalStyle } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export const GlobalStyle = createGlobalStyle`
  
  ol {
    list-style-type: decimal;
    
    margin-bottom: 3.750vw;

    @media (min-width: 30em) {
      margin-bottom: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 12px;
    }
  
  }

  ul {
    list-style-type: disc;
    
    margin-bottom: 3.750vw;

    @media (min-width: 30em) {
      margin-bottom: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 12px;
    }
  
  }

  li {
    display: list-item;
    font-family: open-sans;
    font-weight: 400;
    color: ${(props): string => props.theme.colors.green.bottleGreen};
    
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
    
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
    
    margin-left: 6.250vw;

    @media (min-width: 30em) {
      margin-left: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 1.389vw;
    }

    @media (min-width: 90em) {
      margin-left: 20px;
    }
  
  }

  h1 {
    font-family: proxima-nova;
    font-weight: 600;
    
    font-size: 6.250vw;

    @media (min-width: 30em) {
      font-size: 11.875vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 2.639vw;
    }

    @media (min-width: 90em) {
      font-size: 38px;
    }
  
    
    line-height: 8.750vw;

    @media (min-width: 30em) {
      line-height: 13.750vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 3.056vw;
    }

    @media (min-width: 90em) {
      line-height: 44px;
    }
  
  }

  h2 {
    font-family: open-sans;
    font-weight: 700;
    
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.944vw;
    }

    @media (min-width: 90em) {
      font-size: 28px;
    }
  
    
    line-height: 6.875vw;

    @media (min-width: 30em) {
      line-height: 11.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.500vw;
    }

    @media (min-width: 90em) {
      line-height: 36px;
    }
  
  }

  h3 {
    font-family: proxima-nova;
    font-weight: 600;
    
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.667vw;
    }

    @media (min-width: 90em) {
      font-size: 24px;
    }
  
    
    line-height: 7.500vw;

    @media (min-width: 30em) {
      line-height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.083vw;
    }

    @media (min-width: 90em) {
      line-height: 30px;
    }
  
    
    margin: 5.000vw 0 3.750vw;

    @media (min-width: 30em) {
      margin: 7.500vw 0 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.667vw 0 0.833vw;
    }

    @media (min-width: 90em) {
      margin: 24px 0 12px;
    }
  
  }

  h4 {
    font-family: proxima-nova;
    font-weight: 600;
    
    font-size: 4.375vw;

    @media (min-width: 30em) {
      font-size: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.389vw;
    }

    @media (min-width: 90em) {
      font-size: 20px;
    }
  
    
    line-height: 6.250vw;

    @media (min-width: 30em) {
      line-height: 8.125vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.806vw;
    }

    @media (min-width: 90em) {
      line-height: 26px;
    }
  
  }

  h5 {
    font-family: open-sans;
    font-weight: 700;
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.250vw;
    }

    @media (min-width: 90em) {
      font-size: 18px;
    }
  
    
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.667vw;
    }

    @media (min-width: 90em) {
      line-height: 24px;
    }
  
  }

  a {
    cursor: pointer;
    white-space: nowrap;
    font-weight: 400;
    color: ${(props): string => props.theme.colors.blue.curiousBlue};

    &:hover {
      font-weight: 700;
      color: ${(props): string => props.theme.colors.black.pureBlack};
    }
  }

  p {
    font-family: open-sans;
    color: ${(props): string => props.theme.colors.green.bottleGreen};
    font-weight: 400;
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
    
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.667vw;
    }

    @media (min-width: 90em) {
      line-height: 24px;
    }
  
    
    margin-bottom: 3.750vw;

    @media (min-width: 30em) {
      margin-bottom: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 12px;
    }
  
  }

  strong {
    font-weight: 600;
  }

  table {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.grey.stormDust};
    border-collapse: collapse;
  }

  tr {
    border: 1px solid ${({ theme }) => theme.colors.grey.stormDust};
  }

  th, td {
    
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
    font-family: open-sans;
    color: ${(props): string => props.theme.colors.green.bottleGreen};
    font-weight: 400;
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
    
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
  }

  th {
    border: 1px solid ${({ theme }) => theme.colors.grey.stormDust};
    background: ${({ theme }) => theme.colors.grey.gallery};
  }

  td {
    border: 1px solid ${({ theme }) => theme.colors.grey.stormDust};
  }

  td:first-child, th:first-child {
    border-left: none;
  }

`
