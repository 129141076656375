import React, { useEffect, useState } from 'react'

import { BaseCategoryFragment, BreadcrumbFragment, CategoryFragment } from '@hooks/api/index'
import { ProductFilterInputs } from '@organisms/index'
import { OrderDirectionEnum } from '@uctypes/api/globalTypes'

import { ProductPage } from './ProductPage'

export enum DisplayTypeEnum {
  GRID = 'GRID',
  LIST = 'LIST',
}

export interface OrderValue { field: string, direction: OrderDirectionEnum }

export interface ProductGridProps {
  filters: ProductFilterInputs
  userFilters: ProductFilterInputs
  order?: OrderValue
  productsPerPage: number
  currentPage: number
  displayType: DisplayTypeEnum
  basePath?: string
  overideLoadingBehaviour?: boolean
  overwriteBreadCrumbs?: BreadcrumbFragment[]
  category?: CategoryFragment | BaseCategoryFragment
  term?: string
  isSpellChecked?: boolean
  onFetchMore: () => void
  onFilterChange?: (filters: ProductFilterInputs) => void
  onLoaded?: () => void
}

export const ProductGrid = React.memo(
  function ProductGrid({ filters, userFilters, order, productsPerPage, currentPage, displayType, basePath, overideLoadingBehaviour, overwriteBreadCrumbs, category, term, isSpellChecked = false, onFetchMore, onFilterChange, onLoaded }: ProductGridProps): JSX.Element {

    const [loaded, setLoaded] = useState<number>(0)

    const _handleLoaded = () => {
      setLoaded((prevLoaded) => prevLoaded + 1)
    }

    useEffect(() => {
      if (loaded === currentPage) {
        onLoaded?.()
      }
    }, [loaded])

    let page: number

    return (
      <For each='page' of={[...Array(currentPage).keys()]}>
        <ProductPage
          key={page}
          filters={filters}
          userFilters={userFilters}
          order={order}
          productsPerPage={productsPerPage}
          page={(page + 1)}
          displayType={displayType}
          basePath={basePath}
          overideLoadingBehaviour={overideLoadingBehaviour}
          overwriteBreadCrumbs={overwriteBreadCrumbs}
          onFetchMore={onFetchMore}
          onFilterChange={onFilterChange}
          onLoaded={_handleLoaded}
          isLast={currentPage === (page + 1)}
          category={category}
          term={term}
          isSpellChecked={isSpellChecked}
        />
      </For>
    )

  },
)
