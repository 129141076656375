import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { SmallLoader } from '@atoms/notifications'
import { Paragraph, Tag } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CustomerAddressFragment } from '@hooks/api/index'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};

  .desktop-row{
    width: 100%;
  }

  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    border-radius: 0;

    @media (min-width: 30em) {
      border-radius: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.694vw;
    }

    @media (min-width: 90em) {
      border-radius: 10px;
    }
  
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 4.167vw;
    }

    @media (min-width: 90em) {
      width: 60px;
    }
  
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
  
    border-radius: 3.125vw;

    @media (min-width: 30em) {
      border-radius: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.903vw;
    }

    @media (min-width: 90em) {
      border-radius: 13px;
    }
  
  ${(props): string => {
    if (props.active) {
      return `
        
    border-width: 1.250vw;

    @media (min-width: 30em) {
      border-width: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.347vw;
    }

    @media (min-width: 90em) {
      border-width: 5px;
    }
  
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    
    border-width: 0.313vw;

    @media (min-width: 30em) {
      border-width: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.139vw;
    }

    @media (min-width: 90em) {
      border-width: 2px;
    }
  
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .title {
    font-weight: 600;
  }
`

const RightContainer = styled.div`
  display: flex;
  
    align-items: flex-end;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: center;
    }

    @media (min-width: 90em) {
      align-items: center;
    }
  
  
    justify-content: space-between;

    @media (min-width: 30em) {
      justify-content: center;
    }

    @media (min-width: 30.0625em) {
      justify-content: center;
    }

    @media (min-width: 90em) {
      justify-content: center;
    }
  
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    align-self: stretch;

    @media (min-width: 30em) {
      align-self: auto;
    }

    @media (min-width: 30.0625em) {
      align-self: auto;
    }

    @media (min-width: 90em) {
      align-self: auto;
    }
  
  
    width: 40%;

    @media (min-width: 30em) {
      width: auto;
    }

    @media (min-width: 30.0625em) {
      width: auto;
    }

    @media (min-width: 90em) {
      width: auto;
    }
  
`

const AddressType = styled.div<{ active: boolean }>`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  
    padding: 1.250vw 3.125vw;

    @media (min-width: 30em) {
      padding: 0 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 0 12px;
    }
  
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 11.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.500vw;
    }

    @media (min-width: 90em) {
      height: 36px;
    }
  
  
    border-radius: 5.000vw;

    @media (min-width: 30em) {
      border-radius: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.111vw;
    }

    @media (min-width: 90em) {
      border-radius: 16px;
    }
  
  background-color: ${(props): string => props.active ? props.theme.colors.green.magicMint : props.theme.colors.grey.athens};
`

const DefaultTag = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  
    padding: 1.250vw 3.125vw;

    @media (min-width: 30em) {
      padding: 0 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 0 12px;
    }
  
  
    margin-right: 1.250vw;

    @media (min-width: 30em) {
      margin-right: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-right: 12px;
    }
  
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 11.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.500vw;
    }

    @media (min-width: 90em) {
      height: 36px;
    }
  
  
    border-radius: 5.000vw;

    @media (min-width: 30em) {
      border-radius: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.111vw;
    }

    @media (min-width: 90em) {
      border-radius: 16px;
    }
  
  background-color: ${(props): string => props.theme.colors.green.magicMint};
`

const ControlsContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props): string => props.theme.colors.white.pureWhite};

  
    width: auto;

    @media (min-width: 30em) {
      width: 62.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 13.889vw;
    }

    @media (min-width: 90em) {
      width: 200px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  


  .buttons {
   font-weight: 700;
  }
`

const LoadingContainer = styled.div`
  
    width: 15.625vw;

    @media (min-width: 30em) {
      width: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.472vw;
    }

    @media (min-width: 90em) {
      width: 50px;
    }
  
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.472vw;
    }

    @media (min-width: 90em) {
      height: 50px;
    }
  
`

const MobileAddressRow = styled.div`
  display: flex;
  flex-direction: column;

  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const MobileTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  

  color: ${(props): string => props.theme.colors.white.pureWhite};
  .buttons {
   font-weight: 700;
  }
`

const DesktopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export interface AddressRowProps {
  address: CustomerAddressFragment
  selected: boolean
  loading?: boolean
  onSelect: () => void
  onEdit: () => void
  onRemove: () => void
  canRemove?: boolean
}

export function AddressRow({ selected, address, loading, onSelect, onEdit, onRemove, canRemove }: AddressRowProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container active={selected}>
      <If condition={!!onSelect}>
        <CircleContainer onClick={onSelect} >
          <Circle active={selected} />
        </CircleContainer>
      </If>

      <DeviceContainer mobile>
        <MobileAddressRow>
          <TextContainer onClick={onSelect}>
            <Paragraph className='title'>
              {address.street as unknown as string}
            </Paragraph>
            <Paragraph>
              {`${address.suburb}, ${address.city}, ${address.postcode}`}
            </Paragraph>
            <Paragraph color={theme.colors.grey.gunSmoke}>
              {`${address?.firstname} ${address?.lastname}, ${address?.telephone}`}
            </Paragraph>
          </TextContainer>

          <MobileTags>
            <If condition={address.defaultShipping}>
              <DefaultTag>
                <Tag variant='t4'>Shipping</Tag>
              </DefaultTag>
            </If>

            <If condition={address.defaultBilling}>
              <DefaultTag>
                <Tag variant='t4'>Billing</Tag>
              </DefaultTag>
            </If>

            <AddressType active={selected} onClick={onSelect} >
              <Tag variant='t4'>{address.addressGroup === 'BUSINESS' ? 'Business' : 'Residential'}</Tag>
            </AddressType>

          </MobileTags>

          <MobileTags>
            <Choose>
              <When condition={loading}>
                <LoadingContainer>
                  <SmallLoader color={theme.colors.green.greenVogue} />
                </LoadingContainer>
              </When>
              <Otherwise>
                <Button variant='text' title='Edit' onClick={onEdit} className='buttons' />
                <If condition={canRemove}>
                 | <Button variant='text' title='Remove' onClick={onRemove} className='buttons' />
                </If>
              </Otherwise>
            </Choose>
          </MobileTags>

        </MobileAddressRow>
      </DeviceContainer>

      <DeviceContainer tablet desktop className='desktop-row'>

        <DesktopRow>
          <TextContainer onClick={onSelect}>
            <Paragraph className='title'>
              {address.street as unknown as string}
            </Paragraph>
            <Paragraph>
              {`${address.suburb}, ${address.city}, ${address.postcode}`}
            </Paragraph>
            <Paragraph color={theme.colors.grey.gunSmoke}>
              {`${address?.firstname} ${address?.lastname}, ${address?.telephone}`}
            </Paragraph>
          </TextContainer>

          <If condition={address.defaultShipping}>
            <DefaultTag>
              <Tag variant='t4'>Shipping</Tag>
            </DefaultTag>
          </If>

          <If condition={address.defaultBilling}>
            <DefaultTag>
              <Tag variant='t4'>Billing</Tag>
            </DefaultTag>
          </If>
          <AddressType active={selected} onClick={onSelect} >
            <Tag variant='t4'>{address.addressGroup === 'BUSINESS' ? 'Business' : 'Residential'}</Tag>
          </AddressType>

          <RightContainer>
            <ControlsContainer>
              <Choose>
                <When condition={loading}>
                  <LoadingContainer>
                    <SmallLoader color={theme.colors.green.greenVogue} />
                  </LoadingContainer>
                </When>
                <Otherwise>
                  <Choose>
                    <When condition={canRemove}>
                      <Button variant='text' title='Edit' onClick={onEdit} className='buttons' /> |
                      <Button variant='text' title='Remove' onClick={onRemove} className='buttons' />
                    </When>
                    <Otherwise>
                      <Button variant='text' title='Edit' onClick={onEdit} className='buttons' />

                    </Otherwise>
                  </Choose>
                </Otherwise>
              </Choose>
            </ControlsContainer>
          </RightContainer>
        </DesktopRow>

      </DeviceContainer>

    </Container>
  )

}
