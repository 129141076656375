import React, { useState, useEffect, useMemo } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { Button, Paragraph, Tag, Title } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { BundleProductDetailsFragment, ProductDetailsFragment } from '@hooks/api'
import { SiteHelper } from '@lib/SiteHelper'
import { CheckBoxInput, RadioInput, SelectInput, TextInput } from '@molecules/inputs'
import { Form, useForm } from '@molecules/inputs/Form'

const Container = styled.div`
  .add-or-update {
    width: 100%;
    
    margin: 5.000vw 0;

    @media (min-width: 30em) {
      margin: 10.000vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 2.222vw 0;
    }

    @media (min-width: 90em) {
      margin: 32px 0;
    }
  
  }
`

const OptionGroup = styled.div`

`

const OptionGroupHeader = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  position: relative;
  
    top: 0;

    @media (min-width: 30em) {
      top: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      top: 1.736vw;
    }

    @media (min-width: 90em) {
      top: 25px;
    }
  
  
    left: 0;

    @media (min-width: 30em) {
      left: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      left: 1.389vw;
    }

    @media (min-width: 90em) {
      left: 20px;
    }
  
  
    padding: 1.875vw;

    @media (min-width: 30em) {
      padding: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 8px;
    }
  
  .checkbox {
    flex-grow: 0;
    position: relative;
    
    top: -0.625vw;

    @media (min-width: 30em) {
      top: -0.625vw;
    }

    @media (min-width: 30.0625em) {
      top: -0.139vw;
    }

    @media (min-width: 90em) {
      top: -2px;
    }
  
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  .title {
    
    margin-left: 1.875vw;

    @media (min-width: 30em) {
      margin-left: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.417vw;
    }

    @media (min-width: 90em) {
      margin-left: 6px;
    }
  
    
    position: relative;

    @media (min-width: 30em) {
      position: initial;
    }

    @media (min-width: 30.0625em) {
      position: initial;
    }

    @media (min-width: 90em) {
      position: initial;
    }
  
    
    top: 0.938vw;

    @media (min-width: 30em) {
      top: initial;
    }

    @media (min-width: 30.0625em) {
      top: initial;
    }

    @media (min-width: 90em) {
      top: initial;
    }
  
  }
  .price {
    
    margin-left: 1.875vw;

    @media (min-width: 30em) {
      margin-left: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.417vw;
    }

    @media (min-width: 90em) {
      margin-left: 6px;
    }
  
    position: relative;
    
    top: -0.938vw;

    @media (min-width: 30em) {
      top: -0.938vw;
    }

    @media (min-width: 30.0625em) {
      top: -0.208vw;
    }

    @media (min-width: 90em) {
      top: -3px;
    }
  
  }
`

const OptionsContainer = styled.div<{ hasError: boolean }>`
  
    margin: 0 1.250vw 1.250vw;

    @media (min-width: 30em) {
      margin: 0 2.500vw 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0.556vw 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 0 8px 8px;
    }
  
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 16px;
    }
  
  border-style: dashed;
  border-color: ${(props): string => props.hasError ? props.theme.colors.red.cinnabar : props.theme.colors.grey.gunSmoke};
  
    border-width: 0.313vw;

    @media (min-width: 30em) {
      border-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-width: 1px;
    }
  
`

const OptionContainer = styled.div`
  width: 100%;
  
    margin-bottom: 0.625vw;

    @media (min-width: 30em) {
      margin-bottom: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.278vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 4px;
    }
  
  
    padding: 0.625vw;

    @media (min-width: 30em) {
      padding: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw;
    }

    @media (min-width: 90em) {
      padding: 4px;
    }
  
  display: flex;
  align-items: center;
  
    gap: 0.625vw;

    @media (min-width: 30em) {
      gap: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.278vw;
    }

    @media (min-width: 90em) {
      gap: 4px;
    }
  
  .quantity {
    flex-shrink: 0;
  }
  .checkbox {
    flex-grow: 0;
    position: relative;
    
    top: -0.313vw;

    @media (min-width: 30em) {
      top: -0.313vw;
    }

    @media (min-width: 30.0625em) {
      top: -0.069vw;
    }

    @media (min-width: 90em) {
      top: -1px;
    }
  
    
    margin-right: 0.938vw;

    @media (min-width: 30em) {
      margin-right: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 0.417vw;
    }

    @media (min-width: 90em) {
      margin-right: 6px;
    }
  
  }
  .select {
    flex-grow: 1;
    width: 100%;
    margin-top: 0;
  }
  .quantity {
    flex-shrink: 0;
    
    margin: 1.250vw;

    @media (min-width: 30em) {
      margin: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 8px;
    }
  
  }
  .quantity-title {
    flex-shrink: 0;
    
    margin: 0 1.250vw;

    @media (min-width: 30em) {
      margin: 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 0 8px;
    }
  
    
    display: none;

    @media (min-width: 30em) {
      display: flex;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  }
  .quantity-input {
    flex-shrink: 0;
    margin-top: 0;
    
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.472vw;
    }

    @media (min-width: 90em) {
      width: 50px;
    }
  

    input {
      text-align: center;
    }
  }
  .title {
    
    flex-shrink: initial;

    @media (min-width: 30em) {
      flex-shrink: initial;
    }

    @media (min-width: 30.0625em) {
      flex-shrink: 0;
    }

    @media (min-width: 90em) {
      flex-shrink: 0;
    }
  
    
    flex-grow: 1;

    @media (min-width: 30em) {
      flex-grow: initial;
    }

    @media (min-width: 30.0625em) {
      flex-grow: initial;
    }

    @media (min-width: 90em) {
      flex-grow: initial;
    }
  
    
    width: 100%;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  }
`

const DashedSpacer = styled.div<{ alwaysShow?: boolean }>`
  height: auto;
  flex-grow: 1;
  width: 100%;
  ${(props): string => ResponsivePXValue('display', { mobile: props.alwaysShow ? 'flex' : 'none', tablet: 'flex', desktop: 'flex' })}
  ${(props): string => ResponsivePXValue('border-bottom', { mobile: `1px dashed ${props.theme.colors.grey.gunSmoke}`, tablet: `1px dashed ${props.theme.colors.grey.gunSmoke}`, desktop: `1px dashed ${props.theme.colors.grey.gunSmoke}` })};
  
    height: 4.375vw;

    @media (min-width: 30em) {
      height: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.972vw;
    }

    @media (min-width: 90em) {
      height: 14px;
    }
  
`

const ErrorContainer = styled.div`
  
`

const TotalContainer = styled.div`
  display: flex;
  
    padding: 2.500vw 1.250vw 1.250vw;

    @media (min-width: 30em) {
      padding: 5.000vw 1.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 0.278vw;
    }

    @media (min-width: 90em) {
      padding: 16px 4px;
    }
  
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

export type BundleOptionSelection = { optionId: number, valueId: number, quantity: number }

enum OptionDisplayType {
  STATIC = 'STATIC',
  SELECT = 'SELECT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
}

interface OptionValue {
  id: number
  title: string
  totalPrice: number
  itemFinalPrice: number
  itemOriginalPrice: number
  selected: boolean
  quantity: number
  canChangeQuantity: boolean
  isActive: boolean
  error: string
  maxStock: number
  hasStock: boolean
}

interface Option {
  id: number
  displayType: OptionDisplayType
  title: string
  groupFinalPrice: number
  groupOriginalPrice: number
  shouldShowtitle: boolean
  values: OptionValue[]
  isOptional: boolean
  isActive: boolean
  hasError: boolean
  error: string
  hasStock: boolean
}

export interface BundleProductConfigProps {
  product: ProductDetailsFragment & BundleProductDetailsFragment
  selection: BundleOptionSelection[]
  onSelectionChange: (selection: BundleOptionSelection[]) => void
  onClose: () => void
  onAllowCloseChange: (allowClose: boolean) => void
  hasChanged: boolean
  onCommit: () => void
  loading: boolean
}

interface BundleProductConfigState {
  options: Option[]
  isValid: boolean
}

// type = 'select' | 'radio' | 'multi' | 'checkbox'

const DEFAULT_STATE: BundleProductConfigState = {
  options: [],
  isValid: true,
}

export function BundleProductConfig({ product, selection, onSelectionChange, onClose, onAllowCloseChange, hasChanged, onCommit, loading }: BundleProductConfigProps): JSX.Element {

  const [state, setState] = useState<BundleProductConfigState>({ ...DEFAULT_STATE })
  const form = useForm()
  const theme = useTheme()

  const getFormValues = (): { [k: string]: any } => {
    const defaults: { [k: string]: any } = {}
    for (let i = 0; i < product.items.length; i++) {
      const item = product.items[i]
      defaults[`enable-${item.optionId}`] = item.required ? [true] : []
      if (item.type === 'checkbox' || item.type === 'multi' || item.options.length === 1) {
        const checkedValues = []
        for (let o = 0; o < item.options.length; o++) {
          const selectionItem = selection?.find((sel) => sel.optionId === item.optionId && sel.valueId === item.options[o].id)
          const selected = !!selectionItem
          const quantity = selectionItem?.quantity === undefined ? item.options[o].quantity : selectionItem?.quantity
          defaults[`checkQuantity-${item.options[o].id}`] = quantity
          if (selected) {
            defaults[`enable-${item.optionId}`] = [true]
            checkedValues.push(selectionItem.valueId)
          }
        }
        defaults[`check-${item.optionId}`] = checkedValues
      } else if (item.type === 'select') {
        const selectionItem = selection?.find((sel) => sel.optionId === item.optionId)
        const selected = !!selectionItem
        const quantity = selectionItem?.quantity || null
        defaults[`selectQuantity-${item.optionId}`] = quantity
        defaults[`select-${item.optionId}`] = selectionItem?.valueId || null
        if (selected) {
          defaults[`enable-${item.optionId}`] = [true]
        }
      } else if (item.type === 'radio') {
        const selectionItem = selection?.find((sel) => sel.optionId === item.optionId)
        const selected = !!selectionItem
        defaults[`radio-${item.optionId}`] = selectionItem?.valueId || null
        for (let o = 0; o < item.options.length; o++) {
          const selectionItem = selection?.find((sel) => sel.optionId === item.optionId && sel.valueId === item.options[o].id)
          const quantity = selectionItem?.quantity === undefined ? item.options[o].quantity : selectionItem?.quantity
          defaults[`radioQuantity-${item.options[o].id}`] = quantity
        }
        if (selected) {
          defaults[`enable-${item.optionId}`] = [true]
        }
      }
    }
    return defaults
  }

  const defaultFormValues = useMemo(() => {
    return getFormValues()
  }, [product.items, selection])

  const _handleFormChange = (values: { [k: string]: any }): void => {
    Object.keys(values).forEach((valueKey) => {
      const fieldType = valueKey.split('-')[0]
      const id = parseInt(valueKey.split('-')[1])
      const newValue = values[valueKey]
      switch (fieldType) {
        case 'check': {
          const oldElements: number[] = selection
            .filter((sel) => sel.optionId === id)
            .map((sel) => sel.valueId)
          const newElements = newValue
          const { add, remove } = SiteHelper.getArrayDiff<number>(oldElements, newElements)
          const newSelection = [...selection]
          remove.forEach((rem) => newSelection.splice(newSelection.findIndex((sel) => sel.valueId === rem), 1))
          add.forEach((ad) => {
            const option = product.items.find((itm) => itm.optionId === id).options.find((opt) => opt.id === ad)
            newSelection.push({
              optionId: id,
              valueId: ad,
              quantity: option.quantity,
            })
          })
          onSelectionChange(newSelection)
          break
        } case 'checkQuantity': {
          // update quantity of
          const newSelection = [...selection]
          const itemIndex = selection.findIndex((sel) => sel.valueId === id)
          newSelection[itemIndex].quantity = newValue
          onSelectionChange(newSelection)
          break
        } case 'select': {
          let newSelection = [...selection]
          const index = newSelection.findIndex((sel) => sel.optionId === id)
          if (index !== -1) {
            newSelection.splice(newSelection.findIndex((sel) => sel.optionId === id), 1)
          }
          if (newValue) {
            const option = product.items.find((itm) => itm.optionId === id).options.find((opt) => opt.id === newValue)
            // Item selected
            newSelection = [
              ...newSelection,
              {
                optionId: id,
                valueId: parseInt(newValue),
                quantity: option.quantity,
              },
            ]
          }
          onSelectionChange(newSelection)
          break
        } case 'selectQuantity': {
          // update quantity of
          const newSelection = [...selection]
          const itemIndex = selection.findIndex((sel) => sel.optionId === id)
          newSelection[itemIndex].quantity = newValue
          onSelectionChange(newSelection)
          break
        } case 'radio': {
          let newSelection = [...selection]
          const index = newSelection.findIndex((sel) => sel.optionId === id)
          if (index !== -1) {
            newSelection.splice(newSelection.findIndex((sel) => sel.optionId === id), 1)
          }
          if (newValue) {
            const option = product.items.find((itm) => itm.optionId === id).options.find((opt) => opt.id === newValue)
            // Item selected
            newSelection = [
              ...newSelection,
              {
                optionId: id,
                valueId: parseInt(newValue),
                quantity: option.quantity,
              },
            ]
          }
          onSelectionChange(newSelection)
          break
        } case 'radioQuantity': {
          // update quantity of
          const newSelection = [...selection]
          const itemIndex = selection.findIndex((sel) => sel.valueId === id)
          newSelection[itemIndex].quantity = newValue
          onSelectionChange(newSelection)
          break
        } case 'enable': {
          if ((newValue as boolean[]).length) {
            // group enabled - do nothing
            let newSelection = [...selection]
            product.items.find((itm) => itm.optionId === id).options.forEach((opt) => {
              if (opt.isDefault) {
                newSelection = [
                  ...newSelection,
                  {
                    optionId: id,
                    valueId: opt.id,
                    quantity: opt.quantity,
                  },
                ]
              }
            })
            onSelectionChange(newSelection)
          } else {
            // group disabled (remove all items from group)
            const newSelection = [...selection]
            let safety = 0
            let itemCount = newSelection.filter((sel) => sel.optionId === id).length
            while (itemCount && safety < 20) {
              newSelection.splice(newSelection.findIndex((sel) => sel.optionId === id), 1)
              itemCount = newSelection.filter((sel) => sel.optionId === id).length
              safety++
            }
            onSelectionChange(newSelection)
          }
          break
        } default:
          break
      }
    })
  }

  useEffect(() => {
    const options: Option[] = []
    let isValid = true
    for (let i = 0; i < product.items.length; i++) {
      let hasError = false
      const item = product.items[i]
      let isActive = item.required
      let groupOriginalPrice = 0
      let groupFinalPrice = 0
      let optionHasStock = false
      const values = item.options.map((option): OptionValue => {
        const selectionItem = selection.find((sel) => sel.optionId === item.optionId && sel.valueId === option.id)
        const selected = !!selectionItem
        const quantity = selectionItem?.quantity || option.quantity
        const itemOriginalPrice = item.priceRange.minimumPrice.finalPrice.value
        const itemFinalPrice = (itemOriginalPrice / 100) * (100 - item.priceRange.minimumPrice.discount.percentOff)
        const totalPrice = itemFinalPrice * quantity
        if (selected) {
          groupOriginalPrice += itemOriginalPrice * quantity
          groupFinalPrice += totalPrice
          isActive = true
        }
        const maxStock = option.product.stockInfo.sources.reduce((stock, src) => stock + src.qty, 0)
        const hasStock = (option.canChangeQuantity && maxStock > 0) || maxStock >= option.quantity
        let error = ''
        if (selected && isNaN(selectionItem?.quantity)) {
          error = 'Please enter a quantity'
          isValid = false
          hasError = true
        } else if (selected && selectionItem.quantity > maxStock) {
          if (maxStock === 1) {
            error = 'There is only 1 in stock, please change your selection to 1'
          } else {
            error = `There are only ${maxStock} in stock, please select ${maxStock} or lower`
          }

          isValid = false
          hasError = true
        }

        if (hasStock) {
          optionHasStock = true
        }
        return {
          id: option.id,
          title: option.label,
          itemOriginalPrice,
          itemFinalPrice,
          totalPrice,
          selected,
          quantity,
          isActive: !selected,
          canChangeQuantity: option.canChangeQuantity,
          error,
          maxStock,
          hasStock,
        }
      })
      let displayType = OptionDisplayType.SELECT
      if (item.options.length === 1) {
        displayType = OptionDisplayType.STATIC
      } else {
        if (item.type === 'select') {
          displayType = OptionDisplayType.SELECT
        } else if (item.type === 'checkbox' || item.type === 'multi') {
          displayType = OptionDisplayType.CHECKBOX
        } else {
          displayType = OptionDisplayType.RADIO
        }
      }
      let optionError = ''
      if (item.required && !selection.find((sel) => sel.optionId === item.optionId)) {
        optionError = 'Please select at least one item'
        hasError = true
        isValid = false
      }
      options.push({
        id: item.optionId,
        displayType,
        title: item.title,
        shouldShowtitle: !options.find((opt) => opt.title === item.title),
        isOptional: !item.required,
        isActive,
        values,
        groupOriginalPrice,
        groupFinalPrice,
        hasError,
        error: optionError,
        hasStock: optionHasStock,
      })
    }
    setState((prevState) => update(prevState, {
      options: {
        $set: options,
      },
      isValid: {
        $set: isValid,
      },
    }))
    const formValues = getFormValues()
    form.setFieldsValue(formValues)
  }, [product.items, selection])

  useEffect(() => {
    onAllowCloseChange(state.isValid)
  }, [state.isValid])

  const disabledColor = theme.colors.grey.gunSmoke
  let option: Option
  let value: OptionValue

  return (
    <Container>
      <Form form={form} initialValues={defaultFormValues} onValuesChange={_handleFormChange}>
        <For each='option' of={state.options}>
          <OptionGroup key={option.id}>
            <OptionGroupHeader>
              <CheckBoxInput
                disabled={!option.isOptional}
                wrapperClassName='checkbox'
                name={`enable-${option.id}`}
                options={[{ title: '', value: true }]}
                variant='horizontal' />
              <If condition={option.shouldShowtitle}>
                <TitleContainer>
                  <Title variant='t6' className='title' color={option.hasError ? theme.colors.red.cinnabar : undefined}>{option.title}</Title>
                </TitleContainer>
              </If>
            </OptionGroupHeader>
            <OptionsContainer hasError={option.hasError}>
              <Choose>
                <When condition={option.displayType === OptionDisplayType.STATIC}>
                  <For each='value' of={option.values}>
                    <OptionContainer key={value.id}>
                      <Choose>
                        <When condition={value.canChangeQuantity}>
                          <Paragraph className='title' color={value.isActive ? disabledColor : undefined}>{value.title} (R{value.itemFinalPrice.toFixed(2)})</Paragraph>
                          <DashedSpacer />
                          <Paragraph bold className='quantity-title' color={value.isActive ? disabledColor : undefined}> x </Paragraph>
                          <TextInput name={`checkQuantity-${value.id}`} wrapperClassName='quantity-input' variant='integer' disabled={value.isActive} />
                        </When>
                        <Otherwise>
                          <Paragraph className='title' color={value.isActive ? disabledColor : undefined}>{value.title} (R{value.itemFinalPrice.toFixed(2)})</Paragraph>
                          <DashedSpacer />
                          <Paragraph bold className='quantity' color={value.isActive ? disabledColor : undefined}> x {value.quantity}</Paragraph>
                        </Otherwise>
                      </Choose>
                    </OptionContainer>
                    <If condition={!!value.error}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>{value.error}</Tag>
                      </ErrorContainer>
                    </If>
                  </For>
                  <Choose>
                    <When condition={!option.hasStock}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>Sorry, there is no stock for this option.</Tag>
                      </ErrorContainer>
                    </When>
                    <When condition={!!option.error}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>{option.error}</Tag>
                      </ErrorContainer>
                    </When>
                  </Choose>
                </When>
                <When condition={option.displayType === OptionDisplayType.CHECKBOX}>
                  <CheckBoxInput name={`check-${option.id}`} wrapperClassName='checkbox' options={option.values.map((value) => ({
                    title: (
                      <>
                        <OptionContainer>
                          <Paragraph className='title' color={value.isActive ? disabledColor : undefined} decoration={value.hasStock ? undefined: 'line-through'}>{value.title} (R{value.itemFinalPrice.toFixed(2)})</Paragraph>
                          <DashedSpacer />
                          <Paragraph bold className='quantity' color={value.isActive ? disabledColor : undefined}>{value.hasStock ? ` x ${value.quantity}` : 'No Stock'}</Paragraph>
                        </OptionContainer>
                        <If condition={!!value.error}>
                          <ErrorContainer>
                            <Tag color={theme.colors.red.cinnabar}>{value.error}</Tag>
                          </ErrorContainer>
                        </If>
                      </>
                    ),
                    value: value.id,
                    disabled: !value.hasStock,
                  }))} />
                  <Choose>
                    <When condition={!option.hasStock}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>Sorry, there is no stock for this option.</Tag>
                      </ErrorContainer>
                    </When>
                    <When condition={!!option.error}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>{option.error}</Tag>
                      </ErrorContainer>
                    </When>
                  </Choose>
                </When>
                <When condition={option.displayType === OptionDisplayType.SELECT}>
                  <OptionContainer>
                    <SelectInput
                      wrapperClassName='select'
                      name={`select-${option.id}`}
                      options={option.values.map((val) => ({
                        title: val.title + (val.hasStock ? (` (R${val.itemFinalPrice.toFixed(2)})` + (val.canChangeQuantity ? '' : ` (x ${val.quantity})`)) : ' - No Stock'),
                        value: val.id,
                        disabled: !val.hasStock,
                      }))} />
                    <Choose>
                      <When condition={!!option.values.find((val) => selection.find((sel) => sel.optionId === option.id)?.valueId === val.id)?.canChangeQuantity}>
                        <Paragraph bold className='quantity-title' color={!option.isActive ? disabledColor : undefined}> x </Paragraph>
                        <TextInput name={`selectQuantity-${option.id}`} wrapperClassName='quantity-input' variant='integer' disabled={!option.isActive} />
                      </When>
                      <Otherwise>
                        <Paragraph bold className='quantity' color={!option.isActive ? disabledColor : undefined}> x {option.values.find((val) => selection.find((sel) => sel.optionId === option.id)?.valueId === val.id)?.quantity || 0}</Paragraph>
                      </Otherwise>
                    </Choose>
                  </OptionContainer>
                  <If condition={!!option.values.find((val) => selection.find((sel) => sel.optionId === option.id)?.valueId === val.id)?.error}>
                    <ErrorContainer>
                      <Tag color={theme.colors.red.cinnabar}>{option.values.find((val) => selection.find((sel) => sel.optionId === option.id)?.valueId === val.id)?.error}</Tag>
                    </ErrorContainer>
                  </If>
                  <Choose>
                    <When condition={!option.hasStock}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>Sorry, there is no stock for this option.</Tag>
                      </ErrorContainer>
                    </When>
                    <When condition={!!option.error}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>{option.error}</Tag>
                      </ErrorContainer>
                    </When>
                  </Choose>
                </When>
                <Otherwise>
                  {/* RADIO */}
                  <OptionContainer>
                    <RadioInput
                      wrapperClassName='select'
                      name={`radio-${option.id}`}
                      options={option.values.map((value) => ({
                        title: (
                          <OptionContainer>
                            <Choose>
                              <When condition={value.canChangeQuantity}>
                                <Paragraph className='title' color={value.isActive ? disabledColor : undefined} decoration={value.hasStock ? undefined : 'line-through'}>{value.title} (R{value.itemFinalPrice.toFixed(2)})</Paragraph>
                                <DashedSpacer />
                                <Paragraph bold className='quantity-title' color={value.isActive ? disabledColor : undefined}> x </Paragraph>
                                <TextInput name={`radioQuantity-${value.id}`} wrapperClassName='quantity-input' variant='integer' disabled={value.isActive} />
                              </When>
                              <Otherwise>
                                <Paragraph className='title' color={value.isActive ? disabledColor : undefined} decoration={value.hasStock ? undefined : 'line-through'}>{value.title} (R{value.itemFinalPrice.toFixed(2)})</Paragraph>
                                <DashedSpacer />
                                <Paragraph bold className='quantity' color={value.isActive ? disabledColor : undefined}>{value.hasStock ? ` x ${value.quantity}` : 'No Stock'}</Paragraph>
                              </Otherwise>
                            </Choose>
                          </OptionContainer>
                        ),
                        value: value.id,
                        disabled: !value.hasStock,
                      }))} />
                  </OptionContainer>
                  <If condition={!!option.values.find((val) => selection.find((sel) => sel.optionId === option.id)?.valueId === val.id)?.error}>
                    <ErrorContainer>
                      <Tag color={theme.colors.red.cinnabar}>{option.values.find((val) => selection.find((sel) => sel.optionId === option.id)?.valueId === val.id)?.error}</Tag>
                    </ErrorContainer>
                  </If>
                  <Choose>
                    <When condition={!option.hasStock}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>Sorry, there is no stock for this option.</Tag>
                      </ErrorContainer>
                    </When>
                    <When condition={!!option.error}>
                      <ErrorContainer>
                        <Tag color={theme.colors.red.cinnabar}>{option.error}</Tag>
                      </ErrorContainer>
                    </When>
                  </Choose>
                </Otherwise>
              </Choose>
              <TotalContainer>
                <Paragraph className='title' bold color={option.hasError ? theme.colors.red.cinnabar : undefined}>Total</Paragraph>
                <DashedSpacer alwaysShow />
                <Title variant='t5' className='price' color={option.hasError ? theme.colors.red.cinnabar : undefined}>{option.hasError ? 'R??' : `(R${option.groupFinalPrice.toFixed(2)})`}</Title>
              </TotalContainer>
            </OptionsContainer>
          </OptionGroup>
        </For>
      </Form>
      <If condition={state.isValid}>
        <Choose>
          <When condition={!!product.cartItemUid}>
            <If condition={hasChanged}>
              <Button className='add-or-update' title='UPDATE MY CART' loading={loading} onClick={onCommit} />
            </If>
          </When>
          <Otherwise>
            <Button className='add-or-update' title='ADD TO CART' loading={loading} onClick={onCommit} />
          </Otherwise>
        </Choose>
      </If>
    </Container>
  )

}
