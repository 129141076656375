import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Card } from '@atoms/layout'
import { Tag, Paragraph, Heading, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CartFragment, DiscountFragment, CheckoutFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { Table, Row, Cell } from '@molecules/tables'

const Container = styled.div`
  width: 100%;
  .summary-title {
    
    margin: 0 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 0 0 10px;
    }
  
  }
  .seperator-text {
    flex-shrink: 0;
    margin: 0;
  }
  .seperator {
    
    margin: 5.000vw 0;

    @media (min-width: 30em) {
      margin: 5.000vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0;
    }

    @media (min-width: 90em) {
      margin: 16px 0;
    }
  
  }
`

const Summary = styled.div`
  
    padding: 1.563vw 0;

    @media (min-width: 30em) {
      padding: 1.563vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw 0;
    }

    @media (min-width: 90em) {
      padding: 5px 0;
    }
  
  .tag {
    width: fit-content;
    
    margin: 0 0 1.563vw;

    @media (min-width: 30em) {
      margin: 0 0 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.347vw;
    }

    @media (min-width: 90em) {
      margin: 0 0 5px;
    }
  
  }
  .total-row {
    
    margin-top: 3.750vw;

    @media (min-width: 30em) {
      margin-top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-top: 12px;
    }
  
    
    padding: 3.750vw 0;

    @media (min-width: 30em) {
      padding: 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 0;
    }

    @media (min-width: 90em) {
      padding: 12px 0;
    }
  
    ${(props): string => ResponsivePXValue('border-top', `1px solid ${props.theme.colors.grey.gallery}`)}
  }
  .button {
    width: 100%;
  }
`

const SecureContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 1.563vw;

    @media (min-width: 30em) {
      gap: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.347vw;
    }

    @media (min-width: 90em) {
      gap: 5px;
    }
  
  
    margin-top: 3.750vw;

    @media (min-width: 30em) {
      margin-top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-top: 12px;
    }
  

  .icon {
    
    width: 4.688vw;

    @media (min-width: 30em) {
      width: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.042vw;
    }

    @media (min-width: 90em) {
      width: 15px;
    }
  
    
    height: 4.688vw;

    @media (min-width: 30em) {
      height: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.042vw;
    }

    @media (min-width: 90em) {
      height: 15px;
    }
  
  }

  .text {
    margin: 0;
  }
`

export interface CheckoutSummaryProps {
  cart: CartFragment
  checkout: CheckoutFragment
  loading?: boolean
  onNext: () => void
}

export function CheckoutSummary({ cart, checkout, loading = false, onNext }: CheckoutSummaryProps): JSX.Element {

  const theme = useTheme()

  let discount: DiscountFragment

  const deliveryValue = cart?.shippingAddresses?.[0]?.selectedShippingMethod
    ? cart?.shippingAddresses[0].selectedShippingMethod.priceInclTax.value === 0
      ? 'Free'
      : SiteHelper.formatCurrency(cart?.shippingAddresses?.[0]?.selectedShippingMethod?.priceInclTax)
    : '-- --'

  const hasProducts = cart?.items?.length > 0

  const totalRewardPoints = cart?.amastyRewardsHighlights?.captionText || 0
  const showRewardPoints = cart?.amastyRewardsHighlights?.visible || false

  return (
    <Container>
      <Card>
        <Heading variant='h4' className='summary-title'>Order Summary</Heading>
        <Summary>
          <Table>
            <Row>
              <Cell><Tag variant='t2' className='tag'>{cart.totalQuantity} Items</Tag></Cell>
              <Cell align='end'><Tag variant='t2' className='tag'>{SiteHelper.formatCurrency(cart.prices.subTotal)}</Tag></Cell>
            </Row>
            <Row>
              <Cell><Tag variant='t2' className='tag' color={theme.colors.green.greenVogue}>Delivery</Tag></Cell>
              <Cell align='end'><Tag variant='t2' className='tag' color={theme.colors.green.greenVogue}>{deliveryValue}</Tag></Cell>
            </Row>
            <If condition={showRewardPoints}>
              <Row>
                <Cell><Tag variant='t2' className='tag' color={theme.colors.green.greenVogue}>You will earn</Tag></Cell>
                <Cell align='end'><Tag variant='t2' className='tag' color={theme.colors.green.greenVogue}>{totalRewardPoints} Faithful Points</Tag></Cell>
              </Row>
            </If>
            <For each='discount' of={cart.prices?.discounts || []}>
              <Row key={discount.label}>
                <Cell><Tag variant='t2' className='tag' color={theme.colors.green.greenVogue}>{discount.label}</Tag></Cell>
                <Cell align='end'><Tag variant='t2' className='tag' color={theme.colors.green.greenVogue}>-{SiteHelper.formatCurrency(discount.amount)}</Tag></Cell>
              </Row>
            </For>
            <Row className='total-row'>
              <Cell><Paragraph variant='p2' className='tag'>To pay</Paragraph></Cell>
              <Cell align='end'><Title variant='t2' className='tag'>{SiteHelper.formatCurrency(cart.prices.grandTotal)}</Title></Cell>
            </Row>
          </Table>
          <Button
            fullWidth
            className='button'
            disabled={!checkout.canAdvance || !hasProducts}
            title={hasProducts ? 'CONTINUE' : 'NO PRODUCTS'}
            loading={loading}
            onClick={onNext} />
          <SecureContainer>
            <Icon icon={LocalIconEnums.LOCK_CLOSED_OUTLINE} className='icon' color={theme.colors.black.pureBlack} />
            <Paragraph variant='p2' className='text'>Secure Checkout</Paragraph>
          </SecureContainer>
        </Summary>
      </Card>
    </Container>
  )

}
