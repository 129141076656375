import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { Paragraph, Tag, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PargoLocationFragment, ShippingMethodFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { HTML } from '@molecules/index'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    border-radius: 0;

    @media (min-width: 30em) {
      border-radius: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.694vw;
    }

    @media (min-width: 90em) {
      border-radius: 10px;
    }
  
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 4.167vw;
    }

    @media (min-width: 90em) {
      width: 60px;
    }
  
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
  
    border-radius: 3.125vw;

    @media (min-width: 30em) {
      border-radius: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.903vw;
    }

    @media (min-width: 90em) {
      border-radius: 13px;
    }
  
  ${(props): string => {
    if (props.active) {
      return `
        
    border-width: 1.250vw;

    @media (min-width: 30em) {
      border-width: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.347vw;
    }

    @media (min-width: 90em) {
      border-width: 5px;
    }
  
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    
    border-width: 0.313vw;

    @media (min-width: 30em) {
      border-width: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.139vw;
    }

    @media (min-width: 90em) {
      border-width: 2px;
    }
  
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const RightContainer = styled.div`
  display: flex;
  
    align-items: flex-end;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: center;
    }

    @media (min-width: 90em) {
      align-items: center;
    }
  
  
    justify-content: space-between;

    @media (min-width: 30em) {
      justify-content: center;
    }

    @media (min-width: 30.0625em) {
      justify-content: center;
    }

    @media (min-width: 90em) {
      justify-content: center;
    }
  
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    align-self: stretch;

    @media (min-width: 30em) {
      align-self: auto;
    }

    @media (min-width: 30.0625em) {
      align-self: auto;
    }

    @media (min-width: 90em) {
      align-self: auto;
    }
  
  
    width: 40%;

    @media (min-width: 30em) {
      width: auto;
    }

    @media (min-width: 30.0625em) {
      width: auto;
    }

    @media (min-width: 90em) {
      width: auto;
    }
  
`

const Price = styled.div<{ active: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  
    padding: 0 3.750vw;

    @media (min-width: 30em) {
      padding: 0 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 0 12px;
    }
  
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 11.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.500vw;
    }

    @media (min-width: 90em) {
      height: 36px;
    }
  
  
    border-radius: 5.000vw;

    @media (min-width: 30em) {
      border-radius: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.111vw;
    }

    @media (min-width: 90em) {
      border-radius: 16px;
    }
  
  background-color: ${(props): string => props.active ? props.theme.colors.green.magicMint : props.theme.colors.grey.athens};
`

const ContentContaine = styled.div`
  width: 100%;
`

const ContentItem = styled.div`
  display: flex;
`

const ImageContainer = styled.div`
  
    display: none;

    @media (min-width: 30em) {
      display: initial;
    }

    @media (min-width: 30.0625em) {
      display: initial;
    }

    @media (min-width: 90em) {
      display: initial;
    }
  
  
    width: 100%;

    @media (min-width: 30em) {
      width: 43.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 9.722vw;
    }

    @media (min-width: 90em) {
      width: 140px;
    }
  
  
    height: 50.000vw;

    @media (min-width: 30em) {
      height: 37.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 8.333vw;
    }

    @media (min-width: 90em) {
      height: 120px;
    }
  
  
    margin-right: 5.000vw;

    @media (min-width: 30em) {
      margin-right: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-right: 16px;
    }
  
`

const LocationImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`

const PargoDetailsContainer = styled.div`
  flex-grow: 1;
  .spacer {
    
    margin-top: 5.000vw;

    @media (min-width: 30em) {
      margin-top: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-top: 16px;
    }
  
  }
`

const PargoInfoContainer = styled.div`
  display: flex;
  
    margin-top: 5.000vw;

    @media (min-width: 30em) {
      margin-top: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.667vw;
    }

    @media (min-width: 90em) {
      margin-top: 24px;
    }
  
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
`

const RightPargoContainer = styled.div`
  display: flex;
  
    align-items: flex-start;

    @media (min-width: 30em) {
      align-items: flex-start;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-start;
    }

    @media (min-width: 90em) {
      align-items: flex-start;
    }
  
  
    justify-content: flex-end;

    @media (min-width: 30em) {
      justify-content: flex-end;
    }

    @media (min-width: 30.0625em) {
      justify-content: flex-end;
    }

    @media (min-width: 90em) {
      justify-content: flex-end;
    }
  
  
    width: 40%;

    @media (min-width: 30em) {
      width: auto;
    }

    @media (min-width: 30.0625em) {
      width: auto;
    }

    @media (min-width: 90em) {
      width: auto;
    }
  
  .change-button {
    
    width: 100%;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
    
    margin-top: 7.500vw;

    @media (min-width: 30em) {
      margin-top: initial;
    }

    @media (min-width: 30.0625em) {
      margin-top: initial;
    }

    @media (min-width: 90em) {
      margin-top: initial;
    }
  
  }
`

export interface DeliveryMethodRowProps {
  deliveryMethod: ShippingMethodFragment
  selected: boolean
  pargoLocation?: PargoLocationFragment
  onSelect: () => void
  onPargoLocationChange?: () => void
}

export function DeliveryMethodRow({ selected, deliveryMethod, pargoLocation, onSelect, onPargoLocationChange }: DeliveryMethodRowProps): JSX.Element {

  const theme = useTheme()

  return (
    <>
      <Container active={selected}>
        <If condition={!!onSelect}>
          <CircleContainer onClick={onSelect} >
            <Circle active={selected} />
          </CircleContainer>
        </If>
        <ContentContaine>
          <ContentItem>
            <TextContainer onClick={onSelect}>
              <Title variant='t8'>
                {deliveryMethod.carrierTitle}
              </Title>
              <Paragraph color={theme.colors.grey.gunSmoke}>
                <HTML>{deliveryMethod.methodTitle}</HTML>
              </Paragraph>
            </TextContainer>
            <RightContainer>
              <Price active={selected} onClick={onSelect} >
                <Tag variant='t2' color={theme.colors.grey.stormDust}>
                  {SiteHelper.formatCurrency(deliveryMethod.amount)}
                </Tag>
              </Price>
            </RightContainer>
          </ContentItem>
          <If condition={deliveryMethod.carrierCode.indexOf('pargo') !== -1 && !!pargoLocation}>
            <PargoInfoContainer>
              <ImageContainer onClick={onSelect}>
                <LocationImage src={pargoLocation.photo} />
              </ImageContainer>
              <PargoDetailsContainer onClick={onSelect}>
                <Paragraph bold>
                  {pargoLocation.storeName}
                </Paragraph>
                <Paragraph>
                  {pargoLocation.addressSms}
                </Paragraph>
                <Paragraph bold className='spacer'>
                  Trading hours
                </Paragraph>
                <Paragraph>
                  {pargoLocation.businessHours}
                </Paragraph>
              </PargoDetailsContainer>
              <RightPargoContainer>
                <Button size='medium' title='CHANGE' className='change-button' onClick={onPargoLocationChange} />
              </RightPargoContainer>
            </PargoInfoContainer>

          </If>
        </ContentContaine>

      </Container>
    </>

  )

}
