import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Link, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageSiteNoticeSectionFragment } from '@hooks/api/index'

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  width: 100%;
  
    padding: 3.125vw 0;

    @media (min-width: 30em) {
      padding: 3.125vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.694vw 0;
    }

    @media (min-width: 90em) {
      padding: 10px 0;
    }
  
  background-color: ${({ backgroundColor }) => backgroundColor};
`

const IconContainer = styled.div`
  
    height: 10.000vw;

    @media (min-width: 30em) {
      height: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.222vw;
    }

    @media (min-width: 90em) {
      height: 32px;
    }
  
  
    width: 10.000vw;

    @media (min-width: 30em) {
      width: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.222vw;
    }

    @media (min-width: 90em) {
      width: 32px;
    }
  
`

export interface PageSiteNoticeSectionProps {
  section: PageSiteNoticeSectionFragment
}

export function PageSiteNoticeSection({ section }: PageSiteNoticeSectionProps): JSX.Element {

  const { link, backgroundColor, foregroundColor, image } = section

  return (
    <Container backgroundColor={backgroundColor}>
      <IconContainer>
        <ResponsiveImage image={image} objectFit="cover"/>
      </IconContainer>
      <Paragraph variant='p2'>
        <Link href={link.url || '#'} color={foregroundColor} hoverColor={foregroundColor}>{link.title}</Link>
      </Paragraph>
    </Container>
  )
}
