import React, { useState, useEffect, useRef } from 'react'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { camelCase } from 'camel-case'
import update from 'react-addons-update'
import { useLocation, useNavigate } from 'react-router'
import { animated, useSpring } from 'react-spring'
import { snakeCase } from 'snake-case'
import styled, { useTheme } from 'styled-components'

import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Label, SmallLoader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { AggregationFragment, AggregationOptionFragment, useGetStoreConfigQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { FieldData, Form, useForm, TextInput, RangeInput } from '@molecules/inputs'
import { AggregationTypeEnum, ProductAttributeFilterInput } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductFiltersSekeleton } from './ProductFiltersSkeleton'

const DEFAULT_VISIBLE = 8

const Iconwrapper = styled.div`
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
    
  
    margin-bottom: 1.563vw;

    @media (min-width: 30em) {
      margin-bottom: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 5px;
    }
  
`

const Filters = styled.div`
  position: relative;
  
    padding: 0 5.000vw;

    @media (min-width: 30em) {
      padding: 0 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 0 16px;
    }
  

  .rangeInput {
    
    padding-top: 1.875vw;

    @media (min-width: 30em) {
      padding-top: initial;
    }

    @media (min-width: 30.0625em) {
      padding-top: initial;
    }

    @media (min-width: 90em) {
      padding-top: initial;
    }
  
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 0 5.000vw;

    @media (min-width: 30em) {
      padding: 0 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 0 16px;
    }
  
  
    height: 16.875vw;

    @media (min-width: 30em) {
      height: 16.875vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.750vw;
    }

    @media (min-width: 90em) {
      height: 54px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const RightHeader = styled.div``

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`

const Close = styled.div`
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.111vw;
    }

    @media (min-width: 90em) {
      height: 16px;
    }
  
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.111vw;
    }

    @media (min-width: 90em) {
      width: 16px;
    }
  
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
    width: CALC(100%);

    @media (min-width: 30em) {
      width: CALC(100%);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100%);
    }

    @media (min-width: 90em) {
      width: CALC(100%);
    }
  
  overflow-x: hidden;

  .search-input {
    
    margin: 5.000vw 1.250vw;

    @media (min-width: 30em) {
      margin: 5.000vw 1.250vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0.278vw;
    }

    @media (min-width: 90em) {
      margin: 16px 4px;
    }
  
  }
  .toggle-button {
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
    
    margin: 0.625vw 0;

    @media (min-width: 30em) {
      margin: 0.625vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.139vw 0;
    }

    @media (min-width: 90em) {
      margin: 2px 0;
    }
  
    font-weight: bold;
  }
`

const FilterHeading = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
    padding: 3.750vw 5.000vw;

    @media (min-width: 30em) {
      padding: 3.750vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 12px 16px;
    }
  
  background-color: ${(props): string => props.open ? props.theme.colors.grey.athens : props.theme.colors.white.fantasy};
  transition: all ease-in-out 300ms;

  color: ${(props): string => props.open ? props.theme.colors.grey.gunSmoke : props.theme.colors.green.bottleGreen};
  font-family: open-sans;
  font-weight: 700;
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
  
    line-height: 4.375vw;

    @media (min-width: 30em) {
      line-height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.389vw;
    }

    @media (min-width: 90em) {
      line-height: 20px;
    }
  

  .icon {
    transition: all ease-in-out 300ms;
    
    width: 5.625vw;

    @media (min-width: 30em) {
      width: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.250vw;
    }

    @media (min-width: 90em) {
      width: 18px;
    }
  
    
    height: 5.625vw;

    @media (min-width: 30em) {
      height: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.250vw;
    }

    @media (min-width: 90em) {
      height: 18px;
    }
  
    
    margin: 1.250vw;

    @media (min-width: 30em) {
      margin: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0.278vw;
    }

    @media (min-width: 90em) {
      margin: 4px;
    }
  
    ${(props): string => props.open ? 'transform: rotate(180deg);' : ''}
  }
`

const FilterGroup = styled.div`
  
    padding-bottom: 1.250vw;

    @media (min-width: 30em) {
      padding-bottom: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      padding-bottom: 0.417vw;
    }

    @media (min-width: 90em) {
      padding-bottom: 6px;
    }
  

  .filter-heading {
    
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
    
    padding: 3.750vw 5.000vw;

    @media (min-width: 30em) {
      padding: 3.750vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 12px 16px;
    }
  
    background-color: ${(props): string => props.theme.colors.white.fantasy};
  }
  .filter-seperator {
    
    margin: 6.250vw 0;

    @media (min-width: 30em) {
      margin: 6.250vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.389vw 0;
    }

    @media (min-width: 90em) {
      margin: 20px 0;
    }
  
  }
  .search {
    
    margin: 0 5.000vw 5.000vw 5.000vw;

    @media (min-width: 30em) {
      margin: 0 5.000vw 5.000vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 1.111vw 1.111vw 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 0 16px 16px 16px;
    }
  
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pampas, 0.8)};
  }
  .searchBody {
    
    margin: 0 5.000vw 0 0;

    @media (min-width: 30em) {
      margin: 0 5.000vw 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 1.111vw 0 0;
    }

    @media (min-width: 90em) {
      margin: 0 16px 0 0;
    }
  
  }
`

const Filter = styled.a<{ active: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  
    padding: 3.750vw 0;

    @media (min-width: 30em) {
      padding: 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 0;
    }

    @media (min-width: 90em) {
      padding: 12px 0;
    }
  
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.278vw;
    }

    @media (min-width: 90em) {
      gap: 4px;
    }
  

  .text-item {
    margin: 0;
    
    margin-left: 1.250vw;

    @media (min-width: 30em) {
      margin-left: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.278vw;
    }

    @media (min-width: 90em) {
      margin-left: 4px;
    }
  
    ${(props): string => props.active ? 'font-weight: 600;' : ''}
    white-space: pre-wrap;
  }
  &:hover {
    .text-item {
      color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
    .check-box {
      border-color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
    .check {
      background-color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
  }
`

const CheckBox = styled.div<{ checked: string }>`
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.111vw;
    }

    @media (min-width: 90em) {
      width: 16px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.111vw;
    }

    @media (min-width: 90em) {
      height: 16px;
    }
  
  
    border-radius: 0.625vw;

    @media (min-width: 30em) {
      border-radius: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.139vw;
    }

    @media (min-width: 90em) {
      border-radius: 2px;
    }
  
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.checked ? props.theme.colors.green.greenVogue : props.theme.colors.grey.silver}`)}
  background-color: ${(props): string => props.checked ? props.theme.colors.green.greenVogue : props.theme.colors.white.pureWhite};
  flex-shrink: 0;

  .icon {
    
    width: 4.063vw;

    @media (min-width: 30em) {
      width: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      width: 0.903vw;
    }

    @media (min-width: 90em) {
      width: 13px;
    }
  
    
    height: 4.063vw;

    @media (min-width: 30em) {
      height: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.903vw;
    }

    @media (min-width: 90em) {
      height: 13px;
    }
  
  }
`

const CurrentFiltersBody = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  
    gap: 1.563vw;

    @media (min-width: 30em) {
      gap: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.347vw;
    }

    @media (min-width: 90em) {
      gap: 5px;
    }
  
  
    padding: 3.750vw 5.000vw;

    @media (min-width: 30em) {
      padding: 3.750vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 12px 16px;
    }
  
`

const CurrentFilters = styled.div`
  width: 100%;
`

const CurrentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 3.750vw 5.000vw;

    @media (min-width: 30em) {
      padding: 3.750vw 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 12px 16px;
    }
  
  background-color: ${(props): string => props.theme.colors.white.fantasy};

  .clear-filters {
    cursor: pointer;
  }
`

const LoadingOverlay = styled.div<{ active: boolean }>`
  display: ${(props): string => props.active ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5)};
  z-index: 3;
`

const FilterLoaderContainer = styled.div`
  
    width: 3.750vw;

    @media (min-width: 30em) {
      width: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.111vw;
    }

    @media (min-width: 90em) {
      width: 16px;
    }
  
  
    height: 3.750vw;

    @media (min-width: 30em) {
      height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.111vw;
    }

    @media (min-width: 90em) {
      height: 16px;
    }
  
`

const MenuContainer = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 40;
  
    padding: 0 0 0 12.500vw;

    @media (min-width: 30em) {
      padding: 0 0 0 12.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0 0 2.778vw;
    }

    @media (min-width: 90em) {
      padding: 0 0 0 40px;
    }
  
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.black.pureBlack, 0.77)};
`

const MobileFilterContainer = styled(animated.div)`
  position: relative;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 100%;
    }

    @media (min-width: 90em) {
      width: 100%;
    }
  
  overflow-x: hidden;
`

const Seperator = styled.div`
  flex-grow: 1;
  background: ${(props): string => props.theme.colors.black.pureBlack};
  
    height: 0.313vw;

    @media (min-width: 30em) {
      height: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.069vw;
    }

    @media (min-width: 90em) {
      height: 1px;
    }
  
  width: 100%;
`

const Count = styled.div`
  text-align: right;
  flex-grow: 1;
  font-family: open-sans;
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  color: ${(props): string => props.theme.colors.grey.gunSmoke};
`

const TopSection = styled.div`
  flex-shrink: 0;
  .result-count {
    
    margin: 0 5.000vw;

    @media (min-width: 30em) {
      margin: 0 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 0 16px;
    }
  
  }
`

const Scroller = styled.div`
  flex-grow: 1;
  overflow: scroll;
`

export interface ProductFilterInputs extends ProductAttributeFilterInput {
  [k: string]: any
}

export interface ProductFiltersProps {
  aggregations: Readonly<AggregationFragment[]>
  open?: boolean
  filters: ProductFilterInputs
  loading: boolean
  productCount: number
  onFilterChange?: (filters: ProductFilterInputs) => void
  onToggleFilters?: () => void
}

interface FilterState {
  open: boolean
  expanded: boolean
  requiresExpansion: boolean
  loading?: string
  initialVisible: number
  aggregationType: AggregationTypeEnum
}

interface ProductFiltersState {
  search: string
  tabs: { [k: string]: FilterState }
  options: {[k: string]: AggregationOptionFragment[]}
  resetPriceFilter: boolean
}

const DEFAULT_STATE: ProductFiltersState = {
  search: '',
  tabs: {},
  options: {},
  resetPriceFilter: false,
}

export const ProductFilters = React.memo(
  function ProductFilters({ open, filters, aggregations, loading, productCount, onFilterChange, onToggleFilters }: ProductFiltersProps): JSX.Element {

    const [state, setState] = useState<ProductFiltersState>({ ...DEFAULT_STATE })
    const { data: storeConfigData } = useGetStoreConfigQuery()
    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()
    const form = useForm()
    const initialPriceRange = useRef<{ min: number, max: number } | null>(null)

    const uniqueAggregations: string[] = []
    const filteredAggregations = aggregations.filter((agg) => {
      if (!uniqueAggregations.includes(agg.attributeCode)) {
        uniqueAggregations.push(agg.attributeCode)
        return true
      }
      return false
    })
    const priceFilter = filteredAggregations.find((agg) => agg.attributeCode === 'price')
    const maxOptions = storeConfigData?.storeConfig?.categoryFilterMaxOptions || DEFAULT_VISIBLE

    const setOptions = (): void => {
      const options: { [k: string]: AggregationOptionFragment[] } = {}
      for (let t = 0; t < aggregations.length; t++) {
        const filteredOptions = state.search
          ? aggregations[t].options.filter((opt) => opt.label.toLowerCase().includes(state.search.toLowerCase()))
          : aggregations[t].options
        if (filteredOptions.length > maxOptions && !state.tabs?.[aggregations[t].attributeCode]?.expanded) {
          options[aggregations[t].attributeCode] = filteredOptions.slice(0, maxOptions)
        } else {
          options[aggregations[t].attributeCode] = filteredOptions
        }
      }
      setState((prevState) => update(prevState, {
        options: {
          $set: options,
        },
      }))
    }

    const _handleClearFilters = (): void => {
      navigate(`${location.pathname}`)

      if (filters.price && initialPriceRange.current) {
        form.setFieldsValue({
          price: [
            initialPriceRange.current.min,
            initialPriceRange.current.max,
          ],
        })
      }
    }

    const _handleBackgroundClose = (e: React.MouseEvent<HTMLDivElement>): void => {
      e.preventDefault()
      if (e.target === e.currentTarget) {
        onToggleFilters()
      }
    }

    const _handleFilter = (attributeCode: string, option: string, e?: React.MouseEvent<HTMLAnchorElement>): void => {
      e?.preventDefault()
      if (loading) {
        return
      }
      const tabs = { ...state.tabs }
      if (tabs[attributeCode].loading) {
        tabs[attributeCode].loading = option
      }
      setState((prevState) => update(prevState, {
        tabs: {
          $set: tabs,
        },
      }))
      const group = filteredAggregations?.find((group) => group.attributeCode === attributeCode)
      const isMulti = group?.type === AggregationTypeEnum.MULTI_SELECT
      const newFilters = { ...filters }

      if (attributeCode === 'price') {

        delete newFilters.price
        if (initialPriceRange.current) {
          form.setFieldsValue({
            price: [
              initialPriceRange.current.min,
              initialPriceRange.current.max,
            ],
          })
        }

      } else if (isMulti) {
        if (!newFilters[camelCase(attributeCode)]) {

          newFilters[camelCase(attributeCode)] = {
            in: [option],
          }
        } else if (newFilters[camelCase(attributeCode)].in.includes(option)) {
          newFilters[camelCase(attributeCode)].in.splice(newFilters[camelCase(attributeCode)].in.indexOf(option), 1)
          if (!newFilters[camelCase(attributeCode)].in.length) {
            delete newFilters[camelCase(attributeCode)]
          }
        } else {
          newFilters[camelCase(attributeCode)].in.push(option)
        }
      } else {
        if (newFilters?.[camelCase(attributeCode)]?.in?.[0] === option) {
          delete newFilters[camelCase(attributeCode)]
        } else {
          newFilters[camelCase(attributeCode)] = {
            in: [option],
          }
        }
      }
      onFilterChange(newFilters)
    }

    const _handleFieldsChange = (changedFields: FieldData[]) => {
      const newFilters = { ...filters }
      changedFields.forEach((field) => {
        (field.name as string[]).forEach((name) => {
          if (name === 'search') {
            setState((prevState) => update(prevState, {
              search: { $set: field.value },
            }))
          } else if (name === 'price') {
            if (Array.isArray(field.value) && field.value.length === 2) {
              newFilters.price = {
                from: field.value[0],
                to: field.value[1],
              }
              onFilterChange(newFilters)
            }
          }
        })
      })
    }

    const _handleToggleFilterSection = (attributeCode: string) => {
      const tabs = { ...state.tabs }
      tabs[attributeCode] = {
        ...tabs[attributeCode],
        open: !state.tabs[attributeCode].open,
      }
      setState((prevState) => update(prevState, {
        tabs: {
          $set: tabs,
        },
      }))
    }

    const _handleShowMore = (attributeCode: string): void => {
      const tabs = { ...state.tabs }
      tabs[attributeCode].expanded = true
      setState((prevState) => update(prevState, {
        tabs: {
          $set: tabs,
        },
      }))
    }

    const _handleShowLess = (attributeCode: string): void => {
      const tabs = { ...state.tabs }
      tabs[attributeCode].expanded = false
      setState((prevState) => update(prevState, {
        tabs: {
          $set: tabs,
        },
      }))
    }

    useEffect(() => {
      const tabs: { [k: string]: FilterState } = {}
      for (let t = 0; t < aggregations.length; t++) {
        const aggregationOveride = storeConfigData?.storeConfig?.aggregationTypeOverrides?.find((ove) => ove.attributeCode === aggregations[t].attributeCode)
        const options = state.search ? aggregations[t].options.filter((opt) => opt.label.toLowerCase().includes(state.search.toLowerCase())) : aggregations[t].options
        if (state.tabs[aggregations[t].attributeCode]) {
          const expanded = state.tabs[aggregations[t].attributeCode].expanded && options.length > maxOptions
          tabs[aggregations[t].attributeCode] = {
            open: state.tabs[aggregations[t].attributeCode].open,
            expanded,
            requiresExpansion: options.length > maxOptions,
            initialVisible: maxOptions,
            aggregationType: aggregationOveride?.aggregationType || aggregations[t].type,
          }
        } else {
        // TODO: Pull from config
          tabs[aggregations[t].attributeCode] = {
            open: true,
            expanded: false,
            requiresExpansion: options.length > maxOptions,
            initialVisible: maxOptions,
            aggregationType: aggregationOveride?.aggregationType || aggregations[t].type,
          }
        }
        if (!loading) {
          delete tabs[aggregations[t].attributeCode].loading
        }
      }
      setState((prevState) => update(prevState, {
        tabs: { $set: tabs },
      }))
    }, [aggregations, state.search])

    useEffect(() => {
      if (priceFilter?.options && !initialPriceRange.current) {
        initialPriceRange.current = {
          min: +priceFilter.options[0].label,
          max: +priceFilter.options[priceFilter.options.length - 1].label,
        }
      }
    }, [priceFilter])

    useEffect(() => {
      setOptions()
    }, [state.tabs])

    // Mobile only

    const sidebarContainerProps = useSpring({
      dspl: open ? 1 : 0,
      backgroundColor: open ? SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.25) : theme.colors.misc.transparent,
    })

    const containerProps = useSpring({
      right: open ? '0%' : '-100%',
    })

    useEffect(() => {
      NavigationPlugin.shared().preventScroll(open)
      if (!open) {
        document && enableBodyScroll(document as unknown as Document)
      } else {
        document && disableBodyScroll(document as unknown as Document, {
          allowTouchMove: (el: Element) => {
            while (el && el !== document.body) {
              if (el.classList.contains('scroll-lock-ignore')) {
                return true
              }
              el = el.parentElement
            }
          },
        })
      }
      return () => {
        NavigationPlugin.shared().preventScroll(false)
        document && enableBodyScroll(document as unknown as Document)
      }
    }, [open])

    useEffect(() => {
      if (filters.price) {
        form.setFieldsValue({
          price: [filters.price.from, filters.price.to],
        })
      }
    }, [filters.price, form])

    let filterGroup: AggregationFragment
    let option: AggregationOptionFragment

    const displayedFilters = { ...filters }
    delete displayedFilters.name
    const filterKeys = Object.keys(displayedFilters)
    let filterKey: string
    let filterValue: string

    const $filters = (
      <Container>
        <TopSection>
          <Header>
            <LeftHeader>
              <Iconwrapper>
                <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.FILTER} />
              </Iconwrapper>
              <Title variant='t3' className='filter-title'>Filter</Title>
            </LeftHeader>
            <RightHeader>
              <DeviceContainer mobile>
                <Close onClick={onToggleFilters}>
                  <Icon className=" icon close" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CLOSE} />
                </Close>
              </DeviceContainer>
            </RightHeader>
          </Header>
          <If condition={!!filterKeys.length}>
            <CurrentFilters>
              <CurrentHeader>
                <Heading
                  variant='h8'
                  className='filter-heading'
                  color={theme.colors.green.greenVogue}>
              Currently applied
                </Heading>
                <Paragraph
                  variant='p2'
                  decoration='underline'
                  className='clear-filters'
                  color={theme.colors.orange.piper}
                  onClick={_handleClearFilters} >
              Clear filters
                </Paragraph>
              </CurrentHeader>
              <CurrentFiltersBody>
                <For each='filterKey' of={filterKeys}>
                  <If condition={filterKey === 'price'}>
                    <Label
                      radius={true}
                      key={`${filterKey}-${filters[filterKey].from}`}
                      color={theme.colors.green.bottleGreen}
                      backgroundColor={theme.colors.yellow.dolly}
                      onClose={() => _handleFilter(snakeCase(filterKey), filters[filterKey].from)}>
                      {`Price : R${filters[filterKey].from} - R${filters[filterKey].to}`}
                    </Label>
                  </If>
                  <If condition={filterKey !== 'price' && !!filters[filterKey]?.in}>
                    <For each='filterValue' of={filters[filterKey].in}>
                      <Label
                        radius={true}
                        key={`${filterKey}-${filterValue}`}
                        color={theme.colors.green.bottleGreen}
                        backgroundColor={theme.colors.yellow.dolly}
                        onClose={() => _handleFilter(snakeCase(filterKey), filterValue)}>
                        {filteredAggregations
                          ?.find((agg) => camelCase(agg.attributeCode) === filterKey)?.options
                          ?.find((opt) => opt.value === filterValue)?.label ?? filterValue}
                      </Label>
                    </For>
                  </If>
                </For>
                <LoadingOverlay active={loading} />
              </CurrentFiltersBody>
            </CurrentFilters>
          </If>
          <Paragraph variant='p1' className='result-count' bold>
          Found {productCount} results
          </Paragraph>
          <Form onFieldsChange={_handleFieldsChange} form={form} className='search' >
            <TextInput showLabel={false} name='search' placeholder='Search Filters' wrapperClassName='search-input' />
          </Form>
        </TopSection>
        <Scroller>
          <For each='filterGroup' of={filteredAggregations}>
            <Choose>
              <When condition={state.tabs[filterGroup.attributeCode]?.aggregationType === AggregationTypeEnum.CATEGORY}>
                <FilterGroup key={filterGroup.attributeCode}>
                  <FilterHeading
                    open={state.tabs[filterGroup.attributeCode]?.open}
                    onClick={() => _handleToggleFilterSection(filterGroup.attributeCode)}>
                    {filterGroup.label}
                    <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_DOWN} />
                  </FilterHeading>
                  <If condition={state.tabs[filterGroup.attributeCode]?.open}>
                    <Filters>
                      <For
                        each='option'
                        of={state.options[filterGroup.attributeCode] || []}>
                        <Filter
                          key={option.value}
                          href='#'
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => _handleFilter(filterGroup.attributeCode, option.value, e)}
                          active={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                          <If condition={state.tabs[filterGroup.attributeCode]?.loading === option.value}>
                            <FilterLoaderContainer>
                              <SmallLoader />
                            </FilterLoaderContainer>
                          </If>
                          <Paragraph variant='p2' className='text-item'>
                            {option.label}
                          </Paragraph>
                          <Count>
                        ({option.count})
                          </Count>
                        </Filter>
                      </For>
                      <LoadingOverlay active={loading} />
                    </Filters>
                    <If condition={state.tabs[filterGroup.attributeCode]?.requiresExpansion}>
                      <Seperator />
                      <Choose>
                        <When condition={state.tabs[filterGroup.attributeCode].expanded}>
                          <Button className='toggle-button' variant='nav' title='Show Less' onClick={() => _handleShowLess(filterGroup.attributeCode)} fullWidth />
                        </When>
                        <Otherwise>
                          <Button className='toggle-button' variant='nav' title='Show More' onClick={() => _handleShowMore(filterGroup.attributeCode)} fullWidth />
                        </Otherwise>
                      </Choose>
                    </If>
                  </If>
                </FilterGroup>
              </When>
              <When condition={state.tabs[filterGroup.attributeCode]?.aggregationType === AggregationTypeEnum.SINGLE_SELECT}>
                <FilterGroup key={filterGroup.attributeCode}>
                  <FilterHeading
                    open={state.tabs[filterGroup.attributeCode]?.open}
                    onClick={() => _handleToggleFilterSection(filterGroup.attributeCode)}>
                    {filterGroup.label}
                    <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_DOWN} />
                  </FilterHeading>
                  <If condition={state.tabs[filterGroup.attributeCode]?.open}>
                    <Filters>
                      <For
                        each='option'
                        of={state.options[filterGroup.attributeCode] || []}>
                        <Filter
                          key={option.value}
                          href='#'
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => _handleFilter(filterGroup.attributeCode, option.value, e)}
                          active={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                          <If condition={state.tabs[filterGroup.attributeCode]?.loading === option.value}>
                            <FilterLoaderContainer>
                              <SmallLoader />
                            </FilterLoaderContainer>
                          </If>
                          <Paragraph variant='p2' className='text-item'>
                            {option.label}
                          </Paragraph>
                          <Count>
                        ({option.count})
                          </Count>
                        </Filter>
                      </For>
                      <LoadingOverlay active={loading} />
                    </Filters>
                    <If condition={state.tabs[filterGroup.attributeCode]?.requiresExpansion}>
                      <Seperator />
                      <Choose>
                        <When condition={state.tabs[filterGroup.attributeCode].expanded}>
                          <Button className='toggle-button' variant='nav' title='Show Less' onClick={() => _handleShowLess(filterGroup.attributeCode)} fullWidth />
                        </When>
                        <Otherwise>
                          <Button className='toggle-button' variant='nav' title='Show More' onClick={() => _handleShowMore(filterGroup.attributeCode)} fullWidth />
                        </Otherwise>
                      </Choose>
                    </If>
                  </If>
                </FilterGroup>
              </When>
              <When condition={state.tabs[filterGroup.attributeCode]?.aggregationType === AggregationTypeEnum.MULTI_SELECT}>
                <FilterGroup key={filterGroup.attributeCode}>
                  <FilterHeading
                    open={state.tabs[filterGroup.attributeCode]?.open}
                    onClick={() => _handleToggleFilterSection(filterGroup.attributeCode)} >
                    {filterGroup.label}
                    <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_DOWN} />
                  </FilterHeading>
                  <If condition={state.tabs[filterGroup.attributeCode]?.open}>
                    <Filters>
                      <For
                        each='option'
                        of={state.options[filterGroup.attributeCode] || []}>
                        <Filter
                          key={option.value}
                          href='#'
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => _handleFilter(filterGroup.attributeCode, option.value, e)}
                          active={filters?.[filterGroup.attributeCode]?.in?.includes(option.value)}>
                          <Choose>
                            <When condition={state.tabs[filterGroup.attributeCode]?.loading === option.value}>
                              <FilterLoaderContainer>
                                <SmallLoader />
                              </FilterLoaderContainer>
                            </When>
                            <Otherwise>
                              <CheckBox className='check-box' checked={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                                <If condition={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                                  <Icon className="icon" color={theme.colors.white.pureWhite} icon={LocalIconEnums.CHECK} />
                                </If>
                              </CheckBox>
                            </Otherwise>
                          </Choose>
                          <Paragraph variant='p2' className='text-item'>
                            {option.label}
                          </Paragraph>
                          <Count>
                        ({option.count})
                          </Count>
                        </Filter>
                      </For>
                      <LoadingOverlay active={loading} />
                    </Filters>
                    <If condition={state.tabs[filterGroup.attributeCode]?.requiresExpansion}>
                      <Seperator />
                      <Choose>
                        <When condition={state.tabs[filterGroup.attributeCode].expanded}>
                          <Button className='toggle-button' variant='nav' title='Show Less' onClick={() => _handleShowLess(filterGroup.attributeCode)} fullWidth />
                        </When>
                        <Otherwise>
                          <Button className='toggle-button' variant='nav' title='Show More' onClick={() => _handleShowMore(filterGroup.attributeCode)} fullWidth />
                        </Otherwise>
                      </Choose>
                    </If>
                  </If>
                </FilterGroup>
              </When>
              <When condition={state.tabs[filterGroup.attributeCode]?.aggregationType === AggregationTypeEnum.SLIDER}>
                <FilterGroup key={filterGroup.attributeCode}>
                  <FilterHeading
                    open={state.tabs[filterGroup.attributeCode]?.open}
                    onClick={() => _handleToggleFilterSection(filterGroup.attributeCode)} >
                    {filterGroup.label}
                    <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_DOWN} />
                  </FilterHeading>
                  <If condition={state.tabs[filterGroup.attributeCode]?.open}>
                    <Filters>
                      <Form onFieldsChange={_handleFieldsChange} form={form} loading={loading} className='rangeInput'>
                        <RangeInput
                          name='price'
                          min={+priceFilter?.options[0].label}
                          max={+priceFilter?.options[(priceFilter?.options).length - 1].label}
                          step={150}/>
                      </Form>
                      <LoadingOverlay active={loading} />
                    </Filters>
                  </If>
                </FilterGroup>
              </When>
            </Choose>
          </For>
        </Scroller>
      </Container>
    )

    return (
      <>
        <Choose>
          <When condition={aggregations.length === 0}>
            <ProductFiltersSekeleton />
          </When>
          <Otherwise>
            <DeviceContainer desktop tablet>
              {$filters}
            </DeviceContainer>
            <DeviceContainer mobile>
              <MenuContainer
                className='scroll-lock-ignore'
                style={{
                  ...sidebarContainerProps,
                  display: sidebarContainerProps.dspl.to((displ) =>
                    displ === 0 ? 'none' : 'initial',
                  ),
                }} onClick={_handleBackgroundClose}>
                <MobileFilterContainer style={containerProps}>
                  {$filters}
                </MobileFilterContainer>
              </MenuContainer>
            </DeviceContainer>
          </Otherwise>
        </Choose>

      </>
    )

  },
)
