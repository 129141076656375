import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ $backgroundColor?: string }>`
  width: 100%;
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  background-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.white.pureWhite};
`

export interface CardProps {
  children: React.ReactNode
  className?: string
  topContainer?: boolean
  padding?: string
  backgroundColor?: string
}

export function Card({ children, className, backgroundColor }: CardProps): JSX.Element {

  return (
    <Container className={className} $backgroundColor={backgroundColor}>
      {children}
    </Container>
  )

}
