import React, { } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api/index'
import { NavigationCartButton, NavigationProfile } from '@molecules/navigation'
import { NavigationFavouriteButton } from '@molecules/navigation/NavigationFavouriteButton'
import { DesktopSearch } from '@molecules/search'

const Header = styled.div`
  border-style: solid;
  
    border-bottom-width: 0.313vw;

    @media (min-width: 30em) {
      border-bottom-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-bottom-width: 1px;
    }
  
  border-color: ${(props): string => props.theme.colors.grey.pampas};
`

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
    width: 450.000vw;

    @media (min-width: 30em) {
      width: 450.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 100.000vw;
    }

    @media (min-width: 90em) {
      width: 1440px;
    }
  
  
    height: 22.500vw;

    @media (min-width: 30em) {
      height: 22.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 5.000vw;
    }

    @media (min-width: 90em) {
      height: 72px;
    }
  
  
    padding: 0 17.500vw;

    @media (min-width: 30em) {
      padding: 0 17.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 3.889vw;
    }

    @media (min-width: 90em) {
      padding: 0 56px;
    }
  



  .favourite {
    
    margin-right: 9.375vw;

    @media (min-width: 30em) {
      margin-right: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 2.083vw;
    }

    @media (min-width: 90em) {
      margin-right: 30px;
    }
  
  }
  .cart {
    
    margin-right: 9.375vw;

    @media (min-width: 30em) {
      margin-right: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 2.083vw;
    }

    @media (min-width: 90em) {
      margin-right: 30px;
    }
  
  }
  .search {
    
    margin-right: 18.750vw;

    @media (min-width: 30em) {
      margin-right: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 4.167vw;
    }

    @media (min-width: 90em) {
      margin-right: 60px;
    }
  
  }
`

const HomeLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  
    margin-right: 20.625vw;

    @media (min-width: 30em) {
      margin-right: 20.625vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 4.583vw;
    }

    @media (min-width: 90em) {
      margin-right: 66px;
    }
  

`
const MobileLogoContainer = styled.div`
  
    width: 12.500vw;

    @media (min-width: 30em) {
      width: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.778vw;
    }

    @media (min-width: 90em) {
      width: 40px;
    }
  
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
    
`

const DesktopLogoContainer = styled.div`
  
    width: 59.375vw;

    @media (min-width: 30em) {
      width: 59.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 13.194vw;
    }

    @media (min-width: 90em) {
      width: 190px;
    }
  
`
const CovidAction = styled.a`
  font-family: open-sans;
  border-style: solid;
  text-decoration: none;
  color: ${(props): string => props.theme.colors.grey.silver};
  font-weight: 400;
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
  
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.250vw;
    }

    @media (min-width: 90em) {
      line-height: 18px;
    }
  
  
    margin-right: 5.000vw;

    @media (min-width: 30em) {
      margin-right: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-right: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-right: 16px;
    }
  
  :hover {
    text-decoration: underline;
  }

`

const SearchHolder = styled.div`

  flex-grow: 1;
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
  

`

export function DesktopNavigationBar(): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const navigate = useNavigate()

  const _handleHomeClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <Header>
      <Container>

        <HomeLink href='/' onClick={_handleHomeClicked}>
          <MobileLogoContainer>
            <Icon icon={LocalIconEnums.MOBILE_LOGO} color={theme.colors.green.watercourse} />
          </MobileLogoContainer>
          <DesktopLogoContainer>
            <Icon className='' icon={LocalIconEnums.DESKTOP_LOGO} color={theme.colors.green.watercourse} />
          </DesktopLogoContainer>
        </HomeLink>
        <If condition={appData.app.showHeaderContent}>

          <Choose>
            <When condition={appData.app.showSearch}>
              <DesktopSearch className='search' />
            </When>
            <Otherwise>
              <SearchHolder />
            </Otherwise>
          </Choose>

          <NavigationFavouriteButton className='favourite' />

          <NavigationCartButton className='cart' />

          <NavigationProfile className='profile' />
        </If>
      </Container>
    </Header>
  )

}
