import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { BoxShadow, ResponsivePXValue } from '@components/Theme'
import { MenuCategoryBaseFragment } from '@hooks/api/index'
import { Page } from '@organisms/content'

type GroupCategory = MenuCategoryBaseFragment['children'][0]['children'][0]
type SubCategory = MenuCategoryBaseFragment['children'][0]['children'][0]['children'][0]

const Container = styled.div`
  position: absolute;
  columns: 4;
  width: 100%;
  /* 
    height: 134.375vw;

    @media (min-width: 30em) {
      height: 134.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 29.861vw;
    }

    @media (min-width: 90em) {
      height: 430px;
    }
  ; */
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  
    top: 17.188vw;

    @media (min-width: 30em) {
      top: 17.188vw;
    }

    @media (min-width: 30.0625em) {
      top: 3.819vw;
    }

    @media (min-width: 90em) {
      top: 55px;
    }
  
  
    padding: 5.000vw 20.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw 20.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 4.444vw;
    }

    @media (min-width: 90em) {
      padding: 16px 64px;
    }
  
  
    column-gap: 20.000vw;

    @media (min-width: 30em) {
      column-gap: 20.000vw;
    }

    @media (min-width: 30.0625em) {
      column-gap: 4.444vw;
    }

    @media (min-width: 90em) {
      column-gap: 64px;
    }
  
  
`

const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
  
    top: 17.188vw;

    @media (min-width: 30em) {
      top: 17.188vw;
    }

    @media (min-width: 30.0625em) {
      top: 3.819vw;
    }

    @media (min-width: 90em) {
      top: 55px;
    }
  
  display: flex;
  flex-direction: column;
  ${BoxShadow}

`

const CategoryGroup = styled.div`
  display: inline-block;
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 100%;
    }

    @media (min-width: 90em) {
      width: 100%;
    }
  
  
    margin: 2.500vw 0;

    @media (min-width: 30em) {
      margin: 2.500vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.556vw 0;
    }

    @media (min-width: 90em) {
      margin: 8px 0;
    }
  
`

const CategoryHeader = styled.div`
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 8px;
    }
  
  background-color: ${(props): string => props.theme.colors.green.watercourse};
  color: ${(props): string => props.theme.colors.white.pureWhite};
  
    font-size: 4.375vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
  
    border-radius: 0.625vw;

    @media (min-width: 30em) {
      border-radius: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.139vw;
    }

    @media (min-width: 90em) {
      border-radius: 2px;
    }
  
  font-weight: 700;
  font-family: open-sans;
  cursor: pointer;
`

const SubCategoryContainer = styled.div``

const SubCategoryItem = styled.div`
  
    padding: 1.875vw 2.500vw;

    @media (min-width: 30em) {
      padding: 1.875vw 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.417vw 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 6px 8px;
    }
  
  
    margin: 0.625vw 0;

    @media (min-width: 30em) {
      margin: 0.625vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.139vw 0;
    }

    @media (min-width: 90em) {
      margin: 2px 0;
    }
  
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  font-weight: 600;
  font-family: open-sans;
  cursor: pointer;
  &:hover {
    font-weight: 800;
  }
`

const SelectedSubCategoryItem = styled.div`
  
    padding: 1.875vw 2.500vw;

    @media (min-width: 30em) {
      padding: 1.875vw 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.417vw 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 6px 8px;
    }
  
  
    margin: 0.625vw 0;

    @media (min-width: 30em) {
      margin: 0.625vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.139vw 0;
    }

    @media (min-width: 90em) {
      margin: 2px 0;
    }
  
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  font-weight: 600;
  font-family: open-sans;
  cursor: pointer;
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.theme.colors.black.pureBlack}`)}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

export interface DesktopCategoryMenuProps {
  mainNav: MenuCategoryBaseFragment
  onSelect?: (path: string) => void
  onClose?: () => void
}

export function DesktopCategoryMenu({ mainNav, onSelect, onClose }: DesktopCategoryMenuProps): JSX.Element {

  const [path, setPath] = useState<string | null>(null)
  const [id, setId] = useState<number | null>(null)
  const location = useLocation()
  let groupCategory: GroupCategory
  let subCategory: SubCategory

  useEffect(() => {
    if (mainNav.menuContent) {
      if (id !== mainNav.id) {
        setId(mainNav.id)
        setPath(location.pathname)
      } else if (path !== location.pathname) {
        onClose?.()
      }
    }
  }, [location.pathname, mainNav.id])

  return (
    <Choose>
      <When condition={!!mainNav.menuContent}>
        <ContentContainer>
          <Page page={mainNav.menuContent} />
        </ContentContainer>
      </When>
      <Otherwise>
        <Container>
          <For each='groupCategory' of={mainNav.children}>
            <CategoryGroup key={groupCategory.id}>
              <CategoryHeader onClick={() => onSelect(groupCategory.canonicalUrl)}>
                {groupCategory.name}
              </CategoryHeader>
              <If condition={!!groupCategory.children}>
                <SubCategoryContainer>
                  <For each='subCategory' of={groupCategory.children}>
                    <Choose>
                      <When condition={location.pathname.includes(subCategory.canonicalUrl)}>
                        <SelectedSubCategoryItem key={subCategory.id} onClick={() => onSelect(subCategory.canonicalUrl)}>
                          {subCategory.name}
                        </SelectedSubCategoryItem>
                      </When>
                      <Otherwise>
                        <SubCategoryItem key={subCategory.id} onClick={() => onSelect(subCategory.canonicalUrl)}>
                          {subCategory.name}
                        </SubCategoryItem>
                      </Otherwise>
                    </Choose>
                  </For>
                </SubCategoryContainer>
              </If>
            </CategoryGroup>
          </For>
        </Container>
      </Otherwise>
    </Choose>

  )

}
