import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export const FormBlock = styled.div`
  width: 100%;
  max-height: 95vh;
  padding-top: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  
    width: 87.500vw;

    @media (min-width: 30em) {
      width: 125.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 60%;
    }

    @media (min-width: 90em) {
      width: 60%;
    }
  ;
`
