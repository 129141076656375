import React from 'react'

import styled from 'styled-components'

import { Skeleton, SkeletonNode } from '@atoms/layout'
import { ResponsivePXValue } from '@components/Theme'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
    width: CALC(100%);

    @media (min-width: 30em) {
      width: CALC(100%);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100%);
    }

    @media (min-width: 90em) {
      width: CALC(100%);
    }
  
  overflow-x: hidden;

  .search-input {
    
    margin: 5.000vw 1.250vw;

    @media (min-width: 30em) {
      margin: 5.000vw 1.250vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0.278vw;
    }

    @media (min-width: 90em) {
      margin: 16px 4px;
    }
  
  }
  .toggle-button {
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
    
    margin: 0.625vw 0;

    @media (min-width: 30em) {
      margin: 0.625vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.139vw 0;
    }

    @media (min-width: 90em) {
      margin: 2px 0;
    }
  
    font-weight: bold;
  }
`

export const ProductFiltersSekeleton = React.memo(
  function ProductFiltersSekeleton(): JSX.Element {

    let skeletonIndex!: number

    return (
      <DeviceContainer desktop tablet>
        <Container>
          <Skeleton
            height={{ mobile: '157px', tablet: '350px', desktop: '600px' }}
            direction='column'
            justify='flex-start'>
            <SkeletonNode
              height={{ mobile: '20px', tablet: '50px', desktop: '55px' }} />
            <SkeletonNode
              color='gallery'
              height={{ mobile: '18px', tablet: '45px', desktop: '45px' }}
            />
            <Skeleton
              padding={{ mobile: '4px', tablet: '10px', desktop: '16px' }}
              height={{ mobile: '20px', tablet: '500px', desktop: '500px' }}
              gap='16px'
              justify='flex-start'
              align='flex-start'
              direction='column'
              loop={15}>
              <SkeletonNode
                key={skeletonIndex}
                color='gallery'
                variWidth={{ mobile: '100%', tablet: '40%-80%', desktop: '40%-80%' }}
                height={{ mobile: '18px', tablet: '20px', desktop: '20px' }}
              />
            </Skeleton>
          </Skeleton>
        </Container>
      </DeviceContainer>
    )

  },

)
