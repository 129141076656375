import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export const ModalFormContainer = styled.div`
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    width: 100vw;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 34.722vw;
    }

    @media (min-width: 90em) {
      width: 500px;
    }
  

  .messageClass{
    
    margin: 6.250vw 0;

    @media (min-width: 30em) {
      margin: 6.250vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.389vw 0;
    }

    @media (min-width: 90em) {
      margin: 20px 0;
    }
  
  }
`
