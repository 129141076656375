import React from 'react'

import styled from 'styled-components'

import { LocalIconEnums, Button, ButtonColors, ButtonVariant, ButtonShape, IconButton } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'

const SocialWrapper = styled.div<{ align: 'left' | 'right' | 'center' }>`
  display: flex;
  align-items: center;
  justify-content: ${(props): string => props.align === 'left' ? 'flex-start' : props.align === 'right' ? 'flex-end' : 'center'};
  
    gap: 7.500vw;

    @media (min-width: 30em) {
      gap: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.667vw;
    }

    @media (min-width: 90em) {
      gap: 24px;
    }
  
  .socialIcon {
    
    width: 10.000vw;

    @media (min-width: 30em) {
      width: 15.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.333vw;
    }

    @media (min-width: 90em) {
      width: 48px;
    }
  
    
    height: 10.000vw;

    @media (min-width: 30em) {
      height: 15.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.333vw;
    }

    @media (min-width: 90em) {
      height: 48px;
    }
  
  }

`

export function SocialAccounts({ align = 'center' }: { align?: 'left' | 'right' | 'center' }): JSX.Element {

  const socialButtonColors: ButtonColors = {
    color: theme.colors.green.watercourse,
    backgroundColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.green.watercourse,
    disabledColor: theme.colors.white.pureWhite,
    disabledBackgrondColor: theme.colors.white.pureWhite,
    borderColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
  }

  const defaultProps = {
    target: '_blank',
    className: 'socialIcon',
    variant: 'ghost' as ButtonVariant,
    shape: 'round' as ButtonShape,
  }

  return (
    <SocialWrapper align={align}>
      <IconButton {...defaultProps} href='https://www.facebook.com/FaithfulToNature' colors={socialButtonColors} icon={LocalIconEnums.FACEBOOK} blank={true}/>
      <IconButton {...defaultProps} href='https://www.tiktok.com/@faithfultonature' colors={socialButtonColors} icon={LocalIconEnums.TIK_TOK} blank={true}/>
      <IconButton {...defaultProps} href='https://www.instagram.com/faithfultonature/' colors={socialButtonColors} icon={LocalIconEnums.INSTAGRAM} blank={true}/>
      <IconButton {...defaultProps} href='https://za.pinterest.com/FaithfultoNature/_created/' colors={socialButtonColors} icon={LocalIconEnums.PINTEREST} blank={true}/>
    </SocialWrapper>
  )

}
