import React from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Loader } from '@atoms/notifications'
import { Heading, LineEllipsis, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageHeadingSectionFragment, useCustomerQuery, useGetAppQuery } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { CustomerTypeEnum, DeviceTypeEnum, PageHorizontalAlignment } from '@uctypes/api/globalTypes'

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    padding: 10.000vw;

    @media (min-width: 30em) {
      padding: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 2.222vw;
    }

    @media (min-width: 90em) {
      padding: 32px;
    }
  
`

const Container = styled.div`
  width: 100%;
`

const HeadingContainer = styled.div<{foregroundColor: string }>`
  width: 100%;
  
    padding: 3.750vw 0.000vw;

    @media (min-width: 30em) {
      padding: 3.750vw 0.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.833vw 0.000vw;
    }

    @media (min-width: 90em) {
      padding: 12px 0px;
    }
  

   color: ${(props): string => props.foregroundColor};
`

const DescriptionContainer = styled.div<{ alignment: string }>`
  width: 100%;
  display: flex;
  text-align: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'};
`
export interface PageHeadingSectionProps {
  section: PageHeadingSectionFragment
}

export function PageHeadingSection({ section }: PageHeadingSectionProps): JSX.Element {

  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { foregroundColor, forUserMobileWelcomeMessage } = section
  const theme = useTheme()

  const alignment = section.horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : section.horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  return (
    <Container>
      <Choose>
        <When condition={forUserMobileWelcomeMessage}>
          <HeadingContainer foregroundColor={section.foregroundColor}>
            <LineEllipsis
              variant={section.variant}
              text={`Welcome ${customerData?.currentCustomer?.firstname}`}
              maxLine={25}
              ellipsis='...'
              trimRight
              basedOn='letters'
              align={alignment}
            />
          </HeadingContainer>
        </When>
        <Otherwise>
          <HeadingContainer foregroundColor={section.foregroundColor}>
            <Heading
              variant={section.variant}
              color={section.foregroundColor}
              align={alignment}>
              {section.heading}
            </Heading>
          </HeadingContainer>
          <If condition={!!section.description}>
            <DescriptionContainer alignment={alignment}>
              <HTML color={section.foregroundColor}>
                {section.description}
              </HTML>
            </DescriptionContainer>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )

}
