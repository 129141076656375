
import React from 'react'

import loadable from '@loadable/component'
import { LoadingContainer } from '@atoms/misc'
import { Loader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/FAQ')

const FAQ = loadable(() => import(/* webpackChunkName: "FAQ" */'@pages/FAQ'), {
  resolveComponent: (components) => components.FAQ,
})

export function FAQAsync(): JSX.Element {

  return <FAQ fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
