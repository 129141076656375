import React from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Button, ButtonColors } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/images'
import { Paragraph, Tag } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CartFragment, CartItemFragment, DiscountFragment, useCustomerQuery, useGetStoreConfigQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { Cell, Row, Table } from '@molecules/tables'
import { WidgetDropDownCart } from '@organisms/content/WidgetDropDownCart'
import { CustomerTypeEnum, ProductStockStatus } from '@uctypes/api/globalTypes'

import { ProductDropDownCartCard } from './ProdutDropDownCartCard'

const Container = styled.div`
  width: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  flex-shrink: 0;
`

const ProductsList = styled.div`
  overflow-y: scroll;
  flex-grow: 1;
`

const Footer = styled.div`
  flex-shrink: 0;
`

const Empty = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
    height: 12.500vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
  
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.deYork, 0.15)};
  .empty-text {
    margin: 0;
  }
`

const Summary = styled.div`
  
    padding: 1.563vw 0;

    @media (min-width: 30em) {
      padding: 1.563vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw 0;
    }

    @media (min-width: 90em) {
      padding: 5px 0;
    }
  
  .tag {
    width: fit-content;
    
    margin: 0 0 1.563vw;

    @media (min-width: 30em) {
      margin: 0 0 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.347vw;
    }

    @media (min-width: 90em) {
      margin: 0 0 5px;
    }
  
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 4.375vw;

    @media (min-width: 30em) {
      gap: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.972vw;
    }

    @media (min-width: 90em) {
      gap: 14px;
    }
  
  .button {
    flex-grow: 1;
  }
`

export interface DropDownCartProps {
  cart: CartFragment
  addedItems?: CartItemFragment[]
  shouldClose: () => void
}

export function DropDownCart({ cart, addedItems = [], shouldClose }: DropDownCartProps): JSX.Element {

  const theme = useTheme()
  const navigate = useNavigate()
  const { data: customerData } = useCustomerQuery()
  const { data: storeData } = useGetStoreConfigQuery()
  const outOfStock = cart?.items?.filter((item) => item.product.stockStatus === ProductStockStatus.OUT_OF_STOCK) || []

  const _handleCheckout = (): void => {
    if (!outOfStock.length) {
      if (customerData?.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
        shouldClose()
        ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
      } else if (!customerData?.currentCustomer.mobileVerified && storeData.storeConfig.otpConfirmationRequired) {
        shouldClose()
        ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.VALIDATE_MOBILE)
      } else {
        shouldClose()
        navigate('/checkout')
      }
    }
  }

  const _handleGoToCart = (): void => {
    shouldClose()
    navigate('/cart')
  }

  const goToButtonColors: ButtonColors = {
    color: theme.colors.green.bottleGreen,
    backgroundColor: theme.colors.white.pureWhite,
    borderColor: theme.colors.green.bottleGreen,
    hoverColor: theme.colors.green.bottleGreen,
    hoverBackgroundColor: theme.colors.yellow.cornSilk,
    hoverBorderColor: theme.colors.green.bottleGreen,
  }

  const checkoutButtonColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.bottleGreen,
    borderColor: theme.colors.green.bottleGreen,
    hoverColor: theme.colors.green.bottleGreen,
    hoverBackgroundColor: theme.colors.yellow.cornSilk,
    hoverBorderColor: theme.colors.green.bottleGreen,
  }

  let cartItem: CartItemFragment
  let discount: DiscountFragment

  const items = (addedItems.length ? addedItems : (cart?.items || [])).slice().reverse()

  const deliveryValue = cart?.shippingAddresses?.[0]?.selectedShippingMethod
    ? cart?.shippingAddresses?.[0]?.selectedShippingMethod?.priceInclTax?.value === 0
      ? 'Free'
      : SiteHelper.formatCurrency(cart?.shippingAddresses?.[0]?.selectedShippingMethod?.priceInclTax)
    : '-- --'

  return (
    <Container>
      <Choose>
        <When condition={!!cart?.items?.length}>
          <If condition={!addedItems.length}>
            <Header>
              <WidgetDropDownCart />
            </Header>
          </If>
          <ProductsList>
            <For each='cartItem' of={items}>
              <ProductDropDownCartCard
                cart={cart}
                cartItem={cartItem}
                key={cartItem.id}
                forAdded={!!addedItems.length}
                shouldClose={shouldClose} />
            </For>
          </ProductsList>
          <Footer>
            <Summary>
              <Table>
                <Row>
                  <Cell><Tag variant='t2' className='tag'>Items</Tag></Cell>
                  <Cell align='end'><Tag variant='t2' className='tag'>{cart.totalQuantity}</Tag></Cell>
                </Row>
                <Row>
                  <Cell><Tag variant='t2' className='tag'>Delivery</Tag></Cell>
                  <Cell align='end'><Tag variant='t2' className='tag'>{deliveryValue}</Tag></Cell>
                </Row>
                <For each='discount' of={cart.prices?.discounts || []}>
                  <Row key={discount.label}>
                    <Cell><Tag variant='t2' className='tag' color={theme.colors.green.watercourse}>{discount.label}</Tag></Cell>
                    <Cell align='end'><Tag variant='t2' className='tag' color={theme.colors.green.watercourse}>-{SiteHelper.formatCurrency(discount.amount)}</Tag></Cell>
                  </Row>
                </For>
                <Row>
                  <Cell><Paragraph variant='p2' className='tag'>Total</Paragraph></Cell>
                  <Cell align='end'><Paragraph variant='p2' className='tag'>{SiteHelper.formatCurrency(cart.prices.grandTotal)}</Paragraph></Cell>
                </Row>
              </Table>
            </Summary>
            <ButtonsContainer>
              <Button className='button' colors={goToButtonColors} title='GO TO' rightIcon={LocalIconEnums.CART} onClick={_handleGoToCart} />
              <Button className='button' colors={checkoutButtonColors} title='CHECKOUT' onClick={_handleCheckout} disabled={!!outOfStock.length} />
            </ButtonsContainer>
          </Footer>
        </When>
        <Otherwise>
          <Empty>
            <Paragraph variant='p3' className='empty-text'>Your cart is empty</Paragraph>
          </Empty>
        </Otherwise>
      </Choose>

    </Container>
  )

}
