import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { OrderItemFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { OrderProductCard } from '@molecules/store/OrderProductCard'

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.grey.pampas};
  
    padding: 1.250vw;

    @media (min-width: 30em) {
      padding: 3.438vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.764vw;
    }

    @media (min-width: 90em) {
      padding: 11px;
    }
  ;
  position: relative;
`

const Embla = styled.div`
  position: relative;
  
    overflow: hidden;

    @media (min-width: 30em) {
      overflow: hidden;
    }

    @media (min-width: 30.0625em) {
      overflow: hidden;
    }

    @media (min-width: 90em) {
      overflow: hidden;
    }
  
  width: 100%;
`

const EmblaContainer = styled.div`
  display: flex;
  
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 3.438vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.764vw;
    }

    @media (min-width: 90em) {
      gap: 11px;
    }
  
`

const EmblaSlide = styled.div`
  user-select: none;
  
    flex: 0 0 50.000vw;

    @media (min-width: 30em) {
      flex: 0 0 25%;
    }

    @media (min-width: 30.0625em) {
      flex: 0 0 25%;
    }

    @media (min-width: 90em) {
      flex: 0 0 25%;
    }
  
`

const LeftControl = styled.div<{ visible: boolean }>`
  cursor: pointer;
  user-select: none;  

  ${({ visible }): string => visible
    ? ResponsivePXValue('display', { mobile: 'none', tablet: 'block', desktop: 'block' })
    : ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'none' })}

  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.5)};
  position: absolute;
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: CALC(50% - 4.688vw);

    @media (min-width: 30em) {
      top: CALC(50% - 4.688vw);
    }

    @media (min-width: 30.0625em) {
      top: CALC(50% - 1.042vw);
    }

    @media (min-width: 90em) {
      top: CALC(50% - 15px);
    }
  
  left: -10px;
  
  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.9)};
  }
`
const RightControl = styled.div<{ visible: boolean }>`
  cursor: pointer;
  user-select: none;

  ${({ visible }): string => visible
    ? ResponsivePXValue('display', { mobile: 'none', tablet: 'block', desktop: 'block' })
    : ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'none' })}

  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.5)};
  position: absolute;
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: CALC(50% - 4.688vw);

    @media (min-width: 30em) {
      top: CALC(50% - 4.688vw);
    }

    @media (min-width: 30.0625em) {
      top: CALC(50% - 1.042vw);
    }

    @media (min-width: 90em) {
      top: CALC(50% - 15px);
    }
  
  right: -10px;

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.greenVogue, 0.9)};
  }
`

export interface OrderProductSliderProps {
  products: OrderItemFragment[]
  total: number
  sliderKey?: number
  showCount?: boolean
}

interface OrderProductSliderState {
  prevDisabled: boolean
  nextDisabled: boolean
  currentIndex: number
  displayButtons: boolean
}

const DEFAULT_STATE: OrderProductSliderState = {
  prevDisabled: true,
  nextDisabled: false,
  currentIndex: 0,
  displayButtons: false,
}

export function OrderProductSlider({ products, total, sliderKey, showCount }: OrderProductSliderProps): JSX.Element {

  const { colors } = useTheme()

  const [{ prevDisabled, nextDisabled }, setState] = useState<OrderProductSliderState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    slidesToScroll: 1,
    containScroll: 'trimSnaps',
  })

  const _handleGoLeft = (): void => emblaApi.canScrollPrev() && emblaApi.scrollPrev()

  const _handleGoRight = (): void => emblaApi.canScrollNext() && emblaApi.scrollNext()

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        const prevDisabled = !emblaApi.canScrollPrev()
        const nextDisabled = !emblaApi.canScrollNext()
        const currentIndex = emblaApi.selectedScrollSnap()
        setState((prevState) => update(prevState, {
          prevDisabled: { $set: prevDisabled },
          nextDisabled: { $set: nextDisabled },
          currentIndex: { $set: currentIndex },
        }))
      })
    }
  }, [emblaApi])

  let product: OrderItemFragment

  return (
    <Container>
      <Embla ref={emblaRef} >
        <EmblaContainer>
          <For each='product' of={products}>
            <EmblaSlide key={product.id}>
              <OrderProductCard product={product} />
            </EmblaSlide>
          </For>
        </EmblaContainer>
      </Embla>
      <LeftControl onClick={_handleGoLeft} visible={!prevDisabled}>
        <Icon icon={LocalIconEnums.CHEVRON_LEFT} color={colors.white.pureWhite} />
      </LeftControl>
      <RightControl onClick={_handleGoRight} visible={!nextDisabled}>
        <Icon icon={LocalIconEnums.CHEVRON_RIGHT} color={colors.white.pureWhite} />
      </RightControl>
    </Container>
  )

}
