import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button, ButtonColors, Heading, Link, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

import { CheckBoxInput, TextInput } from '../inputs'
import { Form, useForm } from '../inputs/Form'

const Container = styled.div`

  width: 100%;

  .form-button {
    width: 100%;
    
    margin: 3.750vw 0 3.750vw 0;

    @media (min-width: 30em) {
      margin: 3.750vw 0 3.750vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.833vw 0 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 12px 0 12px 0;
    }
  
  }

  .title {
    
    margin: 0 0 4.375vw 0;

    @media (min-width: 30em) {
      margin: 0 0 4.375vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0.972vw 0;
    }

    @media (min-width: 90em) {
      margin: 0 0 14px 0;
    }
  
  }
  .join {
    
    margin-bottom: 4.375vw;

    @media (min-width: 30em) {
      margin-bottom: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.972vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 14px;
    }
  
  }

  .input {
    
    margin-bottom: 2.813vw;

    @media (min-width: 30em) {
      margin-bottom: 2.813vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.625vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 9px;
    }
  
  }

  .footer {
    
    padding: 4.375vw 7.500vw 0 7.500vw;

    @media (min-width: 30em) {
      padding: 4.375vw 7.500vw 0 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.972vw 1.667vw 0 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 14px 24px 0 24px;
    }
  
  }
`

export interface ThankYouFormProps {
  onLogIn: () => void
}

export function ThankYouForm({ onLogIn }:ThankYouFormProps): JSX.Element {

  const form = useForm()
  const theme = useTheme()

  const _handleSuccess = () => {
    onLogIn()
  }

  const btnColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.black.pureBlack,
    borderColor: theme.colors.black.pureBlack,
    // Hover
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.black.pureBlack,
    hoverBorderColor: theme.colors.black.pureBlack,
    // Disable
    disabledColor: theme.colors.white.pureWhite,
    disabledBackgrondColor: theme.colors.grey.athens,
  }

  return (
    <Container>

      <Heading className="title" variant='h3' >Thanks for joining Faithful to Nature</Heading>
      <Paragraph className="join" variant='p2' align='left'>{'Enter your email address and we\'ll send you a link to reset your password.'}</Paragraph>

      <Form form={form} onFinish={_handleSuccess}>
        <TextInput variant='email' showLabel={true} label="You' ve signed up with Google:" name='email' placeholder='Email address' rules={[{ required: true, message: 'Please enter an email address' }]} className="input" />

        <CheckBoxInput
          name='subscribe'
          options={[{ value: true, title: 'Subscribe to newsletter for unbeatable discounts, great new products and conscious living tips' }]}
          showLabel={true}
        />

        <Button variant='primary' colors={btnColors} className='form-button' title='LOGIN' onClick={() => form.submit()} />
      </Form>

      <Paragraph display='inline' className='footer' align='center' variant='p2'>Go back to </Paragraph><Link decoration='underline' href='#' onClick={onLogIn} >login</Link>

    </Container>
  )

}
