import React, { useEffect, useState } from 'react'

import { PageFragment, PageSectionGroupFragment } from '@hooks/api/index'
import { PageSectionGroup } from '@organisms/index'

export interface PageProps {
  page: PageFragment
}

export const Page = React.memo(
  function Page({ page }: PageProps): JSX.Element {
    const [index, setIndex] = useState<number>(2)

    useEffect(() => {
      if (page && index < page.content.sectionGroups.length) {
        setIndex(index + 2)
      }
    }, [index])

    let section: PageSectionGroupFragment

    return (
      <For each='section' of={[...page?.content?.sectionGroups].splice(0, index) || []}>
        <PageSectionGroup pageSectionGroup={section} key={section.id} />
      </For>
    )

  },
)
