import React, { useState, useEffect, useRef } from 'react'

import update from 'react-addons-update'
import { useNavigate, useLocation } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { NavigationPlugin, NAVIGATION_DEFAULT_STATE } from '@api/local/NavigationPlugin'
import { Card, Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { CartItemFragment, useCartQuery, useGetNavigationQuery } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { useClickOutside } from '@hooks/UseClickOutside'
import useIsomorphicLayoutEffect from '@hooks/UseIsomorphicLayoutEffect'
import { DropDownCart } from '@molecules/store'
import { DropDownCartDisplayType } from '@uctypes/api/globalTypes'

const OuterContainer = styled.div`
  position: relative;
`

const Container = styled.a`
  position: relative;
  display: flex;
  align-items: top;
  cursor: pointer;
  border-style: solid;
  text-decoration: none;
  
    padding: 2.500vw 3.750vw;

    @media (min-width: 30em) {
      padding: 2.500vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 8px 12px;
    }
  
  
    border-width: 0.313vw;

    @media (min-width: 30em) {
      border-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-width: 1px;
    }
  
  
    border-radius: 10.000vw;

    @media (min-width: 30em) {
      border-radius: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 2.222vw;
    }

    @media (min-width: 90em) {
      border-radius: 32px;
    }
  
  
    gap: 1.719vw;

    @media (min-width: 30em) {
      gap: 1.719vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.382vw;
    }

    @media (min-width: 90em) {
      gap: 5.5px;
    }
  
  border-color: ${(props): string => props.theme.colors.grey.gallery};

  :hover {
    border-color: ${(props): string => props.theme.colors.green.bottleGreen};
  }
  
  .icon {
    
    width: 7.813vw;

    @media (min-width: 30em) {
      width: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.736vw;
    }

    @media (min-width: 90em) {
      width: 25px;
    }
  
    
    height: 7.813vw;

    @media (min-width: 30em) {
      height: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.736vw;
    }

    @media (min-width: 90em) {
      height: 25px;
    }
  
  }
`

const Tag = styled.div`
  font-family: open-sans;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
  
    width: 7.813vw;

    @media (min-width: 30em) {
      width: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.736vw;
    }

    @media (min-width: 90em) {
      width: 25px;
    }
  
  
    height: 7.813vw;

    @media (min-width: 30em) {
      height: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.736vw;
    }

    @media (min-width: 90em) {
      height: 25px;
    }
  
  
    border-radius: 7.813vw;

    @media (min-width: 30em) {
      border-radius: 7.813vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.736vw;
    }

    @media (min-width: 90em) {
      border-radius: 25px;
    }
  
  color: ${(props): string => props.theme.colors.white.pureWhite};
  background-color: ${(props): string => props.theme.colors.green.deYork};
  
  .desktopCart {
    
    width: 10.938vw;

    @media (min-width: 30em) {
      width: 10.938vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.431vw;
    }

    @media (min-width: 90em) {
      width: 35px;
    }
  
    
    height: 10.938vw;

    @media (min-width: 30em) {
      height: 10.938vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.431vw;
    }

    @media (min-width: 90em) {
      height: 35px;
    }
  
  }
`

const PopoverContainer = styled.div<{ top: number, right: number }>`
  position: fixed;
  right:  ${(props): number => props.right}px;
  top: ${(props): number => props.top}px;
  z-index: 100;
  .content-card {
    
    box-shadow: 0.000vw 0.000vw 1.875vw 0.625vw rgba(0, 0, 0, 0.1);

    @media (min-width: 30em) {
      box-shadow: 0.000vw 0.000vw 1.875vw 0.625vw rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 30.0625em) {
      box-shadow: 0.000vw 0.000vw 0.417vw 0.139vw rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 90em) {
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    }
  
    
    margin-top: 1.563vw;

    @media (min-width: 30em) {
      margin-top: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-top: 5px;
    }
  
    
    width: 103.125vw;

    @media (min-width: 30em) {
      width: 125.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 27.778vw;
    }

    @media (min-width: 90em) {
      width: 400px;
    }
  
  }
`

export interface NavigationCartButtonProps {
  className?: string
}

const DISPLAY_TIME = 3000
interface NavigationCartButtonState {
  previousCartItems: { [k: string]: number }
  newCartItems: { [k: string]: number }
  firstLoad: boolean
  top: number
  right: number
  scrollOffset: number
  elementBottom: number
}

const DEFAULT_STATE: NavigationCartButtonState = {
  previousCartItems: {},
  newCartItems: {},
  firstLoad: true,
  top: 0,
  right: 0,
  scrollOffset: 0,
  elementBottom: 0,
}

export function NavigationCartButton({ className }: NavigationCartButtonProps): JSX.Element {

  const { cartId } = useCartId()
  const { data: cartData } = useCartQuery({ variables: { cartId }, skip: !cartId })
  const [state, setState] = useState<NavigationCartButtonState>({ ...DEFAULT_STATE })
  const location = useLocation()
  const theme = useTheme()
  const timerRef = useRef(null)
  const containerRef: React.RefObject<HTMLAnchorElement> = useRef()
  const navigate = useNavigate()
  const { isOutside, shouldMonitor, ref } = useClickOutside<HTMLDivElement>(false)
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()

  const getUpdatedTop = (): number => {
    const rect = containerRef.current.getBoundingClientRect()
    const position = rect.bottom > 0 ? rect.bottom : 0
    return position
  }

  const getUpdatedRight = (): number => {
    const width = window.innerWidth
    if (width > theme.calculationSize.desktop) {
      return ((width - theme.calculationSize.desktop) / 2) + 10
    }
    return 10
  }

  const updatePostions = (): void => {
    const top = getUpdatedTop()
    const right = getUpdatedRight()
    setState((prevState) => update(prevState, {
      top: {
        $set: top,
      },
      right: {
        $set: right,
      },
    }))
  }

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault()
    clearTimeout(timerRef.current)
    NavigationPlugin.shared().closeCart()
    setState((prevState) => update(prevState, {
      newCartItems: { $set: {} },
    }))
    navigate('/cart')
  }

  const _handleMouseEnterPopOver = (): void => {
    NavigationPlugin.shared().openCart(false)
    setState((prevState) => update(prevState, {
      top: {
        $set: getUpdatedTop(),
      },
      right: {
        $set: getUpdatedRight(),
      },
    }))
    clearTimeout(timerRef.current)
  }

  const _handleMouseLeavePopOver = (): void => {
    timerRef.current = setTimeout(() => {
      NavigationPlugin.shared().closeCart()
      setState((prevState) => update(prevState, {
        newCartItems: { $set: {} },
      }))
    }, DISPLAY_TIME)
  }

  const _handleCartClose = (): void => {
    clearTimeout(timerRef.current)
    NavigationPlugin.shared().closeCart()
    setState((prevState) => update(prevState, {
      newCartItems: { $set: {} },
    }))
  }

  useIsomorphicLayoutEffect(() => {
    const onScroll = () => {
      updatePostions()
    }
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useIsomorphicLayoutEffect(() => {
    updatePostions()
  }, [])

  useEffect(() => {
    const shouldDisplay = !location.pathname.startsWith('/cart') && !location.pathname.startsWith('/checkout')
    if (cartData && shouldDisplay && navigationData.navigation.dropDownCartDisplayType !== DropDownCartDisplayType.CART) {
      const newCartItems: { [k: string]: number } = { ...state.newCartItems }
      const previousCartItems: { [k: string]: number } = { ...state.newCartItems }
      let popverDisplayType = DropDownCartDisplayType.NONE
      for (let i = 0; i < cartData.cart.items.length; i++) {
        const item = cartData.cart.items[i]
        previousCartItems[item.id] = item.quantity
        if (!state.previousCartItems[item.id] || state.previousCartItems[item.id] !== item.quantity) {
          newCartItems[item.id] = item.quantity
          popverDisplayType = DropDownCartDisplayType.ADD_TO_CART
        }
      }
      if (state.firstLoad) {
        popverDisplayType = DropDownCartDisplayType.NONE
      }
      if (popverDisplayType === DropDownCartDisplayType.NONE) {
        NavigationPlugin.shared().closeCart()
      } else if (popverDisplayType === DropDownCartDisplayType.ADD_TO_CART) {
        NavigationPlugin.shared().openCart(true)
      } else {
        NavigationPlugin.shared().openCart(false)
      }
      setState((prevState) => update(prevState, {
        newCartItems: { $set: newCartItems },
        previousCartItems: { $set: previousCartItems },
        firstLoad: { $set: false },
      }))
      if (popverDisplayType === DropDownCartDisplayType.ADD_TO_CART) {
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          NavigationPlugin.shared().closeCart()
          setState((prevState) => update(prevState, {
            newCartItems: { $set: {} },
          }))
        }, DISPLAY_TIME)
      }
    }
    return () => clearTimeout(timerRef.current)
  }, [cartData?.cart?.totalQuantity])

  useEffect(() => {
    if (isOutside) {
      if (navigationData.navigation.dropDownCartDisplayType === DropDownCartDisplayType.CART) {
        NavigationPlugin.shared().closeCart()
        setState((prevState) => update(prevState, {
          newCartItems: { $set: {} },
        }))
      }
    }
  }, [isOutside])

  useEffect(() => {
    if (navigationData.navigation.dropDownCartDisplayType !== DropDownCartDisplayType.NONE) {
      shouldMonitor(true)
    } else {
      shouldMonitor(false)
    }
  }, [navigationData.navigation.dropDownCartDisplayType])

  const shouldShowPopover = navigationData.navigation.dropDownCartDisplayType !== DropDownCartDisplayType.NONE
  let addedItems: CartItemFragment[] = []
  if (navigationData.navigation.dropDownCartDisplayType === DropDownCartDisplayType.ADD_TO_CART) {
    addedItems = cartData?.cart?.items?.filter((cartItem) => Object.keys(state.newCartItems).includes(cartItem.id))
  }

  return (
    <OuterContainer onMouseEnter={_handleMouseEnterPopOver} onMouseLeave={_handleMouseLeavePopOver}>
      <Container href='/cart' ref={containerRef} onClick={_handleClick} className={className}>
        <Icon className='icon' icon={LocalIconEnums.CART_FILLED} color={theme.colors.green.bottleGreen} />
        <Tag>{cartData?.cart?.totalQuantity || 0}</Tag>
      </Container>
      <If condition={shouldShowPopover}>
        <PopoverContainer top={state.top} right={state.right} ref={ref}>
          <Card className='content-card'>
            <DropDownCart
              shouldClose={_handleCartClose}
              cart={cartData?.cart}
              addedItems={addedItems} />
          </Card>
        </PopoverContainer>
      </If>
    </OuterContainer>

  )

}
