
import React from 'react'

import loadable from '@loadable/component'

import { Loader, PageLoader } from '@atoms/notifications'
import { LoadingContainer } from '@atoms/misc'

import(/* webpackPrefetch: true */ '@pages/OceanAlly')

const OceanAlly = loadable(() => import(/* webpackChunkName: "brand" */'@pages/OceanAlly'), {
  resolveComponent: (components) => components.OceanAlly,
})

export function OceanAllyAsync(): JSX.Element {
  
  return <OceanAlly fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
