
import React from 'react'

import loadable from '@loadable/component'
import { Loader } from '@atoms/notifications'
import { LoadingContainer } from '@atoms/misc'


import(/* webpackPrefetch: true */ '@pages/Me')

const Me = loadable(() => import(/* webpackChunkName: "me" */'@pages/Me'), {
  resolveComponent: (components) => components.Me,
})

export function MeAsync(): JSX.Element {

  return <Me fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
