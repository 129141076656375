import React from 'react'

import styled from 'styled-components'

import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  
    margin: 5.000vw;

    @media (min-width: 30em) {
      margin: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 2.222vw;
    }

    @media (min-width: 90em) {
      margin: 32px;
    }
  
  
    padding: 0 5.000vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
`

export function SectionFallbackComponent(): JSX.Element {

  return (
    <Container>
      <Heading variant='h1' align='center'>An error has occured rendering the section</Heading>
    </Container>
  )
}
