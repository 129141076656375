import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/images/Icons'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    gap: 1.875vw;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
  
    width: 100%;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
`

const ButtonContainer = styled.div`
  display: flex;
  
    width: 100%;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  
    padding: 0 2.500vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
  
    justify-content: space-between;

    @media (min-width: 30em) {
      justify-content: center;
    }

    @media (min-width: 30.0625em) {
      justify-content: center;
    }

    @media (min-width: 90em) {
      justify-content: center;
    }
  
  align-items: center;
  
    gap: initial;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
`

export interface PaginationProps {
  currentPage: number
  itemsPerPage: number
  totalItems: number
  loading: boolean
  itemsToShow?: number
  onPageChange: (page: number) => void
  className?: string
}

export function Pagination({ currentPage, itemsPerPage, totalItems, loading, itemsToShow, onPageChange, className }: PaginationProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  if (!itemsToShow) {
    itemsToShow = appData.app.deviceType === DeviceTypeEnum.MOBILE ? 6 : 10
  }

  let pageCount = Math.round(totalItems / itemsPerPage)
  if (totalItems % itemsPerPage) {
    pageCount++
  }

  const hasMore = currentPage < pageCount
  const hasLess = currentPage > 1
  let startPage = Math.round(currentPage - (itemsToShow / 2))
  if (startPage < 1) {
    startPage = 1
  }
  let endPage = startPage + itemsToShow
  if (endPage > pageCount) {
    endPage = pageCount
    startPage = endPage - itemsToShow
  }
  if (startPage < 1) {
    startPage = 1
  }

  const $buttons: JSX.Element[] = []
  for (let b = startPage; b <= endPage; b++) {
    $buttons.push(
      <Button
        size={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'small' : undefined}
        title={`${b}`} onClick={() => onPageChange(b)}
        variant={currentPage === b ? 'direction' : 'nav'}
        disabled={currentPage === b} />,
    )
  }

  return (
    <Container className={className}>
      <ButtonContainer>
        <Button
          size={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'small' : undefined}
          icon={LocalIconEnums.CHEVRON_LEFT}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={!hasLess} />
        {$buttons}
        <Button
          size={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'small' : undefined}
          icon={LocalIconEnums.CHEVRON_RIGHT}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={!hasMore} />
      </ButtonContainer>
      Page {currentPage} of {pageCount}
    </Container>
  )

}
