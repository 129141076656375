import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { ResponsiveImage, Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useGetAppQuery, ProductDetailsImageFragment } from '@hooks/api/index'
import { Modal } from '@molecules/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  position: relative;
  display: flex;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: column;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row-reverse;
    }

    @media (min-width: 90em) {
      flex-direction: row-reverse;
    }
  
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 69.444vw;
    }

    @media (min-width: 90em) {
      width: 1000px;
    }
  
`

const ImageContainer = styled.div`
  width: 100%;
  
    height: CALC(100vh - 40.625vw);

    @media (min-width: 30em) {
      height: CALC(100vh - 40.625vw);
    }

    @media (min-width: 30.0625em) {
      height: CALC(80vh - 6.944vw);
    }

    @media (min-width: 90em) {
      height: CALC(80vh - 100px);
    }
  
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 69.444vw;
    }

    @media (min-width: 90em) {
      width: 1000px;
    }
  
  
    padding: 3.125vw;

    @media (min-width: 30em) {
      padding: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 20px;
    }
  
`

const SliderContainer = styled.div`
  display: flex;
  
    flex-direction: row;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: column;
    }

    @media (min-width: 90em) {
      flex-direction: column;
    }
  
  align-items: center;
  justify-content: center;
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100%;
    }

    @media (min-width: 30.0625em) {
      width: 5.556vw;
    }

    @media (min-width: 90em) {
      width: 80px;
    }
  
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: CALC(80vh - 6.944vw);
    }

    @media (min-width: 90em) {
      height: CALC(80vh - 100px);
    }
  
  
    margin: 3.125vw 0;

    @media (min-width: 30em) {
      margin: 3.125vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 1.389vw;
    }

    @media (min-width: 90em) {
      margin: 0 20px;
    }
  
`

const CarouselContainer = styled.div`
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: CALC(80vh - 13.889vw);
    }

    @media (min-width: 90em) {
      height: CALC(80vh - 200px);
    }
  
  
    width: 75.000vw;

    @media (min-width: 30em) {
      width: 87.500vw;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
`

const Embla = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`

const EmblaContainer = styled.div`
  position: relative;
  display: flex;
  
    flex-direction: row;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: column;
    }

    @media (min-width: 90em) {
      flex-direction: column;
    }
  
  
    gap: 6.250vw;

    @media (min-width: 30em) {
      gap: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
`

const EmblaSlide = styled.div<{ selected: boolean }>`
  position: relative;
  user-select: none;
  
    flex: 0 0 14.063vw;

    @media (min-width: 30em) {
      flex: 0 0 15.625vw;
    }

    @media (min-width: 30.0625em) {
      flex: 0 0 4.861vw;
    }

    @media (min-width: 90em) {
      flex: 0 0 70px;
    }
  
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.861vw;
    }

    @media (min-width: 90em) {
      height: 70px;
    }
  
  ${(props): string => props.selected
    ? ResponsivePXValue('border', `3px solid ${props.theme.colors.blue.curiousBlue}`)
    : ResponsivePXValue('border', `1px solid ${props.theme.colors.grey.desertStorm}`)}
  ${(props): string => props.selected
    ? ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: '8px' })
    : ResponsivePXValue('padding', { mobile: '2px', tablet: '2px', desktop: '10px' })}
`

const TopControl = styled.div<{ visible: boolean }>`
  user-select: none;
  flex-shrink: 0;
  opacity: ${(props): number => props.visible ? 1 : 0};
  
    width: 15.625vw;

    @media (min-width: 30em) {
      width: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.472vw;
    }

    @media (min-width: 90em) {
      width: 50px;
    }
  
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.472vw;
    }

    @media (min-width: 90em) {
      height: 50px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.694vw;
    }

    @media (min-width: 90em) {
      padding: 10px;
    }
  
`

const BottomControl = styled.div<{ visible: boolean }>`
  user-select: none;
  flex-shrink: 0;
  opacity: ${(props): number => props.visible ? 1 : 0};
  
    width: 15.625vw;

    @media (min-width: 30em) {
      width: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.472vw;
    }

    @media (min-width: 90em) {
      width: 50px;
    }
  
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.472vw;
    }

    @media (min-width: 90em) {
      height: 50px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0.694vw;
    }

    @media (min-width: 90em) {
      padding: 10px;
    }
  
`

export interface ProductImageGalleryModalProps {
  index: number
  onIndexChange: (index: number) => void
  images: ProductDetailsImageFragment[]
  open: boolean
  onClose: () => void
}

interface ProductImageGalleryModalState {
  scrollOffset: number
}

const DEFAULT_STATE: ProductImageGalleryModalState = {
  scrollOffset: 0,
}

export function ProductImageGalleryModal({ images, open, index, onIndexChange, onClose }: ProductImageGalleryModalProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [state, setState] = useState<ProductImageGalleryModalState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: false,
    axis: appData.app.deviceType === DeviceTypeEnum.DESKTOP ? 'y' : 'x',
    align: 'start',
  })

  const maxImages = appData.app.deviceType === DeviceTypeEnum.DESKTOP ? 10 : appData.app.deviceType === DeviceTypeEnum.TABLET ? 5 : 4
  const selectedImage = images?.[index]
  const showTopControl = state.scrollOffset > 0
  const showBottomControl = state.scrollOffset + maxImages < images.length

  const _handleSelectThumbNail = (index: number): void => {
    onIndexChange(index)
  }

  const _handleGoUp = (): void => {
    if (!showTopControl) {
      return
    }
    setState((prevState) => update(prevState, {
      scrollOffset: { $set: prevState.scrollOffset - 1 },
    }))
  }

  const _handleGoDown = (): void => {
    if (!showBottomControl) {
      return
    }
    setState((prevState) => update(prevState, {
      scrollOffset: { $set: prevState.scrollOffset + 1 },
    }))
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(state.scrollOffset)
    }
  }, [state.scrollOffset])

  let image: ProductDetailsImageFragment
  let imageIndex: number

  return (
    <Modal open={open} title='Product Images' onClose={onClose}>
      <Container>
        <ImageContainer>
          <Choose>
            <When condition={!!selectedImage}>
              <ResponsiveImage image={selectedImage} objectFit='contain' />
            </When>
            <Otherwise>
              No Preview
            </Otherwise>
          </Choose>
        </ImageContainer>
        <SliderContainer>
          <TopControl onClick={_handleGoUp} visible={showTopControl}>
            <Icon
              icon={appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
                ? LocalIconEnums.CHEVRON_UP
                : LocalIconEnums.CHEVRON_LEFT}
              color={theme.colors.blue.curiousBlue} />
          </TopControl>
          <CarouselContainer>
            <Embla ref={emblaRef}>
              <EmblaContainer>
                <For each='image' index='imageIndex' of={images}>
                  <EmblaSlide
                    selected={imageIndex === index}
                    key={`${image.id}-${imageIndex}`}
                    onClick={() => _handleSelectThumbNail(imageIndex)}>
                    <ResponsiveImage image={image} objectFit='contain' />
                  </EmblaSlide>
                </For>
              </EmblaContainer>
            </Embla>
          </CarouselContainer>
          <BottomControl onClick={_handleGoDown} visible={showBottomControl}>
            <Icon
              icon={appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
                ? LocalIconEnums.CHEVRON_DOWN
                : LocalIconEnums.CHEVRON_RIGHT}
              color={theme.colors.blue.curiousBlue} />
          </BottomControl>
        </SliderContainer>
      </Container>
    </Modal>
  )

}
