import React, { useState } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, PageBlockFragment } from '@hooks/api/index'
import { DeviceTypeEnum, PageHorizontalAlignment } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'
import { animated, useSpring } from 'react-spring'
import { useAccordion } from '@contexts/AccordionProvider'

const Container = styled(animated.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const HeadingSection = styled.div<{ backgroundColor: string, alignment: string, $hasAccordion: boolean }>`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: ${({ alignment, $hasAccordion }): string => 
    $hasAccordion 
    ? 'space-between'
    : alignment === PageHorizontalAlignment.LEFT
      ? 'flex-start'
      : alignment === PageHorizontalAlignment.RIGHT
        ? 'flex-end'
        : 'center'
        };
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 16px 24px;
    }
  
  background-color: ${(props): string => props.backgroundColor};

  ${({ $hasAccordion }) => $hasAccordion && `
    z-index: 1;
  `}
`

const ChevronContainer = styled.div`
  
    height: 9.375vw;

    @media (min-width: 30em) {
      height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.083vw;
    }

    @media (min-width: 90em) {
      height: 30px;
    }
  
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.083vw;
    }

    @media (min-width: 90em) {
      width: 30px;
    }
  
`

const IconContainer = styled.div`
  
    width: 15.000vw;

    @media (min-width: 30em) {
      width: 15.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.333vw;
    }

    @media (min-width: 90em) {
      width: 48px;
    }
  
  
    height: 15.000vw;

    @media (min-width: 30em) {
      height: 15.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.333vw;
    }

    @media (min-width: 90em) {
      height: 48px;
    }
  
`

const ContentSection = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.667vw;
    }

    @media (min-width: 90em) {
      gap: 24px;
    }
  
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  background-color: ${({ theme }) => theme.colors.white.pureWhite};
  height: 100%;
`

const ButtonContainer = styled.div<{ alignment: string }>`
  display: flex;
  width: 100%;
  ${({ alignment }) => ResponsivePXValue('justify-content', {
    mobile: 'center',
    tablet: alignment === PageHorizontalAlignment.LEFT
      ? 'flex-start'
      : alignment === PageHorizontalAlignment.RIGHT
        ? 'flex-end'
        : 'center',
    desktop: alignment === PageHorizontalAlignment.LEFT
      ? 'flex-start'
      : alignment === PageHorizontalAlignment.RIGHT
        ? 'flex-end'
        : 'center',
  })}
`

interface PageCardBlockStateInterface {
  isAnimating: boolean
}

const DEFAULT_STATE: PageCardBlockStateInterface = {
  isAnimating: false
}

export interface PageCardBlockProps {
  block: PageBlockFragment
}

export function PageCardBlock({ block }: PageCardBlockProps): JSX.Element {

  const { backgroundColor, horizontalAlignment, image, foregroundColor, title, description, link } = block
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [ state, setState ] = useState<PageCardBlockStateInterface>({ ...DEFAULT_STATE })
  const { MOBILE } = DeviceTypeEnum
  const theme = useTheme()
  const isMobile = appData.app.deviceType === MOBILE
  const { openBlockId, toggleBlock, hasAccordion } = useAccordion()
  const isOpen = openBlockId === block.id
  const isAccordionEnabled = hasAccordion && isMobile
  const contentAnimation = useSpring({
    height: isOpen ? 'auto' : 0,
    opacity: isOpen ? 1 : 0,
    transform: `translateY(${isOpen ? '0px' : '-100%'})`,
    config: { tension: 300, friction: 30 }
  })

  const handleToggleBlock = (e: React.MouseEvent<HTMLDivElement>) => toggleBlock(block.id)

  
  if (isAccordionEnabled) {
    return (
      <Container>
        <HeadingSection
          role='button'
          backgroundColor={backgroundColor}
          alignment={horizontalAlignment}
          onClick={handleToggleBlock}
          $hasAccordion={true}>
          <IconContainer>
            <ResponsiveImage image={image} />
          </IconContainer>
          <Heading
            variant='h1'
            color={foregroundColor}>
            {title}
          </Heading>
          <ChevronContainer>
            <Choose>
              <When condition={isOpen}>
                <Icon icon={LocalIconEnums.CHEVRON_UP} color={theme.colors.green.greenVogue}/>
              </When>
              <Otherwise>
                <Icon icon={LocalIconEnums.CHEVRON_DOWN} color={theme.colors.green.greenVogue}/>
              </Otherwise>
            </Choose>
          </ChevronContainer>
        </HeadingSection>
        {(isOpen || state.isAnimating) && (
          <ContentSection style={contentAnimation}>
            <HTML color={theme.colors.green.bottleGreen}>
              {description}
            </HTML>
            <If condition={!!link}>
              <ButtonContainer alignment={horizontalAlignment}>
                <Button variant='ghost'
                  fullWidth={appData.app.deviceType === MOBILE}
                  title={link.title}
                  href={link.url} />
              </ButtonContainer>
            </If>
          </ContentSection>
        )}
      </Container>
    )
  }

  return (
    <Container>
      <HeadingSection
        backgroundColor={backgroundColor}
        alignment={horizontalAlignment}
        $hasAccordion={false}>
        <IconContainer>
          <ResponsiveImage image={image} />
        </IconContainer>
        <Heading variant='h2' color={foregroundColor}>
          {title}
        </Heading>
      </HeadingSection>
      <ContentSection>
        <HTML color={theme.colors.green.bottleGreen}>
          {description}
        </HTML>
        <If condition={!!link}>
          <ButtonContainer alignment={horizontalAlignment}>
            <Button 
              variant='ghost'
              fullWidth={appData.app.deviceType === MOBILE}
              title={link.title}
              href={link.url} 
            />
          </ButtonContainer>
        </If>
      </ContentSection>
    </Container>
  )
  

}
