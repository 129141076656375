
import React from 'react'

import loadable from '@loadable/component'

import { Loader, PageLoader } from '@atoms/notifications'
import { LoadingContainer } from '@atoms/misc'

import(/* webpackPrefetch: true */ '@pages/Cart')

const Cart = loadable(() => import(/* webpackChunkName: "cart" */'@pages/Cart'), {
  resolveComponent: (components) => components.Cart,
})

export function CartAsync(): JSX.Element {

  return <Cart fallback={
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  } />

}
