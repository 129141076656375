import React, { useEffect, useRef, useState } from 'react'

import { constantCase } from 'change-case'
import update from 'react-addons-update'
import styled from 'styled-components'

import { CHECKOUT_DEFAULT_STATE, CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { ResponsiveImage } from '@atoms/images'
import { Divider } from '@atoms/layout/Divider'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { LiteBoxShadow, ResponsivePXValue } from '@components/Theme'
import { CartItemFragment, ShippingActionFragment, ShippingMethodFragment, ShortageItemFragment, useCartQuery, useGetCheckoutQuery } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { ShippingActionEnum } from '@uctypes/api/globalTypes'

import { DeliveryMethodRow } from './DeliveryMethodRow'
import { ShippingActionRow } from './ShippingActionRow'
import { useNavigate } from 'react-router'

const Container = styled.div`
  
    margin-bottom: initial;

    @media (min-width: 30em) {
      margin-bottom: initial;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 4.444vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 64px;
    }
  
`

const SectionContainer = styled.div<{ visible?: boolean }>`
  display: ${(props): string => {
    const disp = props.visible === false ? 'none' : 'inherit'
    return disp
  }};
  position: relative;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};

  .top-title {
    
    margin-bottom: 3.750vw;

    @media (min-width: 30em) {
      margin-bottom: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 12px;
    }
  
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

const List = styled.div`
  
    padding: 0 0 5.000vw 0;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.111vw 1.111vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 0 16px 16px 16px;
    }
  
`

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.white.fantasy};
  
    width: CALC(100% -10.000vw);

    @media (min-width: 30em) {
      width: CALC(100% -10.000vw);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100% -2.222vw);
    }

    @media (min-width: 90em) {
      width: CALC(100% -32px);
    }
  
  
    margin: 5.000vw;

    @media (min-width: 30em) {
      margin: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 16px;
    }
  
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

const DelayedContainer = styled.div`
  
`

const DelayedHeader = styled.div`
  
    padding: 7.500vw 7.500vw 0;

    @media (min-width: 30em) {
      padding: 7.500vw 7.500vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw 1.667vw 0;
    }

    @media (min-width: 90em) {
      padding: 24px 24px 0;
    }
  
`

const CardLink = styled.a`
  text-decoration: none;
  white-space: normal;
  cursor: pointer;
  position: relative;
  z-index: 2;
`

const DelayedProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  
    margin: 5.000vw 0 7.500vw;

    @media (min-width: 30em) {
      margin: 5.000vw 0 7.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0 1.667vw;
    }

    @media (min-width: 90em) {
      margin: 16px 0 24px;
    }
  
  background-color: ${(props): string => props.theme.colors.white.floralWhite};
`

const HeadingContainer = styled.div`
  
    padding: 0 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

const DelayedProduct = styled.div`
  position: relative;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
    padding: 2.500vw;

    @media (min-width: 30em) {
      padding: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 8px;
    }
  
  
    width: 41.250vw;

    @media (min-width: 30em) {
      width: 56.875vw;
    }

    @media (min-width: 30.0625em) {
      width: 12.639vw;
    }

    @media (min-width: 90em) {
      width: 182px;
    }
  
  /* ${(props): string => ResponsivePXValue('border', `1px solid ${props.theme.colors.green.bottleGreen}`)} */
  ${LiteBoxShadow}
`

const DelayedProductLinkWrapper =  styled.a``

const DelayedProductImageContainer = styled.div`
  
    width: 36.250vw;

    @media (min-width: 30em) {
      width: 51.875vw;
    }

    @media (min-width: 30.0625em) {
      width: 11.528vw;
    }

    @media (min-width: 90em) {
      width: 166px;
    }
  
  
    height: 36.250vw;

    @media (min-width: 30em) {
      height: 51.875vw;
    }

    @media (min-width: 30.0625em) {
      height: 11.528vw;
    }

    @media (min-width: 90em) {
      height: 166px;
    }
  
`

const DelayedProductOverlay = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
  }
`

const DelayedProductQuantity = styled.div`
  
    height: 7.500vw;

    @media (min-width: 30em) {
      height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.667vw;
    }

    @media (min-width: 90em) {
      height: 24px;
    }
  
  
    min-width: 7.500vw;

    @media (min-width: 30em) {
      min-width: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      min-width: 1.667vw;
    }

    @media (min-width: 90em) {
      min-width: 24px;
    }
  
  
    border-radius: 3.750vw;

    @media (min-width: 30em) {
      border-radius: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.833vw;
    }

    @media (min-width: 90em) {
      border-radius: 12px;
    }
  
  
    padding: 0 2.500vw;

    @media (min-width: 30em) {
      padding: 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0.556vw;
    }

    @media (min-width: 90em) {
      padding: 0 8px;
    }
  
  color: ${(props): string => props.theme.colors.white.pureWhite};
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  
    top: 1.563vw;

    @media (min-width: 30em) {
      top: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      top: 0.347vw;
    }

    @media (min-width: 90em) {
      top: 5px;
    }
  
  
    right: 1.563vw;

    @media (min-width: 30em) {
      right: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      right: 0.347vw;
    }

    @media (min-width: 90em) {
      right: 5px;
    }
  
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.red.cinnabar, 0.8)};
  font-family: open-sans;
`

const DelayedCount = styled.div`
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.8)};
  color: ${(props): string => props.theme.colors.white.pureWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    padding: 1.250vw 3.750vw;

    @media (min-width: 30em) {
      padding: 1.250vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 4px 12px;
    }
  
  
    height: 9.375vw;

    @media (min-width: 30em) {
      height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.083vw;
    }

    @media (min-width: 90em) {
      height: 30px;
    }
  
  
    border-radius: 4.688vw;

    @media (min-width: 30em) {
      border-radius: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.042vw;
    }

    @media (min-width: 90em) {
      border-radius: 15px;
    }
  
  
    margin: 0 2.500vw;

    @media (min-width: 30em) {
      margin: 0 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0.556vw;
    }

    @media (min-width: 90em) {
      margin: 0 8px;
    }
  
  font-family: open-sans;
`

const DelayedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.8)};
  
    font-size: 6.250vw;

    @media (min-width: 30em) {
      font-size: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.389vw;
    }

    @media (min-width: 90em) {
      font-size: 20px;
    }
  
  display: flex;
  align-items: center;
  justify-content: center;
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

export interface DeliveryMethodsProps {
  selected: string
  isUpdateingItems: boolean
  onSelect: (method: string, rateUid: string, carrierCode: string) => void
  onPargoLocationChange?: () => void
}

interface DeliveryMethodsState {
  delayedCartItems: {[k: string]: CartItemFragment}
}

const DEFAULT_STATE: DeliveryMethodsState = {
  delayedCartItems: {},
}

export function DeliveryMethods({ selected, isUpdateingItems, onSelect, onPargoLocationChange }: DeliveryMethodsProps): JSX.Element {

  const { cartId } = useCartId()
  const { data: cartData, loading: cartLoading } = useCartQuery({ variables: { cartId }, skip: !cartId })
  const { data: { checkout = CHECKOUT_DEFAULT_STATE } } = useGetCheckoutQuery()
  const [state, setState] = useState<DeliveryMethodsState>({ ...DEFAULT_STATE })
  const deliveryOptionsRef: React.RefObject<HTMLDivElement> = useRef()
  const navigate = useNavigate()

  const _handleSelect = (shippingMethod: string, rateUid: string, carrierCode: string): void => {
    onSelect(shippingMethod, rateUid, carrierCode)
  }

  const _handleActionSelect = (action: ShippingActionEnum): void => {
    CheckoutPlugin.shared().setDeliveryAction({ deliveryAction: action })
  }

  const shippingAddress = cartData?.cart?.shippingAddresses?.[0]

  const loading = cartLoading
  const shouldSelect = onSelect !== undefined

  const shippingActions = shippingAddress.shippingActions?.actions.filter((action) => {
    if (action.action === ShippingActionEnum.SPLIT && action.actionItems.length < 2) {
      return false
    }
    return true
  })

  const shippingMethods: ShippingMethodFragment[] = shippingAddress
    .availableShippingMethods
    .filter((item) => {
      return item.available && checkout?.deliveryInfo?.deliveryAction
        ? item.allowedShippingActions.includes(checkout?.deliveryInfo?.deliveryAction)
        : shippingActions.length
          ? item.allowedShippingActions.length
          : true
    })
    
    const handleLink = (href: string) => navigate(href)
    
  useEffect(() => {
    if (shippingActions.length > 0) {
      CheckoutPlugin.shared().setDeliveryAction({ deliveryAction: constantCase(shippingActions[0].action) as ShippingActionEnum })
    }
    CheckoutPlugin.shared().setRequiresDeliveryAction({ requiresDeliveryAction: false })
  }, [shippingActions.length])

  useEffect(() => {
    if (checkout?.deliveryInfo?.deliveryAction) {
      if (selected) {
        const method = shippingMethods.find((meth) => meth.rateUid === selected)
        if (!method) {
          CheckoutPlugin.shared().setDeliveryMethod({ deliveryMethod: null, rateUid: null, carrierCode: null })
        }
      }
      if (checkout.deliveryInfo.deliveryAction !== ShippingActionEnum.NORMAL) {
        deliveryOptionsRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [checkout?.deliveryInfo?.deliveryAction])

  useEffect(() => {
    if (shippingMethods.length === 1) {
      CheckoutPlugin.shared().setDeliveryMethod({ deliveryMethod: shippingMethods[0].methodCode, rateUid: shippingMethods[0].rateUid, carrierCode: shippingMethods[0].carrierCode })
    }
  }, [shippingMethods.length])

  useEffect(() => {
    if (shippingAddress?.shippingActions?.shortageItems && shippingAddress?.shippingActions?.shortageItems.length) {
      const delayedCartItems: {[k: string]: CartItemFragment} = {}
      shippingAddress?.shippingActions?.shortageItems.forEach((shortageItem) => {
        delayedCartItems[shortageItem.cartParentItemUid || shortageItem.cartItemUid] = cartData.cart.items.find((ci) => ci.uid === (shortageItem.cartParentItemUid || shortageItem.cartItemUid))
      })
      setState((prevState) => update(prevState, {
        delayedCartItems: {
          $set: delayedCartItems,
        },
      }))
    }
  }, [shippingAddress?.shippingActions?.shortageItems])

  const shouldShowShippingActions = shippingActions.length > 0
  const shouldShowDeliveryOptions = !shouldShowShippingActions || (checkout.deliveryInfo?.deliveryAction !== null && checkout.deliveryInfo?.deliveryAction !== ShippingActionEnum.NORMAL)

  let shippingAction!: ShippingActionFragment
  let shippingActionIndex!: number
  let deliveryMethod!: ShippingMethodFragment
  let deliveryMethodIndex!: number

  let delayedItem: ShortageItemFragment

  return (
    <Container>
      <If condition={shouldShowShippingActions}>
        <SectionContainer>
          <DelayedContainer>
            <DelayedHeader>
              <Title variant='t3' className='top-title'>
              Manage your order
              </Title>
              <Paragraph className='top-paragraph'>
              The following products are not in stock at your closest warehouse and will delay your order:
              </Paragraph>
            </DelayedHeader>
            <DelayedProducts>
              <For each='delayedItem' of={shippingAddress?.shippingActions?.shortageItems || []}>
                <DelayedProduct 
                  key={delayedItem.cartItemUid} >
                    
                  <DelayedProductImageContainer>
                    <ResponsiveImage image={state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.product?.coverImage} />
                  </DelayedProductImageContainer>
                  <Title align='center' variant='t10'>
                    {state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.product?.name}
                  </Title>
                  <DelayedProductOverlay href={`/${state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.product?.canonicalUrl}`} onClick={handleLink}>
                    <DelayedCount>
                      <Choose>
                        <When condition={!!(state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.quantity - delayedItem.qty)}>
                          Only {state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.quantity - delayedItem.qty} available in {shippingAddress.shippingActions.primarySource.name}
                        </When>
                        <Otherwise>
                          Not available in {shippingAddress.shippingActions.primarySource.name}
                        </Otherwise>
                      </Choose>
                    </DelayedCount>
                  </DelayedProductOverlay>
                  <DelayedProductQuantity>
                    {state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.quantity} in cart
                  </DelayedProductQuantity>
                </DelayedProduct>
              </For>
            </DelayedProducts>
            <HeadingContainer>
              <Heading variant='h6' align='left'>
                How would you like to proceed?
              </Heading >
            </HeadingContainer>
          </DelayedContainer>
          <List>
            <For each='shippingAction' index='shippingActionIndex' of={shippingActions}>
              <ShippingActionRow
                action={shippingAction}
                selected={constantCase(shippingAction.action) === checkout.deliveryInfo?.deliveryAction}
                key={shippingAction.action}
                onSelect={() => _handleActionSelect(constantCase(shippingAction.action) as ShippingActionEnum)} />
              <If condition={shippingActionIndex < shippingAddress.shippingActions.actions.length - 1}>
                <Divider key={`divider-${shippingAction.action}`} />
              </If>
            </For>
          </List>
          <If condition={isUpdateingItems}>
            <DelayedOverlay>
              Updating cart with your choices.
            </DelayedOverlay>
          </If>
        </SectionContainer>
        <Divider />
      </If>
      <SectionContainer ref={deliveryOptionsRef} visible={shouldShowDeliveryOptions}>
        <Header>
          <Choose>
            <When condition={!!shippingAddress.shippingActions}>
              <Title variant='t4'>
                  Delivery Method
              </Title>
            </When>
            <Otherwise>
              <Title variant='t3'>
                  Delivery Method
              </Title>
            </Otherwise>
          </Choose>
        </Header>
        <Choose>
          <When condition={loading}>
            <Loader />
          </When>
          <When condition={shippingMethods.length === 0}>
            <Empty>
              <Paragraph>
                  No delivery methods available.
              </Paragraph>
            </Empty>
          </When>
          <Otherwise>
            <List>
              <For each='deliveryMethod' index='deliveryMethodIndex' of={shippingMethods}>
                <DeliveryMethodRow
                  deliveryMethod={deliveryMethod}
                  selected={deliveryMethod.rateUid === selected}
                  key={deliveryMethod.id}
                  pargoLocation={checkout.deliveryInfo.pargoLocation}
                  onSelect={shouldSelect ? () => _handleSelect(deliveryMethod.methodCode, deliveryMethod.rateUid, deliveryMethod.carrierCode) : undefined}
                  onPargoLocationChange={onPargoLocationChange} />
                <If condition={deliveryMethodIndex < shippingMethods.length - 1}>
                  <Divider key={`divider-${deliveryMethod.id}`} />
                </If>
              </For>
            </List>
          </Otherwise>
        </Choose>
        <If condition={isUpdateingItems}>
          <DelayedOverlay />
        </If>
      </SectionContainer>
    </Container>
  )

}
