import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { AuthPlugin } from '@api/local/AuthPlugin'
import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Button, ButtonColors } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Seperator } from '@atoms/layout'
import { Link, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, useGetAppQuery, useGetFrequentlyBoughtProductsQuery, usePersonalDiscountsQuery, usesubscribeEmailToNewsletterMutation } from '@hooks/api'
import { Form, TextInput, useForm } from '@molecules/inputs'
import { SocialAccounts } from '@molecules/misc'
import { NeedHelp } from '@molecules/navigation'
import { IndexAccordion, IndexSection } from '@organisms/content/IndexAccordion'
import { googleLogout } from '@react-oauth/google'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { FooterLinkGroup, FooterLinkItem, links } from './FooterLinks'
import { MobileFooterReviews } from './MobileFooterReviews'
import { useNavigate } from 'react-router'

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TopBarContentWrapper = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.green.watercourse};
  display: flex;
  justify-content: center;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 5.000vw 15.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 3.333vw;
    }

    @media (min-width: 90em) {
      padding: 16px 48px;
    }
  
  form {
    flex-grow: 1;
    width: 100%;
  }
  .wrapper {
    margin: 0;
  }
  `

const TopBarTile = styled.div`
  flex-shrink: 0;
  
    width: 100%;

    @media (min-width: 30em) {
      width: 104.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 23.194vw;
    }

    @media (min-width: 90em) {
      width: 334px;
    }
  
  `

const TopBarSignup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  
    margin-top: 5.000vw;

    @media (min-width: 30em) {
      margin-top: initial;
    }

    @media (min-width: 30.0625em) {
      margin-top: initial;
    }

    @media (min-width: 90em) {
      margin-top: initial;
    }
  
  .input {
    flex-grow: 1;
  }
  .button {
    flex-shrink: 0;
  }
  `

const ContentContentWrapper = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  display: flex;
  justify-content: center;

`

const Content = styled.div`
  width: 100%;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 7.500vw 15.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw 3.333vw;
    }

    @media (min-width: 90em) {
      padding: 24px 48px;
    }
  
`

const NavigationGrid = styled.div`
  flex-grow: 1;
  
    display: none;

    @media (min-width: 30em) {
      display: flex;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
`

const SwitchToWebsiteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  background-color: ${({ theme }): string => theme.colors.green.snowFlurry};
  
    padding: 6.250vw 3.750vw 6.250vw 3.750vw;

    @media (min-width: 30em) {
      padding: 6.250vw 3.750vw 6.250vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.389vw 0.833vw 1.389vw 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 20px 12px 20px 12px;
    }
  
  
    border-radius: 0.625vw;

    @media (min-width: 30em) {
      border-radius: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.139vw;
    }

    @media (min-width: 90em) {
      border-radius: 2px;
    }
  
  
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
`

const NavigationAccordion = styled.div`
  
    display: flex;

    @media (min-width: 30em) {
      display: none;
    }

    @media (min-width: 30.0625em) {
      display: none;
    }

    @media (min-width: 90em) {
      display: none;
    }
  
  
    margin-top: 5.000vw;

    @media (min-width: 30em) {
      margin-top: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-top: 16px;
    }
  
  width: 100%;
`

const NavColumn = styled.div`
  width: 100%;
  .title {
    
    margin-bottom: 5.000vw;

    @media (min-width: 30em) {
      margin-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
  }
  .link {
    padding: 0;
    margin: 0;
  }
`

const WidgetContainer = styled.div`
  flex-shrink: 0;
  
    width: 100%;

    @media (min-width: 30em) {
      width: 100.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 22.222vw;
    }

    @media (min-width: 90em) {
      width: 320px;
    }
  
`

const LogoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
`

const LogoContainer = styled.div`
  width: 100%;
  
    display: none;

    @media (min-width: 30em) {
      display: block;
    }

    @media (min-width: 30.0625em) {
      display: block;
    }

    @media (min-width: 90em) {
      display: block;
    }
  
  
    margin-bottom: 3.750vw;

    @media (min-width: 30em) {
      margin-bottom: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 16px;
    }
  
`

const BottomBarContentWrapper = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.green.greenVogue};
  display: flex;
  justify-content: center;

`

const BottomBar = styled.div`
  width: 100%;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
  display: flex;
  align-items: center;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: column;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    justify-content: center;

    @media (min-width: 30em) {
      justify-content: center;
    }

    @media (min-width: 30.0625em) {
      justify-content: space-between;
    }

    @media (min-width: 90em) {
      justify-content: space-between;
    }
  
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: 5.000vw 15.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw 3.333vw;
    }

    @media (min-width: 90em) {
      padding: 16px 48px;
    }
  

  .mobile-push {
    
    margin-bottom: 18.750vw;

    @media (min-width: 30em) {
      margin-bottom: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 4.167vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 60px;
    }
  
  }
`

const PaymentIcons = styled.div`
  display: flex;
  align-items: center;
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 2.222vw;
    }

    @media (min-width: 90em) {
      gap: 32px;
    }
  
  
    justify-content: center;

    @media (min-width: 30em) {
      justify-content: flex-start;
    }

    @media (min-width: 30.0625em) {
      justify-content: flex-start;
    }

    @media (min-width: 90em) {
      justify-content: flex-start;
    }
  
`

const PaymentIcon = styled.div`
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.083vw;
    }

    @media (min-width: 90em) {
      height: 30px;
    }
  
`

const BottomLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    height: 4.688vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
  
    margin-top: 3.750vw;

    @media (min-width: 30em) {
      margin-top: initial;
    }

    @media (min-width: 30.0625em) {
      margin-top: initial;
    }

    @media (min-width: 90em) {
      margin-top: initial;
    }
  

  .footer-link-bottom-seperator {
    
    margin: 0 2.500vw;

    @media (min-width: 30em) {
      margin: 0 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 0 16px;
    }
  
  }
`

interface FooterNewState {
  tabs: string[]
  signOutLoading: boolean
  refresh: boolean
}

interface ColorChangerObjectInterface {
  [k: string]: ButtonColors
}

const DEFAULT_STATE: FooterNewState = {
  tabs: [],
  signOutLoading: false,
  refresh: false,
}

export function Footer(): JSX.Element {

  const { refetch: refetchDiscounts } = usePersonalDiscountsQuery()
  const { refetch: refetchMyShop } = useGetFrequentlyBoughtProductsQuery()
  const [state, setState] = useState<FooterNewState>({ ...DEFAULT_STATE })
  const { data: customerData, refetch: refetchCustomer } = useCustomerQuery()
  const [subscribeEmailToNewsletter, { loading: subscriptionLoading }] = usesubscribeEmailToNewsletterMutation()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const form = useForm()
  const theme = useTheme()
  const { addToast } = useSimpleToasts()
  const apolloClient: ApolloClient<NormalizedCacheObject> = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const navigate = useNavigate()

  const _handleLogOut = async (): Promise<void> => {
    googleLogout()
    AuthPlugin.shared().clear()
    await refetchCustomer()
    apolloClient.cache.evict({
      fieldName: 'personalDiscounts',
    })
    apolloClient.cache.evict({
      fieldName: 'frequentlyBoughtProducts',
    })
    refetchDiscounts()
    refetchMyShop()
    navigate('/')
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100)
      

  }

  const _handleLogIn = (): void => {
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleTabsChange = (tabs: string[]): void => {
    setState((prevState) => update(prevState, {
      tabs: { $set: tabs },
    }))
  }

  const _handleSubscribe = async (data: { email: string }): Promise<void> => {
    if (!data.email) {
      return
    }
    try {
      await subscribeEmailToNewsletter({
        variables: {
          email: data.email,
        },
      })
      addToast({
        message: 'Subscribed successfully',
        appearance: 'success',
      })
    } catch (e) {
      if (e instanceof Error) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      }
    }
  }

  let linkGroup!: FooterLinkGroup
  let linkGroupIndex!: number
  let link!: FooterLinkItem
  let linkIndex!: number

  const colorChangerObject: ColorChangerObjectInterface = {
    colorButtonObject: {
      color: theme.colors.white.pureWhite,
      hoverColor: theme.colors.white.pureWhite,
    },
    colorColumnObject: {
      color: theme.colors.black.codGrey,
      hoverColor: theme.colors.black.codGrey,
    },
  }

  return (
    <Container>
      <TopBarContentWrapper>
        <TopBar>
          <TopBarTile>
            <DeviceContainer desktop tablet>
              <Title variant='t5' color={theme.colors.white.pureWhite} align='center'>
                Join our conscious shopping community
              </Title>
            </DeviceContainer>
            <DeviceContainer mobile>
              <Title variant='t8' color={theme.colors.white.pureWhite} align='left'>
                Join our conscious shopping community
              </Title>
            </DeviceContainer>
          </TopBarTile>
          <Form form={form} onFinish={_handleSubscribe} loading={subscriptionLoading}>
            <TopBarSignup>
              <TextInput
                variant='email'
                name='email'
                placeholder='Enter your email address'
                className='input'
                wrapperClassName='wrapper'
                rules={[{ required: true, message: 'Please enter your email address' }]} />
              <Button
                title='SIGN UP'
                className='button'
                variant='cart'
                loading={subscriptionLoading}
                onClick={() => form.submit()} />
            </TopBarSignup>
          </Form>
        </TopBar>
      </TopBarContentWrapper>
      <ContentContentWrapper>
        <Content>
          <NavigationGrid>
            <For each='linkGroup' of={links} index='linkGroupIndex'>
              <NavColumn key={linkGroupIndex}>
                <Paragraph variant='p1' className='title' color={theme.colors.green.greenVogue} bold={true}>{linkGroup.title}</Paragraph>
                <For each='link' of={linkGroup.subs} index='linkIndex'>
                  <Button variant='action' title={link.title} href={link.link} key={`${linkGroupIndex}-${linkIndex}`} colors={colorChangerObject.colorColumnObject} className='link' target='_blank' />
                </For>
              </NavColumn>
            </For>
          </NavigationGrid>
          <If condition={appData.app.isNativeApp}>
            <SwitchToWebsiteContainer>
              <Paragraph
                variant='p1'
                color={theme.colors.green.greenVogue}
                align='center'>
                Our shiny new app is still a work in progress! If something&apos;s missing or not working as well as it should, head over to our website. <br /> If you spot anything worth noting, please let us know — we&apos;d love to hear from you!
              </Paragraph>
              <Link
                href='https://www.faithful-to-nature.co.za/'
                color={theme.colors.green.greenVogue}
                decoration='underline'
                bold={true}>Switch to Website</Link>
            </SwitchToWebsiteContainer>
          </If>
          <WidgetContainer>
            <LogoContainer>
              <Icon icon={LocalIconEnums.LOGO_DESKTOP} color={theme.colors.green.watercourse} />
            </LogoContainer>
            <DeviceContainer desktop tablet>
              <SocialAccounts align='left' />
            </DeviceContainer>
            <DeviceContainer mobile>
              <SocialAccounts align='center' />
            </DeviceContainer>
            <NeedHelp />
            <Choose>
              <When condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
                <LogoutContainer>
                  <Button variant='ghost' title=' LOG OUT' onClick={_handleLogOut} fullWidth />
                  <Paragraph>
                    Hi, {customerData.currentCustomer.firstname} <Link href='' onClick={_handleLogOut}>Not you?</Link>
                  </Paragraph>
                </LogoutContainer>
              </When>
              <Otherwise>
                <Button variant='ghost' title='LOGIN NOW' onClick={_handleLogIn} fullWidth />
              </Otherwise>
            </Choose>
            <DeviceContainer desktop tablet>
              <MobileFooterReviews />
            </DeviceContainer>
          </WidgetContainer>
          <NavigationAccordion>
            <IndexAccordion tabs={state.tabs} onTabsChange={_handleTabsChange}>
              <For each='linkGroup' of={links} index='linkGroupIndex'>
                <IndexSection key={linkGroupIndex} id={linkGroupIndex + ''} title={linkGroup.title}>
                  <For each='link' of={linkGroup.subs} index='linkIndex'>
                    {/* GIFT CARDS AND FAITHFUL POINTS LINKS NOT RENDERED FOR M2 APP LAUNCH */}
                    <If condition={link.title !== 'Gift Cards' && link.title !== 'Faithful Points'}>
                      <Button variant='action' title={link.title} href={link.link} key={`${linkGroupIndex}-${linkIndex}`} colors={colorChangerObject.colorColumnObject} className='link' />
                    </If>
                  </For>
                </IndexSection>
              </For>
            </IndexAccordion>
          </NavigationAccordion>
          <DeviceContainer mobile>
            <MobileFooterReviews />
          </DeviceContainer>
        </Content>
      </ContentContentWrapper>
      <BottomBarContentWrapper>
        <BottomBar>
          <PaymentIcons>
            <PaymentIcon>
              <Icon className='paymentMethods visa' color={theme.colors.white.romance} icon={LocalIconEnums.VISA} />
            </PaymentIcon>
            <PaymentIcon>
              <Icon className='paymentMethods mastercard' color={theme.colors.white.romance} icon={LocalIconEnums.MASTERCARD} />
            </PaymentIcon>
            <PaymentIcon>
              <Icon className='paymentMethods ozow' color={theme.colors.white.romance} icon={LocalIconEnums.OZOW} />
            </PaymentIcon>
            <PaymentIcon>
              <Icon className='paymentMethods peach' color={theme.colors.white.romance} icon={LocalIconEnums.PEACH_PAYMENTS} />
            </PaymentIcon>
            <PaymentIcon>
              <Icon className='paymentMethods ae' color={theme.colors.white.romance} icon={LocalIconEnums.AE} />
            </PaymentIcon>
          </PaymentIcons>
          <DeviceContainer desktop tablet>
            <BottomLinks>
              <Button variant='action' title='Terms & Conditions' href={'/terms-conditions'} colors={colorChangerObject.colorButtonObject} className='link' />
              <Seperator align='vertical' className='footer-link-bottom-seperator' />
              <Button variant='action' title='Privacy Policy' href={'/privacy-policy'} colors={colorChangerObject.colorButtonObject} className='link' />
              <Seperator align='vertical' className='footer-link-bottom-seperator' />
              <Button variant='action' title='PAIA Manual' href={'https://ftn-image.s3.eu-west-1.amazonaws.com/files/Company+PAIA+Manual_FTN_Webversion.pdf'} colors={colorChangerObject.colorButtonObject} className='link' />
            </BottomLinks>
          </DeviceContainer>
          <DeviceContainer mobile >
            <BottomLinks className='mobile-push'>
              <Button variant='action' title='Terms & Conditions' href={'/terms-conditions'} colors={colorChangerObject.colorButtonObject} className='link' />
              <Seperator align='vertical' className='footer-link-bottom-seperator' />
              <Button variant='action' title='Privacy Policy' href={'/privacy-policy'} colors={colorChangerObject.colorButtonObject} className='link' />
              <Seperator align='vertical' className='footer-link-bottom-seperator' />
              <Button variant='plain' title='PAIA Manual' href={'https://ftn-image.s3.eu-west-1.amazonaws.com/files/Company+PAIA+Manual_FTN_Webversion.pdf'} colors={colorChangerObject.colorButtonObject} className='link' />
            </BottomLinks>
          </DeviceContainer>
        </BottomBar>
      </BottomBarContentWrapper>
    </Container>
  )

}
