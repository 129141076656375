import React from 'react'

import { decode } from 'html-entities'
import styled, { useTheme } from 'styled-components'

import { Skeleton, SkeletonNode } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { BreadcrumbFragment, ProductDetailsFragment_SimpleProduct_ as SimpleDetails } from '@hooks/api/index'
import { BreadCrumb, BreadCrumbs } from '@molecules/index'
import { ProductShare } from '@organisms/index'
import { DeviceContainer } from '@utility/DeviceContainer'

type ProductDetailsBreadcrumb = SimpleDetails['categories'][0]['breadcrumbs'][0]

const MobileBreadCrumbContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    margin: 0 1.875vw;

    @media (min-width: 30em) {
      margin: 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
`

const BreadCrumbSeperator = styled.div`
  font-family: open-sans;
  font-weight: 400;
  height: auto;
  color: ${(props): string => props.theme.colors.grey.stormDust};
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
  
    margin-top: 0;

    @media (min-width: 30em) {
      margin-top: 0.938vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.208vw;
    }

    @media (min-width: 90em) {
      margin-top: 3px;
    }
  
`

export const ProductPageShareContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
  
    height: 15.000vw;

    @media (min-width: 30em) {
      height: 15.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.306vw;
    }

    @media (min-width: 90em) {
      height: 62px;
    }
  
  
    padding: 0 3.125vw 0 0;

    @media (min-width: 30em) {
      padding: 0 3.125vw 0 0;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0.694vw 0 0;
    }

    @media (min-width: 90em) {
      padding: 0 10px 0 0;
    }
  
`

export const ProductPageShareIconContainer = styled.div`
  
    width: 4.375vw;

    @media (min-width: 30em) {
      width: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.528vw;
    }

    @media (min-width: 90em) {
      width: 22px;
    }
  
  
    margin-top: 1.563vw;

    @media (min-width: 30em) {
      margin-top: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-top: 5px;
    }
  
  
    margin-left: 3.125vw;

    @media (min-width: 30em) {
      margin-left: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-left: 5px;
    }
  
  
    cursor: initial;

    @media (min-width: 30em) {
      cursor: pointer;
    }

    @media (min-width: 30.0625em) {
      cursor: pointer;
    }

    @media (min-width: 90em) {
      cursor: pointer;
    }
  

  .shareIcon path {
    stroke-width: 1px;
  }

  &:hover {
    .shareIcon path {
      fill: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
    .share-text {
      font-weight: 700;
      color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
  }
`

export interface ProductBreadCrumbProps {
  goBack: () => void
  categories?: SimpleDetails['categories']
  breadcrumbs?: BreadcrumbFragment[]
  productName: string
}

export function ProductBreadCrumbs({ goBack, productName, categories, breadcrumbs }: ProductBreadCrumbProps): JSX.Element {
  const theme = useTheme()

  if (!categories && !breadcrumbs) {
    return <>
      {/** BreadCrumb Share Mobile */}
      <DeviceContainer mobile>
        <Skeleton color='pampas' justify='space-between' height={{ mobile: '50px', tablet: '0', desktop: '0' }}>
          <Skeleton color='pampas' justify='flex-start' align='center' gap='8px'
            height={{ mobile: '50px', tablet: '0', desktop: '0' }}
            padding={{ mobile: '0 16px', tablet: '0', desktop: '0' }}>
            <SkeletonNode color='gallery'
              variWidth={{ mobile: '20%-30%', tablet: '0', desktop: '0' }}
              height={{ mobile: '10px', tablet: '0', desktop: '0' }}
            />
            <BreadCrumbSeperator>&gt;</BreadCrumbSeperator>
            <SkeletonNode color='gallery'
              variWidth={{ mobile: '20%-25%', tablet: '0', desktop: '0' }}
              height={{ mobile: '10px', tablet: '0', desktop: '0' }}
            />
            <BreadCrumbSeperator>&gt;</BreadCrumbSeperator>
            <SkeletonNode color='gallery'
              variWidth={{ mobile: '20%-30%', tablet: '0', desktop: '0' }}
              height={{ mobile: '10px', tablet: '0', desktop: '0' }}
            />
          </Skeleton>
          <ProductShare />
        </Skeleton>
      </DeviceContainer>

      {/** Breadcrumbs Desktop and tablet */}
      <DeviceContainer desktop tablet>
        <Skeleton color='pampas' justify='flex-start' align='center' gap='8px'
          height={{ mobile: '10px', tablet: '20px', desktop: '60px' }}>
          <SkeletonNode color='gallery'
            variWidth={{ mobile: '100%', tablet: '20%', desktop: '50px-150px' }}
            height={{ mobile: '10px', tablet: '20px', desktop: '20px' }}
          />
          <BreadCrumbSeperator>&gt;</BreadCrumbSeperator>
          <SkeletonNode color='gallery'
            variWidth={{ mobile: '100%', tablet: '20%', desktop: '50px-150px' }}
            height={{ mobile: '10px', tablet: '20px', desktop: '20px' }}
          />
          <BreadCrumbSeperator>&gt;</BreadCrumbSeperator>
          <SkeletonNode color='gallery'
            variWidth={{ mobile: '100%', tablet: '20%', desktop: '50px-150px' }}
            height={{ mobile: '10px', tablet: '20px', desktop: '20px' }}
          />
        </Skeleton>
      </DeviceContainer>
    </>
  }

  if (breadcrumbs) {
    let crumb: BreadcrumbFragment
    return (
      <>
        <DeviceContainer mobile>
          <MobileBreadCrumbContainer>
            <BreadCrumbs className='breadcrumbs'>
              <BreadCrumb title='Back' href='/' onClick={() => goBack()} />
              <For each='crumb' of={breadcrumbs || []}>
                <If condition={crumb.categoryName !== 'Categories'}>
                  <BreadCrumb title={crumb.categoryName} href={`/${crumb.categoryUrlPath}`} key={crumb.categoryId} />
                </If>
              </For>
            </BreadCrumbs>

            <ProductShare />
          </MobileBreadCrumbContainer>
        </DeviceContainer>

        <DeviceContainer desktop tablet>
          <BreadCrumbs className='breadcrumbs'>
            <BreadCrumb title='Home' href='/' />
            <For each='crumb' of={breadcrumbs || []}>
              <If condition={crumb.categoryName !== 'Categories'}>
                <BreadCrumb title={crumb.categoryName} href={`/${crumb.categoryUrlPath}`} key={crumb.categoryId} />
              </If>
            </For>
            <BreadCrumb title={decode(productName)} color={theme.colors.green.bottleGreen} href={false} />
          </BreadCrumbs>
        </DeviceContainer>
      </>

    )
  }

  let breadCrumbs: ProductDetailsBreadcrumb[]
  let breadCrumb: ProductDetailsBreadcrumb
  let breadCrumbIndex

  const categoriesWithBreadCrumbs = categories?.filter((c) => {
    return (c.categoryType.toLowerCase() === 'category' && c.breadcrumbs?.length > 0)
  })

  if (categoriesWithBreadCrumbs.length) {
    breadCrumbs = [...categoriesWithBreadCrumbs
      .reduce((catMax, cat) => catMax.breadcrumbs?.length > cat.breadcrumbs?.length ? catMax : cat)
      ?.breadcrumbs] as unknown as ProductDetailsBreadcrumb[]

    breadCrumbs?.sort((a: ProductDetailsBreadcrumb, b: ProductDetailsBreadcrumb) => { return a.categoryLevel - b.categoryLevel })
  }

  return (
    <>
      <DeviceContainer mobile>
        <MobileBreadCrumbContainer>
          <BreadCrumbs className='breadcrumbs'>
            <BreadCrumb title='Back' href='/' onClick={() => goBack()} />
            <For each='breadCrumb' of={breadCrumbs || []} index='breadCrumbIndex'>
              <If condition={breadCrumb.categoryName !== 'Categories'}>
                <BreadCrumb title={breadCrumb.categoryName} href={`/${breadCrumb.categoryUrlPath}`} key={breadCrumbIndex} />
              </If>
            </For>
          </BreadCrumbs>

          <ProductShare />
        </MobileBreadCrumbContainer>
      </DeviceContainer>

      <DeviceContainer desktop tablet>
        <BreadCrumbs className='breadcrumbs'>
          <BreadCrumb title='Home' href='/' />
          <For each='breadCrumb' of={breadCrumbs || []} index='breadCrumbIndex'>
            <If condition={breadCrumb.categoryName !== 'Categories'}>
              <BreadCrumb title={breadCrumb.categoryName} href={`/${breadCrumb.categoryUrlPath}`} key={breadCrumbIndex} />
            </If>
          </For>
          <BreadCrumb title={decode(productName)} color={theme.colors.green.bottleGreen} href={false} />
        </BreadCrumbs>
      </DeviceContainer>
    </>

  )

}
