import React, { ReactNode } from 'react'

import styled, { css } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type TitleVariant = 't1' | 't2' | 't3' | 't4' | 't5' | 't6' | 't7' | 't8' | 't9' | 't10'
export type TitleAlignment = 'left' | 'center' | 'right' | 'justify'
export type TitleTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type TitleDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const T = styled.div<{ align: string, decoration: string, transform: string, color?: string, variant: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};

  ${props => props.variant === 't1' && css`
    font-family: proxima-nova;
    font-weight: 600;
    
    font-size: 9.375vw;

    @media (min-width: 30em) {
      font-size: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 2.639vw;
    }

    @media (min-width: 90em) {
      font-size: 38px;
    }
  
    
    line-height: 9.375vw;

    @media (min-width: 30em) {
      line-height: 11.875vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.639vw;
    }

    @media (min-width: 90em) {
      line-height: 38px;
    }
  
  `}

  ${props => props.variant === 't2' && css`
    font-family: open-sans;
    font-weight: 700;
    
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.944vw;
    }

    @media (min-width: 90em) {
      font-size: 28px;
    }
  
    
    line-height: 6.875vw;

    @media (min-width: 30em) {
      line-height: 11.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.500vw;
    }

    @media (min-width: 90em) {
      line-height: 36px;
    }
  
  `}

  ${props => props.variant === 't3' && css`
    font-family: proxima-nova;
    font-weight: 600;
    
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.667vw;
    }

    @media (min-width: 90em) {
      font-size: 24px;
    }
  
    
    line-height: 6.875vw;

    @media (min-width: 30em) {
      line-height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.083vw;
    }

    @media (min-width: 90em) {
      line-height: 30px;
    }
  
  `}

  ${props => props.variant === 't4' && css`
    font-family: proxima-nova;
    font-weight: 600;
    
    font-size: 4.375vw;

    @media (min-width: 30em) {
      font-size: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.389vw;
    }

    @media (min-width: 90em) {
      font-size: 20px;
    }
  
    
    line-height: 6.250vw;

    @media (min-width: 30em) {
      line-height: 8.125vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.806vw;
    }

    @media (min-width: 90em) {
      line-height: 26px;
    }
  
  `}

  ${props => props.variant === 't5' && css`
    font-family: open-sans;
    font-weight: 700;
    
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.250vw;
    }

    @media (min-width: 90em) {
      font-size: 18px;
    }
  
    
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.667vw;
    }

    @media (min-width: 90em) {
      line-height: 24px;
    }
  
  `}

  ${props => props.variant === 't6' && css`
    font-family: proxima-nova;
    font-weight: 600;
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.250vw;
    }

    @media (min-width: 90em) {
      font-size: 18px;
    }
  
    
    line-height: 6.250vw;

    @media (min-width: 30em) {
      line-height: 6.875vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
  `}

  ${props => props.variant === 't7' && css`
    font-family: proxima-nova;
    font-weight: 600;
    
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.111vw;
    }

    @media (min-width: 90em) {
      font-size: 16px;
    }
  
    
    line-height: 5.000vw;

    @media (min-width: 30em) {
      line-height: 6.875vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
  `}

  ${props => props.variant === 't8' && css`
    font-family: open-sans;
    font-weight: 600;
    
    font-size: 3.438vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
    
    line-height: 4.375vw;

    @media (min-width: 30em) {
      line-height: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
  
  `}

  ${props => props.variant === 't9' && css`
    font-family: open-sans;
    font-weight: 700;
    
    font-size: 5.625vw;

    @media (min-width: 30em) {
      font-size: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 2.222vw;
    }

    @media (min-width: 90em) {
      font-size: 32px;
    }
  
    
    line-height: 7.500vw;

    @media (min-width: 30em) {
      line-height: 11.875vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.639vw;
    }

    @media (min-width: 90em) {
      line-height: 38px;
    }
  
  `}

  ${props => props.variant === 't10' && css`
    font-family: open-sans;
    font-weight: 600;
    
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
    
    line-height: 4.375vw;

    @media (min-width: 30em) {
      line-height: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.111vw;
    }

    @media (min-width: 90em) {
      line-height: 16px;
    }
  
  `}
`
export interface TitleProps {
  children: string | ReactNode | JSX.Element
  variant?: TitleVariant
  align?: TitleAlignment
  transform?: TitleTransforms
  decoration?: TitleDecorations
  color?: string
  skeleton?: boolean
  className?: string
}

export function Title({ children, variant = 't1', align = 'left', transform = 'none', decoration = 'none', color, className }: TitleProps): JSX.Element {

  return <T align={align} variant={variant} decoration={decoration} transform={transform} color={color} className={className}>{children}</T>

}
